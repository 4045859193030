<form [formGroup]="addingForm" (ngSubmit)="submitForm()">
  <div class="container pb-2">
    <div class="row">
      <div class="flex-container">
        <h2 mat-dialog-title style="font-size: 24px; font-weight: lighter;">{{ 'tournaments.manageTournaments.addTournamentDialog.title' | transloco}}</h2>
        <span class="spacer"></span>
      </div>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="container" style="overflow-y: hidden">
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline" style="font-size: 16px; width: 100%;">
          <mat-label>{{ 'tournaments.manageTournaments.addTournamentDialog.tournamentName' | transloco}}</mat-label>
          <input matInput formControlName="tournamentName" [value]="" style="font-size: 16px">
          <mat-error *ngIf="addingForm.get('tournamentName')!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Tournament name' } }}</mat-error>
          <mat-error *ngIf="addingForm.get('tournamentName')!.errors?.minlength">{{ 'errorMessages.minlength' | transloco: { name: 'Tournament name' } }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
          <mat-label>{{ 'tournaments.manageTournaments.addTournamentDialog.tournamentCurrency' | transloco }}</mat-label>
          <mat-select formControlName="currency">
            <mat-option [value]="currency" *ngFor="let currency of currencies">
              {{currency.CurrencyID}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="addingForm.get('currency')!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Tournament currency' } }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <app-date-picker [placeholder]="'From date'" (date)="setStartDate($event)" [init]="addingForm.get('fromDate')?.value" [max-width]="195" [appearance]="'outline'" class="w-50"></app-date-picker>
        <app-time-picker-input [placeholder]="'From time'" (timeEmitter)="setStartTime($event)" [appearance]="'outline'" [max-width]="225" [default]="'00:00'" class="w-50 d-flex justify-content-end"></app-time-picker-input>
      </div>
      <div class="row my-0 justify-content-center">
        <app-date-picker [placeholder]="'To date'" (date)="setEndDate($event)" [init]="addingForm.get('toDate')?.value" [max-width]="195" [appearance]="'outline'" class="w-50"></app-date-picker>
        <app-time-picker-input [placeholder]="'To time'" (timeEmitter)="setEndTime($event)" [appearance]="'outline'" [max-width]="225" [default]="'23:59'" class="w-50 d-flex justify-content-end"></app-time-picker-input>
      </div>
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
          <mat-label>{{ 'tournaments.manageTournaments.addTournamentDialog.tournamentType' | transloco }}</mat-label>
          <mat-select formControlName="tournamentType">
            <mat-option [value]="type" *ngFor="let type of tournamentTypes">
              {{type}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="addingForm.get('tournamentType')!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Tournament type' } }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline" style="font-size: 16px; width: 50%;">
          <mat-label>{{ 'tournaments.manageTournaments.addTournamentDialog.tournamentMinBet' | transloco }}</mat-label>
          <input matInput type="number" min="0" formControlName="minBet">
          <mat-error *ngIf="addingForm.get('minBet')!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Tournament min bet value' } }}</mat-error>
          <mat-error *ngIf="addingForm.get('minBet')!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Tournament min bet value' } }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" style="font-size: 16px; width: 50%;" class="small-form-field">
          <mat-label>{{ 'tournaments.manageTournaments.addTournamentDialog.tournamentShowBefore' | transloco }}</mat-label>
          <mat-select formControlName="showBefore">
            <mat-option [value]="hours.value" *ngFor="let hours of hoursBefore">
              {{hours.text}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="addingForm.get('showBefore')!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Tournament show hours before start value' } }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline" style="font-size: 16px; width: 100%;">
          <mat-label>{{ 'tournaments.manageTournaments.addTournamentDialog.tournamentDescription' | transloco}}</mat-label>
          <input matInput formControlName="tournamentDescription" [value]="" style="font-size: 16px">
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="container my-2 pe-2">
    <div mat-dialog-actions class="d-flex flex-row-reverse w-100">
      <button class="px-4 ms-2" type="submit" mat-flat-button color="primary" [disabled]="!addingForm.valid">{{ 'buttons.saveButton' | transloco }}</button>
      <button class="px-3" type="button" mat-stroked-button color="accent" (click)="onNoClick()">{{ 'buttons.cancelButton' | transloco }}</button>
    </div>
  </div>
</form>
