import {ReportColumns} from "../report-columns.interface";

export class ExchangeRate {

  exchangeDateTime: string
  exchangeRate: number

  columnInfo: ReportColumns[]

  constructor(exchangeDateTime: string, exchangeRate: number) {
    this.exchangeDateTime = exchangeDateTime;
    this.exchangeRate = exchangeRate;

    this.columnInfo = [
      {key: 'Exchange date time', info: {field: 'exchangeDateTime', total: this.exchangeDateTime, format: this.formatString(this.exchangeDateTime), align: 'normal'}},
      {key: 'Exchange rate', info: {field: 'exchangeRate', total: this.exchangeRate, format: this.formatRate(this.exchangeRate), align: 'normal'}}
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatString(string: string): string {
    return string;
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }

  public formatRate(rate: number): any {
    return rate.toFixed(4);
  }
}
