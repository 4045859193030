<h2 class="component-title">{{'reports.exchangeRatesReport.title' | transloco }}</h2>
<hr>

<div class="frame round-border">
  <div class="row d-flex align-items-center pb-3">
    <div class="ps-3 pe-0 w-auto">
      <app-date-picker [placeholder]="'Start date'" (date)="setStartDate($event)" [init]="startDate"></app-date-picker>
    </div>
    <div class="ps-3 pe-0 w-auto">
      <app-date-picker [placeholder]="'End date'" (date)="setEndDate($event)" [init]="endDate"></app-date-picker>
    </div>
    <div class="ps-3 pe-0 w-auto">
      <mat-form-field appearance="standard" class="select-input" style="font-size: 16px">
        <mat-label>Currency</mat-label>
        <mat-select [(value)]="currency">
          <mat-option [value]="null">{{'buttons.currencyButton' | transloco }}</mat-option>
          <mat-option [value]="curr" *ngFor="let curr of currencies">
            {{curr.CurrencyID}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ps-3 pe-0 w-auto mb-2">
      <button mat-stroked-button color="primary" (click)="refreshData()">
        <!--        <mat-icon>refresh</mat-icon>-->
        {{ 'buttons.refreshDataButton' | transloco }}
      </button>
    </div>
    <div class="ps-3 pe-3 w-auto mb-2">
      <button mat-stroked-button color="accent" (click)="downloadExcel()">
        <mat-icon>file_download</mat-icon>
        {{ 'buttons.excelButton' | transloco }}
      </button>
    </div>
  </div>
  <div class="max-height-table">
    <table mat-table [dataSource]="dataSource" class="mt-0" matSort [hidden]="!showTable">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
        <th mat-header-cell
            class="header-cell"
            *matHeaderCellDef
            mat-sort-header="{{headerCell(column)}}"
            arrowPosition="after"
            [ngStyle]="{'text-align': alignHeaderCell(column)}"
            [class.header-end]="alignHeaderCell(column) === 'end'"
            [class.header-start]="alignHeaderCell(column) === 'normal'">
          {{ translationColumns[i] | transloco }} </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'text-align': element.align(column)}"> {{element.format(column)}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; let i = index; columns: columns;"
          [ngClass]="{alternating: i % 2 === 0}"></tr>
    </table>
  </div>
</div>

