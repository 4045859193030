import {Component, Inject, OnInit, Pipe, PipeTransform} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-game-info',
  templateUrl: './game-info.component.html',
  styleUrls: ['./game-info.component.scss']
})
export class GameInfoComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<GameInfoComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string) {
  }

  ngOnInit(): void {
  }

}
