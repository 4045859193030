import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ConfigurationsService} from "../../../services/configurations/configurations.service";
import {CompanySimple} from "../../../models/configurations/company-simple.interface";

@Component({
  selector: 'app-company-message-dialog',
  templateUrl: './company-message-dialog.component.html',
  styleUrls: ['./company-message-dialog.component.scss']
})
export class CompanyMessageDialogComponent implements OnInit {

  public form: FormGroup;
  public companies: CompanySimple[] = [];

  constructor(private dialogRef: MatDialogRef<CompanyMessageDialogComponent>,
              private formBuilder: FormBuilder,
              private configurationsService: ConfigurationsService,
              @Inject(MAT_DIALOG_DATA) public input: string) {
    configurationsService.getSimpleCompanies().subscribe(value => {
      this.companies.push({CompanyID: 0, CompanyName: 'All companies'})
      this.companies.push(...value);
    })

    this.form = this.formBuilder.group({
      company: [null, Validators.required],
      message: [input, [Validators.required, Validators.minLength(1)]]
    });
  }

  ngOnInit(): void {
  }

  public submitForm() {
    if (this.form.invalid) {
      return;
    }

    const company = this.company?.value.CompanyID;
    const message = this.message?.value;
    this.dialogRef.close({company: company, message: message});
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public get company() {
    return this.form.get('company');
  }

  public get message() {
    return this.form.get('message');
  }

}
