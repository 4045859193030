import {Version} from "../app/models/utils/version.interface";

export const changelog: Version[] = [
    { version: "1.4.6.", changes: "Added option to sort configuration tables and enabled chat room management for everyone." },
    { version: "1.4.7.", changes: "Changed background in detail (child) tables." },
    { version: "1.4.8.", changes: "Added option to translate chat room messages and view release history." },
    { version: "1.4.9.", changes: "Bugs and fixes." },
    { version: "1.5.0.", changes: "Efficiency improvements, code refactoring." },
    { version: "1.5.1.", changes: "Fixed exchange rate report date time column format." },
    { version: "1.5.2.", changes: "Fixed company configurations table bug and made general improvements." },
    { version: "1.5.3.", changes: "Game report is now Round report and it's divided into games." },
    { version: "1.5.4.", changes: "Bugs and fixes." },
    { version: "1.5.5.", changes: "Added chat room online count and sorting features." },
    { version: "1.5.6.", changes: "Changed datepicker formats to be identical across the platform." },
    { version: "1.5.7.", changes: "Fixed minor styling issues." },
    { version: "1.5.8.", changes: "General improvements." },
    { version: "1.6.0.", changes: "Efficiency improvements, code refactoring." },
    { version: "1.6.1.", changes: "Added chat room message levels and option to change chat username." },
    { version: "1.6.2.", changes: "General improvements." },
    { version: "1.6.3.", changes: "Added new columns in round report, fixed company configuration table columns, chat features, bugs and fixes." },
    { version: "1.6.4.", changes: "Bugs and fixes." },
    { version: "1.6.5.", changes: "Added new time field." },
    { version: "1.6.6.", changes: "Fixed couple of issues with tournaments." },
    { version: "1.6.7.", changes: "Bugs and fixes." },
    { version: "1.6.8.", changes: "Player audit changes." },
    { version: "1.6.9.", changes: "Added a new report, Promo player report." },
    { version: "1.7.0.", changes: "Added Fast balls game to currency configuration." },
    { version: "1.7.1.", changes: "Added Fast balls to player audit search." },
    { version: "1.7.2.", changes: "Added a new field in company configurations." },
    { version: "1.7.3.", changes: "Added a new feature to tournaments. Tournaments will now be shown N hours before the start" },
    { version: "1.7.4.", changes: "Added a new way to see game information in Player audit" },
    { version: "1.7.5.", changes: "Added new tournament type" },
    { version: "1.7.6.", changes: "Added new reports under 'Bonusing' folder in navigation tree" },
    { version: "1.7.7.", changes: "Added a way to display past tournaments" },
    { version: "1.7.8.", changes: "Changed a behavior of start and end dates" },
]

