import {Window} from "./windows/window.interface";
import {RoleState} from "./roles/role-state.enum";
import {Routes} from "./utils/routes.enum";

export class NavigationItem {

  private readonly displayName: string;
  private readonly route: Routes;
  private readonly iconName: string;
  private readonly window: Window;
  private readonly mobile: boolean;
  private readonly beta: boolean;
  private readonly betaInfo: string;
  private readonly children: NavigationItem[];

  constructor(
      displayName: string,
      route: Routes,
      iconName: string,
      window: Window,
      mobile: boolean,
      children: NavigationItem[],
      beta?: boolean,
      betaInfo?: string) {
    this.displayName = displayName;
    this.route = route;
    this.iconName = iconName;
    this.window = window;
    this.children = children;
    this.mobile = mobile;
    this.beta = !!beta;
    this.betaInfo = betaInfo ? betaInfo : '';
  }

  public getDisplayName(): string {
    return this.displayName;
  }

  public getRoute(): Routes {
    return this.route;
  }

  public getIconName(): string {
    return this.iconName;
  }

  public getWindow(): Window {
    return this.window;
  }

  public getMobile(): boolean {
    return this.mobile;
  }

  public getBeta(): boolean {
    return this.beta;
  }

  public getBetaInfo(): string {
    return this.betaInfo;
  }

  public getChildren(): NavigationItem[] {
    return this.children;
  }

}
