import {Component, OnInit, ViewChild} from '@angular/core';
import {DashboardService} from "../../../services/dashboard/dashboard.service";

import {
  ApexChart,
  ChartComponent,
  ApexAxisChartSeries,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexResponsive,
  ApexLegend, ApexTheme, ApexTooltip
} from 'ng-apexcharts';
import {UnmappedDashboard5Data} from "../../../models/dashboard/unmapped/unmapped-dashboard5-data.interface";
import {LoadingService} from "../../../services/loading/loading.service";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {ThemeService} from "../../../services/theme/theme.service";
import {UtilsService} from "../../../services/utils/utils.service";

@Component({
  selector: 'app-dashboard-chart5',
  templateUrl: './dashboard-chart5.component.html',
  styleUrls: ['./dashboard-chart5.component.scss']
})
export class DashboardChart5Component implements OnInit {

  public showChart!: Promise<boolean>;
  public date: string = '';
  public data: XY[] = []

  @ViewChild('areaChart') chart!: ChartComponent;
  public chartOptions!: ChartOptions;

  constructor(private dashboardService: DashboardService,
              private handlingService: HandlingService,
              public loadingService: LoadingService,
              private themeService: ThemeService,
              private utilsService: UtilsService) {

    themeService.themeObs.subscribe(newTheme => {
      if (this.chartOptions){
        if (newTheme !== 'light'){
          this.chartOptions.theme = themeService.getDarkTheme();
          this.chartOptions.tooltip = themeService.getDarkTooltip();
          this.chartOptions.xaxis.labels!.style!.colors = themeService.getDarkText();
          this.chartOptions.yaxis.labels!.style!.colors = themeService.getDarkText();
        }else{
          this.chartOptions.theme = themeService.getLightTheme();
          this.chartOptions.tooltip = themeService.getLightTooltip();
          this.chartOptions.xaxis.labels!.style!.colors = themeService.getLightText();
          this.chartOptions.yaxis.labels!.style!.colors = themeService.getLightText();
        }
      }
    })
  }

  ngOnInit(): void {
    this.dashboardService.getChart5Data().subscribe(newData => {
      this.date = this.utilsService.getTodaysDate();
      this.data = this.mapData(newData);
      this.chartOptions = this.initOptions();
      this.showChart = Promise.resolve(true);
    },error => {
      this.handlingService.error('Trouble fetching data for dashboard 5', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    });
  }

  private initOptions(): ChartOptions {
    return {
      series: [
        {
          name: 'Bets',
          data: this.data
        }
      ],
      chart: {
        type: 'area',
        height: 300,
        width: '100%',
        zoom: {
          enabled: true
        },
        animations: {
          enabled: false
        },
        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: false,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      fill: {
        type: 'gradient'
      },
      title: {
        text: "",
        align: 'left'
      },
      subtitle: {
        text: "",
        align: 'left'
      },
      labels: [],
      tooltip: this.themeService.getDefaultTooltip(),
      xaxis: {
        tickPlacement: 'on',
        tickAmount: 6,
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          showDuplicates: false,
          style: {
            colors: this.themeService.getDefaultText()
          }
        }
      },
      theme: this.themeService.getDefaultTheme(),
      yaxis: {
        opposite: false,
        labels: {
          style: {
            colors: this.themeService.getDefaultText()
          }
        }
      },
      legend: {
        horizontalAlign: 'left'
      },
      responsive: [

      ]
    };
  }

  private mapData(unmapped: UnmappedDashboard5Data[]): XY[]{
    let data: XY[] = []
    unmapped.forEach(item => {
      data.push({x: item.HourTime + ':00', y: item.Bets})
    })
    return data;
  }

}

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  tooltip: ApexTooltip;
  xaxis: ApexXAxis;
  theme: ApexTheme;
  stroke: ApexStroke;
  fill: any;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
  responsive: ApexResponsive[];
};

export interface XY{
  x: string
  y: number
}
