<div (click)="itemClicked(item)" class="nav-item" matRipple [matRippleCentered]="matRippleCentered" [matRippleUnbounded]="matRippleUnbounded">
  <div class="d-flex flex-row-reverse">
    <mat-icon class="online_icon">circle</mat-icon>
    <span class="online_text">{{item.online}} Online</span>
  </div>
  <div class="row d-flex align-items-center justify-content-between mx-0">
    <div class="w-auto mt-1">
      <p class="pb-0 mb-0">{{ 'chatRooms.chatRoomRoom' | transloco }}</p>
      <p class="card-title overflow-title-text">{{item.name}}</p>
    </div>
    <mat-icon>forum</mat-icon>
  </div>
</div>
