import {ReportColumns} from "../reports/report-columns.interface";
import {ColumnMetaData} from "../utils/column-meta-data.interface";

export class PlayerAudit {
  roundId: number
  roundOpen: string
  roundClose: string
  roundMultiplierTime: string
  roundEnd: string
  roundMultiplier: number
  roundNumbers: string
  playerId: number
  username: string
  ticketId: number
  platformExtID: number
  createdTime: string
  ticketStatus: string
  ticketType: string
  bet: number
  ticketMultiplier: number
  numbers: string
  numbersHit: string
  autoCashout: number
  win: number
  externalId: string

  columnInfo: ReportColumns[]
  columnData: ColumnMetaData[]

  constructor(roundId: number,
              roundOpen: string,
              roundClose: string,
              roundMultiplierTime: string,
              roundEnd: string,
              roundMultiplier: number,
              roundNumbers: string,
              playerId: number,
              username: string,
              ticketId: number,
              platformExtID: number,
              createdTime: string,
              ticketStatus: string,
              ticketType: string,
              bet: number,
              ticketMultiplier: number,
              numbers: string,
              numbersHit: string,
              autoCashout: number,
              win: number,
              externalId: string) {
    this.roundId = roundId;
    this.roundOpen = roundOpen;
    this.roundClose = roundClose;
    this.roundMultiplierTime = roundMultiplierTime;
    this.roundEnd = roundEnd;
    this.roundMultiplier = roundMultiplier;
    this.roundNumbers = roundNumbers;
    this.playerId = playerId;
    this.username = username;
    this.ticketId = ticketId;
    this.platformExtID = platformExtID;
    this.createdTime = createdTime;
    this.ticketStatus = ticketStatus;
    this.ticketType = ticketType;
    this.bet = bet;
    this.ticketMultiplier = ticketMultiplier;
    this.numbers = numbers;
    this.numbersHit = numbersHit;
    this.autoCashout = autoCashout;
    this.win = win;
    this.externalId = externalId;

    this.columnInfo = [
      {key: 'Round ID', info: {field: 'roundID', total: this.roundId, format: this.roundId, align: 'normal'}},
      {key: 'Round opened', info: {field: 'roundOpen', total: this.roundOpen, format: this.formatString(this.roundOpen), align: 'center'}},
      {key: 'Round closed', info: {field: 'roundClose', total: this.roundClose, format: this.formatString(this.roundClose), align: 'center'}},
      {key: 'Round multiplier time', info: {field: 'roundMultiplierTime', total: this.roundMultiplierTime, format: this.formatString(this.roundMultiplierTime), align: 'center'}},
      {key: 'Round multiplier', info: {field: 'roundMultiplier', total: this.roundMultiplier, format: this.formatMoney(this.roundMultiplier), align: 'end'}},
      {key: 'Player ID', info: {field: 'playerId', total: this.playerId, format: this.playerId, align: 'center'}},
      {key: 'Username', info: {field: 'username', total: this.username, format: this.formatString(this.username), align: 'center'}},
      {key: 'Ticket ID', info: {field: 'ticketId', total: this.ticketId, format: this.ticketId, align: 'center'}},
      {key: 'Platform ExtID', info: {field: 'platformExtID', total: this.platformExtID, format: this.platformExtID, align: 'center'}},
      {key: 'Created time', info: {field: 'createdTime', total: this.createdTime, format: this.formatString(this.createdTime), align: 'center'}},
      {key: 'Ticket status', info: {field: 'ticketStatus', total: this.ticketStatus, format: this.formatString(this.ticketStatus), align: 'center'}},
      {key: 'Ticket type', info: {field: 'ticketType', total: this.ticketType, format: this.formatString(this.ticketType), align: 'center'}},
      {key: 'Bet', info: {field: 'bet', total: this.bet, format: this.formatMoney(this.bet), align: 'end'}},
      {key: 'Ticket multiplier', info: {field: 'ticketMultiplier', total: this.ticketMultiplier, format: this.formatMoney(this.ticketMultiplier), align: 'end'}},
      {key: 'Auto cashout', info: {field: 'autoCashout', total: this.autoCashout, format: this.formatMoney(this.autoCashout), align: 'end'}},
      {key: 'Win', info: {field: 'win', total: this.win, format: this.formatMoney(this.win), align: 'end'}},
      {key: 'External ID', info: {field: 'externalId', total: this.externalId, format: this.formatString(this.externalId), align: 'normal'}},
    ];

    this.columnData = [
      {key: 'round-id', column: 'playerAudit.columns.roundId', field: 'roundId', total: 0, format: this.roundId, align: 'normal'},
      {key: 'round-open', column: 'playerAudit.columns.roundOpen', field: 'roundOpen', total: 0, format: this.formatString(this.roundOpen), align: 'center'},
      {key: 'round-close', column: 'playerAudit.columns.roundClose', field: 'roundClose', total: 0, format: this.formatString(this.roundClose), align: 'center'},
      {key: 'round-multiplier-time', column: 'playerAudit.columns.roundMultiplierTime', field: 'roundMultiplierTime', total: 0, format: this.formatString(this.roundMultiplierTime), align: 'center', show: this.showIfRocketman},
      {key: 'round-end', column: 'playerAudit.columns.roundEnd', field: 'roundEnd', total: 0, format: this.formatString(this.roundEnd), align: 'center', show: this.showIfFastballs},
      {key: 'round-multiplier', column: 'playerAudit.columns.roundMultiplier', field: 'roundMultiplier', total: this.roundMultiplier, format: this.formatMoney(this.roundMultiplier), align: 'end', show: this.showIfRocketman},
      {key: 'round-numbers', column: 'playerAudit.columns.roundNumbers', field: 'roundNumbers', total: 0, format: this.formatString(this.roundNumbers), align: 'center', show: this.showIfFastballs},
      {key: 'player-id', column: 'playerAudit.columns.playerId', field: 'playerId', total: 0, format: this.playerId, align: 'center'},
      {key: 'username', column: 'playerAudit.columns.username', field: 'username', total: 0, format: this.formatString(this.username), align: 'normal'},
      {key: 'ticket-id', column: 'playerAudit.columns.ticketId', field: 'ticketId', total: 0, format: this.ticketId, align: 'center'},
      {key: 'platform-external-id', column: 'playerAudit.columns.platformExtId', field: 'platformExtID', total: 0, format: this.platformExtID, align: 'normal'},
      {key: 'created-time', column: 'playerAudit.columns.createdTime', field: 'createdTime', total: 0, format: this.formatString(this.createdTime), align: 'center'},
      {key: 'ticket-status', column: 'playerAudit.columns.ticketStatus', field: 'ticketStatus', total: 0, format: this.formatString(this.ticketStatus), align: 'center'},
      {key: 'ticket-type', column: 'playerAudit.columns.ticketType', field: 'ticketType', total: 0, format: this.formatString(this.ticketType), align: 'center'},
      {key: 'bet', column: 'playerAudit.columns.bet', field: 'bet', total: this.bet, format: this.formatMoney(this.bet), align: 'end'},
      {key: 'ticket-multiplier', column: 'playerAudit.columns.ticketMultiplier', field: 'ticketMultiplier', total: this.ticketMultiplier, format: this.formatMoney(this.ticketMultiplier), align: 'end', show: this.showIfRocketman},
      {key: 'numbers', column: 'playerAudit.columns.numbers', field: 'numbers', total: 0, format: this.formatString(this.numbers), align: 'center', show: this.showIfFastballs},
      {key: 'numbers-hit', column: 'playerAudit.columns.numbersHit', field: 'numbersHit', total: 0, format: this.formatString(this.numbersHit), align: 'center', show: this.showIfFastballs},
      {key: 'auto-cashout', column: 'playerAudit.columns.autoCashout', field: 'autoCashout', total: this.autoCashout, format: this.formatMoney(this.autoCashout), align: 'end', show: this.showIfRocketman},
      {key: 'win', column: 'playerAudit.columns.win', field: 'win', total: this.win, format: this.formatMoney(this.win), align: 'end'},
      {key: 'external-id', column: 'playerAudit.columns.externalId', field: 'externalId', total: 0, format: this.formatString(this.externalId), align: 'end'},
      {key: 'game-info', column: 'playerAudit.columns.gameInfo', field: 'externalId', total: 0, format: this.formatString(this.externalId), align: 'end'},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public total(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.total;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatString(string: string): any {
    if (string === null){
      return '';
    }
    return string;
  }

  public formatInteger(integer: number): any {
    if (integer === null){
      return '';
    }
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    if (money === null){
      return '';
    }
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }

  public showIfRocketman(gameId: number): boolean {
    return gameId === 101;
  }

  public showIfFastballs(gameId: number): boolean {
    return gameId === 102;
  }
}
