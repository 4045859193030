import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfigJackpot} from "../../../../../models/configurations/company-config/jackpot/config-jackpot.model";

@Component({
  selector: 'app-edit-jackpot-dialog',
  templateUrl: './edit-jackpot-dialog.component.html',
  styleUrls: ['./edit-jackpot-dialog.component.scss']
})
export class EditJackpotDialogComponent implements OnInit {

  public editingForm: FormGroup;
  public copy: ConfigJackpot;

  constructor(private dialogRef: MatDialogRef<EditJackpotDialogComponent>,
              private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public jackpot: ConfigJackpot) {
    this.editingForm = this.formBuilder.group({
      jackPotPercentage: [jackpot.jackPotPercentage, [Validators.required, Validators.min(0)]],
      jackPotReservePercentage: [jackpot.jackPotReservePercentage, [Validators.required, Validators.min(0)]],
      jackPotMinValue: [jackpot.jackPotMinValue, [Validators.required, Validators.min(0)]],
      jackPotMaxValue: [jackpot.jackPotMaxValue, [Validators.required, Validators.min(0)]],
      jackPotActive: [jackpot.jackPotActive, Validators.required]
    })
    this.copy = this.deepCopyJackpot(this.jackpot);
  }

  ngOnInit(): void {
  }

  public submitForm(): void {
    if (this.editingForm.invalid){ return; }
    this.copy.jackPotPercentage = this.editingForm.get('jackPotPercentage')?.value;
    this.copy.jackPotReservePercentage = this.editingForm.get('jackPotReservePercentage')?.value;
    this.copy.jackPotMinValue = this.editingForm.get('jackPotMinValue')?.value;
    this.copy.jackPotMaxValue = this.editingForm.get('jackPotMaxValue')?.value;
    this.copy.jackPotActive = this.editingForm.get('jackPotActive')?.value;
    this.dialogRef.close({data: this.copy});
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public get jackPotPercentage(){
    return this.editingForm.get('jackPotPercentage');
  }
  public get jackPotReservePercentage(){
    return this.editingForm.get('jackPotReservePercentage');
  }
  public get jackPotMinValue(){
    return this.editingForm.get('jackPotMinValue');
  }
  public get jackPotMaxValue(){
    return this.editingForm.get('jackPotMaxValue');
  }
  public get jackPotActive(){
    return this.editingForm.get('jackPotActive');
  }

  private deepCopyJackpot(jackpot: ConfigJackpot): ConfigJackpot {
    return new ConfigJackpot(jackpot.id,
      jackpot.companyName,
      jackpot.levelID,
      jackpot.jackPotName,
      jackpot.jackPotPercentage,
      jackpot.jackPotReservePercentage,
      jackpot.jackPotMinValue,
      jackpot.jackPotMaxValue,
      jackpot.jackPotActive)
  }

}
