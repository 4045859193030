import {TimeOffset} from "../time-offset.interface";
import {ReportColumns} from "../../../reports/report-columns.interface";

export class ConfigCompany {
  id: number
  companyName: string
  platformName: string
  timeOffset: TimeOffset
  useDST: boolean
  nonBillingPeriod: string
  platformID: number
  extData: string
  customization: string
  chatGroupID: number
  active: boolean
  billingType: number
  billingPerc: number
  eomfx: boolean
  useJackpotL1: boolean
  isolated: boolean
  delUnusedPromo: number
  delUnusedFreeTickets: number
  fixedCurrency: boolean
  description: string
  promoWinToBonus: boolean
  payoutDescription: string

  adminUsername: string = ''
  adminEmail: string = ''

  columnInfo: ReportColumns[]

  constructor(id: number,
              companyName: string,
              platformName: string,
              timeOffset: TimeOffset,
              useDST: boolean,
              nonBillingPeriod: string,
              platformID: number,
              extData: string,
              customization: string,
              chatGroupID: number,
              active: boolean,
              billingType: number,
              billingPerc: number,
              eomfx: boolean,
              useJackpotL1: boolean,
              isolated: boolean,
              delUnusedPromo: number,
              delUnusedFreeTickets: number,
              fixedCurrency: boolean,
              description: string,
              promoWinToBonus: boolean,
              adminUsername?: string,
              adminEmail?: string,
              payoutDescription?: string) {
    this.id = id;
    this.companyName = companyName;
    this.platformName = platformName;
    this.timeOffset = timeOffset;
    this.useDST = useDST;
    this.nonBillingPeriod = nonBillingPeriod;
    this.platformID = platformID;
    this.extData = extData;
    this.customization = customization;
    this.chatGroupID = chatGroupID;
    this.active = active;
    this.billingType = billingType;
    this.billingPerc = billingPerc;
    this.eomfx = eomfx;
    this.useJackpotL1 = useJackpotL1;
    this.isolated = isolated;
    this.delUnusedPromo = delUnusedPromo;
    this.delUnusedFreeTickets = delUnusedFreeTickets;
    this.fixedCurrency = fixedCurrency;
    this.description = description;
    this.promoWinToBonus = promoWinToBonus;
    this.payoutDescription = payoutDescription ? payoutDescription : '';

    if(adminUsername){ this.adminUsername = adminUsername; }
    if(adminEmail){ this.adminEmail = adminEmail; }

    this.columnInfo = [
      {key: 'ID', info: {field: 'id', total: 0, format: this.id, align: 'normal'}},
      {key: 'Name', info: {field: 'companyName', total: 0, format: this.companyName, align: 'normal'}},
      {key: 'Platform name', info: {field: 'platformName', total: 0, format: this.platformName, align: 'normal'}},
      {key: 'Time offset', info: {field: 'timeOffset', total: 0, format: this.timeOffset.code, align: 'end'}},
      {key: 'Use DST', info: {field: 'useDST', total: 0, format: this.useDST, align: 'end'}},
      {key: 'Non billing period', info: {field: 'nonBillingPeriod', total: 0, format: this.nonBillingPeriod, align: 'end'}},
      {key: 'Active', info: {field: 'active', total: 0, format: this.active, align: 'end'}},
      {key: 'Billing type', info: {field: 'billingType', total: 0, format: this.billingType, align: 'end'}},
      {key: 'Billing percentage', info: {field: 'billingPerc', total: 0, format: this.billingPerc, align: 'end'}},
      {key: 'EOMFX', info: {field: 'eomfx', total: 0, format: this.eomfx, align: 'end'}},
      {key: 'Use jackpot L1', info: {field: 'useJackpotL1', total: 0, format: this.useJackpotL1, align: 'end'}},
      {key: 'Isolated', info: {field: 'isolated', total: 0, format: this.isolated, align: 'end'}},
      {key: 'Del promo bonus after', info: {field: 'delUnusedPromo', total: 0, format: this.delUnusedPromo, align: 'end'}},
      {key: 'Del free tickets bonus after', info: {field: 'delUnusedFreeTickets', total: 0, format: this.delUnusedFreeTickets, align: 'end'}},
      {key: 'Fixed currency', info: {field: 'fixedCurrency', total: 0, format: this.fixedCurrency, align: 'end'}},
      {key: 'Short description', info: {field: 'description', total: 0, format: this.description, align: 'end'}},
      {key: 'Use extra bonus', info: {field: 'promoWinToBonus', total: 0, format: this.promoWinToBonus, align: 'end'}},
      {key: 'Fast balls payout table', info: {field: 'payoutDescription', total: 0, format: this.payoutDescription, align: 'end'}},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }
}
