import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {ReportsService} from "../../../services/reports/reports.service";
import {Currency} from "../../../models/reports/exchange-rates/currency.model";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {UtilsService} from "../../../services/utils/utils.service";
import {Tournament} from "../../../models/tournaments/tournament.model";
import * as moment from "moment";
import {TournamentsService} from "../../../services/tournaments/tournaments.service";

const startTime = '00:00';
const endTime = '23:59';

@Component({
  selector: 'app-add-tournament',
  templateUrl: './add-tournament.component.html',
  styleUrls: ['./add-tournament.component.scss']
})
export class AddTournamentComponent implements OnInit {

  public addingForm: FormGroup;
  public currencies: Currency[] = [];

  public currency: Currency | null = null;
  public displayCurrency: string = 'Currency'

  public tournamentTypes: string[] = [
    '1 - The sum of winning multipliers',
    '2 - The sum of winning multipliers minus the losing ones',
    '3 - Max multiplier',
    '4 - The sum of bet points according to the minimum bet',
    '5 - Max Multiplier per round'
  ];

  public hoursBefore: any[] = [
    {text: 'Immediately', value: 0},
    {text: '1 hour', value: 1},
    {text: '2 hours', value: 2},
    {text: '3 hours', value: 3},
    {text: '4 hours', value: 4},
    {text: '5 hours', value: 5},
    {text: '6 hours', value: 6},
    {text: '7 hours', value: 7},
    {text: '8 hours', value: 8},
    {text: '9 hours', value: 9},
    {text: '10 hours', value: 10},
    {text: '11 hours', value: 11},
    {text: '12 hours', value: 12},
    {text: '13 hours', value: 13},
    {text: '14 hours', value: 14},
    {text: '15 hours', value: 15},
    {text: '16 hours', value: 16},
    {text: '17 hours', value: 17},
    {text: '18 hours', value: 18},
    {text: '19 hours', value: 19},
    {text: '20 hours', value: 20},
    {text: '21 hours', value: 21},
    {text: '22 hours', value: 22},
    {text: '23 hours', value: 23},
    {text: '24 hours', value: 24},
  ]

  constructor(private dialogRef: MatDialogRef<AddTournamentComponent>,
              private tournamentsService: TournamentsService,
              private formBuilder: FormBuilder,
              private handlingService: HandlingService,
              private utilsService: UtilsService,
              private reportsService: ReportsService,) {
    this.reportsService.getCurrencies().subscribe(
      currencies => {
        this.currencies = currencies;
        if (this.currencies.length === 0) {
          return this.handlingService.error('No currencies found');
        }
        this.setCurrency(this.currencies[0]);
      },
      error => {
        handlingService.error('Error fetching available currencies', error);
      }
    )

    this.addingForm = this.formBuilder.group({
      tournamentName: ['', [Validators.required, Validators.minLength(3)]],
      currency: [this.currencies[0], Validators.required],
      fromDate: [utilsService.getTodaysDate(), Validators.required],
      fromTime: [startTime, Validators.required],
      toDate: [utilsService.getTodaysDate(), Validators.required],
      toTime: [endTime, Validators.required],
      tournamentType: [this.tournamentTypes[0], Validators.required],
      minBet: [0, [Validators.required, Validators.min(0)]],
      tournamentDescription: [''],
      showBefore: [null, [Validators.required, Validators.min(0)]]
    });
  }

  public submitForm() {
    if (this.addingForm.invalid) {
      return;
    }

    let startDate = this.addingForm.get('fromDate')?.value;
    let endDate = this.addingForm.get('toDate')?.value;
    if (startDate > endDate) {
      [startDate, endDate] = [endDate, startDate];
      this.addingForm.get('fromDate')?.setValue(startDate);
      this.addingForm.get('toDate')?.setValue(endDate);
    }

    let startDateTime = this.mapDateTime(this.addingForm.get('fromDate')?.value, this.addingForm.get('fromTime')?.value);
    let endDateTime = this.mapDateTime(this.addingForm.get('toDate')?.value, this.addingForm.get('toTime')?.value);


    const newTournament = new Tournament(
      -1,
      this.addingForm.get('tournamentName')?.value,
      this.addingForm.get('currency')?.value.CurrencyID,
      startDateTime,
      endDateTime,
      this.addingForm.get('tournamentType')?.value,
      this.addingForm.get('minBet')?.value,
      1,
      '1 - Created',
      this.addingForm.get('tournamentDescription')?.value,
      this.addingForm.get('showBefore')?.value
      );

    this.dialogRef.close({data: newTournament});
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  public setHoursBefore(hoursBefore: any) {
    this.addingForm.get('hoursBefore')?.setValue(hoursBefore.value);
  }

  public setCurrency(currency: any){
    this.displayCurrency = currency === null ? 'Currency' : currency.CurrencyID;
    this.currency = currency;
  }

  private mapDateTime(date: string, time: string): string {
    return this.utilsService.mapDateTime(date, time);
  }

  public setStartDate(date: string | null): void {
    this.addingForm.get('fromDate')?.setValue(date);
  }
  public setEndDate(date: string | null): void {
    this.addingForm.get('toDate')?.setValue(date);
  }
  public setStartTime(time: string | null): void {
    this.addingForm.get('fromTime')?.setValue(time);
  }
  public setEndTime(time: string | null): void {
    this.addingForm.get('toTime')?.setValue(time);
  }

}
