import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ConfigurationsService} from "../../../../../services/configurations/configurations.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ConfigCompany} from "../../../../../models/configurations/company-config/overview/config-company.model";
import {TimeOffset} from "../../../../../models/configurations/company-config/time-offset.interface";
import {HandlingService} from "../../../../../services/global-handling/handling.service";
import {UtilsService} from "../../../../../services/utils/utils.service";

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent implements OnInit, OnChanges {

  @Input('company') company!: ConfigCompany;
  public companyForm: FormGroup;
  public timeOffsets: TimeOffset[];

  public minDate: Date | undefined;
  public maxDate: Date | undefined;
  public nonBillingPeriodDate: Date | null = null;

  constructor(private configurationsService: ConfigurationsService,
              private handlingService: HandlingService,
              private formBuilder: FormBuilder,
              private utilsService: UtilsService) {

    this.companyForm = this.formBuilder.group({
      companyName: ['', [Validators.required, Validators.minLength(3)]],
      timeOffset: ['', Validators.required],
      useDST: ['', Validators.required]
    })

    this.timeOffsets = utilsService.getUtcTimeOffsets();
  }

  ngOnInit(): void {
  }

  ngOnChanges(){
    if(this.company){
      this.initDates(this.company.nonBillingPeriod);
      this.resetForm();
    }
  }

  public submitForm(){
    if (this.companyForm.invalid){ return; }
    this.company.companyName = this.companyForm.get('companyName')?.value;
    this.company.timeOffset = this.companyForm.get('timeOffset')?.value;
    this.company.useDST = this.companyForm.get('useDST')?.value;
    this.configurationsService.updateCompany(this.company).subscribe(() => {
      this.handlingService.success('Company successfully updated');
    },error => {
      this.handlingService.error('Error while updating company', error);
    });
  }

  public resetForm(){
    this.companyForm.get('companyName')?.setValue(this.company.companyName);
    this.companyForm.get('timeOffset')?.setValue(this.company.timeOffset);
    this.companyForm.get('useDST')?.setValue(this.company.useDST);
  }

  private initDates(companyDate: string): void {
    this.nonBillingPeriodDate = new Date(companyDate);
    this.minDate = this.nonBillingPeriodDate;
    this.maxDate = this.nonBillingPeriodDate;
    this.minDate.setHours(this.minDate.getHours() + 24);
    this.maxDate.setHours(this.maxDate.getHours() + 24);

  }

  public get companyName() {
    return this.companyForm.get('companyName');
  }
  public get timeOffset() {
    return this.companyForm.get('timeOffset');
  }
  public get useDST() {
    return this.companyForm.get('useDST');
  }

  public billingPeriodDateChanged(date: Date){}
}
