import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {CurrencyReport} from "../../../models/reports/currency/currency-report.model";
import {PercentageTotal} from "../../../models/reports/percentage-total.interface";
import {ReportsService} from "../../../services/reports/reports.service";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {ExcelService} from "../../../services/excel/excel.service";
import {UnmappedCurrencyReport} from "../../../models/reports/currency/unmapped/unmapped-currency-report.interface";
import {UtilsService} from "../../../services/utils/utils.service";

@Component({
  selector: 'app-currency-report',
  templateUrl: './currency-report.component.html',
  styleUrls: ['./currency-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CurrencyReportComponent implements OnInit {

  public dataSource!: MatTableDataSource<CurrencyReport>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  public showTable = false;
  public columns: string[] = [];
  public translationColumns: string[] = [];
  public expandedElement: CurrencyReport | null = null;

  public startDate: string | null = null;
  public endDate: string | null  = null;

  private percentageTotal: PercentageTotal;

  constructor(private reportsService: ReportsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private excelService: ExcelService,
              private utilsService: UtilsService) {
    this.translationColumns = reportsService.getCurrencyReportTranslationColumns();

    this.startDate = utilsService.getFirstDateInMonth();
    this.endDate = utilsService.getTodaysDate();

    this.fetchReports(this.startDate, this.endDate);

    this.percentageTotal = {stake: 0, result: 0, multiplier: 100};
  }

  ngOnInit(): void {
  }

  private fetchReports(startDate: string, endDate: string): void {
    this.loadingService.setLoadingSteps(1);
    this.reportsService.getCurrencyReports(startDate, endDate).subscribe(data => {
      this.dataSource = new MatTableDataSource(this.mapData(data));
      this.dataSource.sort = this.sort;

      if (this.dataSource.data.length === 0){
        this.hideReports();
      }else{
        this.showReports();
      }

    }, error => {
      this.handlingService.error('Error while fetching currency report data', error)
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  private mapData(unmapped: UnmappedCurrencyReport[]): CurrencyReport[]{
    let data: CurrencyReport[] = [];
    unmapped.forEach(item => {
      data.push(
        new CurrencyReport(
          item.CurrencyID,
          item.BetCount,
          item.PlayerCount,
          item.BetInCurrency,
          item.BetEUR,
          item.WinCount,
          item.WinInCurrency,
          item.WinEUR,
          item.PaidCount,
          item.PaidInCurrency,
          item.PaidEUR));
    })
    return data;
  }

  private showReports(): void {
    if (this.columns.length === 0){
      this.columns = this.dataSource.data[0].getFields();
    }
    this.showTable = true;
  }

  private hideReports(): void {
    this.showTable = false;
    this.dataSource = new MatTableDataSource<CurrencyReport>([]);
  }

  public setStartDate(date: string | null): void {
    this.startDate = date;
  }
  public setEndDate(date: string | null): void {
    this.endDate = date;
  }

  public refreshData(): void {
    if (this.startDate && this.endDate){
      if (this.startDate > this.endDate) {
        [this.startDate, this.endDate] = [this.endDate, this.startDate];
      }
      this.fetchReports(this.startDate, this.endDate);
    }else {
      this.handlingService.error('Please select start & end date');
    }
  }

  public downloadExcel(): void {
    let data = this.reportsService.currencyReportExcelMapper(this.dataSource.data);
    const name = 'Currency report - ' + this.utilsService.getTodaysDate();
    this.excelService.exportAsExcelFile(data, name);
  }

  public alignHeaderCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].align(column);
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }

  public total(column: string): string | number {
    if(column === this.columns[0]){
      return 'Total';
    }else if (column === this.columns[3] || column === this.columns[6] || column === this.columns[9]){
      return '';
    } else {
      let result = this.sumData(column);
      return this.totalFormat(result, column);
    }
  }

  private sumData(column: string): number {
    let result: number = 0;
    this.dataSource.data.map(report => {
      result += report.total(column);
    })
    return result;
  }

  private totalFormat(result: number, column: string): any {
    if (column === this.columns[1] ||
      column === this.columns[2] ||
      column === this.columns[5] ||
      column === this.columns[8]){
      return this.utilsService.formatInteger(result);
    }else{
      return this.utilsService.formatMoney(result);
    }
  }

}
