import {Component, OnInit} from '@angular/core';
import {RoleState} from "../../../models/roles/role-state.enum";
import {RolesService} from "../../../services/roles/roles.service";
import {ConfigUser} from "../../../models/configurations/user-config/config-user.model";
import {ConfigurationsService} from "../../../services/configurations/configurations.service";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {LoadingService} from "../../../services/loading/loading.service";

@Component({
  selector: 'app-user-config',
  templateUrl: './user-config.component.html',
  styleUrls: ['./user-config.component.scss']
})
export class UserConfigComponent implements OnInit {

  public userTableApproval: boolean = false;
  public users: ConfigUser[] = [];

  constructor(private rolesService: RolesService,
              private configurationsService: ConfigurationsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService) {

    this.userTableApproval = rolesService.checkRole(RoleState.ADMIN);
    loadingService.setLoadingSteps(1);

    configurationsService.getUsers().subscribe(newData => {
      this.users = configurationsService.userInputMapper(newData);

      if (this.users.length === 1) {
        this.userTableApproval = false;
      }
    },error => {
      handlingService.error('Error while fetching users', error);
    }, () => {
      loadingService.incrementLoadingSteps();
    })
  }

  ngOnInit(): void {}

}
