<div class="container p-0 m-0">
  <div class="row">
    <div class="col pe-0">
      <div class="panel d-flex flex-column justify-content-end">
        <div class="col messages align-items-start" #scrollMe>
          <ng-container *ngFor="let item of item?.messages">
            <app-chat-room-message [item]="item" [selected]="selectedMessage" (message)="messageSelected($event)" [maxWidth]="550"></app-chat-room-message>
          </ng-container>
        </div>
        <div class="row mb-0 h-auto justify-content-between">
          <mat-checkbox class="checkbox pb-1 w-min" color="accent" style="font-size: 13px" [(ngModel)]="sendToAll">
            {{ 'chatRooms.chatRoomSendToAll' | transloco }}
          </mat-checkbox>
          <span class="span-btn" *ngIf="isOwner" (click)="sendToCompany()">{{ 'chatRooms.sendToCompanyDialog.title' | transloco }}</span>
        </div>
        <div class="row new-message mt-0">
          <mat-form-field appearance="outline" style="width: 350px; padding-right: 0;" class="form-field">
            <input matInput (keydown.enter)="sendMessage()" [(ngModel)]="newMessage" style="font-size: 16px" autocomplete="off">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="messageLevelMenu" class="message-level-btn">
            <mat-icon
              [class.owner-color]="messageLevel.value === 1"
              [class.admin-color]="messageLevel.value === 2"
              [class.viewer-support-color]="messageLevel.value === 3">format_color_text</mat-icon>
          </button>
          <mat-menu #messageLevelMenu="matMenu" yPosition="above">
            <button mat-menu-item [value]="msgLvl" *ngFor="let msgLvl of availableMessageLevels" (click)="changeMessageLevel(msgLvl)"
                    [class.owner-color]="msgLvl.value === 1"
                    [class.admin-color]="msgLvl.value === 2"
                    [class.viewer-support-color]="msgLvl.value === 3">
              <mat-icon>format_color_text</mat-icon>
              <span>{{msgLvl.text}}</span>
            </button>
          </mat-menu>
          <button class="w-auto h-75 mt-2" type="button" mat-stroked-button color="primary" (click)="sendMessage()">{{ 'buttons.sendButton' | transloco }}</button>
          <button class="w-auto h-75 mt-2 ms-2" type="button" mat-stroked-button color="warn" (click)="deleteMessages()">{{ 'buttons.deleteSelectedButton' | transloco }}</button>
        </div>
      </div>
    </div>
    <div class="col px-0" *ngIf="selectedMessage !== null">
      <app-chat-room-user-panel [message]="selectedMessage"></app-chat-room-user-panel>
    </div>
  </div>
</div>


