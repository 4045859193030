import {ReportColumns} from "../../reports/report-columns.interface";

export class PlayerTicketInfo {
  createdTime: string
  roundId: number
  ticketStatus: number
  bet: number
  paid: number
  multiplier: number
  currencyId: string
  betEur: number
  paidEur: number
  ticketType: number

  columnInfo: ReportColumns[]

  constructor(createdTime: string, roundId: number, ticketStatus: number, bet: number, paid: number, multiplier: number, currencyId: string, betEur: number, paidEur: number, ticketType: number) {
    this.createdTime = createdTime;
    this.roundId = roundId;
    this.ticketStatus = ticketStatus;
    this.bet = bet;
    this.paid = paid;
    this.multiplier = multiplier;
    this.currencyId = currencyId;
    this.betEur = betEur;
    this.paidEur = paidEur;
    this.ticketType = ticketType;

    this.columnInfo = [
      {key: 'Created time', info: {field: 'createdTime', total: this.createdTime, format: this.formatString(this.createdTime), align: 'normal'}},
      {key: 'Bet', info: {field: 'bet', total: this.bet, format: this.formatMoney(this.bet), align: 'end'}},
      {key: 'Win', info: {field: 'paid', total: this.paid, format: this.formatMoney(this.paid), align: 'end'}},
      {key: 'Multiplier', info: {field: 'multiplier', total: this.multiplier, format: this.formatMoney(this.multiplier), align: 'center'}},
      {key: 'Currency', info: {field: 'currencyId', total: this.currencyId, format: this.formatString(this.currencyId), align: 'center'}},
      {key: 'Bet EUR', info: {field: 'betEur', total: this.betEur, format: this.formatMoney(this.betEur), align: 'end'}},
      {key: 'Win EUR', info: {field: 'paidEur', total: this.paidEur, format: this.formatMoney(this.paidEur), align: 'end'}}
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public total(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.total;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatString(string: string): string {
    return string;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }
}
