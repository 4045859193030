import {ReportColumns} from "../reports/report-columns.interface";

export class PlayerAuditActivity {
  activity: string
  activityTime: string

  columnInfo: ReportColumns[]

  constructor(activity: string, activityTime: string) {
    this.activity = activity;
    this.activityTime = activityTime;

    this.columnInfo = [
      {key: 'Activity', info: {field: 'activity', total: this.activity, format: this.activity, align: 'normal'}},
      {key: 'Activity time', info: {field: 'activityTime', total: this.activityTime, format: this.activityTime, align: 'normal'}},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }
}
