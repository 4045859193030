import {ColumnMetaData} from "../../utils/column-meta-data.interface";
import {Injectable} from "@angular/core";
import {Mapper} from "../../utils/mapper.interface";
import {UnmappedCashbackPlayerDetailsReport} from "./unmapped/unmapped-cashback-player-details-report.interface";
import * as moment from "moment";

export class CashbackPlayerDetailsReport {
  id: number
  time: string
  bet: number
  final: number
  percentage: number
  money: number
  currencyId: string

  columnInfo: ColumnMetaData[]

  constructor(id: number, time: string, bet: number, final: number, percentage: number, money: number, currencyId: string) {
    this.id = id;
    this.time = time;
    this.bet = bet;
    this.final = final;
    this.percentage = percentage;
    this.money = money;
    this.currencyId = currencyId;

    this.columnInfo = [
      {key: 'id', column: 'reports.cashbackPlayerReport.details.id', field: 'id', total: 0, format: this.formatString(this.id.toString()), align: 'normal'},
      {key: 'time', column: 'reports.cashbackPlayerReport.details.time', field: 'time', total: 0, format: this.formatString(this.time), align: 'normal'},
      {key: 'bet', column: 'reports.cashbackPlayerReport.details.bet', field: 'bet', total: this.bet, format: this.formatMoney(this.bet), align: 'end'},
      {key: 'final', column: 'reports.cashbackPlayerReport.details.final', field: 'final', total: this.final, format: this.formatMoney(this.final), align: 'end'},
      {key: 'percentage', column: 'reports.cashbackPlayerReport.details.percentage', field: 'percentage', total: this.percentage, format: this.formatInteger(this.percentage), align: 'end'},
      {key: 'money', column: 'reports.cashbackPlayerReport.details.money', field: 'money', total: this.money, format: this.formatMoney(this.money), align: 'end'},
      {key: 'currency-id', column: 'reports.cashbackPlayerReport.details.currency', field: 'currencyId', total: 0, format: this.formatString(this.currencyId), align: 'center'},
    ]
  }

  public formatString(string: string): any {
    if (string === null){
      return '';
    }
    return string;
  }

  public formatInteger(integer: number): any {
    if (integer === null){
      return '';
    }
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    if (money === null){
      return '';
    }
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }
}

@Injectable({providedIn: "root"})
export class CashbackPlayerDetailsReportMapper implements Mapper<CashbackPlayerDetailsReport> {
  public map(unmapped: UnmappedCashbackPlayerDetailsReport): CashbackPlayerDetailsReport {
    return new CashbackPlayerDetailsReport(
      unmapped.ID,
      moment(unmapped.CashBackTime).utc().format("YYYY-MM-DD HH:mm:ss"),
      unmapped.BetMoney,
      unmapped.FinalResult,
      unmapped.CashBackPerc,
      unmapped.MoneyCashBack,
      unmapped.CurrencyID
    );
  }
}
