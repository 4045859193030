<form>
  <div class="container pb-2 px-0">
    <div class="row">
      <div class="flex-container d-inline-flex justify-content-between">
        <h2 mat-dialog-title style="font-size: 24px; font-weight: lighter;; width: 50%">{{ 'configurations.company.currencies.swapDialog.title' | transloco }}</h2>
        <p class="dashboard-card-title pt-1 w-auto">{{game}}</p>
      </div>
    </div>
    <div class="row d-flex align-items-center my-4">
      <div class="ps-2 pe-0 w-auto mb-2">
        <button mat-stroked-button color="primary" (click)="editCurrency()">
          <!--            <mat-icon>edit</mat-icon>-->
          {{ 'buttons.editCurrenciesButton' | transloco }}
        </button>
      </div>
      <div class="ps-2 ms-1 pe-0 w-auto mb-2">
        <button mat-stroked-button color="primary" (click)="transfer()">
          <mat-icon>chevron_right</mat-icon>
          {{ 'buttons.transferButton' | transloco }}
        </button>
      </div>
      <div class="ps-2 ms-1 pe-0 w-auto mb-2">
        <button mat-stroked-button color="primary" (click)="transferAll()">
          <mat-icon>double_arrow</mat-icon>
          {{ 'buttons.transferAllButton' | transloco }}
        </button>
      </div>
      <div class="ps-2 ms-1 pe-0 w-auto mb-2">
        <button mat-stroked-button color="warn" (click)="remove()">
          <mat-icon>clear</mat-icon>
          {{ 'buttons.removeButton' | transloco }}
        </button>
      </div>
      <div class="ps-2 ms-1 pe-0 w-auto mb-2">
        <button mat-stroked-button color="warn" (click)="removeAll()">
          <mat-icon>clear_all</mat-icon>
          {{ 'buttons.removeAllButton' | transloco }}
        </button>
      </div>
      <div class="ps-2 ms-1 pe-0 w-auto mb-2">
        <button mat-stroked-button color="accent" (click)="deselectAll()">
          <mat-icon>remove_circle_outline</mat-icon>
          {{ 'buttons.deselectButton' | transloco }}
        </button>
      </div>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="frame round-border row d-flex justify-content-start mx-0 mt-2" cdkDropListGroup>
      <div class="col ps-0 pb-2" [class.expanded-col]="!expanded" [class.collapsed-col]="expanded">
        <div class="flex-container">
          <h2 class="title ps-1 mb-0 pb-0" style="font-size: 24px; font-weight: lighter;">{{ 'configurations.company.currencies.allCurrenciesLabel' | transloco }}</h2>
          <span class="spacer"></span>
          <button mat-stroked-button color="primary" (click)="expandCollapse()" *ngIf="expanded">{{ 'buttons.expandButton' | transloco }}</button>
          <button mat-stroked-button color="primary" (click)="expandCollapse()" *ngIf="!expanded">{{ 'buttons.collapseButton' | transloco }}</button>
        </div>
        <mat-form-field appearance="standard" style="width: 100%; padding-left: 5px; padding-right: 5px;">
          <mat-label>{{ 'configurations.company.currencies.filterLabel' | transloco }}</mat-label>
          <input matInput (keyup)="filterAllCurrencies($event)" placeholder="Ex. all">
        </mat-form-field>
        <table mat-table [dataSource]="dataSource1" class="ms-1 mat-elevation-z8 table-list"
               cdkDropList
               [cdkDropListData]="dataSource1"
               (cdkDropListDropped)="drop($event)"
               [cdkDropListEnterPredicate]="noReturnPredicate"
               [class.expanded-table]="!expanded"
               [class.collapsed-table]="expanded">

          <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
            <th mat-header-cell
                [hidden]="allCurrenciesHidden(column)"
                class="header-cell multiple-row-header"
                [style.text-align]="'center'"
                *matHeaderCellDef>
              {{ translation(column) | transloco }}
            </th>
            <td mat-cell class="overflow-text" *matCellDef="let element" [hidden]="allCurrenciesHidden(column)" [style.text-align]="'center'"> {{format(element, column)}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
          <tr mat-row class="row-box element-row"
              *matRowDef="let row; let i = index; columns: columns;"
              cdkDrag [cdkDragData]=row
              (click)="selectGlobalCurrency(row, $event)"
              [class.clicked-row]="checkIfExists(row, globalSelectedCurrencies)"
              [ngClass]="{alternating: i % 2 === 0}"></tr>
        </table>
      </div>
      <div class="col px-0 pb-2" [class.expanded-col]="expanded" [class.collapsed-col]="!expanded">
          <div class="flex-container">
            <h2 class="title ps-1 mb-0 pb-0" style="font-size: 24px; font-weight: lighter;">{{ 'configurations.company.currencies.companyCurrencies' | transloco }}</h2>
            <span class="spacer"></span>
            <button mat-stroked-button color="primary" (click)="expandCollapse()" *ngIf="!expanded">{{ 'buttons.expandButton' | transloco }}</button>
            <button mat-stroked-button color="primary" (click)="expandCollapse()" *ngIf="expanded">{{ 'buttons.collapseButton' | transloco }}</button>
          </div>
          <mat-form-field appearance="standard" style="width: 100%; padding-left: 5px; padding-right: 5px;">
            <mat-label>{{ 'configurations.company.currencies.filterLabel' | transloco }}</mat-label>
            <input matInput (keyup)="filterCompanyCurrencies($event)" placeholder="Ex. all">
          </mat-form-field>
          <table mat-table [dataSource]="dataSource2" class="ms-1 mat-elevation-z8 table-list"
                 cdkDropList
                 [cdkDropListData]="dataSource2"
                 (cdkDropListDropped)="drop($event)"
                 [class.expanded-table]="expanded"
                 [class.collapsed-table]="!expanded">

            <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
              <th mat-header-cell
                  [hidden]="companyCurrenciesHidden(column)"
                  class="header-cell multiple-row-header"
                  [style.text-align]="'center'"
                  *matHeaderCellDef>
                {{ translation(column) | transloco }}
              </th>
              <td mat-cell class="overflow-text" *matCellDef="let element" [hidden]="companyCurrenciesHidden(column)" [style.text-align]="'center'"> {{format(element, column)}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
            <tr mat-row class="row-box element-row"
                *matRowDef="let row; let i = index; columns: columns;"
                cdkDrag [cdkDragData]=row
                (click)="selectMyCurrency(row, $event)"
                [class.clicked-row]="checkIfExists(row, companySelectedCurrencies)"
                [ngClass]="{alternating: i % 2 === 0}"></tr>
          </table>
        </div>
    </div>
  </div>

  <div class="container mb-2 px-0">
    <div mat-dialog-actions class="d-flex flex-row-reverse w-100 mt-2">
      <button class="px-4 ms-2" type="button" mat-flat-button color="primary" (click)="save()">{{ 'buttons.saveButton' | transloco }}</button>
      <button class="px-3" type="button" mat-stroked-button color="accent" (click)="onNoClick()">{{ 'buttons.cancelButton' | transloco }}</button>
    </div>
  </div>
</form>
