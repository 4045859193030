<div class="round-border dashboard-card2">
  <div class="row h-100">
    <div class="col d-flex flex-column justify-content-between">
      <div class="row d-flex flex-row-reverse">
        <i class="dashboard-card2-icon pe-1 me-2" [class]="iconClass"></i>
      </div>
      <div class="row d-flex justify-content-center">
        <p class="dashboard-card2-data" [ngStyle]="dataSize">
          {{data}}
        </p>
      </div>
      <div class="row">
        <div class="col flex-column">
          <p class="pe-0 today-label">Today's</p>
          <p class="dashboard-card-title pe-0">{{title}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
