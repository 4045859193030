import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {IndecentUser} from "../../../models/chat-rooms/indecent-user.model";
import {ChatRoomProfaneMessage} from "../../../models/chat-rooms/chat-room-profane-message.model";
import {ChatRoomsService} from "../../../services/chat-rooms/chat-rooms.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {ChatRoomMessage} from "../../../models/chat-rooms/chat-room-message.interface";
import {ChatRoomUserBanOption} from "../../../models/chat-rooms/chat-room-user-ban-option.interface";
import {CustomDialogComponent} from "../../dialogs/custom-dialog/custom-dialog.component";
import {UtilsService} from "../../../services/utils/utils.service";

@Component({
  selector: 'app-user-history-dialog',
  templateUrl: './user-history-dialog.component.html',
  styleUrls: ['./user-history-dialog.component.scss']
})
export class UserHistoryDialogComponent implements OnInit {

  public banUntil: Date;
  public showBannedUntil: boolean;
  public messageHistoryDate: string;
  public messageHistory: ChatRoomProfaneMessage[] = [];
  public selectedMessage: ChatRoomProfaneMessage | null = null;

  public banOptions: ChatRoomUserBanOption[] = [
    {text: '1 day', value: 1},
    {text: '2 days', value: 2},
    {text: '5 days', value: 5},
    {text: 'Indefinitely', value: 0}];
  public selectedBanOption: ChatRoomUserBanOption = this.banOptions[0];

  constructor(private chatRoomService: ChatRoomsService,
              private loadingService: LoadingService,
              private dialogRef: MatDialogRef<UserHistoryDialogComponent>,
              private handlingService: HandlingService,
              @Inject(MAT_DIALOG_DATA) public user: IndecentUser,
              private utilsService: UtilsService,
              private dialog: MatDialog) {
    this.messageHistoryDate = this.utilsService.getLastThirtyDays();
    this.banUntil = new Date(user.bannedUntil);
    this.showBannedUntil = user.bannedUntil !== '';
    this.getMessageHistory();
  }

  ngOnInit(): void {
  }

  public getMessageHistory(): void {
    this.chatRoomService.getMessageHistory(this.user.id, this.messageHistoryDate).subscribe(value => {
      this.messageHistory = this.chatRoomService.mapMessageHistory(value);
    }, error => {
      this.handlingService.error("Error fetching user message history", error);
    });
  }

  public onNoClick(): void {
    this.dialogRef.close({banned: false, days: -1});
  }

  public messageSelected(message: ChatRoomMessage | ChatRoomProfaneMessage): void {
    if (message instanceof ChatRoomProfaneMessage){
      this.selectedMessage = message;
    }
  }

  public selectBanOption(option: ChatRoomUserBanOption): void {
    this.selectedBanOption = option;
  }

  public banUser(): void {
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      width: '350px',
      data: {
        title: `Ban user ${this.user?.username}`,
        icon: 'remove_moderator',
        message: `Are you sure you want to ban user ${this.user?.username} for ${this.selectedBanOption.text}?`,
        action: 'Ban'
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.result){
        this.chatRoomService.banUser(this.user.id, this.selectedBanOption.value).subscribe(
          () => {
            this.dialogRef.close({banned: true, days: this.selectedBanOption.value});
          }, error => {
            this.handlingService.error("Error banning user", error);
          }
        );
      }
    });
  }

}
