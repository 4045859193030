<ng-container *ngIf="approved">
    <a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" (click)="onItemSelected(item)"
       class="nav-item menu-list-item">
      <mat-icon class="routeIcon">{{item.getIconName()}}</mat-icon>
      <div class="navItemName">
        {{name}}
      </div>
      <span *ngIf="item.getBeta()">
        <mat-icon class="mt-1" (click)="openInfo()" color="warn">
          warning
        </mat-icon>
      </span>
      <span *ngIf="item.getChildren() && item.getChildren().length">
        <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
          expand_more
        </mat-icon>
      </span>
    </a>
    <div *ngIf="expanded">
      <ng-container *ngFor="let child of item.getChildren()">
        <app-navigation-item *ngIf="checkChild(child)" [item]="child" [depth]="depth+1"></app-navigation-item>
      </ng-container>
    </div>
</ng-container>
