import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {ConfigurationsService} from "../../../../../services/configurations/configurations.service";
import {TimeOffset} from "../../../../../models/configurations/company-config/time-offset.interface";
import {ConfigCompany} from "../../../../../models/configurations/company-config/overview/config-company.model";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from "@angular/material-moment-adapter";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import * as moment from "moment";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Platform} from "../../../../../models/configurations/company-config/overview/platform.model";
import {HandlingService} from "../../../../../services/global-handling/handling.service";
import {UtilsService} from "../../../../../services/utils/utils.service";
import {BillingType} from "../../../../../models/configurations/company-config/overview/billing-type.interface";

@Component({
  selector: 'app-add-company-dialog',
  templateUrl: './add-company-dialog.component.html',
  styleUrls: ['./add-company-dialog.component.scss'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  ],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '90px',
        visibility: 'visible'
      })),
      state('closed', style({
        height: '0px',
        visibility: 'hidden'
      })),
      transition('* => *', [
        animate('0.15s')
      ]),
    ]),
  ],
})
export class AddCompanyDialogComponent implements OnInit {

  private emailRegex: string = '^\\S+@\\S+\\.\\S{2,4}$';

  public addingForm: FormGroup;
  public timeOffsets: TimeOffset[];

  public min: Date = new Date();
  public max: Date = new Date();
  public currentDate: any | null = null;
  public calendarClicked: boolean = false;

  public advanced: boolean = false;

  public platforms: Platform[] = [];
  public billingTypes: BillingType[];

  constructor(private _adapter: DateAdapter<any>,
              private dialogRef: MatDialogRef<AddCompanyDialogComponent>,
              private formBuilder: FormBuilder,
              private handlingService: HandlingService,
              private configurationsService: ConfigurationsService,
              private utilsService: UtilsService) {
    this.billingTypes = configurationsService.getBillingTypes();

    let start = utilsService.getUtcStartOffset();
    configurationsService.getPlatforms().subscribe(value => {
      value.forEach(item => {
        this.platforms.push(new Platform(item.PlatformID, item.PlatformName, item.ExtData, item.BillingType, item.BillingPercentage));
      });
    }, error => {
      return handlingService.error("Error fetching platform suggestions", error);
    })

    this.addingForm = this.formBuilder.group({
      companyName: ['', [Validators.required, Validators.minLength(3)]],
      timeOffset: [start, Validators.required],
      useDST: [false, Validators.required],
      nonBillingPeriod: [moment().utc(), Validators.required],
      platform: [null, Validators.required],
      extData: ['', this.configurationsService.jsonValidator],
      customization: ['', this.configurationsService.jsonValidator],
      billingType: [this.billingTypes[0], Validators.required],
      billingPerc: [0, [Validators.required, Validators.min(0), Validators.max(50)]],
      eomfx: [false, Validators.required],
      adminUsername: ['', [Validators.required, Validators.minLength(3)]],
      adminEmail: ['', [Validators.required, Validators.email]],
      isolated: [false, Validators.required],
      delUnusedPromo: [7, [Validators.required, Validators.min(0), Validators.max(366)]],
      delUnusedFreeTickets: [7, [Validators.required, Validators.min(0), Validators.max(366)]],
      fixedCurrency: [false, Validators.required],
      description: [''],
      promoWinToBonus: [false, Validators.required],
    });

    this.timeOffsets = utilsService.getUtcTimeOffsets();
    this.max.setMonth(this.min.getMonth() + 6);

    this.currentDate = moment().utc();
  }

  public setCalendarClicked(): void {
    this.calendarClicked = true;
  }

  ngOnInit(): void {
  }

  public submitForm(): void {
    if (this.addingForm.invalid){ return; }
    let billingPeriod = this.addingForm.get('nonBillingPeriod')?.value.format("YYYY-MM-DD");

    let newCompany = new ConfigCompany(0,
      this.addingForm.get('companyName')?.value,
      this.addingForm.get('platform')?.value.platformName,
      this.addingForm.get('timeOffset')?.value,
      this.addingForm.get('useDST')?.value,
      billingPeriod,
      this.addingForm.get('platform')?.value.platformID,
      this.addingForm.get('extData')?.value,
      this.addingForm.get('customization')?.value,
      0,
      true,
      this.addingForm.get('billingType')?.value.value,
      this.addingForm.get('billingPerc')?.value,
      this.addingForm.get('eomfx')?.value,
      true,
      this.addingForm.get('isolated')?.value,
      this.addingForm.get('delUnusedPromo')?.value,
      this.addingForm.get('delUnusedFreeTickets')?.value,
      this.addingForm.get('fixedCurrency')?.value,
      this.addingForm.get('description')?.value,
      this.addingForm.get('promoWinToBonus')?.value,
      this.addingForm.get('adminUsername')?.value,
      this.addingForm.get('adminEmail')?.value,
      );

    this.dialogRef.close({data: newCompany});
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public platformChanged(platform: Platform): void {
    this.addingForm.get('extData')?.setValue(platform.extData);
    const billingType = this.configurationsService.findBillingType(this.billingTypes, platform.billingType);
    this.addingForm.get('billingType')?.setValue(billingType);
    this.addingForm.get('billingPerc')?.setValue(platform.billingPercentage);
  }

  public billingPeriodDateChanged(date: any){
    this.addingForm.get('nonBillingPeriod')?.setValue(date);
    this.currentDate = date;
  }

  public get companyName() {
    return this.addingForm.get('companyName');
  }
  public get timeOffset() {
    return this.addingForm.get('timeOffset');
  }
  public get useDST() {
    return this.addingForm.get('useDST');
  }
  public get nonBillingPeriod() {
    return this.addingForm.get('nonBillingPeriod');
  }
  public get platform() {
    return this.addingForm.get('platform');
  }
  public get extData() {
    return this.addingForm.get('extData');
  }
  public get customization() {
    return this.addingForm.get('customization');
  }
  public get billingType() {
    return this.addingForm.get('billingType');
  }
  public get billingPerc() {
    return this.addingForm.get('billingPerc');
  }
  public get eomfx() {
    return this.addingForm.get('eomfx');
  }
  public get adminUsername() {
    return this.addingForm.get('adminUsername');
  }
  public get adminEmail() {
    return this.addingForm.get('adminEmail');
  }
  public get isolated() {
    return this.addingForm.get('isolated');
  }
  public get delUnusedPromo() {
    return this.addingForm.get('delUnusedPromo');
  }
  public get delUnusedFreeTickets() {
    return this.addingForm.get('delUnusedFreeTickets');
  }
  public get fixedCurrency() {
    return this.addingForm.get('fixedCurrency');
  }
  public get description() {
    return this.addingForm.get('description');
  }
  public get promoWinToBonus() {
    return this.addingForm.get('promoWinToBonus');
  }

}
