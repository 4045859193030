import {Component, OnInit} from '@angular/core';
import {ConfigurationsService} from "../../../../services/configurations/configurations.service";
import {RoleState} from "../../../../models/roles/role-state.enum";
import {RolesService} from "../../../../services/roles/roles.service";
import {HandlingService} from "../../../../services/global-handling/handling.service";
import {LoadingService} from "../../../../services/loading/loading.service";
import {ConfigMeteor} from "../../../../models/reports/bonusing/meteors-config/config-meteors.model";

@Component({
  selector: 'app-meteor-shower',
  templateUrl: './meteor-shower.component.html',
  styleUrls: ['./meteor-shower.component.scss']
})
export class MeteorShowerComponent implements OnInit {
  public meteorShowerTableApproval: boolean = false;
  public meteors: ConfigMeteor[] = [];

  constructor(rolesService: RolesService, configurationsService: ConfigurationsService, handlingService: HandlingService, loadingService: LoadingService) {
    this.meteorShowerTableApproval = rolesService.checkRole(RoleState.ADMIN);
    loadingService.setLoadingSteps(1);

    configurationsService.getMeteors().subscribe(newData => {
      this.meteors = configurationsService.meteorInputMapper(newData);

      if(this.meteors.length < 0){
        this.meteorShowerTableApproval = false;
      }

    },error => {
      handlingService.error('Error while fetching meteors', error);
    }, () => {
      loadingService.incrementLoadingSteps();
    })
  }

  ngOnInit(): void {}
}

