<div class="container w-96 round-border">
  <div class="row d-flex">
    <p class="dashboard-title w-auto pe-0">{{ 'dashboard.dashboard3.title' | transloco }}</p>
    <p class="percent-increase w-auto ps-2" *ngIf="percentChange.increase">{{percentChange.change}}</p>
    <p class="percent-decrease w-auto ps-2" *ngIf="!percentChange.increase">{{percentChange.change}}</p>
  </div>

  <div *ngIf="showChart | async">
    <div id="barChart" >
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [dataLabels]="chartOptions.dataLabels"
        [plotOptions]="chartOptions.plotOptions"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [theme]="chartOptions.theme"
        [tooltip]="chartOptions.tooltip"
      ></apx-chart>
    </div>
  </div>
</div>



