import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatTableDataSource} from "@angular/material/table";
import {CashbackReport} from "../../../../models/reports/cashback/cashback-report.model";
import {MatSort} from "@angular/material/sort";
import {HandlingService} from "../../../../services/global-handling/handling.service";
import {UtilsService} from "../../../../services/utils/utils.service";
import {ExcelService} from "../../../../services/excel/excel.service";
import {ReportsService} from "../../../../services/reports/reports.service";
import {CashbackPlayerReport} from "../../../../models/reports/cashback/cashback-player-report.model";
import {CashbackPlayerReportExcel} from "../../../../models/reports/cashback/cashback-player-report-excel.interface";
import {CompanySimple} from "../../../../models/configurations/company-simple.interface";

@Component({
  selector: 'app-cashback-player-report',
  templateUrl: './cashback-player-report.component.html',
  styleUrls: ['./cashback-player-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CashbackPlayerReportComponent implements OnInit {

  public dataSource!: MatTableDataSource<CashbackPlayerReport>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  public showTable = false;
  public columns: string[] = [];
  public expandedElement: CashbackPlayerReport | null = null;

  public companies: CompanySimple[] = [];
  public company: CompanySimple | null = null;

  public startDate: string | null = null;
  public endDate: string | null  = null;

  constructor(private handlingService: HandlingService,
              private utilsService: UtilsService,
              private excelService: ExcelService,
              private reportsService: ReportsService) {
    this.startDate = utilsService.getFirstDateInMonth();
    this.endDate = utilsService.getTodaysDate();
    this.fetchCompanies();
  }

  private fetchCompanies(): void {
    this.reportsService.getSimpleCompanies()
      .subscribe(data => {
        this.companies = data;
        this.company = this.companies.length > 0 ? this.companies[0] : null;
        this.company ? this.fetchReports() : null;
      }, error => {
        this.handlingService.error('Error while fetching available companies', error);
      })
  }

  public fetchReports(): void {
    if (!this.company) {
      return this.handlingService.error('Please select a desired company');
    }
    if (!this.startDate || !this.endDate) {
      return this.handlingService.error('Please select start & end date');
    }
    if (this.startDate > this.endDate) {
      [this.startDate, this.endDate] = [this.endDate, this.startDate];
    }
    this.reportsService.getCashbackPlayerReports(this.startDate, this.endDate, this.company.CompanyID)
      .subscribe(data => {
        this.dataSource = new MatTableDataSource<CashbackPlayerReport>(data);
        this.dataSource.sort = this.sort;
        this.showTable = this.dataSource.data.length > 0;
        this.columns = this.showTable ? this.dataSource.data[0].columnInfo.map(item => item.key) : [];
      }, error => {
        this.handlingService.error('Error while fetching cashback player report data', error);
      });
  }

  public setStartDate(date: string | null): void {
    this.startDate = date;
  }
  public setEndDate(date: string | null): void {
    this.endDate = date;
  }

  public downloadExcel(): void {
    let data = this.dataSource.data.map(item => this.mapToExcel(item));
    const name = 'Cashback player report - ' + this.utilsService.getTodaysDate();
    this.excelService.exportAsExcelFile(data, name);
  }

  private mapToExcel(item: CashbackPlayerReport): CashbackPlayerReportExcel {
    return {
      playerId: item.id,
      username: item.username,
      externalId: item.externalId,
      bet: item.bet,
      cashbackValue: item.value,
      currency: item.currencyId,
    }
  }

  public translation(column: string): string {
    if (this.dataSource.data.length < 1){
      return '';
    }
    const columnMetaData = this.dataSource.data[0].columnInfo.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.column;
  }

  public sortHeader(column: string): string {
    if (this.dataSource.data.length < 1){
      return '';
    }
    const columnMetaData = this.dataSource.data[0].columnInfo.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.field;
  }

  public alignment(column: string): string {
    if (this.dataSource.data.length < 1){
      return '';
    }
    const columnMetaData = this.dataSource.data[0].columnInfo.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.align;
  }

  public format(element: CashbackReport, column: string): any {
    const columnMetaData = element.columnInfo.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.format;
  }

  ngOnInit(): void {
  }

  public setSelectedCompany(company: CompanySimple | null) {
    this.company = company;
  }

}
