import {ReportColumns} from "../report-columns.interface";

export class CurrencyDetailsReport {

  companyName: string
  bets: number
  playerCount: number
  stake: number
  stakeEur: number
  wins: number
  winMoney: number
  winMoneyEur: number
  result: number
  resultEur: number
  perc: number

  columnInfo: ReportColumns[]

  constructor(companyName: string, bets: number, playerCount: number, stake: number, stakeEur: number, wins: number, winMoney: number, winMoneyEur: number, result: number, resultEur: number, perc: number) {
    this.companyName = companyName;
    this.bets = bets;
    this.playerCount = playerCount;
    this.stake = stake;
    this.stakeEur = stakeEur;
    this.wins = wins;
    this.winMoney = winMoney;
    this.winMoneyEur = winMoneyEur;
    this.result = result;
    this.resultEur = resultEur;
    this.perc = perc;

    this.columnInfo = [
      {key: 'Company', info: {field: 'companyName', total: this.companyName, format: this.formatString(this.companyName), align: 'normal'}},
      {key: 'Bets', info: {field: 'bets', total: this.bets, format: this.formatInteger(this.bets), align: 'end'}},
      {key: 'Players', info: {field: 'playerCount', total: this.playerCount, format: this.formatInteger(this.playerCount), align: 'end'}},
      {key: 'Stake', info: {field: 'stake', total: this.stake, format: this.formatMoney(this.stake), align: 'end'}},
      {key: 'Stake In EUR', info: {field: 'stakeEur', total: this.stakeEur, format: this.formatMoney(this.stakeEur), align: 'end'}},
      {key: 'Wins', info: {field: 'wins', total: this.wins, format: this.formatInteger(this.wins), align: 'end'}},
      {key: 'Win', info: {field: 'winMoney', total: this.winMoney, format: this.formatMoney(this.winMoney), align: 'end'}},
      {key: 'Wins In EUR', info: {field: 'winMoneyEur', total: this.winMoneyEur, format: this.formatInteger(this.winMoneyEur), align: 'end'}},
      {key: 'Result', info: {field: 'result', total: this.result, format: this.formatMoney(this.result), align: 'end'}},
      {key: 'Result In EUR', info: {field: 'resultEur', total: this.resultEur, format: this.formatMoney(this.resultEur), align: 'end'}},
      {key: 'Percentage', info: {field: 'perc', total: this.perc, format: this.formatMoney(this.perc), align: 'end'}}
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }


  public formatString(string: string): string {
    return string;
  }

  public formatInteger(integer: number): string {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }
}
