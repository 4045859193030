import { Component, OnInit } from '@angular/core';
import {ConfigUser} from "../../../../../models/configurations/user-config/config-user.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CompanySimple} from "../../../../../models/configurations/company-simple.interface";
import {MatDialogRef} from "@angular/material/dialog";
import {ConfigurationsService} from "../../../../../services/configurations/configurations.service";
import {RolesService} from "../../../../../services/roles/roles.service";
import {HandlingService} from "../../../../../services/global-handling/handling.service";
import {Currency} from "../../../../../models/reports/exchange-rates/currency.model";
import {ReportsService} from "../../../../../services/reports/reports.service";
import {ConfigMeteor} from "../../../../../models/reports/bonusing/meteors-config/config-meteors.model";

@Component({
  selector: 'app-add-meteor-shower-dialog',
  templateUrl: './add-meteor-shower.component.html',
  styleUrls: ['./add-meteor-shower.component.scss']
})
export class AddMeteorShowerDialogComponent implements OnInit {
  public hidePass: boolean = false;
  public addingForm: FormGroup;
  public companies: CompanySimple[] = [];
  public roles: string[] = [];
  public currencies : Currency[] = []

  constructor(
    private dialogRef: MatDialogRef<AddMeteorShowerDialogComponent>,
    private formBuilder: FormBuilder,
    configurationsService: ConfigurationsService,
    reportService: ReportsService,
    rolesService: RolesService,
    handlingService: HandlingService
  ) {
    configurationsService.getSimpleCompanies().subscribe(newData => this.companies = newData, error => handlingService.error('Error fetching available companies', error));
    reportService.getCurrencies().subscribe(newData => this.currencies = this.sortCurrencies(newData), error => handlingService.error('Error fetching available currencies', error));

    this.roles = rolesService.getAvailableRoles();
    this.addingForm = this.formBuilder.group({
      companyId: ['', [Validators.required]],
      hoursActiveFrom: [8, [Validators.required,Validators.min(0)]],
      hoursActiveTo: [21, [Validators.required,Validators.min(0)]],
      offHoursCoef: [5, [Validators.required,Validators.min(0)]],
      name: ['', [Validators.required]],
      currency: ['', [Validators.required,Validators.min(3)]],
      amount: ['', [Validators.required,Validators.min(0)]],
      players: ['', [Validators.required,Validators.min(0)]],
      minutesMin: [1, [Validators.required,Validators.min(0)]],
      minutesMax: [30, [Validators.required,Validators.min(0)]],
    })
  }

  ngOnInit(): void {}

  public sortCurrencies(arr:Currency[]) {
    return arr.sort((a, b) => {
      if (a.CurrencyID < b.CurrencyID) return -1;
      if (a.CurrencyID > b.CurrencyID) return 1;
      return 0;
    });
  }

  public submitForm() {
    if(!this.addingForm.invalid){
      let newMeteor = new ConfigMeteor(
        this.addingForm.get("id")?.value.ID,
        this.addingForm.get("companyId")?.value.CompanyID,
        this.addingForm.get("hoursActiveFrom")?.value,
        this.addingForm.get("hoursActiveTo")?.value,
        this.addingForm.get("offHoursCoef")?.value,
        this.addingForm.get("name")?.value,
        this.addingForm.get("currency")?.value.CurrencyID,
        this.addingForm.get("amount")?.value,
        this.addingForm.get("players")?.value,
        this.addingForm.get("minutesMin")?.value,
        this.addingForm.get("minutesMax")?.value,
        this.addingForm.get("nextTriggerTime")?.value
      );

      this.dialogRef.close({ data: newMeteor });
    }
  }

  public get companyId() {
    return this.addingForm.get('companyId');
  }

  public get hoursActiveFrom() {
    return this.addingForm.get('hoursActiveFrom');
  }

  public get hoursActiveTo() {
    return this.addingForm.get('hoursActiveTo');
  }

  public get offHoursCoef() {
    return this.addingForm.get('offHoursCoef');
  }

  public get amount() {
    return this.addingForm.get('amount');
  }

  public get players() {
    return this.addingForm.get('players');
  }

  public get name() {
    return this.addingForm.get('name');
  }

  public get minutesMin() {
    return this.addingForm.get('minutesMin');
  }

  public get minutesMax() {
    return this.addingForm.get('minutesMax');
  }

  public get currency() {
    return this.addingForm.get('currency');
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
