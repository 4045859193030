import {SummaryReportDetails} from "./summary-report-details.interface";
import {ReportColumns} from "../report-columns.interface";

export class SummaryReportRocketmanDetails implements SummaryReportDetails {

  currencyId: string;
  bets: number
  stake: number
  wins: number
  winMoney: number
  result: number
  perc: number;

  columnInfo: ReportColumns[];

  constructor(currencyId: string, bets: number, stake: number, wins: number, winMoney: number, result: number, perc: number) {
    this.currencyId = currencyId;
    this.bets = bets;
    this.stake = stake;
    this.wins = wins;
    this.winMoney = winMoney;
    this.result = result;
    this.perc = perc;

    this.columnInfo = [
      {key: 'Currency', info: {field: 'currencyId', total: this.currencyId, format: this.formatString(this.currencyId), align: 'normal'}},
      {key: 'Bets', info: {field: 'bets', total: this.bets, format: this.formatInteger(this.bets), align: 'end'}},
      {key: 'Stake', info: {field: 'stake', total: this.stake, format: this.formatMoney(this.stake), align: 'end'}},
      {key: 'Wins', info: {field: 'wins', total: this.wins, format: this.formatInteger(this.wins), align: 'end'}},
      {key: 'Win', info: {field: 'winMoney', total: this.winMoney, format: this.formatMoney(this.winMoney), align: 'end'}},
      {key: 'Result', info: {field: 'result', total: this.result, format: this.formatMoney(this.result), align: 'end'}},
      {key: 'Percentage', info: {field: 'perc', total: this.perc, format: this.formatMoney(this.perc), align: 'end'}}
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatString(string: string): string {
    return string;
  }

  public formatInteger(integer: number): string {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }

}
