import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ConfigJackpot} from "../../../../models/configurations/company-config/jackpot/config-jackpot.model";
import {ConfigurationsService} from "../../../../services/configurations/configurations.service";
import {RolesService} from "../../../../services/roles/roles.service";
import {RoleState} from "../../../../models/roles/role-state.enum";
import {ConfigCompany} from "../../../../models/configurations/company-config/overview/config-company.model";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {EditJackpotDialogComponent} from "./edit-jackpot-dialog/edit-jackpot-dialog.component";
import {HandlingService} from "../../../../services/global-handling/handling.service";
import {LoadingService} from "../../../../services/loading/loading.service";
import {LocalizationService} from "../../../../services/localization/localization.service";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-jackpot-config',
  templateUrl: './jackpot-config.component.html',
  styleUrls: ['./jackpot-config.component.scss']
})
export class JackpotConfigComponent implements OnInit, OnChanges {

  public columns: string[] = [];
  public translationColumns: string[] = [];
  public selected: ConfigJackpot | null = null;
  public privileges: boolean = false;
  public support: boolean = false;

  @Input("gameId") gameId: number | undefined;
  @Input("gameName") gameName: string = 'configurations.company.jackpot.title';
  @ViewChild(MatTable) table!: MatTable<ConfigCompany>;
  public dataSource!: MatTableDataSource<ConfigJackpot>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(private configurationsService: ConfigurationsService,
              private handlingService: HandlingService,
              private rolesService: RolesService,
              private loadingService: LoadingService,
              private localizationService: LocalizationService,
              private dialog: MatDialog) {
    this.translationColumns = localizationService.getJackpotConfigurationsTableTranslations();

    this.privileges = rolesService.checkRole(RoleState.OWNER);
    this.support = rolesService.checkEqualRole(RoleState.SUPPORT) === 0;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.gameId && this.gameName){
      this.fetchJackpots();
    }
  }

  private fetchJackpots(): void {
    if (!this.gameId) return;
    this.loadingService.setLoadingSteps(1);
    this.configurationsService.getJackpots(this.gameId).subscribe(data => {
      const mapped = this.configurationsService.jackpotInputMapper(data);
      this.dataSource = new MatTableDataSource<ConfigJackpot>(mapped);
      this.dataSource.sort = this.sort;
      this.columns = this.dataSource.data[0].getFields();
    },error => {
      this.handlingService.error('Error while fetching jackpots', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    });
  }

  public editJackpot(): void {
    if (this.selected){
      if (this.privileges){
        this.openEditingDialog();
      }else {
        if (this.selected.id !== 1){
          this.openEditingDialog();
        }
      }
    }else{
      this.handlingService.error('Please select a jackpot');
    }
  }

  public selectJackpot(jackpot: ConfigJackpot): void {
    this.selected = jackpot;
  }

  public deselectAll(): void {
    this.selected = null;
  }

  public refreshData(): void {
    this.fetchJackpots();
  }

  private openEditingDialog(): void {
    const dialogRef = this.dialog.open(EditJackpotDialogComponent, {
      width: '400px',
      data: this.selected,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.checkJackpotPercentages(result.data) && this.gameId){
          this.configurationsService.updateJackpot(result.data, this.gameId).subscribe(() => {
            this.handlingService.success('Jackpot successfully updated');
          },error => {
            this.handlingService.error('Error while updating jackpot', error);
          }, () => {
            this.refreshElement(result.data);
            this.table.renderRows();
          })
        }else{
          this.handlingService.custom("Amount Entered More Than Allowed", "Sum of all Jackpots, main + reserve percent must not exceed 1%.");
        }
      }
    })
  }

  private checkJackpotPercentages(newValue: ConfigJackpot): boolean {
    if (!this.privileges){
      return !this.checkJackpotSum(newValue);
    }else{
      return true;
    }
  }

  private checkJackpotSum(newValue: ConfigJackpot): boolean {
    let percentage: number = 0;
    let overflow: boolean = false;
    this.dataSource.data.map(jackpot => {
      if (jackpot.id === newValue.id) {
        percentage += this.addSingleJackpotPercentage(newValue);
        if (percentage > 1){ overflow = true; return; }
      }else {
        percentage += this.addSingleJackpotPercentage(jackpot);
        if (percentage > 1){ overflow = true; return; }
      }
    })
    return overflow;
  }

  private addSingleJackpotPercentage(jackpot: ConfigJackpot): number {
    return jackpot.jackPotPercentage + jackpot.jackPotReservePercentage;
  }

  public alignHeaderCell(column: string): string {
    return this.dataSource.data[0].align(column);
  }

  private refreshElement(element: ConfigJackpot): void {
    if(this.selected){
      let jackpot = new ConfigJackpot(
        element.id,
        element.companyName,
        element.levelID,
        element.jackPotName,
        element.jackPotPercentage,
        element.jackPotReservePercentage,
        element.jackPotMinValue,
        element.jackPotMaxValue,
        element.jackPotActive
      )
      let index = this.dataSource.data.indexOf(this.selected);
      this.dataSource.data.splice(index, 1);
      this.dataSource.data.splice(index, 0, jackpot);
      this.dataSource.data = this.dataSource.data;
    }
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }

}
