import {Component, OnInit, ViewChild} from '@angular/core';
import {Currency} from "../../../models/reports/exchange-rates/currency.model";
import {ReportsService} from "../../../services/reports/reports.service";
import {ExchangeRate} from "../../../models/reports/exchange-rates/exchange-rate.model";
import {UnmappedExchangeRate} from "../../../models/reports/exchange-rates/unmapped/unmapped-exchange-rate.interface";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {Router} from "@angular/router";
import {ExcelService} from "../../../services/excel/excel.service";
import {MatTableDataSource} from "@angular/material/table";
import {FinancialReport} from "../../../models/reports/financial/financial-report.model";
import {MatSort} from "@angular/material/sort";
import {UtilsService} from "../../../services/utils/utils.service";

@Component({
  selector: 'app-exchange-rates-report',
  templateUrl: './exchange-rates-report.component.html',
  styleUrls: ['./exchange-rates-report.component.scss'],

})
export class ExchangeRatesReportComponent implements OnInit {

  public dataSource!: MatTableDataSource<ExchangeRate>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  public showTable = false;

  public columns: string[] = [];
  public currencies: Currency[] = [];
  public translationColumns: string[] = [];

  public currency: Currency | null = null;
  public displayCurrency: string = 'Currency'

  public startDate: string | null = null;
  public endDate: string | null  = null;

  constructor(private reportsService: ReportsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private excelService: ExcelService,
              private utilsService: UtilsService,
              private router: Router) {
    this.translationColumns = reportsService.getExchangeRatesReportTranslationColumns();
    this.fetchCurrencies();
  }

  ngOnInit(): void {}

  private fetchCurrencies(): void {
    this.loadingService.setLoadingSteps(1);
    this.reportsService.getCurrencies().subscribe(currencies => {
      this.currencies = currencies;
      if (this.currencies.length === 0) {
        this.router.navigate(['/home']).then(() => {});
        return this.handlingService.error('No currencies found');
      }
      this.setCurrency(this.currencies[0]);
      this.startDate = this.utilsService.getFirstDateInMonth();
      this.endDate = this.utilsService.getTodaysDate();
      this.fetchReports(this.startDate, this.endDate, this.currency!.CurrencyID)
    }, error => {
      this.handlingService.error('Error while fetching available currencies', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  private fetchReports(startDate: string, endDate: string, currency: string): void {
    this.loadingService.setLoadingSteps(1);
    this.reportsService.getExchangeRates(startDate, endDate, currency).subscribe(data => {
      this.dataSource = new MatTableDataSource(this.mapData(data));
      this.dataSource.sort = this.sort;

      if (this.dataSource.data.length === 0){
        this.hideReports();
      }else{
        this.showReports();
      }
    }, error => {
      this.handlingService.error('Error while fetching exchange rate report data', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  private mapData(unmapped: UnmappedExchangeRate[]): ExchangeRate[] {
    let data: ExchangeRate[] = [];
    unmapped.forEach(item => {
      const date = this.utilsService.timestampToString(item.ExchangeDateTime);
      data.push(
        new ExchangeRate(
          date,
          item.ExchangeRate));
    })
    return data;
  }

  private showReports(): void {
    if (this.columns.length === 0){
      this.columns = this.dataSource.data[0].getFields();
    }
    this.showTable = true;
  }

  private hideReports(): void {
    this.showTable = false;
    this.dataSource = new MatTableDataSource<ExchangeRate>([]);
  }

  public refreshData(){
    if (this.startDate && this.endDate && this.currency){
      if (this.startDate > this.endDate) {
        [this.startDate, this.endDate] = [this.endDate, this.startDate];
      }
      this.fetchReports(this.startDate, this.endDate, this.currency.CurrencyID);
    }else {
      this.handlingService.error('Please select start date, end date and currency');
    }
  }

  public setCurrency(currency: any){
    this.displayCurrency = currency === null ? 'Currency' : currency.CurrencyID;
    this.currency = currency;
  }

  public setStartDate(date: string | null): void {
    this.startDate = date;
  }
  public setEndDate(date: string | null): void {
    this.endDate = date;
  }

  public downloadExcel(): void {
    let data = this.reportsService.exchangeRatesExcelMapper(this.dataSource.data);
    const name = 'Exchange rates report - ' + this.currency?.CurrencyID + ' - ' + this.utilsService.getTodaysDate();
    this.excelService.exportAsExcelFile(data, name);
  }

  public alignHeaderCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].align(column);
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }

}
