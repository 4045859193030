<h2 class="component-title">{{ 'chatRooms.chatRoomTitle' | transloco }}</h2>
<hr>
<div class="frame round-border chat-rooms" [ngStyle]="{'width':messagePanelSize}">
  <div class="container px-0">
    <div class="row">
      <div class="col chat-room-list d-flex flex-column pe-0" style="max-height: 606px; overflow-y: auto; overflow-x: hidden">
        <div class="row d-flex justify-content-between">
          <div class="col flex-column">
            <div class="d-flex">
              <mat-icon class="online_icon">circle</mat-icon>
              <span class="online_text">{{onlineCounter}} Online</span>
            </div>
            <p class="chat-room-title">{{ 'chatRooms.chatRoomChannels' | transloco }}</p>
          </div>
          <button class="me-3 my-1" mat-icon-button [matMenuTriggerFor]="sortMenu">
            <mat-icon>sort</mat-icon>
          </button>
          <mat-menu #sortMenu="matMenu">
            <button mat-menu-item (click)="aToZ()">
              <span>A-Z</span>
            </button>
            <button mat-menu-item (click)="zToA()">
              <span>Z-A</span>
            </button>
            <button mat-menu-item (click)="onlineAsc()">
              <span>Online ASC</span>
            </button>
            <button mat-menu-item (click)="onlineDesc()">
              <span>Online DESC</span>
            </button>
          </mat-menu>
        </div>
        <div class="h-78">
<!--          <ng-container *ngFor="let item of chatRooms">-->
            <app-chat-room-item [item]="item" *ngFor="let item of chatRooms" (room)="chatRoomChanged($event)"></app-chat-room-item>
<!--          </ng-container>-->
        </div>
        <mat-divider></mat-divider>
        <div class="round-border mt-2 ms-0">
          <div class="row d-flex h-100">
            <div class="col-3 icon-col d-flex justify-content-center">
              <button mat-icon-button (mouseenter)="changeNameHover = true" (mouseleave)="changeNameHover = false" (click)="changeChatUsername()">
                <mat-icon *ngIf="!changeNameHover" class="icon">person</mat-icon>
                <mat-icon *ngIf="changeNameHover" class="icon">manage_accounts</mat-icon>
              </button>
            </div>
            <div class="col p-0">
              <div class="row d-flex">
                <span class="username-label">{{ 'chatRooms.chatRoomUsernameLabel' | transloco }}</span>
              </div>
              <div class="row d-flex p-0">
                <span class="chat-username">{{chatUsername}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col d-flex flex-column ps-0 pe-0">
        <app-chat-room-panel [item]="currentChatRoom" (changeSize)="changeWidthSize($event)"></app-chat-room-panel>
      </div>
    </div>
  </div>
</div>
