<h2 class="component-title">{{ 'playerAudit.title' | transloco }}</h2>
<hr>

<div class="frame round-border">
  <div class="row d-flex align-items-start">
    <div class="col-sm-auto d-flex flex-column ms-1">
      <div class="row d-inline-flex">
        <mat-form-field appearance="standard" class="select-input" style="font-size: 16px">
          <mat-label>{{'buttons.games' | transloco }}</mat-label>
          <mat-select [(ngModel)]="currentGame">
            <mat-option [value]="game" *ngFor="let game of games">
              {{game.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="standard" style="font-size: 16px;" class="search-type">
          <mat-label>{{ 'playerAudit.searchType' | transloco }}</mat-label>
          <mat-select [(ngModel)]="selectedSearchType">
            <mat-option [value]="type" *ngFor="let type of searchTypes">
              {{type.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row d-inline-flex">
        <mat-form-field appearance="standard" style="font-size: 16px;" class="search-param">
          <mat-label>{{ 'playerAudit.searchParam' | transloco }}</mat-label>
          <input matInput placeholder="Search..." [(ngModel)]="searchParam" autocomplete="off">
        </mat-form-field>
      </div>
    </div>
    <div class="col-sm-auto d-flex flex-column">
      <div class="row">
        <app-date-picker [placeholder]="'From date'" (date)="setStartDate($event)" [init]="startDate" class="w-min"></app-date-picker>
        <app-time-picker-input [placeholder]="'From time'" (timeEmitter)="setStartTime($event)" [default]="'00:00'" class="w-min mt-1 from-time-picker"></app-time-picker-input>
      </div>
      <div class="mt-1 row">
        <app-date-picker [placeholder]="'To date'" (date)="setEndDate($event)" [init]="endDate" class="w-min"></app-date-picker>
        <app-time-picker-input [placeholder]="'To time'" (timeEmitter)="setEndTime($event)" [default]="defaultEndTime" class="w-min mt-1 to-time-picker"></app-time-picker-input>
      </div>
    </div>
    <div class="col-sm-auto d-flex flex-column mt-2 me-2">
      <mat-checkbox class="checkbox" color="primary" style="font-size: 16px" [(ngModel)]="allRocketRound">{{ 'playerAudit.allRocketRoundLabel' | transloco }}</mat-checkbox>
      <button mat-stroked-button color="primary" (click)="refreshData()" class="mt-3">
        {{ 'buttons.refreshDataButton' | transloco }}
      </button>
      <button class="mt-3" mat-stroked-button color="accent" (click)="downloadExcel()">
        <mat-icon>file_download</mat-icon>
        {{ 'buttons.excelButton' | transloco }}
      </button>
    </div>
  </div>
  <div class="overflow-max-height-table row d-flex">
    <div class="col-sm-9 col custom-overflow audit-max-width">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="auditTable mat-elevation-z8 mt-0" matSort [hidden]="!showTable">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
          <th mat-header-cell
              class="header-cell"
              *matHeaderCellDef
              mat-sort-header="{{sortHeader(column)}}"
              arrowPosition="after"
              [ngStyle]="{'text-align': alignment(column)}"
              [class.header-end]="alignment(column) === 'end'"
              [class.header-start]="alignment(column) === 'normal'">
            {{ translation(column) | transloco }}
          </th>
          <ng-container *ngIf="column === 'game-info';">
            <td mat-cell *matCellDef="let element" style="text-align: end">
              <button mat-icon-button (click)="getGameInfo(element)">
                <mat-icon>info</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container *ngIf="column !== 'game-info';">
            <td mat-cell isEllipsis *matCellDef="let element" [ngStyle]="{'text-align': alignment(column)}"> {{format(element, column)}} </td>
          </ng-container>
          <td mat-footer-cell class="footer-cell" *matFooterCellDef [ngStyle]="{'text-align': alignment(column)}"> {{total(column)}} </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell class="expanded-row" *matCellDef="let element" [attr.colspan]="columns.length">
            <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <app-player-audit-details
                [playerId]="element?.playerId"
                [expandedPlayerId]="expandedElement?.playerId"
                [ticketId]="element?.ticketId"
                [expandedTicketId]="expandedElement?.ticketId"
                [gameId]="currentGame.value"
              ></app-player-audit-details>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; let index = dataIndex; columns: columns;"
            class="element-row"
            [class.clicked-row]="expandedElement === element"
            [ngClass]="{alternating: index % 2 === 0}"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        <tr mat-footer-row *matFooterRowDef="columns"></tr>
      </table>
    </div>
    <div class="col-sm-auto col custom-overflow">
      <table mat-table [dataSource]="activityDataSource" class="activityTable mat-elevation-z8 mt-0" [hidden]="!showActivityTable">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of activityColumns; let i = index">
          <th mat-header-cell
              class="header-cell"
              *matHeaderCellDef
              [ngStyle]="{'text-align': alignActivityHeaderCell(column)}"
              [class.header-end]="alignActivityHeaderCell(column) === 'end'"
              [class.header-start]="alignActivityHeaderCell(column) === 'normal'">
            {{ activityTranslationColumns[i] | transloco }}
          </th>
          <td mat-cell *matCellDef="let element" [ngStyle]="{'text-align': element.align(column)}"> {{element.format(column)}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="activityColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; let index = dataIndex; columns: activityColumns;"
            class="element-row"
            [ngClass]="{alternating: index % 2 === 0}">
        </tr>
      </table>
    </div>
  </div>
</div>
