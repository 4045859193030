import {AfterViewInit, Directive, ElementRef, HostListener} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[timeFormat]'
})
export class TimeFormatDirective implements AfterViewInit{

  @HostListener('input') onInputChange(){
    this.checkForSeparator();
  }

  private prevLen: number = 0;

  constructor(private control: NgControl, private el: ElementRef) { }

  ngAfterViewInit(): void {
    this.checkForSeparator();
  }

  private checkForSeparator(){
    if (this.control && this.control.control && this.control.control.value){
      let value: string = this.control.control.value;

      if (value.length == 3 && this.prevLen !== 4 && value.charAt(value.length-1) !== ':'){
        value = value.slice(0, -1) + ":" + value.slice(-1);
        this.control.control.setValue(value);
        const input: HTMLInputElement = this.el.nativeElement;
        input.value = value;
      }
      this.prevLen = value.length;
    }
  }

}
