import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FinancialReportDetails} from "../../../../models/reports/financial/financial-report-details.interface";
import {ReportsService} from "../../../../services/reports/reports.service";
import {HandlingService} from "../../../../services/global-handling/handling.service";
import {UnmappedGameDetails} from "../../../../models/reports/financial/unmapped/unmapped-game-details.interface";
import {FinancialReportRocketmanDetails} from "../../../../models/reports/financial/financial-report-rocketman-details.model";

@Component({
  selector: 'app-financial-report-details',
  templateUrl: './financial-report-details.component.html',
  styleUrls: ['./financial-report-details.component.scss']
})
export class FinancialReportDetailsComponent implements OnInit, OnChanges {

  @Input('elementDate') elementDate?: string | null;
  @Input('expandedElementDate') expandedElementDate?: string | null;
  @Input('game') game: string | undefined;

  public financialReportDetails: FinancialReportDetails[] = [];
  public columns: string[] = []
  public translationColumns: string[] = [];

  constructor(private reportsService: ReportsService,
              private handlingService: HandlingService) {
    this.translationColumns = reportsService.getFinancialReportDetailsTranslationColumns();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.elementDate === this.expandedElementDate){
      if(this.elementDate){
        this.reportsService.getFinancialDetailsReport(this.elementDate, this.game).subscribe(newData => {

          this.financialReportDetails = this.mapData(newData);
          if(this.financialReportDetails[0] && this.columns.length === 0){
            this.columns = this.financialReportDetails[0].getFields();
          }
        },error => {
          this.handlingService.error('Error while fetching financial details report data', error);
        });
      }
    }
  }

  private mapData(unmapped: UnmappedGameDetails[]): FinancialReportDetails[]{
    let data: FinancialReportDetails[] = []
    unmapped.forEach(item => {
      data.push(
        new FinancialReportRocketmanDetails(
          item.CurrencyID,
          item.Bets,
          item.PlayerCount,
          item.Stake,
          item.Wins,
          item.WinMoney,
          item.Result,
          item.Perc))
    })
    return data;
  }

  public alignHeaderCell(column: string): string {
    return this.financialReportDetails[0].align(column);
  }

}
