import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChatRoomMessage} from "../../../models/chat-rooms/chat-room-message.interface";
import {ChatRoomProfaneMessage} from "../../../models/chat-rooms/chat-room-profane-message.model";
import {TranslationService} from "../../../services/translation/translation.service";
import {HandlingService} from "../../../services/global-handling/handling.service";

@Component({
  selector: 'app-chat-room-message',
  templateUrl: './chat-room-message.component.html',
  styleUrls: ['./chat-room-message.component.scss']
})
export class ChatRoomMessageComponent implements OnInit, AfterViewInit {

  @Input("item") item!: ChatRoomMessage | ChatRoomProfaneMessage;
  @Input("selected") selected!: ChatRoomMessage | ChatRoomProfaneMessage | null;
  @Input("maxWidth") maxWidth!: number;
  @Output() message = new EventEmitter<ChatRoomMessage | ChatRoomProfaneMessage>();
  public messageType: string = 'message';
  public profane: boolean = false;
  public sendingState: boolean = false;

  public clicked: boolean = false;
  public time: string = '';
  public msgSize: string = this.calculateMessageSize();

  public specialMessage: boolean = false;

  public translated: boolean = false;
  public translatedMessage: string = '';

  constructor(private translationService: TranslationService,
              private handlingService: HandlingService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.item){
      setTimeout(() => {
        this.specialMessage = this.checkSpecialMessage(this.item.message);
        if(this.specialMessage){
          this.maxWidth = this.calculateGifWidth();
        }
        this.msgSize = this.calculateMessageSize();
      });
      if (this.item instanceof ChatRoomMessage){
        setTimeout(() => {
          this.time = new Date((<ChatRoomMessage>this.item).time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
          this.sendingState = (<ChatRoomMessage>this.item).sendingState;
        });
      }else{
        setTimeout(() => {
          this.messageType = 'profane';
          this.profane = (<ChatRoomProfaneMessage>this.item).profane;
        });
      }
    }
  }

  private calculateMessageSize(): string {
    let result: number = 0;
    if (this.item){
      if (this.item.username.length > this.item.message.length){
        result = ((this.item.username.length + 2) * 7);
      }else{
        result = ((this.item.message.length + 2) * 7);
      }
      if(result > this.maxWidth){
        result = this.maxWidth
      }
      if (result < 190 && !this.specialMessage){
        result = 190;
      }
    }
    return result + 'px';
  }

  /*
  When calculating gif message size, we calculate it by username size, but
  in case of username being empty, we need baseline width (70).
   */
  private calculateGifWidth(): number {
    let usernameWidth = ((this.item.username.length + 2) * 7);
    if (usernameWidth <= 70){
      usernameWidth = 70;
    }
    return usernameWidth;
  }

  public messageClicked(msg: ChatRoomMessage | ChatRoomProfaneMessage): void {
    this.message.emit(msg);
  }

  private checkSpecialMessage(message: string): boolean {
    return message.includes('sharedBet-RCF2408-') || message.includes('.gif');
  }

  public translateMessage() {
    this.translated = true;
    if (this.translatedMessage.length === 0){
      this.translationService.translate(this.item.message).subscribe(value => {
        this.translatedMessage = value.data.translations[0].translatedText;
      }, () => {
        this.handlingService.error("Couldn't translate this message");
      })
    }
  }

  public showOriginal() {
    this.translated = false;
  }

}
