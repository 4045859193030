import {ReportColumns} from "../report-columns.interface";

export class PlayersDetailsReport {
  gameName: string
  bets: number
  stakeEur: number
  winEur: number
  ggr: number
  ggrPerc: number
  columnInfo: ReportColumns[]

  constructor(gameName: string, bets: number, stakeEur: number, winEur: number, ggr: number, ggrPerc: number) {
    this.gameName = gameName;
    this.bets = bets;
    this.stakeEur = stakeEur;
    this.winEur = winEur;
    this.ggr = ggr;
    this.ggrPerc = ggrPerc;

    this.columnInfo = [
      {key: 'Game name', info: {field: 'gameName', total: 0, format: this.gameName, align: 'normal'}},
      {key: 'Bets', info: {field: 'bets', total: this.bets, format: this.formatInteger(this.bets), align: 'end'}},
      {key: 'Stake in EUR', info: {field: 'stakeEur', total: this.stakeEur, format: this.formatMoney(this.stakeEur), align: 'end'}},
      {key: 'Win in EUR', info: {field: 'winEur', total: this.winEur, format: this.formatMoney(this.winEur), align: 'end'}},
      {key: 'GGR', info: {field: 'ggr', total: this.ggr, format: this.formatMoney(this.ggr), align: 'end'}},
      {key: 'GGR %', info: {field: 'ggrPerc', total: this.ggrPerc, format: this.formatMoney(this.ggrPerc), align: 'end'}},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }
  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }

}
