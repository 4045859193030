import {ColumnMetaData} from "../../utils/column-meta-data.interface";
import {Injectable} from "@angular/core";
import {Mapper} from "../../utils/mapper.interface";
import {UnmappedCashbackReport} from "./unmapped/unmapped-cashback-report.interface";
import * as moment from "moment";

export class CashbackReport {

  date: string
  bet: number
  value: number
  currencyId: string

  columnInfo: ColumnMetaData[]

  constructor(date: string, bet: number, value: number, currencyId: string) {
    this.date = date;
    this.bet = bet;
    this.value = value;
    this.currencyId = currencyId;

    this.columnInfo = [
      {key: 'date', column: 'reports.cashbackReport.columns.date', field: 'date', total: 0, format: this.formatString(this.date), align: 'normal'},
      {key: 'bet', column: 'reports.cashbackReport.columns.bet', field: 'bet', total: this.bet, format: this.formatMoney(this.bet), align: 'end'},
      {key: 'value', column: 'reports.cashbackReport.columns.value', field: 'value', total: this.value, format: this.formatMoney(this.value), align: 'end'},
      {key: 'currency-id', column: 'reports.cashbackReport.columns.currency', field: 'currencyId', total: 0, format: this.formatString(this.currencyId), align: 'end'},
    ]
  }

  public formatString(string: string): any {
    if (string === null){
      return '';
    }
    return string;
  }

  public formatInteger(integer: number): any {
    if (integer === null){
      return '';
    }
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    if (money === null){
      return '';
    }
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }
}

@Injectable({providedIn: "root"})
export class CashbackReportMapper implements Mapper<CashbackReport> {
  public map(unmapped: UnmappedCashbackReport): CashbackReport {
    return new CashbackReport(
      moment(unmapped.CashBackDate).utc().format("YYYY-MM-DD"),
      unmapped.BetValue,
      unmapped.CashBackValue,
      unmapped.CurrencyID
    );
  }
}
