import {ReportColumns} from "../report-columns.interface";

export class RocketmanRoundReportDetails {

  ticketStatus: string
  ticketType: string
  bet: number
  currencyID: string
  multiplier: number
  win: number
  jackpot: number
  paid: number

  columnInfo: ReportColumns[];

  constructor(ticketStatus: string, ticketType: string, bet: number, currencyID: string, multiplier: number, win: number, jackpot: number, paid: number) {
    this.ticketStatus = ticketStatus;
    this.ticketType = ticketType;
    this.bet = bet;
    this.currencyID = currencyID;
    this.multiplier = multiplier;
    this.win = win;
    this.jackpot = jackpot;
    this.paid = paid;

    this.columnInfo = [
      {key: 'Ticket status', info: {field: 'ticketStatus', total: this.ticketStatus, format: this.ticketStatus, align: 'normal'}},
      {key: 'Ticket type', info: {field: 'ticketType', total: this.ticketType, format: this.ticketType, align: 'normal'}},
      {key: 'Bet', info: {field: 'bet', total: this.bet, format: this.formatInteger(this.bet), align: 'end'}},
      {key: 'Currency', info: {field: 'currencyID', total: this.currencyID, format: this.formatString(this.currencyID), align: 'end'}},
      {key: 'Multiplier', info: {field: 'multiplier', total: this.multiplier, format: this.formatTwoDecimals(this.multiplier), align: 'end'}},
      {key: 'Win', info: {field: 'win', total: this.win, format: this.formatMoney(this.win), align: 'end'}},
      {key: 'Jackpot', info: {field: 'jackpot', total: this.jackpot, format: this.formatMoney(this.jackpot), align: 'end'}},
      {key: 'Paid', info: {field: 'paid', total: this.paid, format: this.formatMoney(this.paid), align: 'end'}}
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }



  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatInteger(integer: number): string {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }

  public formatString(string: string): string {
    return string;
  }

  public formatTwoDecimals(integer: number): string {
    return integer.toFixed(2);
  }

}
