<h2 class="component-title">{{ 'dashboard.title' | transloco }}</h2>
<hr>
<div class="container">
<!--  <div class="row pb-2" *ngIf="!mobileDevice">-->
<!--    <app-dashboard-chart1></app-dashboard-chart1>-->
<!--  </div>-->
  <div class="row">
    <div class="col-lg-8" id="left">
      <app-dashboard-chart2></app-dashboard-chart2>
      <div class="row mt-1 pt-1">
        <div class="col-lg-7">
          <app-dashboard-chart5></app-dashboard-chart5>
        </div>
        <div class="col-lg-5">
          <app-dashboard-chart4></app-dashboard-chart4>
        </div>
      </div>
    </div>
    <div class="col-lg-4" id="right">
      <app-dashboard-chart3></app-dashboard-chart3>
    </div>
  </div>
</div>
