<h2 class="component-title">{{ 'reports.summaryReport.games.rocketman.title' | transloco }}</h2>
<hr>

<div class="frame round-border">
  <div class="row d-flex align-items-center">
    <div class="ps-3 pe-0 w-auto">
      <app-date-picker [placeholder]="'Start date'" (date)="setStartDate($event)" [init]="startDate"></app-date-picker>
    </div>
    <div class="ps-3 pe-0 w-auto">
      <app-date-picker [placeholder]="'End date'" (date)="setEndDate($event)" [init]="endDate"></app-date-picker>
    </div>
    <div class="ps-3 pe-0 w-auto mb-2">
      <button mat-stroked-button color="primary" (click)="refreshData()">
        <!--        <mat-icon>refresh</mat-icon>-->
        {{ 'buttons.refreshDataButton' | transloco }}
      </button>
    </div>
    <div class="ps-3 pe-3 w-auto mb-2">
      <button mat-stroked-button color="accent" (click)="downloadExcel()">
        <mat-icon>file_download</mat-icon>
        {{ 'buttons.excelButton' | transloco }}
      </button>
    </div>
  </div>
  <div class="max-height-table">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8 mt-0" matSort [hidden]="!showTable">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
        <th mat-header-cell
            class="header-cell"
            *matHeaderCellDef
            mat-sort-header="{{headerCell(column)}}"
            arrowPosition="after"
            [ngStyle]="{'text-align': alignHeaderCell(column)}"
            [class.header-end]="alignHeaderCell(column) === 'end'"
            [class.header-start]="alignHeaderCell(column) === 'normal'">
          {{ translationColumns[i] | transloco }}
        </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'text-align': element.align(column)}"> {{element.format(column)}} </td>
        <td mat-footer-cell class="footer-cell" *matFooterCellDef [ngStyle]="{'text-align': alignHeaderCell(column)}"> {{total(column)}} </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell class="expanded-row" *matCellDef="let element" [attr.colspan]="columns.length">
          <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <app-financial-report-details [elementDate]="element.dateId" [expandedElementDate]="expandedElement?.dateId" [game]="'rocketman'"></app-financial-report-details>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr mat-row *matRowDef="let element; let index = dataIndex; columns: columns;"
          class="element-row"
          [class.clicked-row]="expandedElement === element"
          [ngClass]="{alternating: index % 2 === 0}"
          (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      <tr mat-footer-row *matFooterRowDef="columns"></tr>
    </table>
  </div>
</div>





