<div class="d-flex w-100 h-100 align-items-center justify-content-center">
  <div class="frame round-border align-items-center justify-content-center">
    <form [formGroup]="loginForm" (ngSubmit)="submitForm()">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex flex-column">
            <div class="row">
              <div class="col d-flex justify-content-center">
                <img src="../../../assets/logo.png" alt="" width="110">
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <h2 class="login-text" style="font-size: 28px; font-weight: lighter;text-align: center;">{{ 'login.title' | transloco }}</h2>
            </div>
            <div class="row mt-3 pb-1 px-4 justify-content-center">
              <mat-form-field appearance="standard">
                <mat-label>{{ 'login.usernameLabel' | transloco }}</mat-label>
                <input class="login-text" type="text" matInput formControlName="username" style="font-size: 16px;">
                <mat-error *ngIf="password!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Username' } }}</mat-error>
              </mat-form-field>
            </div>
            <div class="row pb-1 px-4 justify-content-center">
              <mat-form-field appearance="standard">
                <mat-label>{{ 'login.passwordLabel' | transloco }}</mat-label>
                <input class="login-text" [autocomplete]="'new-password'" matInput [type]="hidePass ? 'password' : 'text'" formControlName="password" style="font-size: 16px">
                <button class="login-text" mat-icon-button type="button" matSuffix (click)="hidePass = !hidePass" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePass">
                  <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="password!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Password' } }}</mat-error>
              </mat-form-field>
            </div>
            <div class="row mt-5 mx-4 mb-4 d-flex justify-content-center">
              <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid">{{ 'login.loginButton' | transloco }}</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>



