import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {ChatRoom} from "../../models/chat-rooms/chat-room.interface";
import {ChatRoomMessage} from "../../models/chat-rooms/chat-room-message.interface";
import {UnmappedChatRoomMessage} from "../../models/chat-rooms/unmapped/unmapped-chat-room-message.interface";
import {ChatRoomProfaneMessage} from "../../models/chat-rooms/chat-room-profane-message.model";
import {
  UnmappedChatRoomProfaneMessage
} from "../../models/chat-rooms/unmapped/unmapped-chat-room-profane-message.interface";
import {HandlingService} from "../global-handling/handling.service";
import {HttpClient} from "@angular/common/http";
import {UnmappedIndecentUser} from "../../models/chat-rooms/unmapped/unmapped-indecent-user.interface";
import {UnmappedChatRoom} from "../../models/chat-rooms/unmapped/unmapped-chat-room.interface";
import {environment} from "../../../environments/environment";
import {RolesService} from "../roles/roles.service";
import {RoleState} from "../../models/roles/role-state.enum";

const baseUrl = environment.baseUrl;

export const ENDPOINT = environment.chatUrl;
export const SECRET = environment.chatSecret;

const ROCKETMAN = 'ROCKETMAN';
const COMMANDER = 'COMMANDER';

interface Function {
  fun: any
  params: any[]
  delay?: number
}

@Injectable({
  providedIn: 'root'
})
export class ChatRoomsService {

  public chatUsername: BehaviorSubject<string>
  public chatUsernameObs: Observable<string>

  public socket!: WebSocket
  private chatRooms = new BehaviorSubject<ChatRoom[]>([]);
  public chatRoomsObs = this.chatRooms.asObservable();

  public socketGate: boolean = false;
  private interval: number = 0;

  private callStack: Function[] = [];

  constructor(private handlingService: HandlingService,
              private rolesService: RolesService,
              private http: HttpClient) {
    this.chatUsername = new BehaviorSubject<string>('');
    this.chatUsernameObs = this.chatUsername.asObservable();
  }

  public initChatroomUsername(): void {
    const username = localStorage.getItem('chatName');
    if (username) {
      if (this.rolesService.isRole(RoleState.ADMIN) && username.toLowerCase() === ROCKETMAN.toLowerCase()){
        this.setChatroomUsername(COMMANDER);
      }else{
        this.setChatroomUsername(username);
      }
    }else{
      this.rolesService.isRole(RoleState.OWNER) ? this.setChatroomUsername(ROCKETMAN) : this.setChatroomUsername(COMMANDER);
    }
  }

  public setChatroomUsername(username: string) {
    if (this.rolesService.isRole(RoleState.ADMIN) && username.toLowerCase() === ROCKETMAN.toLowerCase()){
      return this.handlingService.error("Can't change username. This username requires higher privileges.");
    }
    localStorage.setItem('chatName', username);
    this.chatUsername.next(username);
  }

  public getChatroomUsername(): string {
    return this.chatUsername.value;
  }

  public initChatRooms(): void {
    this.callStack = [];
    this.callStack.push(
      {
        fun: this.fetchChatRooms,
        params: []
      }
    );
    this.socketGate = true;
    this.openSocket();
    this.refreshChats();
  }

  public openSocket(){
    if (!this.socket || !this.checkSocketStatus()){
      this.socket = new WebSocket(ENDPOINT);
      this.initSocket();
    }
  }

  public initSocket() {
    this.socket.onopen = (event) => {
      this.executeArray();
    }
    this.socket.onmessage = (event) => {
      this.mapData(event.data);
    }
    this.socket.onerror = (event) => {
      this.handlingService.error('There was an error with the socket connection to the chat server.');
    }
    this.socket.onclose = (event) => {
    }
  }

  private executeArray(): void {
    for (let i = 0; i < this.callStack.length; i++) {
      const executable = this.callStack[i];
      const delay = executable.delay ? executable.delay : 0;
      setTimeout(() => {
        executable.fun.apply(this, executable.params);
      }, delay);
    }
    this.callStack = [];
  }

  public closeSocket() {
    this.socketGate = false;
    clearInterval(this.interval);
  }

  public refreshChats(): void {
    this.interval = setInterval(() => {
      if (this.socketGate){
        this.fetchChatRooms();
      }
      return;
    }, 5000);
  }

  public fetchChatRooms(): void {
    if (this.checkSocketStatus()){
      this.socket.send(JSON.stringify({
        'method': 'FETCH_CHATROOMS',
        'secret': SECRET
      }));
    }else{
      this.callStack.push(
        {
          fun: this.fetchChatRooms,
          params: []
        }
      );
      this.openSocket();
    }
  }

  public sendNewMessage(message: string, companyId: number, msgLvl: number, chatRoom: string = '') {
    if (this.checkSocketStatus()){
      this.sendNewMessageRequest(message, companyId, msgLvl, chatRoom);
    }else{
      this.callStack.push(
        {
          fun: this.sendNewMessageRequest,
          params: [message, companyId, msgLvl, chatRoom]
        }
      );
      this.callStack.push(
        {
          fun: this.fetchChatRooms,
          params: [],
          delay: 2500
        }
      );
      this.openSocket();
    }
  }

  public sendNewMessageRequest(message: string, companyId: number, msgLvl: number, chatRoom: string = '') {
    const username = this.getChatroomUsername();
    try {
      this.socket.send(JSON.stringify({
        "method": 'ROCKETMAN_MOD_MESSAGE',
        "secret": SECRET,
        "message": message,
        "username": username,
        "company_id": companyId,
        "chat_room": chatRoom,
        "msg_lvl": msgLvl
      }));
      this.addSendingMessage(username, message, chatRoom);
    } catch (e) {
      this.handlingService.error('Error sending message to a company. Please try again.');
    }
  }

  private addSendingMessage(username: string, message: string, chatRoom: string = ''): void {
    let chatRooms = this.chatRooms.value;
    let msg = new ChatRoomMessage(
      username,
      '',
      message,
      '',
      -1,
      '',
      [],
      true);
    if (chatRoom === ''){
      chatRooms.forEach(room => {
        room.messages.push(msg);
      });
    }else{
      chatRooms.forEach(room => {
        if (room.name === chatRoom){
          room.messages.push(msg);
          return;
        }
      });
    }
    this.chatRooms.next(chatRooms);
  }

  public deleteMessage(message: ChatRoomMessage, room: ChatRoom): void {
    let messageId = message.messageId;
    let chatRoom = room.name;

    if (this.checkSocketStatus()){
      this.removeMessageRequest(messageId, chatRoom);
    }else{
      this.callStack.push(
        {
          fun: this.removeMessageRequest,
          params: [messageId, chatRoom]
        }
      );
      this.callStack.push(
        {
          fun: this.fetchChatRooms,
          params: []
        }
      );
      this.openSocket();
    }
  }

  private removeMessageRequest(messageId: string, chatRoom: string): void {
    try{
      this.socket.send(JSON.stringify({
        "method": 'REMOVE_CHAT_MESSAGE',
        "secret": SECRET,
        "chat_message_id": messageId,
        "chat_room": chatRoom
      }));
      this.removeMessageLocally(messageId, chatRoom);
    }catch (e) {
      this.handlingService.error('Error deleting message. Please try again.');
    }
  }

  private removeMessageLocally(messageId: string, chatRoom: string): void {
    let rooms = this.chatRooms.value;
    rooms.forEach(room => {
      if (room.name === chatRoom){
        let index = room.messages.map(function(message){return message.messageId; }).indexOf(messageId);
        if (index > -1){
          room.messages.splice(index, 1);
        }
        return;
      }
    });
  }

  private mapData(json: string) {
    let obj = JSON.parse(json);
    let chatRooms = this.chatRooms.value;
    Object.entries(obj).forEach(([key, value]) => {
      if (this.chatRoomExists(key)){
        chatRooms.forEach(room => {
          this.removeSendingMessages(room);
          if (room.name === key){
            let messages = this.mapMessages((<UnmappedChatRoom>value).history);
            messages.forEach(msg => {
              if (!this.messageExists(room, msg.messageId)){
                room.messages.push(new ChatRoomMessage(
                  msg.username,
                  msg.messageId,
                  msg.message,
                  msg.time,
                  msg.avatar_id,
                  msg.country_code,
                  msg.likes_who));
              }
            });
            room.online = (<UnmappedChatRoom>value).online;
          }
        })
      }else{
        const chatRoom = <UnmappedChatRoom>value;
        chatRooms.push(new ChatRoom(key, this.mapMessages(chatRoom.history), chatRoom.online));
      }
    })
    this.chatRooms.next(chatRooms);
  }

  private removeSendingMessages(chatRoom: ChatRoom): void {
    let index: number = -1;
    chatRoom.messages.forEach(msg => {
      if (msg.sendingState){
        index = chatRoom.messages.indexOf(msg);
        return;
      }
    });
    if (index > -1){
      chatRoom.messages.splice(index, 1);
    }
  }

  private chatRoomExists(name: string): boolean {
    let result = false;
    this.chatRooms.value.forEach(room => {
      if (room.name === name){
        result = true;
        return
      }
    })
    return result;
  }

  private messageExists(room: ChatRoom, id: string): boolean {
    let result = false;
    room.messages.forEach(msg => {
      if (msg.messageId === id){
        result = true;
        return
      }
    })
    return result;
  }

  private mapMessages(unmapped: UnmappedChatRoomMessage[]): ChatRoomMessage[] {
    let result: ChatRoomMessage[] = [];
    unmapped.forEach(msg => {
      result.push(
        new ChatRoomMessage(
          msg.username,
          msg.id,
          msg.msg,
          msg.time,
          msg.avatar_id,
          msg.country_code,
          msg.likes_who))
    });
    return result;
  }

  private checkSocketStatus(): boolean {
    return this.socket.readyState === 1;
  }

  public getMessageHistoryAlt(messageId: string, date: string): Observable<UnmappedChatRoomProfaneMessage[]> {
    const url = baseUrl.concat('chat-room/history-alt');
    return this.http.get<UnmappedChatRoomProfaneMessage[]>(url, {
      params: {
        messageId: ~~messageId,
        date: date
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
  }

  public getMessageHistory(userId: number, date: string): Observable<UnmappedChatRoomProfaneMessage[]> {
    const url = baseUrl.concat('chat-room/history');
    return this.http.get<UnmappedChatRoomProfaneMessage[]>(url, {
      params: {
        userId: userId,
        date: date
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
  }

  public mapMessageHistory(unmappedMessages: UnmappedChatRoomProfaneMessage[]): ChatRoomProfaneMessage[] {
    let result: ChatRoomProfaneMessage[] = [];
    unmappedMessages.forEach(unmapped => {
      result.push(
        new ChatRoomProfaneMessage(unmapped.UserID, unmapped.UserName, unmapped.MessageID, unmapped.MessageText, unmapped.Profane)
      );
    });
    return result;
  }

  public banUser(userId: number, banDays: number) {
    const url = baseUrl.concat('chat-room/ban-user');
    return this.http.post(url, {
      userId: userId,
      banDays: banDays
    }, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
  }

  public banUserAlt(messageId: string, banDays: number) {
    const url = baseUrl.concat('chat-room/ban-user-alt');
    return this.http.post(url, {
      messageId: ~~messageId,
      banDays: banDays
    }, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
  }

  public getIndecentUsers(date: string): Observable<UnmappedIndecentUser[]> {
    const url = baseUrl.concat('chat-room/profane-users');
    return this.http.get<UnmappedIndecentUser[]>(url, {
      params: {
        date: date,
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
  }

}
