<mat-form-field [appearance]="appearance" class="date-input" [style.max-width]="maxWidth + 'px'">
  <mat-label>{{placeholder}}</mat-label>
  <input #input matInput
         [placeholder]="placeholder"
         [matDatepicker]="picker"
         (dateChange)="changeDate($event.value)"
         [(ngModel)]="default"
         style="font-size: 18px">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
