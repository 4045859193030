<form [formGroup]="editingForm" (ngSubmit)="submitForm()">
  <div class="container pb-2">
    <div class="row">
      <div class="flex-container">
        <h2 mat-dialog-title style="font-size: 24px; font-weight: lighter;">{{ 'configurations.company.jackpot.editJackpotDialog.title' | transloco: {value: jackpot.jackPotName} }}</h2>
        <span class="spacer"></span>
      </div>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="container" style="overflow-y: hidden">
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline" style="font-size: 16px; width: 100%;">
          <mat-label>{{ 'configurations.company.jackpot.editJackpotDialog.jackpotPercentageLabel' | transloco: {value: jackpot.jackPotName} }}</mat-label>
          <input matInput type="number" min="0" formControlName="jackPotPercentage">
          <mat-error *ngIf="jackPotPercentage!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Jackpot percentage' } }}</mat-error>
          <mat-error *ngIf="jackPotPercentage!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Jackpot percentage' } }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline" style="font-size: 16px; width: 100%;">
          <mat-label>{{ 'configurations.company.jackpot.editJackpotDialog.jackpotReservePercentageLabel' | transloco: {value: jackpot.jackPotName} }}</mat-label>
          <input matInput type="number" min="0" formControlName="jackPotReservePercentage">
          <mat-error *ngIf="jackPotReservePercentage!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Jackpot reserve percentage' } }}</mat-error>
          <mat-error *ngIf="jackPotReservePercentage!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Jackpot reserve percentage' } }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline" style="font-size: 16px; width: 100%;">
          <mat-label>{{ 'configurations.company.jackpot.editJackpotDialog.jackpotMinValueLabel' | transloco: {value: jackpot.jackPotName} }}</mat-label>
          <input matInput type="number" min="0" formControlName="jackPotMinValue">
          <mat-error *ngIf="jackPotMinValue!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Jackpot min value' } }}</mat-error>
          <mat-error *ngIf="jackPotMinValue!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Jackpot min value' } }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline" style="font-size: 16px; width: 100%;">
          <mat-label>{{ 'configurations.company.jackpot.editJackpotDialog.jackpotMaxValueLabel' | transloco: {value: jackpot.jackPotName} }}</mat-label>
          <input matInput type="number" min="0" formControlName="jackPotMaxValue">
          <mat-error *ngIf="jackPotMaxValue!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Jackpot max value' } }}</mat-error>
          <mat-error *ngIf="jackPotMaxValue!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Jackpot max value' } }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0">
        <mat-checkbox class="checkbox pb-1 w-auto" color="primary" style="font-size: 16px" formControlName="jackPotActive">
          {{ 'configurations.company.jackpot.editJackpotDialog.jackpotActiveLabel' | transloco: {value: jackpot.jackPotName} }}
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="container my-2 pe-2">
    <div mat-dialog-actions class="d-flex flex-row-reverse w-100">
      <button class="px-4 ms-2" type="submit" mat-flat-button color="primary" [disabled]="!editingForm.valid">{{ 'buttons.saveButton' | transloco }}</button>
      <button class="px-3" type="button" mat-stroked-button color="accent" (click)="onNoClick()">{{ 'buttons.cancelButton' | transloco }}</button>
    </div>
  </div>
</form>
