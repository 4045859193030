import {ReportColumns} from "../reports/report-columns.interface";

export class TournamentAward {
  tournamentId: number
  place: number
  award: number
  columnInfo: ReportColumns[]

  constructor(tournamentId: number, place: number, award: number) {
    this.tournamentId = tournamentId;
    this.place = place;
    this.award = award;

    this.columnInfo = [
      {key: 'Place', info: {field: 'place', total: this.place, format: this.place, align: 'normal'}},
      {key: 'Award', info: {field: 'award', total: this.award, format: this.formatMoney(this.award), align: 'normal'}},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }
}
