import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {Tournament} from "../../../models/tournaments/tournament.model";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {UtilsService} from "../../../services/utils/utils.service";
import {ExcelService} from "../../../services/excel/excel.service";
import {TournamentsService} from "../../../services/tournaments/tournaments.service";
import {UnmappedTournament} from "../../../models/tournaments/unmapped/unmapped-tournament.interface";
import {MatDialog} from "@angular/material/dialog";
import {AddTournamentComponent} from "../add-tournament/add-tournament.component";
import {EditTournamentComponent} from "../edit-tournament/edit-tournament.component";
import {CustomDialogComponent} from "../../dialogs/custom-dialog/custom-dialog.component";
import {UnmappedTournamentCompany} from "../../../models/tournaments/unmapped/unmapped-tournament-company.interface";
import {TournamentCompany} from "../../../models/tournaments/tournament-company.model";
import {UnmappedTournamentAward} from "../../../models/tournaments/unmapped/unmapped-tournament-award.interface";
import {TournamentAward} from "../../../models/tournaments/tournament-award.model";
import {CompanyEditorDialogComponent} from "../company-editor-dialog/company-editor-dialog.component";
import {AwardEditorDialogComponent} from "../award-editor-dialog/award-editor-dialog.component";
import { CompanySimple } from 'src/app/models/configurations/company-simple.interface';
import * as moment from "moment";

@Component({
  selector: 'app-manage-tournaments',
  templateUrl: './manage-tournaments.component.html',
  styleUrls: ['./manage-tournaments.component.scss']
})
export class ManageTournamentsComponent implements OnInit, AfterViewInit {

  public tournamentsDataSource!: MatTableDataSource<Tournament>;
  @ViewChildren(MatSort) sortingRaw!: QueryList<MatSort>;
  private sorting: MatSort[] = [];

  public showTournamentsTable = false;
  public tournamentsColumns: string[] = [];
  public tournamentsTranslationColumns: string[] = [];
  public expandedTournamentElement: Tournament | null = null;

  public tournamentCompanies: TournamentCompany[] = [];
  public tournamentAwards: TournamentAward[] = [];

  public tournamentCompaniesColumns: string[] = [];
  public tournamentCompaniesTranslationsColumns: string[] = [];
  public tournamentAwardsColumns: string[] = [];
  public tournamentAwardsTranslationsColumns: string[] = [];

  public expandedCompanyElement: TournamentCompany | null = null;
  public expandedAwardElement: TournamentAward | null = null;

  public showTournamentCompanies: boolean = false;
  public showTournamentAwards: boolean = false;

  public allTournaments: boolean = false;

  constructor(private tournamentsService: TournamentsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private utilsService: UtilsService,
              private excelService: ExcelService,
              private dialog: MatDialog) {
    this.tournamentsTranslationColumns = tournamentsService.getTournamentsTranslationColumns();
    this.tournamentCompaniesTranslationsColumns = tournamentsService.getTournamentCompaniesTranslationColumns();
    this.tournamentAwardsTranslationsColumns = tournamentsService.getTournamentAwardsTranslationColumns();
    this.fetchTournaments();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.sorting = this.sortingRaw.toArray();
  }

  public showPreviousTournaments(): void {
    this.allTournaments = !this.allTournaments;
    this.expandedTournamentElement = null;
    this.tournamentCompanies = [];
    this.tournamentAwards = [];
    this.expandedCompanyElement = null;
    this.expandedAwardElement = null;
    this.showTournamentCompanies = false;
    this.showTournamentAwards = false;
    this.fetchTournaments();
  }

  private fetchTournaments(): void {
    this.loadingService.setLoadingSteps(1);
    this.tournamentsService.getTournaments(this.allTournaments ? 1 : 0).subscribe(data => {
      this.tournamentsDataSource = new MatTableDataSource(this.mapTournamentsData(data));
      this.tournamentsDataSource.sort = this.sorting[0];

      if (this.tournamentsDataSource.data.length === 0) {
        this.hideTournaments();
      } else {
        this.showTournaments();
      }

    }, error => {
      this.handlingService.error('Error while fetching tournaments data', error)
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  public addTournament() {
    const dialogRef = this.dialog.open(AddTournamentComponent, {
      width: '505px',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.setLoadingSteps(1);
        this.tournamentsService.addTournament(result.data).subscribe(value => {
            if (value.ID === 0) {
              this.handlingService.error('Failed to add new tournament');
            } else {
              this.handlingService.success('Tournament successfully added');
              this.refreshElement(result.data, value.ID, value.TournamentStatus, value.TournamentStatusDesc);
            }
          },
          error => {
            this.handlingService.error('Failed to add new tournament', error);
          },
          () => this.loadingService.incrementLoadingSteps());
      }
    })
  }

  public editTournament() {
    if (this.expandedTournamentElement) {
      if (this.expandedTournamentElement.tournamentStatusNumber === 1) {
        const dialogRef = this.dialog.open(EditTournamentComponent, {
          width: '505px',
          autoFocus: false,
          data: this.expandedTournamentElement
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.loadingService.setLoadingSteps(1);
            this.tournamentsService.updateTournament(result.data).subscribe(value => {
                this.updateElementAfterEdit(result.data, value.TournamentStatus, value.TournamentStatusDesc);
                this.handlingService.success('Tournament successfully updated');
              }, error => {
                this.handlingService.error('Failed to update tournament', error);
              },
              () => this.loadingService.incrementLoadingSteps());
          }
        });
      } else {
        this.handlingService.error("Can't update tournament after it has been announced");
      }
    } else {
      this.handlingService.error('Please select a tournament');
    }
  }

  public deleteTournament() {
    if (this.expandedTournamentElement) {
      if (this.expandedTournamentElement.tournamentStatusNumber === 1) {
        const dialogRef = this.dialog.open(CustomDialogComponent, {
          width: '350px',
          data: {
            title: `Delete tournament`,
            icon: 'delete',
            message: `Are you sure you want to delete tournament ${this.expandedTournamentElement.tournamentName}?`,
            action: 'Delete'
          },
          autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result && result.result && this.expandedTournamentElement) {
            this.loadingService.setLoadingSteps(1);
            this.tournamentsService.deleteTournament(this.expandedTournamentElement).subscribe(value => {
              this.checkCompaniesAndAwardsAfterDeletedTournament();
              this.tournamentsDataSource.data = this.tournamentsDataSource.data.filter(item => item.id !== this.expandedTournamentElement?.id);
              this.handlingService.success('Tournament successfully deleted');
            }, error => {
              this.handlingService.error('Failed to delete tournament', error);
            }, () => this.loadingService.incrementLoadingSteps())
          }
        });
      } else {
        this.handlingService.error("Can't delete tournament after it has been announced");
      }
    } else {
      this.handlingService.error('Please select a tournament');
    }
  }

  private mapTournamentsData(unmapped: UnmappedTournament[]): Tournament[] {
    let data: Tournament[] = [];
    unmapped.forEach(item => {
      const fromDateLocal = this.utilsService.timestampToString(item.FromDateTime);
      const toDateLocal = this.utilsService.timestampToString(item.ToDateTime);

      data.push(
        new Tournament(
          item.ID,
          item.TournamentName,
          item.CurrencyID,
          fromDateLocal,
          toDateLocal,
          item.TournamentTypeDesc,
          item.MinBet,
          item.TournamentStatus,
          item.TournamentStatusDesc,
          item.TournamentDescription,
          item.ShowHoursBeforeStart));
    })
    return data;
  }

  private refreshElement(element: Tournament, newId: number, newStatusNumber: number, newStatus: string): void {
    const newElement = new Tournament(newId, element.tournamentName, element.currencyId,
      element.fromDateTime,
      element.toDateTime,
      element.tournamentType, element.minBet, newStatusNumber, newStatus, element.tournamentDescription, element.showBefore);
    this.tournamentsDataSource.data.push(newElement);
    this.tournamentsDataSource.data = this.tournamentsDataSource.data;
    this.showTournaments()
  }

  private updateElementAfterEdit(tournament: Tournament, newStatusNumber: number, newStatus: string) {
    const newTournament = new Tournament(
      tournament.id,
      tournament.tournamentName,
      tournament.currencyId,
      tournament.fromDateTime,
      tournament.toDateTime,
      tournament.tournamentType,
      tournament.minBet,
      newStatusNumber,
      newStatus,
      tournament.tournamentDescription,
      tournament.showBefore
    );
    const index = this.tournamentsDataSource.data.indexOf(tournament);
    this.tournamentsDataSource.data.splice(index, 1);
    this.tournamentsDataSource.data.splice(index, 0, newTournament);
    this.tournamentsDataSource.data = this.tournamentsDataSource.data;
  }

  private showTournaments(): void {
    if (this.tournamentsColumns.length === 0) {
      this.tournamentsColumns = this.tournamentsDataSource.data[0].getFields();
    }
    this.showTournamentsTable = true;
  }

  private hideTournaments(): void {
    this.showTournamentsTable = false;
    this.tournamentsDataSource = new MatTableDataSource<Tournament>([]);
  }

  public alignTournamentHeaderCell(column: string): string {
    if (this.tournamentsDataSource.data.length === 0) {
      return 'normal';
    }
    return this.tournamentsDataSource.data[0].align(column);
  }

  public tournamentHeaderCell(column: string): string {
    if (this.tournamentsDataSource.data.length === 0) {
      return 'normal';
    }
    return this.tournamentsDataSource.data[0].field(column);
  }

  public fetchTournamentCompanies() {
    if (this.expandedTournamentElement) {
      this.loadingService.setLoadingSteps(1);
      this.tournamentsService.getTournamentCompanies(this.expandedTournamentElement.id).subscribe(value => {
        if (!this.expandedTournamentElement) {
          const id = value[0].TournamentID;
          const selected = this.tournamentsDataSource.data.find(item => item.id === id);
          this.expandedTournamentElement = selected ? selected : null;
        }
        if (this.expandedTournamentElement) {
          this.tournamentCompanies = this.mapTournamentCompanies(value);
          this.initTournamentCompaniesColumns(this.tournamentCompanies);
        }
      }, error => {
        this.handlingService.error('Failed to fetch tournament companies', error);
      }, () => this.loadingService.incrementLoadingSteps())
    }
  }

  public fetchTournamentAwards() {
    if (this.expandedTournamentElement) {
      this.loadingService.setLoadingSteps(1);
      this.tournamentsService.getTournamentAwards(this.expandedTournamentElement.id).subscribe(value => {
        if (!this.expandedTournamentElement) {
          const id = value[0].TournamentID;
          const selected = this.tournamentsDataSource.data.find(item => item.id === id);
          this.expandedTournamentElement = selected ? selected : null;
        }
        if (this.expandedTournamentElement) {
          this.tournamentAwards = this.mapTournamentAwards(value);
          this.initTournamentAwardsColumns(this.tournamentAwards);
        }
      }, error => {
        this.handlingService.error('Failed to fetch tournament awards', error);
      }, () => this.loadingService.incrementLoadingSteps())
    }
  }

  public tournamentClicked(tournament: Tournament) {
    this.expandedTournamentElement = tournament;
    this.fetchTournamentCompanies();
    this.fetchTournamentAwards();
  }

  private mapTournamentCompanies(unmapped: UnmappedTournamentCompany[]): TournamentCompany[] {
    return unmapped.map(item => new TournamentCompany(item.CompanyID, item.TournamentID, item.CompanyName));
  }

  private mapTournamentAwards(unmapped: UnmappedTournamentAward[]): TournamentAward[] {
    return unmapped.map(item => new TournamentAward(item.TournamentID, item.Place, item.PlaceAward));
  }

  private initTournamentCompaniesColumns(companies: TournamentCompany[]) {
    if (companies.length > 0) {
      this.showTournamentCompanies = true;
      this.tournamentCompaniesColumns = companies[0].getFields();
    } else {
      this.showTournamentCompanies = false;
      this.tournamentCompaniesColumns = [];
    }
  }

  private initTournamentAwardsColumns(awards: TournamentAward[]) {
    if (awards.length > 0) {
      this.showTournamentAwards = true;
      this.tournamentAwardsColumns = awards[0].getFields();
    } else {
      this.showTournamentAwards = false;
      this.tournamentAwardsColumns = [];
    }
  }

  private checkCompaniesAndAwardsAfterDeletedTournament() {
    if (this.expandedTournamentElement) {
      if (this.tournamentAwards.length > 0) {
        if (this.tournamentAwards[0].tournamentId === this.expandedTournamentElement.id) {
          this.tournamentAwards = [];
        }
      }
      if (this.tournamentCompanies.length > 0) {
        if (this.tournamentCompanies[0].tournamentId === this.expandedTournamentElement.id) {
          this.tournamentCompanies = [];
        }
      }
    }
  }

  public editCompaniesInTournament() {
    if (this.expandedTournamentElement) {
      if (this.expandedTournamentElement.tournamentStatusNumber === 1) {
        const dialogRef = this.dialog.open(CompanyEditorDialogComponent, {
          width: '505px',
          autoFocus: false,
          data: {tournament: this.expandedTournamentElement, companies: this.tournamentCompanies}
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result && this.expandedTournamentElement) {
            this.fetchTournamentCompanies();
            this.fetchTournamentAwards();
          }
        });
      }else{
        this.handlingService.error("Can't update tournaments companies after it has been announced");
      }
    }else{
      this.handlingService.error('Please select a tournament');
    }
  }

  public editAwardsInTournament() {
    if (this.expandedTournamentElement){
      if (this.expandedTournamentElement.tournamentStatusNumber === 1) {
        const dialogRef = this.dialog.open(AwardEditorDialogComponent, {
          width: '505px',
          autoFocus: false,
          data: {tournament: this.expandedTournamentElement, awards: this.tournamentAwards}
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result && this.expandedTournamentElement) {
            this.fetchTournamentCompanies();
            this.fetchTournamentAwards();
          }
        });
      }else {
        this.handlingService.error("Can't update tournaments awards after it has been announced");
      }
    }else{
      this.handlingService.error('Please select a tournament');
    }
  }

  public announceTournament() {
    if (this.expandedTournamentElement) {
      if (this.tournamentAwards.length === 0 || this.tournamentCompanies.length === 0){
        this.handlingService.error("Tournament must have at least one award and company to be announced");
        return;
      }

      if (this.expandedTournamentElement.tournamentStatusNumber === 1) {
        const dialogRef = this.dialog.open(CustomDialogComponent, {
          width: '350px',
          data: {
            title: `Announce tournament`,
            icon: 'announcement',
            message: `Are you sure you want to announce tournament ${this.expandedTournamentElement.tournamentName}?`,
            action: 'Announce'
          },
          autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result && result.result && this.expandedTournamentElement) {
            this.loadingService.setLoadingSteps(1);
            this.tournamentsService.announceTournament(this.expandedTournamentElement.id).subscribe(value => {
              if (!this.expandedTournamentElement) {
                const id = value.ID;
                const selected = this.tournamentsDataSource.data.find(item => item.id === id);
                this.expandedTournamentElement = selected ? selected : null;
              }
              if (this.expandedTournamentElement) {
                this.updateElementAfterEdit(this.expandedTournamentElement, value.TournamentStatus, value.TournamentStatusDesc);
                this.handlingService.success('Tournament successfully announced');
              }
            }, error => {
              this.handlingService.error('Failed to announce tournament', error);
            }, () => this.loadingService.incrementLoadingSteps());
          }
        });
      }else{
        this.handlingService.error("This tournament has already been announced");
      }
    }else {
      this.handlingService.error('Please select a tournament');
    }
  }

}
