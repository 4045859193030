import {ReportColumns} from "../reports/report-columns.interface";

export class TournamentCompany {
  id: number
  tournamentId: number
  name: string
  columnInfo: ReportColumns[]

  constructor(id: number, tournamentId: number, name: string) {
    this.id = id;
    this.tournamentId = tournamentId;
    this.name = name;

    this.columnInfo = [
      {key: 'Company name', info: {field: 'name', total: this.name, format: this.name, align: 'normal'}},
    ]
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }
}
