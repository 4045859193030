<h2 class="component-title">{{ gameName | transloco }}</h2>
<hr>

<div class="frame round-border">
  <div class="row d-flex align-items-center my-2" *ngIf="!support">
    <div class="ps-2 ms-1 pe-0 w-auto mb-2">
      <button mat-stroked-button color="primary" (click)="editJackpot()">
        <mat-icon>edit</mat-icon>
        {{ 'buttons.editJackpotButton' | transloco }}
      </button>
    </div>
    <div class="ps-2 ms-1 pe-0 w-auto mb-2">
      <button mat-stroked-button color="accent" (click)="refreshData()">
        <!--        <mat-icon>remove_circle_outline</mat-icon> -->
        {{ 'buttons.refreshButton' | transloco }}
      </button>
    </div>
  </div>
  <div class="max-height-table">
    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8 mt-0">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
        <th mat-header-cell
            class="header-cell"
            mat-sort-header="{{headerCell(column)}}"
            arrowPosition="after"
            *matHeaderCellDef
            [ngStyle]="{'text-align': alignHeaderCell(column)}">
          {{ translationColumns[i] | transloco }}
        </th>
        <td mat-cell class="cell" *matCellDef="let element" [ngStyle]="{'text-align': element.align(column)}"> {{element.format(column)}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; let i = index; columns: columns;"
          (click)="selectJackpot(row)"
          class="element-row"
          [class.clicked-row]="row === selected"
          [ngClass]="{alternating: i % 2 === 0}"></tr>
    </table>
  </div>
</div>
