<div class="d-flex w-100 h-100 align-items-center justify-content-center">
  <div class="frame align-items-center justify-content-center">
    <form [formGroup]="signUpForm" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <img class="" src="../../../assets/logo.png" alt="" width="90" height="90">
        </div>
        <div class="col-12 mb-1 px-4 d-flex justify-content-center">
          <h2 class="" style="font-size: 28px; font-weight: lighter;text-align:center;">{{ 'twoFactorSignUp.title' | transloco }}</h2>
        </div>
        <div class="col-12 mb-1 d-flex justify-content-center inputField">
          <input class="form-control" [value]="" aria-label="" placeholder="First name" formControlName="firstName">
        </div>
        <div class="col-12 mb-3 d-flex inputField" style="color: red">
          <div class="error-message" *ngIf="firstName?.errors?.required">
            {{ 'errorMessages.required' | transloco: { name: 'First name' } }}
          </div>
        </div>
        <div class="col-12 mb-1 d-flex justify-content-center inputField">
          <input class="form-control" [value]="" aria-label="" placeholder="Last name" formControlName="lastName">
        </div>
        <div class="col-12 mb-3 d-flex inputField" style="color: red">
          <div class="error-message" *ngIf="lastName?.errors?.required">
            {{ 'errorMessages.required' | transloco: { name: 'Last name' } }}
          </div>
        </div>
        <div class="col-12 mb-1 d-flex justify-content-center inputField">
          <input type="email" class="form-control" [value]="" aria-label="" placeholder="Email" formControlName="email">
        </div>
        <div class="col-12 mb-3 d-flex inputField" style="color: red">
          <div class="error-message" *ngIf="email?.errors?.required">
            {{ 'errorMessages.required' | transloco: { name: 'Email' } }}
          </div>
          <small *ngIf="email!.errors?.pattern" class="text-danger">
            {{ 'errorMessages.email' | transloco }}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-3 d-flex justify-content-center">
          <a [routerLink]="'/'">{{ 'twoFactorSignUp.back' | transloco }}</a>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-4 d-flex justify-content-center">
          <button class="btn btn-primary buttonLogin" type="submit" [disabled]="!signUpForm.valid">{{ 'twoFactorSignUp.signUpButton' | transloco }}</button>
        </div>
      </div>
    </form>
  </div>
</div>



