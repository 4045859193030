<form [formGroup]="editingForm" (ngSubmit)="submitForm()">
  <div class="container pb-2">
    <div class="row">
      <div class="flex-container">
        <h2 mat-dialog-title style="font-size: 24px; font-weight: lighter;">{{ 'configurations.user.editUserDialog.title' | transloco: { value: user.username } }}</h2>
      </div>
    </div>
  </div>

  <div mat-dialog-content style="min-height: 450px;">
    <div class="container" style="overflow-y: hidden">
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'configurations.user.usernameLabel' | transloco }}</mat-label>
          <input matInput formControlName="username" [value]="user.username" style="font-size: 16px">
          <mat-error *ngIf="username!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Username' } }}</mat-error>
          <mat-error *ngIf="username!.errors?.minlength">{{ 'errorMessages.minlength' | transloco: { name: 'Username' } }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'configurations.user.passwordLabel' | transloco }}</mat-label>
          <input matInput [type]="hidePass ? 'password' : 'text'" [value]="" formControlName="password" style="font-size: 16px">
          <button mat-icon-button matSuffix type="button" (click)="hidePass = !hidePass" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePass">
            <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="password!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Password' } }}</mat-error>
          <mat-error *ngIf="password!.errors?.minlength">{{ 'errorMessages.minlength' | transloco: { name: 'Password' } }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'configurations.user.emailLabel' | transloco }}</mat-label>
          <input matInput type="email" formControlName="email" [value]="user.email" style="font-size: 16px">
          <mat-error *ngIf="email!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Email' } }}</mat-error>
          <mat-error *ngIf="email!.errors?.email">{{ 'errorMessages.email' | transloco }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <div class="col">
          <mat-form-field appearance="outline" class="small-form-field">
            <mat-label>{{ 'configurations.user.companyLabel' | transloco }}</mat-label>
            <input matInput formControlName="company" [value]="user.companyName" style="font-size: 16px">
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline" class="small-form-field">
            <mat-label>{{ 'configurations.user.roleLabel' | transloco }}</mat-label>
            <input matInput formControlName="role" [value]="user.userRole" style="font-size: 16px">
          </mat-form-field>
        </div>
      </div>
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'configurations.user.commentLabel' | transloco }}</mat-label>
          <input matInput formControlName="comment" [value]="user.comment" style="font-size: 16px">
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <a (click)="advanced = !advanced" style="width: 140px; cursor: pointer; text-decoration: underline">Advanced options</a>
      </div>
      <div class="row" [@openClose]="advanced ? 'open' : 'closed'" *ngIf="advanced">
        <div class="col d-flex flex-column">
          <mat-checkbox *ngIf="!editingMyself" class="checkbox py-1" color="primary" style="font-size: 16px" formControlName="lockedOut">
            {{ 'configurations.user.lockedOutLabel' | transloco }}
          </mat-checkbox>
          <mat-checkbox *ngIf="owner" class="checkbox pb-1" color="primary" style="font-size: 16px" formControlName="resetSecretCode">
            {{ 'configurations.user.resetSecretCodeLabel' | transloco }}
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>

  <div class="container mb-2">
      <div mat-dialog-actions class="d-flex flex-row-reverse w-100">
        <button class="px-4 ms-2" type="submit" mat-flat-button color="primary" [disabled]="!editingForm.valid">{{ 'buttons.saveButton' | transloco }}</button>
        <button class="px-3" type="button" mat-stroked-button color="accent" (click)="onNoClick()">{{ 'buttons.cancelButton' | transloco }}</button>
      </div>
  </div>
</form>
