import { Component, OnInit } from '@angular/core';
import {NavigationService} from "../../../services/navigation/navigation.service";
import {TranslocoService} from "@ngneat/transloco";
import {LocalizationService} from "../../../services/localization/localization.service";
import {LoginService} from "../../../services/login/login.service";
import {MatDialog} from "@angular/material/dialog";
import {ProfileDialogComponent} from "../../profile-dialog/profile-dialog.component";
import {Credentials} from "../../../models/user/credentials.model";
import {ConfigurationsService} from "../../../services/configurations/configurations.service";
import {ThemeService} from "../../../services/theme/theme.service";
import {MobileService} from "../../../services/mobile/mobile.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public locales: string[] = ['en-GB', 'sr-Latn', 'fr', 'ru', 'it']
  public locale: string = this.locales[0];

  public name: string = ''

  constructor(private configurationsService: ConfigurationsService,
              public navigationService: NavigationService,
              private translocoService: TranslocoService,
              private localizationService: LocalizationService,
              private mobileService: MobileService,
              public loginService: LoginService,
              private themeService: ThemeService,
              public dialog: MatDialog) {
    configurationsService.usernameObs.subscribe(username => {
      this.name = username;
    })
  }

  ngOnInit(): void {}

  public updateLocale(locale: string): void {
    if (this.locales.some(l => l === locale)) {
      this.locale = locale;
    }
    const lang = locale.substring(0, 2);
    this.translocoService.setActiveLang(lang);
    this.localizationService.setLocale(locale);
  }

  public profile() {
    let width: string = this.mobileService.getMobileDevice() ? '288px' : '350px';
    const dialogRef = this.dialog.open(ProfileDialogComponent, {
      width: width,
      height: '540px',
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
    })
  }

  public logout(){
    this.loginService.logout();
  }

  public lightTheme(): void {
    this.themeService.selectLightTheme();
  }

  public darkTheme(): void {
    this.themeService.selectDarkTheme();
  }

}
