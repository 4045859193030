<form [formGroup]="addingForm" (ngSubmit)="submitForm()" autocomplete="off">
  <div class="container pb-2">
    <div class="row">
      <div class="flex-container">
        <h2 mat-dialog-title style="font-size: 24px; font-weight: lighter;">{{ 'configurations.user.addUserDialog.title' | transloco }}</h2>
      </div>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="container">
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'configurations.user.usernameLabel' | transloco }}</mat-label>
          <input matInput formControlName="username" [value]="" style="font-size: 16px">
          <mat-error *ngIf="username!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Username' } }}</mat-error>
          <mat-error *ngIf="username!.errors?.minlength">{{ 'errorMessages.minlength' | transloco: { name: 'Username' } }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'configurations.user.passwordLabel' | transloco }}</mat-label>
          <input matInput [type]="hidePass ? 'password' : 'text'" formControlName="password" style="font-size: 16px">
          <button mat-icon-button matSuffix (click)="hidePass = !hidePass" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePass">
            <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="password!.errors?.required">{{ 'Password is required.' | transloco }}</mat-error>
          <mat-error *ngIf="password!.errors?.pattern">{{ 'At least 8 char. long, include a mix of upper and lowercase letters, num and spec. char.' | transloco }}</mat-error>
          <mat-error *ngIf="password?.errors?.usernamePasswordMatch">
            The username and password must not match.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'configurations.user.emailLabel' | transloco }}</mat-label>
          <input matInput type="email" formControlName="email" [value]="" style="font-size: 16px">
          <mat-error *ngIf="email!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Email' } }}</mat-error>
          <mat-error *ngIf="email!.errors?.email">{{ 'errorMessages.email' | transloco }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <div class="col">
          <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
            <mat-label>{{ 'configurations.user.companyLabel' | transloco }}</mat-label>
            <mat-select formControlName="company">
              <mat-option [value]="company" *ngFor="let company of companies">
                {{company.CompanyName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="company!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Company' } }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
            <mat-label>{{ 'configurations.user.roleLabel' | transloco }}</mat-label>
            <mat-select formControlName="role">
              <mat-option [value]="role" *ngFor="let role of roles">
                {{role}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="role!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Company' } }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'configurations.user.commentLabel' | transloco }}</mat-label>
          <input matInput formControlName="comment" [value]="" style="font-size: 16px">
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="container mt-1 mb-2">
    <div mat-dialog-actions class="d-flex flex-row-reverse w-100 mt-2">
      <button class="px-4 ms-2" type="submit" mat-flat-button color="primary" [disabled]="!addingForm.valid">{{ 'buttons.saveButton' | transloco }}</button>
      <button class="px-3" type="button" mat-stroked-button color="accent" (click)="onNoClick()">{{ 'buttons.cancelButton' | transloco }}</button>
    </div>
  </div>
</form>
