<!-- Sidenav -->
<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" opened="true">
    <mat-nav-list>
      <ng-container *ngFor="let group of sidenavItems; let y = index">
        <div *ngIf="checkArray(group.approval)">
          <ng-container *ngFor="let item of group.items; let i = index">
            <app-navigation-item *ngIf="group.approval[i]" [item]="item"></app-navigation-item>
          </ng-container>
          <hr *ngIf="y !== sidenavItems.length - 1">
        </div>
      </ng-container>
    </mat-nav-list>
    <div class="utc-time">
      <div class="mt-3">
        <div class="d-flex align-items-center">
          <div class="d-flex justify-content-center align-items-center ms-2 mt-1">
            <i class="bi bi-clock ms-2 utc-time-icon"></i>
          </div>
          <div class="col">
            <label class="utc-time-label ms-2 mt-2">{{ 'utcLabel' | transloco }}</label>
            <p class="utc-time-data ms-2">{{today | date:'y-MM-dd HH:mm:ss':"+0000"}}</p>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-container #window></ng-container>
    <div class="loadingSpinnerContainer" *ngIf="loading">
      <div class="loadingSpinner">
        <mat-spinner diameter="120" color="primary"></mat-spinner>
      </div>
    </div>
    <div class="version">
      <span *ngIf="demo" class="demo-text">Demo</span>
      <button mat-icon-button color="accent" [matMenuTriggerFor]="versionMenu">
        {{changelog[0].version}}
      </button>
      <mat-menu #versionMenu="matMenu">
        <button mat-menu-item (click)="versionDetails(version)" *ngFor="let version of changelog">
          <span>{{version.version}} - {{version.changes}}</span>
        </button>
      </mat-menu>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
