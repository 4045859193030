import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  private dashboardLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Dashboard' },
    { locale: 'sr-Latn', value: 'Komandna tabla' },
    { locale: 'fr', value: 'Tableau de bord' },
    { locale: 'ru', value: 'Щиток приборов' },
    { locale: 'it', value: 'Pannello di controllo' }
  ]
  private reportsLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Reports' },
    { locale: 'sr-Latn', value: 'Izveštaji' },
    { locale: 'fr', value: 'Rapports' },
    { locale: 'ru', value: 'Отчеты' },
    { locale: 'it', value: 'Rapporti' }
  ]
  private globalSummaryReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Summary report' },
    { locale: 'sr-Latn', value: 'Rezime izveštaj' },
    { locale: 'fr', value: 'Rapport sommaire' },
    { locale: 'ru', value: 'Отчет' },
    { locale: 'it', value: 'Relazione di sintesi' }
  ]
  private gameReportsLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Game reports' },
    { locale: 'sr-Latn', value: 'Izveštaj igara' },
    { locale: 'fr', value: 'Rapports de jeu' },
    { locale: 'ru', value: 'Отчеты об играх' },
    { locale: 'it', value: 'Rapporti di gioco' }
  ]

  private rocketmanGameReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Rocketman' },
    { locale: 'sr-Latn', value: 'Rocketman' },
    { locale: 'fr', value: 'Rocketman' },
    { locale: 'ru', value: 'Rocketman' },
    { locale: 'it', value: 'Rocketman' }
  ]
  private rocketmanRoundReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Rocketman' },
    { locale: 'sr-Latn', value: 'Rocketman' },
    { locale: 'fr', value: 'Rocketman' },
    { locale: 'ru', value: 'Rocketman' },
    { locale: 'it', value: 'Rocketman' }
  ]
  private rocketmanJackpotConfigLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Rocketman - Jackpot' },
    { locale: 'sr-Latn', value: 'Rocketman - Džekpot' },
    { locale: 'fr', value: 'Rocketman - Cagnotte' },
    { locale: 'ru', value: 'Rocketman - Джекпот' },
    { locale: 'it', value: 'Rocketman - Montepremi' }
  ]

  private fastballsGameReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Fast Balls' },
    { locale: 'sr-Latn', value: 'Fast Balls' },
    { locale: 'fr', value: 'Fast Balls' },
    { locale: 'ru', value: 'Fast Balls' },
    { locale: 'it', value: 'Fast Balls' }
  ]
  private fastballsRoundReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Fast Balls' },
    { locale: 'sr-Latn', value: 'Fast Balls' },
    { locale: 'fr', value: 'Fast Balls' },
    { locale: 'ru', value: 'Fast Balls' },
    { locale: 'it', value: 'Fast Balls' }
  ]
  private fastballsJackpotConfigLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Fast Balls - Jackpot' },
    { locale: 'sr-Latn', value: 'Fast Balls - Džekpot' },
    { locale: 'fr', value: 'Fast Balls - Cagnotte' },
    { locale: 'ru', value: 'Fast Balls - Джекпот' },
    { locale: 'it', value: 'Fast Balls - Montepremi' }
  ]

  private goldminesGameReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Gold Mines' },
    { locale: 'sr-Latn', value: 'Gold Mines' },
    { locale: 'fr', value: 'Gold Mines' },
    { locale: 'ru', value: 'Gold Mines' },
    { locale: 'it', value: 'Gold Mines' }
  ]
  private goldminesRoundReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Gold Mines' },
    { locale: 'sr-Latn', value: 'Gold Mines' },
    { locale: 'fr', value: 'Gold Mines' },
    { locale: 'ru', value: 'Gold Mines' },
    { locale: 'it', value: 'Gold Mines' }
  ]
  private goldminesJackpotConfigLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Gold Mines - Jackpot' },
    { locale: 'sr-Latn', value: 'Gold Mines - Džekpot' },
    { locale: 'fr', value: 'Gold Mines - Cagnotte' },
    { locale: 'ru', value: 'Gold Mines - Джекпот' },
    { locale: 'it', value: 'Gold Mines - Montepremi' }
  ]

  private raccoonrushGameReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Raccoon Rush' },
    { locale: 'sr-Latn', value: 'Raccoon Rush' },
    { locale: 'fr', value: 'Raccoon Rush' },
    { locale: 'ru', value: 'Raccoon Rush' },
    { locale: 'it', value: 'Raccoon Rush' }
  ]
  private raccoonrushRoundReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Raccoon Rush' },
    { locale: 'sr-Latn', value: 'Raccoon Rush' },
    { locale: 'fr', value: 'Raccoon Rush' },
    { locale: 'ru', value: 'Raccoon Rush' },
    { locale: 'it', value: 'Raccoon Rush' }
  ]
  private raccoonrushJackpotConfigLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Raccoon Rush - Jackpot' },
    { locale: 'sr-Latn', value: 'Raccoon Rush - Džekpot' },
    { locale: 'fr', value: 'Raccoon Rush - Cagnotte' },
    { locale: 'ru', value: 'Raccoon Rush - Джекпот' },
    { locale: 'it', value: 'Raccoon Rush - Montepremi' }
  ]

  private financialReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Financial report' },
    { locale: 'sr-Latn', value: 'Finansijski izveštaj' },
    { locale: 'fr', value: 'Rapport financier' },
    { locale: 'ru', value: 'Финансовый отчет' },
    { locale: 'it', value: 'Relazione finanziaria' }
  ]
  private currencyReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Currency report' },
    { locale: 'sr-Latn', value: 'Izveštaj valuta' },
    { locale: 'fr', value: 'Rapport sur les devises' },
    { locale: 'ru', value: 'Валютный отчет' },
    { locale: 'it', value: 'Rapporto valutario' }
  ]
  private platformSummaryReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Platform report' },
    { locale: 'sr-Latn', value: 'Rezime platformi' },
    { locale: 'fr', value: 'Rapport de plate-forme' },
    { locale: 'ru', value: 'Отчет о платформе' },
    { locale: 'it', value: 'Rapporto sulla piattaforma' }
  ]
  private roundReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Round reports' },
    { locale: 'sr-Latn', value: 'Izveštaji rundi' },
    { locale: 'fr', value: 'Rapports de ronde' },
    { locale: 'ru', value: 'Раундовые отчеты' },
    { locale: 'it', value: 'Rapporti rotondi' }
  ]
  private bonusingLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Bonusing' },
    { locale: 'sr-Latn', value: 'Bonusi' },
    { locale: 'fr', value: 'Bonus' },
    { locale: 'ru', value: 'Бонус' },
    { locale: 'it', value: 'Bonus' }
  ];
  private cashbackReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Cashback report' },
    { locale: 'sr-Latn', value: 'Izveštaj povrata novca' },
    { locale: 'fr', value: 'Rapport de remboursement' },
    { locale: 'ru', value: 'Кэшбэк отчет' },
    { locale: 'it', value: 'Rapporto di rimborso' }
  ];
  private cashbackPlayerReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Cashback player report' },
    { locale: 'sr-Latn', value: 'Izveštaj igrača sa povratom novca' },
    { locale: 'fr', value: 'Rapport joueur Cashback' },
    { locale: 'ru', value: 'Отчет по кэшбэку игрока' },
    { locale: 'it', value: 'Rapporto giocatore cashback' }
  ];
  private promoReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Promo report' },
    { locale: 'sr-Latn', value: 'Promo izveštaj' },
    { locale: 'fr', value: 'Promo rapport' },
    { locale: 'ru', value: 'Промо отчет' },
    { locale: 'it', value: 'Rapporto promo' }
  ]
  private promoPlayerReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Promo player report' },
    { locale: 'sr-Latn', value: 'Promo izveštaj igrača' },
    { locale: 'fr', value: 'Rapport du joueur promo' },
    { locale: 'ru', value: 'Отчет о промо-плеере' },
    { locale: 'it', value: 'Rapporto giocatore promo' }
  ]
  private exchangeRateReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Exchange rates report' },
    { locale: 'sr-Latn', value: 'Kursni izveštaj' },
    { locale: 'fr', value: 'Rapport sur les taux de change' },
    { locale: 'ru', value: 'Отчет о курсах валют' },
    { locale: 'it', value: 'Rapporto sui tassi di cambio' }
  ]
  private playersReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Players report' },
    { locale: 'sr-Latn', value: 'Izveštaj igrača' },
    { locale: 'fr', value: 'Rapport des joueurs' },
    { locale: 'ru', value: 'Отчет игроков' },
    { locale: 'it', value: 'Rapporto dei giocatori' }
  ]
  private jackpotValuesReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Jackpot values report' },
    { locale: 'sr-Latn', value: 'Izveštaj jackpot vrednosti' },
    { locale: 'fr', value: 'Rapport sur les valeurs du jackpot' },
    { locale: 'ru', value: 'Rapporto sui valori del jackpot' },
    { locale: 'it', value: 'Отчет о значениях джекпота' }
  ]
  private jackpotHistoryReportLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Jackpot history report' },
    { locale: 'sr-Latn', value: 'Izveštaj jackpot istorije' },
    { locale: 'fr', value: 'Rapport sur l\'historique des jackpots' },
    { locale: 'ru', value: 'Отчет об истории джекпота' },
    { locale: 'it', value: 'Rapporto sulla storia del jackpot' }
  ]
  private meteorShowerLocales: LocaleNavItem[] = [
    { locale: "en-GB", value: "Meteor Shower" },
    { locale: "sr-Latn", value: "Kiša meteora" },
    { locale: "fr", value: "Pluie de météores" },
    { locale: "ru", value: "Метеорный дождь" },
    { locale: "it", value: "Pioggia di meteore" }
  ]
  private tournamentsLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Tournaments' },
    { locale: 'sr-Latn', value: 'Turniri' },
    { locale: 'fr', value: 'Tournois' },
    { locale: 'ru', value: 'Турниры' },
    { locale: 'it', value: 'Tornei' }
  ]
  private manageTournamentsLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Manage tournaments' },
    { locale: 'sr-Latn', value: 'Upravljanje turnirima' },
    { locale: 'fr', value: 'Gérer les tournois' },
    { locale: 'ru', value: 'Управлять турнирами' },
    { locale: 'it', value: 'Gestisci i tornei' }
  ]
  private tournamentResultsLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Tournament results' },
    { locale: 'sr-Latn', value: 'Rezultati turnira' },
    { locale: 'fr', value: 'Résultats du tournoi' },
    { locale: 'ru', value: 'Результаты турнира' },
    { locale: 'it', value: 'Risultati del torneo' }
  ]
  private configLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Configurations' },
    { locale: 'sr-Latn', value: 'Konfiguracije' },
    { locale: 'fr', value: 'Configurations' },
    { locale: 'ru', value: 'Конфигурации' },
    { locale: 'it', value: 'Configurazioni' }
  ]
  private userConfigLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'User' },
    { locale: 'sr-Latn', value: 'Korisnik' },
    { locale: 'fr', value: 'Configurations utilisateur' },
    { locale: 'ru', value: 'Конфигурации пользователя' },
    { locale: 'it', value: 'Configurazioni utente' }
  ]
  private companyConfigLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Company' },
    { locale: 'sr-Latn', value: 'Kompanija' },
    { locale: 'fr', value: 'Société' },
    { locale: 'ru', value: 'Компания' },
    { locale: 'it', value: 'Società' }
  ]
  private overviewConfigLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Overview' },
    { locale: 'sr-Latn', value: 'Pregled' },
    { locale: 'fr', value: 'Aperçu' },
    { locale: 'ru', value: 'Обзор' },
    { locale: 'it', value: 'Panoramica' }
  ]
  private jackpotConfigLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Jackpot' },
    { locale: 'sr-Latn', value: 'Džekpot' },
    { locale: 'fr', value: 'Cagnotte' },
    { locale: 'ru', value: 'Джекпот' },
    { locale: 'it', value: 'Montepremi' }
  ]
  private currenciesConfigLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Currencies' },
    { locale: 'sr-Latn', value: 'Valute' },
    { locale: 'fr', value: 'Devises' },
    { locale: 'ru', value: 'Валюты' },
    { locale: 'it', value: 'Valute' }
  ]
  private promoManagementLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Promo management' },
    { locale: 'sr-Latn', value: 'Promo upravljanje' },
    { locale: 'fr', value: 'Gestion des promotions' },
    { locale: 'ru', value: 'Промо-менеджмент' },
    { locale: 'it', value: 'Gestione promozionale' }
  ]
  private chatRoomsLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Chat rooms' },
    { locale: 'sr-Latn', value: 'Sobe za razgovore' },
    { locale: 'fr', value: 'Salles de discussions' },
    { locale: 'ru', value: 'Чаты' },
    { locale: 'it', value: 'Chat room' }
  ]
  private indecentUsersLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Indecent users' },
    { locale: 'sr-Latn', value: 'Nepristojni korisnici' },
    { locale: 'fr', value: 'Utilisateurs indécents' },
    { locale: 'ru', value: 'Непристойные пользователи' },
    { locale: 'it', value: 'Utenti indecenti' }
  ]
  private playerAuditLocales: LocaleNavItem[] = [
    { locale: 'en-GB', value: 'Player audit' },
    { locale: 'sr-Latn', value: 'Revizija igrača' },
    { locale: 'fr', value: 'Audit des joueurs' },
    { locale: 'ru', value: 'Аудит игрока' },
    { locale: 'it', value: 'Controllo del giocatore' }
  ]
  private index = new BehaviorSubject<number>(0);
  public obsIndex = this.index.asObservable();

  constructor() { }

  public setLocale(locale: string): void{
    this.dashboardLocales.forEach((item, index) => {
      if (locale === item.locale){
        this.index.next(index);
        return;
      }})
  }

  public getDisplayName(name: string): string {
    if (name === 'Dashboard'){
      return this.dashboardLocales[this.index.value].value;
    }else if (name === 'Reports'){
      return this.reportsLocales[this.index.value].value;
    }else if (name === 'Summary report'){
      return this.globalSummaryReportLocales[this.index.value].value;
    }else if (name === 'Game reports'){
      return this.gameReportsLocales[this.index.value].value;
    }else if (name === 'Financial report'){
      return this.financialReportLocales[this.index.value].value;
    }else if (name === 'Currency report'){
      return this.currencyReportLocales[this.index.value].value;
    }else if (name === 'Platform summary report') {
      return this.platformSummaryReportLocales[this.index.value].value;
    }else if (name === 'Round reports'){
      return this.roundReportLocales[this.index.value].value;
    }else if (name === 'Rocketman - Game report'){
      return this.rocketmanGameReportLocales[this.index.value].value;
    }else if (name === 'Rocketman - Round report'){
      return this.rocketmanRoundReportLocales[this.index.value].value;
    }else if (name === 'Rocketman - Jackpot'){
      return this.rocketmanJackpotConfigLocales[this.index.value].value;
    }else if (name === 'Fast Balls - Game report') {
      return this.fastballsGameReportLocales[this.index.value].value;
    }else if (name === 'Fast Balls - Round report'){
      return this.fastballsRoundReportLocales[this.index.value].value;
    }else if (name === 'Fast Balls - Jackpot'){
      return this.fastballsJackpotConfigLocales[this.index.value].value;
    }else if (name === 'Gold Mines - Round report'){
      return this.goldminesRoundReportLocales[this.index.value].value;
    }else if (name === 'Gold Mines - Jackpot'){
      return this.goldminesJackpotConfigLocales[this.index.value].value;
    }else if (name === 'Gold Mines - Game report'){
      return this.goldminesGameReportLocales[this.index.value].value;
    }else if (name === 'Raccoon Rush - Round report'){
      return this.raccoonrushRoundReportLocales[this.index.value].value;
    }else if (name === 'Raccoon Rush - Jackpot'){
      return this.raccoonrushJackpotConfigLocales[this.index.value].value;
    }else if (name === 'Raccoon Rush - Game report'){
      return this.raccoonrushGameReportLocales[this.index.value].value;
    }else if (name === 'Bonusing'){
      return this.bonusingLocales[this.index.value].value;
    }else if (name === 'Cashback report'){
      return this.cashbackReportLocales[this.index.value].value;
    }else if (name === 'Cashback player report'){
      return this.cashbackPlayerReportLocales[this.index.value].value;
    }else if (name === 'Promo report'){
      return this.promoReportLocales[this.index.value].value;
    }else if (name === 'Promo player report'){
      return this.promoPlayerReportLocales[this.index.value].value;
    }else if (name === 'Exchange rates report'){
      return this.exchangeRateReportLocales[this.index.value].value;
    }else if(name === 'Players report') {
      return this.playersReportLocales[this.index.value].value;
    }else if(name === 'Jackpot values report') {
      return this.jackpotValuesReportLocales[this.index.value].value;
    }else if(name === 'Jackpot history report') {
      return this.jackpotHistoryReportLocales[this.index.value].value;
    } else if(name === 'Meteor shower') {
      return this.meteorShowerLocales[this.index.value].value;
    }else if(name === 'Tournaments') {
      return this.tournamentsLocales[this.index.value].value;
    }else if(name === 'Manage tournaments') {
      return this.manageTournamentsLocales[this.index.value].value;
    }else if(name === 'Tournament results') {
      return this.tournamentResultsLocales[this.index.value].value;
    }else if (name === 'Configurations'){
      return this.configLocales[this.index.value].value;
    }else if (name === 'User'){
      return this.userConfigLocales[this.index.value].value;
    }else if (name === 'Company'){
      return this.companyConfigLocales[this.index.value].value;
    }else if (name === 'Overview'){
      return this.overviewConfigLocales[this.index.value].value;
    }else if (name === 'Jackpot'){
      return this.jackpotConfigLocales[this.index.value].value;
    }else if (name === 'Currencies'){
      return this.currenciesConfigLocales[this.index.value].value;
    }else if(name === 'Promo management') {
      return this.promoManagementLocales[this.index.value].value;
    } else if (name === 'Chat rooms'){
      return this.chatRoomsLocales[this.index.value].value;
    }else if (name === 'Indecent users'){
      return this.indecentUsersLocales[this.index.value].value;
    }else if (name === 'Player audit'){
      return this.playerAuditLocales[this.index.value].value;
    }
    return '';
  }

  public getUserConfigurationsTableTranslations(): string[] {
    return [
      'configurations.user.userTable.tableColumns.id',
      'configurations.user.userTable.tableColumns.username',
      'configurations.user.userTable.tableColumns.email',
      'configurations.user.userTable.tableColumns.comment',
      'configurations.user.userTable.tableColumns.isLockedOut',
      'configurations.user.userTable.tableColumns.company',
      'configurations.user.userTable.tableColumns.userRole',
      'configurations.user.userTable.tableColumns.isUsing2FA',
    ]
  }

  public getMeteorConfigurationsTableTranslations(): string[] {
    return [
      'configurations.user.meteorTable.tableColumns.ID',
      'configurations.user.meteorTable.tableColumns.CompanyID',
      'configurations.user.meteorTable.tableColumns.HoursActiveFrom',
      'configurations.user.meteorTable.tableColumns.HoursActiveTo',
      'configurations.user.meteorTable.tableColumns.OffHoursCoef',
      'configurations.user.meteorTable.tableColumns.Name',
      'configurations.user.meteorTable.tableColumns.Currency',
      'configurations.user.meteorTable.tableColumns.Amount',
      'configurations.user.meteorTable.tableColumns.Players',
      'configurations.user.meteorTable.tableColumns.MinutesMin',
      'configurations.user.meteorTable.tableColumns.MinutesMax',
      'configurations.user.meteorTable.tableColumns.NextTriggerTime',
    ]
  }

  public getCompanyConfigurationsTableTranslations(): string[] {
    return [
      'configurations.company.overview.tableColumns.id',
      'configurations.company.overview.tableColumns.name',
      'configurations.company.overview.tableColumns.platformName',
      'configurations.company.overview.tableColumns.timeOffset',
      'configurations.company.overview.tableColumns.useDST',
      'configurations.company.overview.tableColumns.nonBillingPeriod',
      'configurations.company.overview.tableColumns.active',
      'configurations.company.overview.tableColumns.billingType',
      'configurations.company.overview.tableColumns.billingPercentage',
      'configurations.company.overview.tableColumns.eomfx',
      'configurations.company.overview.tableColumns.useJackpotL1',
      'configurations.company.overview.tableColumns.isolated',
      'configurations.company.overview.tableColumns.delUnusedPromo',
      'configurations.company.overview.tableColumns.delUnusedFreeTickets',
      'configurations.company.overview.tableColumns.fixedCurrency',
      'configurations.company.overview.tableColumns.description',
      'configurations.company.overview.tableColumns.promoWinToBonus',
      'configurations.company.overview.tableColumns.payoutDescription',
    ]
  }

  public getJackpotConfigurationsTableTranslations(): string[] {
    return [
      'configurations.company.jackpot.tableColumns.id',
      'configurations.company.jackpot.tableColumns.companyName',
      'configurations.company.jackpot.tableColumns.level',
      'configurations.company.jackpot.tableColumns.name',
      'configurations.company.jackpot.tableColumns.percentage',
      'configurations.company.jackpot.tableColumns.reservePercentage',
      'configurations.company.jackpot.tableColumns.minValue',
      'configurations.company.jackpot.tableColumns.maxValue',
      'configurations.company.jackpot.tableColumns.active',
    ]
  }

  public getCurrencyConfigurationsTableTranslations(): string[] {
    return [
      'configurations.company.currencies.tableColumns.currency',
      'configurations.company.currencies.tableColumns.minBet',
      'configurations.company.currencies.tableColumns.defaultBet',
      'configurations.company.currencies.tableColumns.maxBet',
      'configurations.company.currencies.tableColumns.maxWin',
      'configurations.company.currencies.tableColumns.preset1',
      'configurations.company.currencies.tableColumns.preset2',
      'configurations.company.currencies.tableColumns.preset3',
      'configurations.company.currencies.tableColumns.preset4',
      'configurations.company.currencies.tableColumns.preset5',
      'configurations.company.currencies.tableColumns.preset6',
    ]
  }

  public getIndecentUsersTableTranslations(): string[] {
    return [
      'chatRooms.columns.userId',
      'chatRooms.columns.username',
      'chatRooms.columns.chatBan',
      'chatRooms.columns.banUntil',
      'chatRooms.columns.companyName',
      'chatRooms.columns.profaneCount',
      'chatRooms.columns.totalMessages',
      'chatRooms.columns.profanePerc'
    ]
  }

  public getPlayerAuditTranslations(): string[] {
    return [
      'playerAudit.columns.roundId',
      'playerAudit.columns.roundOpen',
      'playerAudit.columns.roundClose',
      'playerAudit.columns.roundMultiplierTime',
      'playerAudit.columns.roundMultiplier',
      'playerAudit.columns.playerId',
      'playerAudit.columns.username',
      'playerAudit.columns.ticketId',
      'playerAudit.columns.platformExtId',
      'playerAudit.columns.createdTime',
      'playerAudit.columns.ticketStatus',
      'playerAudit.columns.ticketType',
      'playerAudit.columns.bet',
      'playerAudit.columns.ticketMultiplier',
      'playerAudit.columns.autoCashout',
      'playerAudit.columns.win',
      'playerAudit.columns.externalId',
    ]
  }

  public getPlayerAuditActivityTranslations(): string[] {
    return [
      'playerAudit.activityColumns.activity',
      'playerAudit.activityColumns.activityTime'
    ]
  }

  public getPlayerAuditDetailsTranslations(): string[] {
    return [
      'playerAudit.detailColumns.ticketId',
      'playerAudit.detailColumns.source',
      'playerAudit.detailColumns.id',
      'playerAudit.detailColumns.activity',
      'playerAudit.detailColumns.activityTime'
    ]
  }
}

export interface LocaleNavItem {
  locale: string
  value: string
}
