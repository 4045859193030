import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {UnmappedPromoManagement} from "../../models/configurations/promo-management/unmapped/unmapped-promo-management.interface";
import {
  InsertPromoCreditsResponse
} from "../../models/configurations/promo-management/insert-promo-credits-response.interface";
import {InsertFreeBetsResponse} from "../../models/configurations/promo-management/insert-free-bets-response.interface";
import {UnmappedPlayerInfo} from "../../models/configurations/promo-management/unmapped/unmapped-player-info.interface";
import {
  UnmappedPlayerTicketsInfo
} from "../../models/configurations/promo-management/unmapped/unmapped-player-tickets-info.interface";
import {
  UnmappedPlayerGraphInfo
} from "../../models/configurations/promo-management/unmapped/unmapped-player-graph-info.interface";
import {
  MakePlayerAVipResponse
} from "../../models/configurations/promo-management/unmapped/make-player-a-vip.interface";

import {environment} from "../../../environments/environment";
const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class PromoManagementService {

  constructor(private http: HttpClient) { }

  public getPromoManagement(companyId: number, fromDate: string, toDate: string, orderBy: number, minTickets: number, topResults: number, playerId: number | null, games: number | null): Observable<UnmappedPromoManagement[]> {
    const url = baseUrl.concat('promo-management/data');
    let params: { [key: string]: any } = {
      companyId: companyId,
      dateFrom: fromDate,
      dateTo: toDate,
      orderBy: orderBy,
      minTickets: minTickets,
      topResults: topResults
    }
    if (playerId){
      params['playerId'] = playerId;
    }
    if (games){
      params['games'] = games;
    }
    return this.http.get<UnmappedPromoManagement[]>(url, {
      params: params,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
  }

  public addPromoCredits(playerId: number, promoCredits: number, currency: string, minCashout: number): Observable<InsertPromoCreditsResponse[]> {
    const url = baseUrl.concat('promo-management/insert-promo-credits');
    return this.http.post<InsertPromoCreditsResponse[]>(url, {
      playerId: playerId,
      promoCredits: promoCredits,
      promoCurrencyId: currency,
      minCashout: minCashout
    }, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
  }

  public addFreeTickets(playerId: number, freeTickets: number, freeTicketsMoney: number, freeTicketsCurrency: string): Observable<InsertFreeBetsResponse[]> {
    const url = baseUrl.concat('promo-management/insert-free-tickets');
    return this.http.post<InsertFreeBetsResponse[]>(url, {
      playerId: playerId,
      freeTicketsCount: freeTickets,
      freeTicketsMoney: freeTicketsMoney,
      freeTicketsCurrencyId: freeTicketsCurrency
    }, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
  }

  public getPlayerInfo(playerId: number): Observable<UnmappedPlayerInfo[]> {
    const url = baseUrl.concat('promo-management/player-info');
    return this.http.get<UnmappedPlayerInfo[]>(url, {
      params: {
        playerId: playerId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
  }

  public getPlayerTicketInfo(playerId: number, dateFrom: string, dateTo: string): Observable<UnmappedPlayerTicketsInfo[]> {
    const url = baseUrl.concat('promo-management/player-tickets-info');
    return this.http.get<UnmappedPlayerTicketsInfo[]>(url, {
      params: {
        playerId: playerId,
        dateFrom: dateFrom,
        dateTo: dateTo
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
  }

  public getPlayerGraphInfo(playerId: number, dateFrom: string, dateTo: string): Observable<UnmappedPlayerGraphInfo[]> {
    const url = baseUrl.concat('promo-management/player-graph-info');
    return this.http.get<UnmappedPlayerGraphInfo[]>(url, {
      params: {
        playerId: playerId,
        dateFrom: dateFrom,
        dateTo: dateTo
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
  }

  public makePlayerAVip(playerId: number) {
    const url = baseUrl.concat('promo-management/vip-player');
    return this.http.post<MakePlayerAVipResponse[]>(url, {
      playerId: playerId
    }, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
  }

  public getPromoManagementTranslations() : string[] {
    return [
      'configurations.promoManagement.columns.playerLevel',
      'configurations.promoManagement.columns.id',
      'configurations.promoManagement.columns.username',
      'configurations.promoManagement.columns.firstName',
      'configurations.promoManagement.columns.lastName',
      'configurations.promoManagement.columns.sessions',
      'configurations.promoManagement.columns.bets',
      'configurations.promoManagement.columns.stakeEur',
      'configurations.promoManagement.columns.winEur',
      'configurations.promoManagement.columns.ggr',
      'configurations.promoManagement.columns.percentage',
      'configurations.promoManagement.columns.promoCredits',
      'configurations.promoManagement.columns.creditCurrency',
      'configurations.promoManagement.columns.freeTickets',
      'configurations.promoManagement.columns.freeMoney',
      'configurations.promoManagement.columns.freeCurrency',
      'configurations.promoManagement.columns.minCashout',
    ]
  }

  public getPlayerTicketTranslations(): string[] {
    return [
      'configurations.promoManagement.playerInfo.columns.createdTime',
      'configurations.promoManagement.playerInfo.columns.bet',
      'configurations.promoManagement.playerInfo.columns.win',
      'configurations.promoManagement.playerInfo.columns.multiplier',
      'configurations.promoManagement.playerInfo.columns.currency',
      'configurations.promoManagement.playerInfo.columns.betEur',
      'configurations.promoManagement.playerInfo.columns.winEur',
    ];
  }
}
