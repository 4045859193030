import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TimeOffset} from "../../../../../models/configurations/company-config/time-offset.interface";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfigCompany} from "../../../../../models/configurations/company-config/overview/config-company.model";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from "@angular/material-moment-adapter";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import * as moment from "moment";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Platform} from "../../../../../models/configurations/company-config/overview/platform.model";
import {HandlingService} from "../../../../../services/global-handling/handling.service";
import {UtilsService} from "../../../../../services/utils/utils.service";
import {BillingType} from "../../../../../models/configurations/company-config/overview/billing-type.interface";
import {ConfigurationsService} from "../../../../../services/configurations/configurations.service";

@Component({
  selector: 'app-edit-company-dialog',
  templateUrl: './edit-company-dialog.component.html',
  styleUrls: ['./edit-company-dialog.component.scss'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  ],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '90px',
        visibility: 'visible'
      })),
      state('closed', style({
        height: '0px',
        visibility: 'hidden'
      })),
      transition('* => *', [
        animate('0.15s')
      ]),
    ]),
  ],
})
export class EditCompanyDialogComponent implements OnInit {

  public editingForm: FormGroup;
  public timeOffsets: TimeOffset[];
  public Insurance: boolean = false;

  public min: Date = new Date();
  public max: Date = new Date();
  public currentDate: any | null = null;

  public advanced: boolean = false;

  public platforms: Platform[] = [];
  public billingTypes: BillingType[];

  constructor(private _adapter: DateAdapter<any>,
              private dialogRef: MatDialogRef<EditCompanyDialogComponent>,
              private configurationsService: ConfigurationsService,
              private formBuilder: FormBuilder,
              private handlingService: HandlingService,
              private utilsService: UtilsService,
              @Inject(MAT_DIALOG_DATA) public company: ConfigCompany) {
    this.billingTypes = configurationsService.getBillingTypes();
    const billingType = configurationsService.findBillingType(this.billingTypes, this.company.billingType);

    this.editingForm = this.formBuilder.group({
      companyName: [company.companyName, [Validators.required, Validators.minLength(3)]],
      timeOffset: [company.timeOffset, Validators.required],
      useDST: [company.useDST, Validators.required],
      nonBillingPeriod: [moment(company.nonBillingPeriod), Validators.required],
      platformID: [company.platformID, [Validators.required, Validators.min(1), Validators.max(10000)]],
      extData: [company.extData, this.configurationsService.jsonValidator],
      customization: [company.customization, this.configurationsService.jsonValidator],
      active: [company.active, Validators.required],
      billingType: [billingType, Validators.required],
      billingPerc: [company.billingPerc, [Validators.required, Validators.min(0), Validators.max(50)]],
      eomfx: [company.eomfx, Validators.required],
      isolated: [company.isolated, Validators.required],
      delUnusedPromo: [company.delUnusedPromo, [Validators.required, Validators.min(0), Validators.max(366)]],
      delUnusedFreeTickets: [company.delUnusedFreeTickets, [Validators.required, Validators.min(0), Validators.max(366)]],
      fixedCurrency: [company.fixedCurrency, Validators.required],
      description: [company.description],
      promoWinToBonus: [company.promoWinToBonus, Validators.required],
    });

    this.timeOffsets = utilsService.getUtcTimeOffsets();
    this.max.setMonth(this.min.getMonth() + 6);
    this.currentDate = moment(company.nonBillingPeriod);
  }

  ngOnInit(): void {
    if(this.company.customization){
      const jsonObject = JSON.parse(this.company.customization);

      if (jsonObject.hasOwnProperty('useInsurance')){
        this.Insurance = true
      }
    }
  }

  public submitForm(): void {
    if (this.editingForm.invalid){ return; }
    let billingPeriod = this.editingForm.get('nonBillingPeriod')?.value.format("YYYY-MM-DD");

    const customization = this.editingForm.get('customization')?.value;
    const description = this.editingForm.get('description')?.value;

    this.company.companyName = this.editingForm.get('companyName')?.value;
    this.company.timeOffset = this.editingForm.get('timeOffset')?.value;
    this.company.useDST = this.editingForm.get('useDST')?.value;
    this.company.nonBillingPeriod = billingPeriod;
    this.company.platformID = this.editingForm.get('platformID')?.value;
    this.company.extData = this.editingForm.get('extData')?.value;
    this.company.customization = customization ? customization : '';
    this.company.active = this.editingForm.get('active')?.value;
    this.company.billingType = this.editingForm.get('billingType')?.value.value;
    this.company.billingPerc = this.editingForm.get('billingPerc')?.value;
    this.company.eomfx = this.editingForm.get('eomfx')?.value;
    this.company.isolated = this.editingForm.get('isolated')?.value;
    this.company.delUnusedPromo = this.editingForm.get('delUnusedPromo')?.value;
    this.company.delUnusedFreeTickets = this.editingForm.get('delUnusedFreeTickets')?.value;
    this.company.fixedCurrency = this.editingForm.get('fixedCurrency')?.value;
    this.company.description = description ? description : '';
    this.company.promoWinToBonus = this.editingForm.get('promoWinToBonus')?.value;

    this.dialogRef.close({data: this.company});
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public billingPeriodDateChanged(date: any){
    this.editingForm.get('nonBillingPeriod')?.setValue(date);
    this.currentDate = date;
  }

  public get companyName() {
    return this.editingForm.get('companyName');
  }
  public get timeOffset() {
    return this.editingForm.get('timeOffset');
  }
  public get useDST() {
    return this.editingForm.get('useDST');
  }
  public get nonBillingPeriod() {
    return this.editingForm.get('nonBillingPeriod');
  }
  public get platformID() {
    return this.editingForm.get('platformID');
  }
  public get extData() {
    return this.editingForm.get('extData');
  }
  public get customization() {
    return this.editingForm.get('customization');
  }
  public get active() {
    return this.editingForm.get('active');
  }
  public get billingType() {
    return this.editingForm.get('billingType');
  }
  public get billingPerc() {
    return this.editingForm.get('billingPerc');
  }
  public get eomfx() {
    return this.editingForm.get('eomfx');
  }
  public get isolated() {
    return this.editingForm.get('isolated');
  }
  public get delUnusedPromo() {
    return this.editingForm.get('delUnusedPromo');
  }
  public get delUnusedFreeTickets() {
    return this.editingForm.get('delUnusedFreeTickets');
  }
  public get fixedCurrency() {
    return this.editingForm.get('fixedCurrency');
  }
  public get description() {
    return this.editingForm.get('description');
  }
  public get promoWinToBonus() {
    return this.editingForm.get('promoWinToBonus');
  }

  public handleInsurance(checked: boolean):void {
    if(this.company.customization){
      const jsonObject = JSON.parse(this.company.customization);

      if (jsonObject.hasOwnProperty('useInsurance')) {
        if(checked){
          jsonObject.useInsurance = true
        }else{
          delete jsonObject.useInsurance
        }

        this.editingForm.get('customization')?.setValue(JSON.stringify(jsonObject))
      }else{
        if(checked){
          jsonObject.useInsurance = true
        }else{
          delete jsonObject.useInsurance
        }

        this.editingForm.get('customization')?.setValue(JSON.stringify(jsonObject))
      }
    }else{
      if(checked){
        this.editingForm.get('customization')?.setValue('{"useInsurance":true}')
      }else{
        this.editingForm.get('customization')?.setValue('')
      }
    }
  }
}
