<div class="user-panel round-border d-flex flex-column align-items-center justify-content-end" *ngIf="showPanel">
  <div class="user-picture-frame">
    <div class="row d-flex justify-content-end me-3">
      <mat-icon class="user-icon">manage_accounts</mat-icon>
    </div>
    <div class="row">
      <p class="user-label">{{ 'chatRooms.chatRoomBanUser' | transloco }}</p>
      <p class="user-name">{{message?.username}}</p>
    </div>
  </div>
  <div class="row d-flex w-100 mt-1">
    <button class="ban-btn ms-1 me-0 mt-2" type="button" mat-raised-button color="warn" (click)="banUser()">{{ 'buttons.banButton' | transloco }} {{message?.username}}</button>
    <button class="mt-2" type="button" mat-icon-button color="warn" [matMenuTriggerFor]="banOptionsMenu"><mat-icon color="warn">more_vert</mat-icon></button>
    <mat-menu #banOptionsMenu="matMenu">
      <button mat-menu-item (click)="selectBanOption(option)" *ngFor="let option of banOptions">{{option.text}}</button>
    </mat-menu>
    <hr class="mt-3 mb-2">
  </div>
  <div class="row d-flex justify-content-start w-100 mb-1">
    <p class="user-label mx-1 mb-0 pt-1 px-0 w-75" style="margin-top: 7px">{{ 'chatRooms.chatRoomMessageHistory' | transloco }}</p>
    <mat-form-field class="date-select p-0 ms-2">
      <input #input matInput
             [matDatepicker]="picker"
             [(ngModel)]="messageHistoryDate"
             [readonly]="true"
             (input)="changeInput(input)"
             (dateChange)="changeDate($event.value)"
             style="visibility:hidden;"/>
      <mat-datepicker #picker disabled="false" style="visibility:hidden;" [xPosition]="'end'"></mat-datepicker>
      <button mat-icon-button (click)="picker.open()">
        <mat-icon>calendar_today</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="col flex-column user-messages">
    <ng-container *ngFor="let item of messageHistory">
      <app-chat-room-message [item]="item" [selected]="selectedMessage" (message)="messageSelected($event)" [maxWidth]="190"></app-chat-room-message>
    </ng-container>
  </div>
</div>
