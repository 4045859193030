import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ConfigUser} from "../../../../models/configurations/user-config/config-user.model";
import {ConfigurationsService} from "../../../../services/configurations/configurations.service";
import {RolesService} from "../../../../services/roles/roles.service";
import {RoleState} from "../../../../models/roles/role-state.enum";
import {MatDialog} from "@angular/material/dialog";
import {EditUserDialogComponent} from "../edit-user-dialog/edit-user-dialog.component";
import {AddUserDialogComponent} from "../add-user-dialog/add-user-dialog.component";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {HandlingService} from "../../../../services/global-handling/handling.service";
import {LoadingService} from "../../../../services/loading/loading.service";
import {LocalizationService} from "../../../../services/localization/localization.service";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent implements OnInit, OnChanges {

  public columns: string[] = [];
  public translationColumns: string[] = [];
  public selected: ConfigUser | null = null;

  public lockedOutApproval: boolean = false;

  @ViewChild(MatTable) table!: MatTable<ConfigUser>;
  public dataSource!: MatTableDataSource<ConfigUser>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(private configurationsService: ConfigurationsService,
              private handlingService: HandlingService,
              private rolesService: RolesService,
              private loadingService: LoadingService,
              private localizationService: LocalizationService,
              private dialog: MatDialog) {
    this.translationColumns = localizationService.getUserConfigurationsTableTranslations();

    this.columns = [];
    this.lockedOutApproval = rolesService.checkRole(RoleState.OWNER);
    this.refreshData();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {}

  public addUser(): void {
    this.openAddingDialog();
  }

  public editUser(): void {
    if(this.selected){
      this.openEditingDialog();
    }else{
      this.handlingService.error('Please select a user');
    }
  }

  public selectUser(user: ConfigUser): void {
    this.selected = user;
  }

  public refreshData(): void {
    this.selected = null;
    this.loadingService.setLoadingSteps(1);
    this.configurationsService.getUsers().subscribe(newData => {
      const mapped = this.configurationsService.userInputMapper(newData);
      this.dataSource = new MatTableDataSource<ConfigUser>(mapped);
      this.dataSource.sort = this.sort;
      this.columns = this.dataSource.data[0].getFields();
    },error => {
      this.handlingService.error('Error while fetching users', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  public deselectAll(): void {
    this.selected = null;
  }

  private isUserEditable(): boolean {
    let userRole: RoleState;

    if(this.selected?.userRole === 'Owner'){
      userRole = RoleState.OWNER;
    }else if(this.selected?.userRole === 'Admin'){
      userRole = RoleState.ADMIN;
    }else {
      userRole = RoleState.VIEWER;
    }

    let res = this.rolesService.checkEqualRole(userRole);
    if (res === 1){
      return true;
    }else if(res === 0){
      return this.configurationsService.getCredentials().username === this.selected?.username;
    }
    return false;
  }

  private openEditingDialog(): void {
    const dialogRef = this.dialog.open(EditUserDialogComponent, {
      width: '505px',
      data: this.selected,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.configurationsService.updateUser(result.data).subscribe(() => {
          this.handlingService.success('User successfully updated');
          //todo possibly fix refresh bug
        },error => {
          this.handlingService.error('Error while updating user', error);
        }, () => {
          this.refreshElement(result.data);
          this.table.renderRows();
        });
      }
    })
  }

  private openAddingDialog(): void {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '505px',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.configurationsService.addUser(result.data).subscribe(() => {
          this.handlingService.success('User successfully added');
          this.dataSource.data.push(result.data);
          this.dataSource.data = this.dataSource.data;
          this.table.renderRows();
        },error => {
          this.handlingService.error('Error while adding user', error);
        });
      }
    })
  }

  public alignHeaderCell(column: string): string {
    return this.dataSource.data[0].align(column);
  }

  private refreshElement(element: ConfigUser): void {
    if(this.selected){
      let user = new ConfigUser(
        element.id,
        element.username,
        element.password,
        element.email,
        element.comment,
        element.isLockedOut,
        element.companyId,
        element.companyName,
        element.userRole,
        element.isUsing2FA
      )
      let index = this.dataSource.data.indexOf(this.selected);
      this.dataSource.data.splice(index, 1);
      this.dataSource.data.splice(index, 0, user);
    }
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }

}
