export class ChatRoomMessage {
  public username: string
  public messageId: string
  public message: string
  public time: string
  public avatar_id: number
  public country_code: string
  public likes_who: string[]
  public sendingState: boolean

  constructor(username: string, messageId: string, message: string, time: string, avatar_id: number, country_code: string, likes_who: string[], sendingState?: boolean) {
    this.username = username;
    this.messageId = messageId;
    this.message = message;
    this.time = time;
    this.avatar_id = avatar_id;
    this.country_code = country_code;
    this.likes_who = likes_who;
    sendingState ? this.sendingState = sendingState : this.sendingState = false;
  }
}
