import {ReportColumns} from "../report-columns.interface";

export class PlayersReport {
  id: number
  username: string
  firstName: string
  lastName: string
  sessionsCount: number
  bets: number
  stakeEUR: number
  winEUR: number
  ggr: number

  columnInfo: ReportColumns[]

  constructor(id: number, username: string, firstName: string, lastName: string, sessionsCount: number, bets: number, stakeEUR: number, winEUR: number, ggr: number) {
    this.id = id;
    this.username = username;
    this.firstName = firstName;
    this.lastName = lastName;
    this.sessionsCount = sessionsCount;
    this.bets = bets;
    this.stakeEUR = stakeEUR;
    this.winEUR = winEUR;
    this.ggr = ggr;

    this.columnInfo = [
      {key: 'ID', info: {field: 'id', total: 0, format: this.id, align: 'normal'}},
      {key: 'Username', info: {field: 'username', total: 0, format: this.username, align: 'normal'}},
      {key: 'FirstName', info: {field: 'firstName', total: 0, format: this.firstName, align: 'normal'}},
      {key: 'LastName', info: {field: 'lastName', total: 0, format: this.lastName, align: 'normal'}},
      {key: 'SessionsCount', info: {field: 'sessionsCount', total: this.sessionsCount, format: this.formatInteger(this.sessionsCount), align: 'end'}},
      {key: 'Bets', info: {field: 'bets', total: this.bets, format: this.formatInteger(this.bets), align: 'end'}},
      {key: 'StakeEUR', info: {field: 'stakeEUR', total: this.stakeEUR, format: this.formatMoney(this.stakeEUR), align: 'end'}},
      {key: 'WinEUR', info: {field: 'winEUR', total: this.winEUR, format: this.formatMoney(this.winEUR), align: 'end'}},
      {key: 'GGR', info: {field: 'ggr', total: this.ggr, format: this.formatMoney(this.ggr), align: 'end'}},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }
  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }

}
