import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {UnmappedDashboard1Data} from "../../models/dashboard/unmapped/unmapped-dashboard1-data.interface";
import {UnmappedDashboard2Data} from "../../models/dashboard/unmapped/unmapped-dashboard2-data.interface";
import {UnmappedDashboard3Data} from "../../models/dashboard/unmapped/unmapped-dashboard3-data.interface";
import {UnmappedDashboard4Data} from "../../models/dashboard/unmapped/unmapped-dashboard4-data.interface";
import {UnmappedDashboard5Data} from "../../models/dashboard/unmapped/unmapped-dashboard5-data.interface";
import {Chart4Type} from "../../models/dashboard/chart4-type.enum";
import {UtilsService} from "../utils/utils.service";

import {environment} from "../../../environments/environment";
const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient,
              private utilsService: UtilsService){}

  public getChart1Data(): Observable<UnmappedDashboard1Data[]> {
    const url = baseUrl.concat('dashboard/q');
    const date = this.utilsService.getTodaysDate();
    return this.http.get<UnmappedDashboard1Data[]>(url, {
      params: {
        forDate: date,
        dashboardType: 1
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getChart2Data(): Observable<UnmappedDashboard2Data[]> {
    const url = baseUrl.concat('dashboard/q');
    const date = this.utilsService.getTodaysDate();
    return this.http.get<UnmappedDashboard2Data[]>(url, {
      params: {
        forDate: date,
        dashboardType: 2
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getChart3Data(): Observable<UnmappedDashboard3Data[]> {
    const url = baseUrl.concat('dashboard/q');
    const date = this.utilsService.getTodaysDate();
    return this.http.get<UnmappedDashboard3Data[]>(url, {
      params: {
        forDate: date,
        dashboardType: 3
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getChart4Data(type: number = 4): Promise<UnmappedDashboard4Data[]> {
    const url = baseUrl.concat('dashboard/q');
    const date = this.utilsService.getTodaysDate();
    return this.http.get<UnmappedDashboard4Data[]>(url, {
      params: {
        forDate: date,
        dashboardType: type
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise();
  }

  public getChart5Data(): Observable<UnmappedDashboard5Data[]> {
    const url = baseUrl.concat('dashboard/q');
    const date = this.utilsService.getTodaysDate();
    return this.http.get<UnmappedDashboard5Data[]>(url, {
      params: {
        forDate: date,
        dashboardType: 5
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  /*
    Returns true if bets is dashboard pie chart type
    Returns false if stake is dashboard pie chart type
   */
  public getDashboardPieChartType(): boolean {
    let item = localStorage.getItem('pieChartType');
    if (item) {
      if (item === Chart4Type.STAKE){
        return false;
      }else {
        this.setDashboardPieChartType(Chart4Type.BETS);
        return true;
      }
    }else{
      this.setDashboardPieChartType(Chart4Type.BETS);
      return true;
    }
  }

  public setDashboardPieChartType(value: Chart4Type): void {
    if (value === Chart4Type.STAKE){
      localStorage.setItem('pieChartType', Chart4Type.STAKE);
    }else {
      localStorage.setItem('pieChartType', Chart4Type.BETS);
    }
  }

}
