<h2 class="component-title">{{ 'tournaments.manageTournaments.title' | transloco }}</h2>
<hr>

<div class="frame round-border">
  <div class="row d-flex align-items-center">
    <div class="ps-3 pe-0 w-auto mb-2">
      <button mat-stroked-button color="primary" (click)="addTournament()">
        {{ 'tournaments.manageTournaments.buttons.addTournament' | transloco }}
      </button>
    </div>
    <div class="ps-3 pe-0 w-auto mb-2">
      <button mat-stroked-button color="accent" (click)="editTournament()">
        {{ 'tournaments.manageTournaments.buttons.editTournament' | transloco }}
      </button>
    </div>
    <div class="ps-3 pe-0 w-auto mb-2">
      <button mat-stroked-button color="warn" (click)="deleteTournament()">
        {{ 'tournaments.manageTournaments.buttons.deleteTournament' | transloco }}
      </button>
    </div>
    <div class="ps-3 pe-0 w-auto mb-2">
      <button mat-stroked-button color="primary" (click)="editCompaniesInTournament()">
        {{ 'tournaments.manageTournaments.buttons.companiesEditor' | transloco }}
      </button>
    </div>
    <div class="ps-3 pe-0 w-auto mb-2">
      <button mat-stroked-button color="primary" (click)="editAwardsInTournament()">
        {{ 'tournaments.manageTournaments.buttons.awardsEditor' | transloco }}
      </button>
    </div>
    <div class="ps-3 pe-0 w-auto mb-2">
      <button mat-stroked-button color="warn" (click)="announceTournament()">
        {{ 'tournaments.manageTournaments.buttons.announceTournament' | transloco }}
      </button>
    </div>
    <div class="ps-3 pe-0 w-auto mb-2">
      <button mat-stroked-button color="accent" (click)="showPreviousTournaments()">
        <ng-container *ngIf="allTournaments; else currentTournament">Show current</ng-container>
        <ng-template #currentTournament>Show previous</ng-template>
      </button>
    </div>
  </div>
  <div class="row gap-3">
    <div class="col max-height-table">
      <p class="subtitle" *ngIf="showTournamentsTable">{{ 'tournaments.manageTournaments.tournamentsSubtitle' | transloco }}</p>
      <table mat-table [dataSource]="tournamentsDataSource" multiTemplateDataRows class="mat-elevation-z8 mt-0 tournamentsTable" matSort [hidden]="!showTournamentsTable">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of tournamentsColumns; let i = index">
          <th mat-header-cell
              class="header-cell"
              *matHeaderCellDef
              mat-sort-header="{{tournamentHeaderCell(column)}}"
              arrowPosition="after"
              [ngStyle]="{'text-align': alignTournamentHeaderCell(column)}"
              [class.header-end]="alignTournamentHeaderCell(column) === 'end'"
              [class.header-start]="alignTournamentHeaderCell(column) === 'normal'">
            {{ tournamentsTranslationColumns[i] | transloco }}
          </th>
          <td mat-cell *matCellDef="let element" [ngStyle]="{'text-align': element.align(column)}"> {{element.format(column)}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tournamentsColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; let index = dataIndex; columns: tournamentsColumns;"
            class="element-row"
            [class.clicked-row]="expandedTournamentElement === element"
            [ngClass]="{alternating: index % 2 === 0}"
            (click)="tournamentClicked(element)">
        </tr>
      </table>
    </div>
    <div class="col-auto max-height-table companies-div">
      <p class="subtitle" *ngIf="showTournamentCompanies">{{ 'tournaments.manageTournaments.tournamentCompaniesSubtitle' | transloco }}</p>
      <table mat-table [dataSource]="tournamentCompanies" multiTemplateDataRows class="mat-elevation-z8 mt-0 companiesTable" [hidden]="!showTournamentCompanies">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of tournamentCompaniesColumns; let i = index">
          <th mat-header-cell
              class="header-cell"
              *matHeaderCellDef
              [ngStyle]="{'text-align': alignTournamentHeaderCell(column)}"
              [class.header-end]="alignTournamentHeaderCell(column) === 'end'"
              [class.header-start]="alignTournamentHeaderCell(column) === 'normal'">
            {{ tournamentCompaniesTranslationsColumns[i] | transloco }}
          </th>
          <td mat-cell *matCellDef="let element" [ngStyle]="{'text-align': element.align(column)}"> {{element.format(column)}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tournamentCompaniesColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; let index = dataIndex; columns: tournamentCompaniesColumns;"
            class="element-row"
            [class.clicked-row]="expandedCompanyElement === element"
            [ngClass]="{alternating: index % 2 === 0}"
            (click)="expandedCompanyElement = expandedCompanyElement === element ? null : element;">
        </tr>
      </table>
    </div>
    <div class="col-auto max-height-table awards-div">
      <p class="subtitle" *ngIf="showTournamentAwards">{{ 'tournaments.manageTournaments.tournamentAwardsSubtitle' | transloco }}</p>
      <table mat-table [dataSource]="tournamentAwards" multiTemplateDataRows class="mat-elevation-z8 mt-0 awardsTable" [hidden]="!showTournamentAwards">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of tournamentAwardsColumns; let i = index">
          <th mat-header-cell
              class="header-cell"
              *matHeaderCellDef
              [ngStyle]="{'text-align': alignTournamentHeaderCell(column)}"
              [class.header-end]="alignTournamentHeaderCell(column) === 'end'"
              [class.header-start]="alignTournamentHeaderCell(column) === 'normal'">
            {{ tournamentAwardsTranslationsColumns[i] | transloco }}
          </th>
          <td mat-cell *matCellDef="let element" [ngStyle]="{'text-align': element.align(column)}"> {{element.format(column)}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tournamentAwardsColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; let index = dataIndex; columns: tournamentAwardsColumns;"
            class="element-row"
            [class.clicked-row]="expandedAwardElement === element"
            [ngClass]="{alternating: index % 2 === 0}"
            (click)="expandedAwardElement = expandedAwardElement === element ? null : element;">
        </tr>
      </table>
    </div>
  </div>
</div>
