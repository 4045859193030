<h2 class="component-title">{{ 'dashboard.dashboard1.title' | transloco: { date: today | date:'EEEE, MMMM d, y' } }}</h2>
<hr>

<div class="container padding-x" *ngIf="!noData">
  <div class="row">
    <ng-container *ngFor="let item of rocketmanData">
      <div class="col-auto d-flex justify-content-center mx-2 p-0 my-2">
        <app-dashboard-card [gameTitle]="item.gameName" [data]="item.jackPotValue" [title]="item.jackPotName" [time]="dateTime"></app-dashboard-card>
      </div>
    </ng-container>
    <!--    <div class="col mx-4 p-0 my-2 time"><app-dashboard-time></app-dashboard-time></div>-->
  </div>
  <div class="row">
    <ng-container *ngFor="let item of fastballsData">
      <div class="col-auto d-flex justify-content-center mx-2 p-0 my-2">
        <app-dashboard-card [gameTitle]="item.gameName" [data]="item.jackPotValue" [title]="item.jackPotName" [time]="dateTime"></app-dashboard-card>
      </div>
    </ng-container>
    <!--    <div class="col mx-4 p-0 my-2 time"><app-dashboard-time></app-dashboard-time></div>-->
  </div>
</div>

<h3 class="component-subtitle ms-1" style="font-size: 20px; text-align: start" *ngIf="noData">{{ 'dashboard.dashboard1.error' | transloco }}</h3>
