import { Component, OnInit } from '@angular/core';
import {Chart1Data} from "../../../models/dashboard/chart1-data.model";
import {DashboardService} from "../../../services/dashboard/dashboard.service";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {UtilsService} from "../../../services/utils/utils.service";
import {UnmappedDashboard1Data} from "../../../models/dashboard/unmapped/unmapped-dashboard1-data.interface";

const ROCKET_MAN = 'Rocketman'
const FAST_BALLS = 'Fast Balls'

@Component({
  selector: 'app-jackpot-values-report',
  templateUrl: './jackpot-values-report.component.html',
  styleUrls: ['./jackpot-values-report.component.scss']
})
export class JackpotValuesReportComponent implements OnInit {

  public rocketmanData: Chart1Data[] = [];
  public fastballsData: Chart1Data[] = [];
  public dateTime: string = '';
  public noData: boolean = false;
  today: number = Date.now();

  constructor(private dashboardService: DashboardService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private utilsService: UtilsService) {
    dashboardService.getChart1Data().subscribe(newData => {
      this.prepareData();
      this.mapData(newData);
      this.noData = this.checkData();
      this.dateTime = utilsService.getUTCTime();
    },error => {
      this.handlingService.error('Trouble fetching data for dashboard 1', error);
    }, () => {
      loadingService.incrementLoadingSteps();
    });
  }

  private mapData(array: UnmappedDashboard1Data[]): void {
    array.forEach(unmapped => {
      if (unmapped.GameName === ROCKET_MAN){
        this.rocketmanData.forEach(mapped => this.replaceValues(mapped, unmapped));
      }else if (unmapped.GameName === FAST_BALLS){
        this.fastballsData.forEach(mapped => this.replaceValues(mapped, unmapped));
      }
    })
  }

  private prepareData(): void {
    this.rocketmanData.push(new Chart1Data(ROCKET_MAN,1, 'Jackpot', -1));
    this.rocketmanData.push(new Chart1Data(ROCKET_MAN,2, 'Rocketpot', -1));
    this.rocketmanData.push(new Chart1Data(ROCKET_MAN,3, 'Boosterpot', -1));

    this.fastballsData.push(new Chart1Data(FAST_BALLS,1, 'Jackpot', -1));
    this.fastballsData.push(new Chart1Data(FAST_BALLS,2, 'Min', -1));
    this.fastballsData.push(new Chart1Data(FAST_BALLS,3, 'Max', -1));
  }

  private replaceValues(mapped: Chart1Data, unmapped: UnmappedDashboard1Data){
    if (mapped.levelID === unmapped.LevelID){
      mapped.jackPotValue = unmapped.JackPotValue;
      mapped.jackPotName = unmapped.JackPotName;
      mapped.gameName = unmapped.GameName;
    }
  }

  private checkData(): boolean {
    return this.rocketmanData[0].jackPotValue === -1 && this.rocketmanData[1].jackPotValue === -1 && this.rocketmanData[2].jackPotValue === -1;
  }

  ngOnInit(): void {
  }

}
