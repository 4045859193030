<h2 class="component-title">{{'configurations.promoManagement.title' | transloco}}</h2>
<hr>

<div class="frame round-border">
  <div class="row">
    <div class="col w-45">
      <form class="row d-flex" [formGroup]="searchForm" (ngSubmit)="submitSearch()" autocomplete="off">
        <div class="col w-51">
          <div class="row d-flex">
            <mat-form-field appearance="standard" style="font-size: 16px;" class="pe-4">
              <mat-label>{{ 'configurations.user.companyLabel' | transloco }}</mat-label>
              <mat-select formControlName="company">
                <mat-option [value]="company" *ngFor="let company of companyList">
                  {{company.CompanyName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="company!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Company' } }}</mat-error>
            </mat-form-field>
          </div>
          <div class="row d-flex">
            <app-date-picker [placeholder]="'From date'" (date)="setStartDate($event)" [init]="startDate?.value" class="w-min"></app-date-picker>
            <app-date-picker [placeholder]="'To date'" (date)="setEndDate($event)" [init]="endDate?.value" class="w-min"></app-date-picker>
          </div>
          <div class="row d-flex">
            <mat-form-field appearance="standard" style="font-size: 16px;" class="pe-4">
              <mat-label>{{'configurations.promoManagement.orderBy' | transloco}}</mat-label>
              <mat-select formControlName="orderBy">
                <mat-option [value]="orderBy" *ngFor="let orderBy of orderByList">
                  {{orderBy.orderByText}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="orderBy!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Order by' } }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col w-45 my-1">
            <div class="row d-flex justify-content-between">
              <mat-form-field appearance="standard" style="font-size: 16px;" class="small-form-field">
                <mat-label>{{'configurations.promoManagement.minTickets' | transloco}}</mat-label>
                <input matInput type="number" min="0" formControlName="minTickets">
                <mat-error *ngIf="minTickets!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Min tickets' } }}</mat-error>
                <mat-error *ngIf="minTickets!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Min tickets' } }}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="standard" style="font-size: 16px;" class="small-form-field">
                <mat-label>{{'configurations.promoManagement.top' | transloco}}</mat-label>
                <input matInput type="number" min="0" formControlName="top">
                <mat-error *ngIf="top!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Top' } }}</mat-error>
                <mat-error *ngIf="top!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Top' } }}</mat-error>
              </mat-form-field>
            </div>
            <div class="row d-flex justify-content-between">
              <mat-form-field appearance="standard" style="font-size: 16px;" class="small-form-field">
                <mat-label>{{'configurations.promoManagement.playerId' | transloco}}</mat-label>
                <input matInput type="number" min="0" formControlName="playerId" (ngModelChange)="playerIdChange($event)">
                <mat-error *ngIf="playerId!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Player ID' } }}</mat-error>
                <mat-error *ngIf="playerId!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Player ID' } }}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="standard" style="font-size: 16px;" class="small-form-field">
                <mat-label>{{'configurations.promoManagement.games' | transloco}}</mat-label>
                <input matInput type="number" min="0" formControlName="games" (ngModelChange)="gamesChange($event)">
                <mat-error *ngIf="games!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Games' } }}</mat-error>
                <mat-error *ngIf="games!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Games' } }}</mat-error>
              </mat-form-field>
            </div>
            <div class="row d-flex justify-content-between">
              <button type="button" mat-stroked-button color="accent" class="action-btn mx-2 mt-3" (click)="clearSearch()">
                <mat-icon>cancel</mat-icon>
                {{ 'buttons.cancelButton' | transloco }}
              </button>
              <button mat-stroked-button color="primary" class="action-btn mx-2 mt-3">
                <!--                <mat-icon>clear</mat-icon>-->
                {{ 'buttons.refreshButton' | transloco }}
              </button>
            </div>
          </div>
      </form>
    </div>
    <div class="col d-flex w-13 border-left justify-content-center">
      <form [formGroup]="promoCreditsForm" (ngSubmit)="addPromoCredits()" autocomplete="off">
          <div class="row d-flex">
            <mat-form-field appearance="standard" style="font-size: 16px;" class="small-form-field">
              <mat-label>{{'configurations.promoManagement.amountLocal' | transloco}}</mat-label>
              <input matInput type="number" min="0" formControlName="amount">
              <mat-error *ngIf="amount!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Amount (Local)' } }}</mat-error>
              <mat-error *ngIf="amount!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Amount (Local)' } }}</mat-error>
            </mat-form-field>
          </div>
          <div class="row d-flex">
            <mat-form-field appearance="standard" style="font-size: 16px;" class="small-form-field">
              <mat-label>{{'configurations.promoManagement.columns.minCashout' | transloco}}</mat-label>
              <input matInput type="number" formControlName="minCashout">
              <mat-error *ngIf="promoCreditsForm.get('minCashout')!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Min cashout' } }}</mat-error>
              <mat-error *ngIf="promoCreditsForm.get('minCashout')!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Min cashout' } }}</mat-error>
            </mat-form-field>
          </div>
          <div class="row d-flex">
            <button type="button" mat-stroked-button color="accent" class="small-form-field mx-2" (click)="clearPromoCreditsForm()">
              <mat-icon>cancel</mat-icon>
              {{ 'buttons.cancelButton' | transloco }}
            </button>
          </div>
          <div class="row d-flex">
            <button mat-stroked-button color="primary" [disabled]="promoCreditsForm.invalid" class="small-form-field mx-2 mt-2 w-auto">
              <!--                <mat-icon>clear</mat-icon>-->
              {{'configurations.promoManagement.addPromoCreditsButton' | transloco}}
            </button>
          </div>
      </form>
    </div>
    <div class="col d-flex w-20 border-left justify-content-center">
      <form [formGroup]="freeBetsForm" (ngSubmit)="addFreeBets()" autocomplete="off">
        <div class="row">
          <mat-form-field appearance="standard" style="font-size: 16px;" class="small-form-field">
            <mat-label>{{'configurations.promoManagement.betCount' | transloco}}</mat-label>
            <input matInput type="number" min="0" formControlName="betCount">
            <mat-error *ngIf="betCount!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Bet Count' } }}</mat-error>
            <mat-error *ngIf="betCount!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Bet Count' } }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" style="font-size: 16px;" class="small-form-field">
            <mat-label>{{'configurations.promoManagement.betAmountLocal' | transloco}}</mat-label>
            <input matInput type="number" min="0" formControlName="betAmount">
            <mat-error *ngIf="betAmount!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Bet Amount (Local)' } }}</mat-error>
            <mat-error *ngIf="betAmount!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Bet Amount (Local)' } }}</mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <button type="button" mat-stroked-button color="accent" class="action-btn mx-2 mt-3" (click)="clearFreeBetsForm()">
            <mat-icon>cancel</mat-icon>
            {{ 'buttons.cancelButton' | transloco }}
          </button>
          <button mat-stroked-button color="primary" class="action-btn mx-2 mt-3">
            <!--                <mat-icon>clear</mat-icon>-->
            {{'configurations.promoManagement.addFreeBetsButton' | transloco}}
          </button>
        </div>
      </form>
    </div>
    <div class="col d-flex flex-column w-13 border-left border-right">
      <div class="row d-flex justify-content-center gap-3 mt-2">
        <button mat-stroked-button color="primary" (click)="getPlayerInfo()" class="w-75">
          <!--                <mat-icon>clear</mat-icon>-->
          {{'configurations.promoManagement.playerInfoButton' | transloco}}
        </button>
        <button mat-stroked-button color="accent" (click)="makePlayerAVip()" class="w-75">
          <!--                <mat-icon>clear</mat-icon>-->
          {{'configurations.promoManagement.vipPlayerButton' | transloco}}
        </button>
      </div>
    </div>
  </div>
  <div class="mt-1 max-height-table">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mt-0" matSort [hidden]="!showTable">

      <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
        <th mat-header-cell
            class="header-cell"
            *matHeaderCellDef
            mat-sort-header="{{headerCell(column)}}"
            arrowPosition="after"
            [ngStyle]="{'text-align': alignHeaderCell(column)}"
            [class.header-end]="alignHeaderCell(column) === 'end'"
            [class.header-start]="alignHeaderCell(column) === 'normal'">
          {{ translationColumns[i] | transloco }}
        </th>
        <ng-container *ngIf="column !== 'VIP'">
          <td mat-cell *matCellDef="let element" [ngStyle]="{'text-align': element.align(column)}"> {{element.format(column)}} </td>
        </ng-container>
        <ng-container *ngIf="column === 'VIP'">
          <td mat-cell *matCellDef="let element" [ngStyle]="{'text-align': element.align(column)}">
            <mat-icon svgIcon="crown" *ngIf="checkPlayerLevel(element.playerLevel)"></mat-icon>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr mat-row *matRowDef="let element; let index = dataIndex; columns: columns;"
          class="element-row"
          [class.clicked-row]="expandedElement === element"
          [ngClass]="{alternating: index % 2 === 0}"
          (click)="playerClicked(element)">
      </tr>
    </table>
  </div>
</div>
