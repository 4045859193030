<form [formGroup]="editingForm" (ngSubmit)="submitForm()">
  <div class="container pb-2">
    <div class="row">
      <div class="flex-container d-inline-flex justify-content-between">
        <h2 mat-dialog-title style="font-size: 24px; font-weight: lighter; width: 50%">{{ 'configurations.company.currencies.editDialog.title' | transloco: {value: data.currency.currencyId} }}</h2>
        <p class="dashboard-card-title pt-1 w-auto">{{game}}</p>
      </div>
    </div>
  </div>

  <div mat-dialog-content style="min-height: 630px;">
    <div class="container" style="overflow-y: hidden">
      <div class="row my-0 justify-content-center" *ngFor="let data of dynamicFormFieldArrayTop">
        <mat-form-field appearance="outline" style="font-size: 16px; width: 100%;">
          <mat-label>{{ data.label | transloco }}</mat-label>
          <input matInput type="number" min="0" [formControlName]="data.formControlName">
          <mat-error *ngIf="data.formControl!.errors?.required">{{ 'errorMessages.required' | transloco: { name: data.errorText} }}</mat-error>
          <mat-error *ngIf="data.formControl!.errors?.min">{{ 'errorMessages.min' | transloco: { name: data.errorText } }}</mat-error>
        </mat-form-field>
      </div>

      <div class="row my-0 justify-content-center">
        <div class="col">
          <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
            <mat-label>{{ 'configurations.company.currencies.tableColumns.preset1' | transloco }}</mat-label>
            <input matInput type="number" min="0" formControlName="predef1">
            <mat-error *ngIf="predef1!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Predefined value 1' } }}</mat-error>
            <mat-error *ngIf="predef1!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Predefined value 1' } }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
            <mat-label>{{ 'configurations.company.currencies.tableColumns.preset2' | transloco }}</mat-label>
            <input matInput type="number" min="0" formControlName="predef2">
            <mat-error *ngIf="predef2!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Predefined value 2' } }}</mat-error>
            <mat-error *ngIf="predef2!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Predefined value 2' } }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row my-0 justify-content-center">
        <div class="col">
          <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
            <mat-label>{{ 'configurations.company.currencies.tableColumns.preset3' | transloco }}</mat-label>
            <input matInput type="number" min="0" formControlName="predef3">
            <mat-error *ngIf="predef3!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Predefined value 3' } }}</mat-error>
            <mat-error *ngIf="predef3!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Predefined value 3' } }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
            <mat-label>{{ 'configurations.company.currencies.tableColumns.preset4' | transloco }}</mat-label>
            <input matInput type="number" min="0" formControlName="predef4">
            <mat-error *ngIf="predef4!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Predefined value 4' } }}</mat-error>
            <mat-error *ngIf="predef4!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Predefined value 4' } }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row my-0 justify-content-center" *ngIf="data.gameId !== 102">
        <div class="col">
          <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
            <mat-label>{{ 'configurations.company.currencies.tableColumns.preset5' | transloco }}</mat-label>
            <input matInput type="number" min="0" formControlName="predef5">
            <mat-error *ngIf="predef5!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Predefined value 5' } }}</mat-error>
            <mat-error *ngIf="predef5!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Predefined value 5' } }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
            <mat-label>{{ 'configurations.company.currencies.tableColumns.preset6' | transloco }}</mat-label>
            <input matInput type="number" min="0" formControlName="predef6">
            <mat-error *ngIf="predef6!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Predefined value 6' } }}</mat-error>
            <mat-error *ngIf="predef6!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Predefined value 6' } }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="container mb-2 me-2">
    <div mat-dialog-actions class="d-flex flex-row-reverse w-100">
      <button class="px-4 ms-2" type="submit" mat-flat-button color="primary" [disabled]="!editingForm.valid">{{ 'buttons.saveButton' | transloco }}</button>
      <button class="px-3" type="button" mat-stroked-button color="accent" (click)="onNoClick()">{{ 'buttons.cancelButton' | transloco }}</button>
    </div>
  </div>
</form>
