import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./components/login/login.component";
import {MainPageComponent} from "./components/main-page/main-page.component";
import {PageNotFoundComponent} from "./components/page-not-found/page-not-found.component";
import {PostLoginComponent} from "./components/login/post-login/post-login.component";
import {AuthGuardService} from "./services/authentication/auth-guard.service";
import {TwoFactorService} from "./services/two-factor/two-factor.service";
import {TwoFactorSignUpComponent} from "./components/two-factor-sign-up/two-factor-sign-up.component";

const routes: Routes = [
  // http://localhost:4200/
  {path: '', component: LoginComponent},
  // http://localhost:4200/post-login
  {path: 'post-login', component: PostLoginComponent, canActivate: [TwoFactorService]},
  // http://localhost:4200/home
  {path: 'home', component: MainPageComponent, canActivate: [AuthGuardService]},
  // http://localhost:4200/2fa-support
  {path: '2fa-support', component: TwoFactorSignUpComponent, canActivate: [TwoFactorService]},
  // Page not found
  {path: '**', pathMatch: 'full', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
