<form [formGroup]="editingForm" (ngSubmit)="submitForm()" autocomplete="off">
  <div class="container pb-2">
    <div class="row">
      <div class="flex-container">
        <h2 mat-dialog-title style="font-size: 24px; font-weight: lighter;">{{ 'reports.meteorShower.editMeteorDialog.title' | transloco }}</h2>
      </div>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="container">
      <!-- Company -->
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
          <mat-label>{{ 'reports.meteorShower.fields.company' | transloco }}</mat-label>
          <mat-select formControlName="companyId" [(value)]="company" (selectionChange)="setCompany($event)">
            <mat-option [value]="company.CompanyID" *ngFor="let company of companies">
              {{company.CompanyName}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="companyId!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Company' } }}</mat-error>
        </mat-form-field>
      </div>

      <!-- HoursActiveFrom && HoursActiveTo -->
      <div class="row my-0 justify-content-center">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'reports.meteorShower.fields.hoursActiveFrom' | transloco }}</mat-label>
            <input type="number" matInput formControlName="hoursActiveFrom" [value]="meteor.HoursActiveFrom" style="font-size: 16px">
            <mat-error *ngIf="hoursActiveFrom!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'HoursActiveFrom' } }}</mat-error>
            <mat-error *ngIf="hoursActiveFrom!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'HoursActiveFrom' } }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'reports.meteorShower.fields.hoursActiveTo' | transloco }}</mat-label>
            <input type="number" matInput formControlName="hoursActiveTo" [value]="meteor.HoursActiveTo" style="font-size: 16px">
            <mat-error *ngIf="hoursActiveTo!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'HoursActiveTo' } }}</mat-error>
            <mat-error *ngIf="hoursActiveTo!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'HoursActiveTo' } }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- OffHoursCoef && Name -->
      <div class="row my-0 justify-content-center">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'reports.meteorShower.fields.offHoursCoef' | transloco }}</mat-label>
            <input type="number" matInput formControlName="offHoursCoef" [value]="meteor.OffHoursCoef" style="font-size: 16px">
            <mat-error *ngIf="offHoursCoef!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'OffHoursCoef' } }}</mat-error>
            <mat-error *ngIf="offHoursCoef!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'OffHoursCoef' } }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'reports.meteorShower.fields.name' | transloco }}</mat-label>
            <input matInput formControlName="name" [value]="meteor.Name" style="font-size: 16px">
            <mat-error *ngIf="name!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Name' } }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- currency && amount -->
      <div class="row my-0 justify-content-center">
        <div class="col">
          <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
            <mat-label>{{ 'reports.meteorShower.fields.currency' | transloco }}</mat-label>
            <mat-select formControlName="currency" [(value)]="currencyCurrency" (selectionChange)="setCurrency($event)">
              <mat-option [value]="currency.CurrencyID" *ngFor="let currency of currencies">
                {{currency.CurrencyID}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="currency!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Currency' } }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'reports.meteorShower.fields.amount' | transloco }}</mat-label>
            <input type="number" matInput formControlName="amount" [value]="meteor.Amount" style="font-size: 16px">
            <mat-error *ngIf="amount!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Amount' } }}</mat-error>
            <mat-error *ngIf="amount!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Amount' } }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- players -->
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'reports.meteorShower.fields.players' | transloco }}</mat-label>
          <input type="number" matInput formControlName="players" [value]="meteor.Players" style="font-size: 16px">
          <mat-error *ngIf="players!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Players' } }}</mat-error>
          <mat-error *ngIf="players!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Players' } }}</mat-error>
        </mat-form-field>
      </div>

      <!-- MinutesMin && MinutesMax -->
      <div class="row my-0 justify-content-center">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'reports.meteorShower.fields.minutesMin' | transloco }}</mat-label>
            <input type="number" matInput formControlName="minutesMin" [value]="meteor.MinutesMin" style="font-size: 16px">
            <mat-error *ngIf="minutesMin!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'MinutesMin' } }}</mat-error>
            <mat-error *ngIf="minutesMin!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'MinutesMin' } }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'reports.meteorShower.fields.minutesMax' | transloco }}</mat-label>
            <input type="number" matInput formControlName="minutesMax" [value]="meteor.MinutesMax" style="font-size: 16px">
            <mat-error *ngIf="minutesMax!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'MinutesMax' } }}</mat-error>
            <mat-error *ngIf="minutesMax!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'MinutesMax' } }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-1 mb-2">
    <div mat-dialog-actions class="d-flex flex-row-reverse w-100 mt-2">
      <button class="px-4 ms-2" type="submit" mat-flat-button color="primary" [disabled]="!editingForm.valid">{{ 'buttons.saveButton' | transloco }}</button>
      <button class="px-3" type="button" mat-stroked-button color="accent" (click)="onNoClick()">{{ 'buttons.cancelButton' | transloco }}</button>
    </div>
  </div>
</form>
