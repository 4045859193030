import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ReportsService} from "../../../../services/reports/reports.service";
import {HandlingService} from "../../../../services/global-handling/handling.service";
import {PlayersDetailsReport} from "../../../../models/reports/players/players-details-report.model";
import {
  UnmappedPlayersDetailsReport
} from "../../../../models/reports/players/unmapped-players-details-report.interface";

@Component({
  selector: 'app-player-report-details',
  templateUrl: './player-report-details.component.html',
  styleUrls: ['./player-report-details.component.scss']
})
export class PlayerReportDetailsComponent implements OnInit, OnChanges {

  @Input('dateFrom') dateFrom?: string | null;
  @Input('dateTo') dateTo?: string | null;
  @Input('elementID') elementID?: number | null;
  @Input('expandedElementID') expandedElementID?: number | null;

  public playersDetails: PlayersDetailsReport[] = [];
  public translationColumns: string[] = [];
  public columns: string[] = []

  constructor(private reportsService: ReportsService,
              private errorHandlingService: HandlingService) {
    this.translationColumns = reportsService.getPlayersDetailsReportTranslationColumns();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.elementID === this.expandedElementID){
      if(this.elementID && this.dateFrom && this.dateTo){
        this.reportsService.getPlayersReportDetails(this.dateFrom, this.dateTo, this.elementID).subscribe(newData => {
          this.playersDetails = this.mapData(newData);
          if(this.playersDetails[0]) {
            this.columns = this.playersDetails[0].getFields();
          }
        },error => {
          this.errorHandlingService.error('Error while fetching player details report data', error);
        });
      }
    }
  }

  private mapData(unmapped: UnmappedPlayersDetailsReport[]): PlayersDetailsReport[] {
    let data: PlayersDetailsReport[] = [];
    unmapped.forEach(item => {
      data.push(
        new PlayersDetailsReport(
          item.GameName,
          item.Bets,
          item.StakeEUR,
          item.WinEUR,
          item.GGR,
          item.GGRPerc));
    })
    return data;
  }

  public alignHeaderCell(column: string): string {
    return this.playersDetails[0].align(column);
  }

}
