import {ReportColumns} from "../report-columns.interface";

export class Platform {
  platformId: number
  platformName: string

  columnInfo: ReportColumns[]

  constructor(platformId: number, platformName: string) {
    this.platformId = platformId;
    this.platformName = platformName;

    this.columnInfo = [
      {key: 'Platform ID', info: {field: 'platformId', total: 0, format: this.platformId, align: 'normal'}},
      {key: 'Platform name', info: {field: 'platformName', total: 0, format: this.platformName, align: 'normal'}},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }
}
