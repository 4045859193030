import {ReportColumns} from "../report-columns.interface";

export class SummaryReport {
  companyID: number
  companyName: string
  bets: number
  stakeInEUR: number
  wins: number
  winInEUR: number
  resultInEUR: number
  percEUR: number

  columnInfo: ReportColumns[]

  constructor(companyID: number, companyName: string, bets: number, stakeInEUR: number, wins: number, winInEUR: number, resultInEUR: number, percEUR: number) {
    this.companyID = companyID;
    this.companyName = companyName;
    this.bets = bets;
    this.stakeInEUR = stakeInEUR;
    this.wins = wins;
    this.winInEUR = winInEUR;
    this.resultInEUR = resultInEUR;
    this.percEUR = percEUR;

    this.columnInfo = [
      {key: 'Company ID', info: {field: 'companyID', total: this.companyID, format: this.companyID, align: 'normal'}},
      {key: 'Company Name', info: {field: 'companyName', total: this.companyName, format: this.formatString(this.companyName), align: 'normal'}},
      {key: 'Bets', info: {field: 'bets', total: this.bets, format: this.formatInteger(this.bets), align: 'end'}},
      {key: 'Stake In EUR', info: {field: 'stakeInEUR', total: this.stakeInEUR, format: this.formatMoney(this.stakeInEUR), align: 'end'}},
      {key: 'Wins', info: {field: 'wins', total: this.wins, format: this.formatInteger(this.wins), align: 'end'}},
      {key: 'Wins In EUR', info: {field: 'winInEUR', total: this.winInEUR, format: this.formatMoney(this.winInEUR), align: 'end'}},
      {key: 'Result', info: {field: 'resultInEUR', total: this.resultInEUR, format: this.formatMoney(this.resultInEUR), align: 'end'}},
      {key: 'Percentage', info: {field: 'percEUR', total: this.percEUR, format: this.formatMoney(this.percEUR), align: 'end'}}
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public total(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.total;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatString(string: string): string {
    return string;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2});
  }
}
