// baseUrl: "http://localhost:8081/",
// baseUrl: "http://192.168.2.120:8081/",
// baseUrl: "https://demo.backoffice.elbet.com/",

export const environment = {
  production: false,
  baseUrl: "https://backoffice.elbetdev.com:8081/",
  chatUrl: "wss://chat.elbetdev.com:8765",
  chatSecret: "*&!)z@asdf&#)(!@u#",
  chatUsername: "ROCKETMAN",
  translateApiKey: "AIzaSyC7PtqmvGkiohHyAN7CXr6ytIkdXi4icKA",
  gameInfoUrl: "https://api.stage.public.elbet.com/getGameInfo/"
};
