<h2 class="component-title">{{ 'reports.jackpotHistoryReport.title' | transloco }}</h2>
<hr>

<div class="frame round-border">
  <div class="row d-flex align-items-center">
    <div class="ps-3 pe-0 w-auto">
      <app-date-picker [placeholder]="'Start date'" (date)="setStartDate($event)" [init]="startDate"></app-date-picker>
    </div>
    <div class="ps-3 pe-0 w-auto">
      <app-date-picker [placeholder]="'End date'" (date)="setEndDate($event)" [init]="endDate"></app-date-picker>
    </div>
    <div class="ps-3 pe-0 w-auto">
      <mat-form-field appearance="standard" style="font-size: 16px;" class="small-form-field">
        <mat-label>{{ 'reports.jackpotHistoryReport.game' | transloco }}</mat-label>
        <mat-select [(ngModel)]="currentGame">
          <mat-option [value]="game" *ngFor="let game of games">
            {{game.text}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ps-3 pe-0 w-auto">
      <mat-form-field appearance="standard" style="font-size: 16px;" class="small-form-field">
        <mat-label>{{ 'reports.jackpotHistoryReport.level' | transloco }}</mat-label>
        <mat-select [(ngModel)]="currentLevel">
          <mat-option [value]="level" *ngFor="let level of jackpotLevels">
            {{level.text}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ps-3 pe-0 w-auto mb-2">
      <button mat-stroked-button color="primary" (click)="refreshData()">
        <!--        <mat-icon>refresh</mat-icon>-->
        {{ 'buttons.refreshDataButton' | transloco }}
      </button>
    </div>
  </div>
  <div class="max-height-table">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mt-0" matSort [hidden]="!showTable">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
        <th mat-header-cell
            class="header-cell"
            *matHeaderCellDef
            mat-sort-header="{{headerCell(column)}}"
            arrowPosition="after"
            [ngStyle]="{'text-align': alignHeaderCell(column)}"
            [class.header-end]="alignHeaderCell(column) === 'end'"
            [class.header-start]="alignHeaderCell(column) === 'normal'">
          {{ translationColumns[i] | transloco }}
        </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'text-align': element.align(column)}"> {{element.format(column)}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr mat-row *matRowDef="let element; let index = dataIndex; columns: columns;"
          class="element-row"
          [ngClass]="{alternating: index % 2 === 0}">
      </tr>
    </table>
  </div>
</div>
