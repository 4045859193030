<mat-toolbar class="header">
  <mat-toolbar-row class="firstRow">
    <button mat-icon-button (click)="navigationService.toggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
    <h4 id="bigTitle" class="header-title">{{ 'title' | transloco }}</h4>
    <h4 class="smallTitle">{{ 'smallTitle1' | transloco }}</h4>
    <span class="spacer"></span>
    <p class="username">{{name}}</p>

    <button mat-icon-button [matMenuTriggerFor]="profileMenu">
      <mat-icon>person</mat-icon>
    </button>
    <button mat-icon-button [matMenuTriggerFor]="settingsMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </mat-toolbar-row>
  <mat-toolbar-row class="secondRow">
    <h4 class="pb-4 mb-3 smallTitle" style="padding-left: 40px">{{ 'smallTitle2' | transloco }}</h4>
  </mat-toolbar-row>
  <mat-menu #profileMenu="matMenu">
    <button mat-menu-item (click)="profile()">
      <mat-icon>person</mat-icon>
      <span>{{ 'buttons.profileButton' | transloco }}</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>logout</mat-icon>
      <span>{{ 'buttons.logoutButton' | transloco }}</span>
    </button>
  </mat-menu>
  <mat-menu #languageMenu="matMenu">
    <button mat-menu-item (click)="updateLocale('en-GB')">
<!--      <mat-icon>translate</mat-icon>-->
      <img src="assets/flags/gb_flag.png" alt="EN">
      <span class="ms-2">English</span>
    </button>
    <button mat-menu-item (click)="updateLocale('sr-Latn')">
      <img src="assets/flags/rs_flag.png" alt="RS">
      <span class="ms-2">Serbian</span>
    </button>
    <button mat-menu-item (click)="updateLocale('fr')">
      <img src="assets/flags/fr_flag.png" alt="FR">
      <span class="ms-2">French</span>
    </button>
    <button mat-menu-item [disabled]="true" (click)="updateLocale('ru')">
      <img src="assets/flags/ru_flag.png" alt="RU">
      <span class="ms-2">Russian</span>
    </button>
    <button mat-menu-item [disabled]="true" (click)="updateLocale('it')">
      <img src="assets/flags/it_flag.png" alt="IT">
      <span class="ms-2">Italian</span>
    </button>
  </mat-menu>
  <mat-menu #settingsMenu="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="themeMenu">
      <mat-icon>color_lens</mat-icon>
      <span>Theme</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="languageMenu">
      <mat-icon>translate</mat-icon>
      <span>{{ 'language.languageButton' | transloco }}</span>
    </button>
  </mat-menu>
  <mat-menu #themeMenu="matMenu">
    <button mat-menu-item (click)="lightTheme()">
      <mat-icon>color_lens</mat-icon>
      <span>Light</span>
    </button>
    <button mat-menu-item (click)="darkTheme()">
      <mat-icon>color_lens</mat-icon>
      <span>Dark</span>
    </button>
  </mat-menu>
</mat-toolbar>
