import {Window} from "./window.interface";
import {JackpotConfigComponent} from "../../components/configurations/company/jackpot-config/jackpot-config.component";
import {
  RocketmanJackpotConfigComponent
} from "../../components/configurations/company/jackpot-config/rocketman-jackpot-config/rocketman-jackpot-config.component";
import {
  FastballsJackpotConfigComponent
} from "../../components/configurations/company/jackpot-config/fastballs-jackpot-config/fastballs-jackpot-config.component";

export class JackpotConfigWindow implements Window {
  readonly component = JackpotConfigComponent;
}
