import {Component, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {PromoManagementService} from "../../../services/promo-management/promo-management.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PlayerInfoDialogData} from "../../../models/configurations/promo-management/player-info-dialog-data.interface";
import {LoadingService} from "../../../services/loading/loading.service";
import {
  UnmappedPlayerInfo
} from "../../../models/configurations/promo-management/unmapped/unmapped-player-info.interface";
import {PlayerInfo} from "../../../models/configurations/promo-management/player-info.model";
import {
  UnmappedPlayerGraphInfo
} from "../../../models/configurations/promo-management/unmapped/unmapped-player-graph-info.interface";
import {
  UnmappedPlayerTicketsInfo
} from "../../../models/configurations/promo-management/unmapped/unmapped-player-tickets-info.interface";
import {PlayerTicketInfo} from "../../../models/configurations/promo-management/player-ticket-info.model";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {
  ApexAxisChartSeries,
  ApexChart, ApexDataLabels, ApexLegend, ApexResponsive,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis, ApexYAxis,
  ChartComponent
} from "ng-apexcharts";
import {DashboardService} from "../../../services/dashboard/dashboard.service";
import {ThemeService} from "../../../services/theme/theme.service";
import {forkJoin} from "rxjs";
import {first, map} from "rxjs/operators";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {ReportsService} from "../../../services/reports/reports.service";
import {UtilsService} from "../../../services/utils/utils.service";

@Component({
  selector: 'app-player-info-dialog',
  templateUrl: './player-info-dialog.component.html',
  styleUrls: ['./player-info-dialog.component.scss']
})
export class PlayerInfoDialogComponent implements OnInit {

  public playerInfo: PlayerInfo | null = null;
  public chartData: number[] = [];
  public playerTicketInfo: PlayerTicketInfo[] = [];

  public bets: number = 0;
  public stakeTotal: number = 0;
  public winTotal: number = 0;
  public ggr: number = 0;
  public freeBets: number = 0;

  public showChart!: Promise<boolean>;
  @ViewChild('areaChart') chart!: ChartComponent;
  public chartOptions!: ChartOptions;
  public chartHighlightData: any[] = [];

  private currentTheme: string = 'light';

  public dataSource!: MatTableDataSource<PlayerTicketInfo>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  public showTable = false;
  public columns: string[] = [];
  public translationColumns: string[] = [];

  constructor(public dialogRef: MatDialogRef<PlayerInfoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: PlayerInfoDialogData,
              private promoManagementService: PromoManagementService,
              private loadingService: LoadingService,
              private handlingService: HandlingService,
              private dashboardService: DashboardService,
              private themeService: ThemeService,
              private utilsService: UtilsService,
              private ngZone: NgZone) {
    loadingService.setLoadingSteps(3);
    promoManagementService.getPlayerInfo(data.element.id)
      .subscribe(value => {
        this.playerInfo = this.mapPlayerInfo(value);
      }, error => {
        this.handlingService.error('Error fetching player information', error);
      }, () => {
        loadingService.incrementLoadingSteps();
      });

    this.refreshData();

    themeService.themeObs.subscribe(newTheme => {
      if (this.chartOptions){
        if (newTheme !== 'light'){
          this.chartOptions.tooltip = themeService.getDarkTooltip();
          this.chartOptions.xaxis.labels!.style!.colors = themeService.getDarkText();
          this.chartOptions.yaxis.labels!.style!.colors = themeService.getDarkText();
        }else{
          this.chartOptions.tooltip = themeService.getLightTooltip();
          this.chartOptions.xaxis.labels!.style!.colors = themeService.getLightText();
          this.chartOptions.yaxis.labels!.style!.colors = themeService.getLightText();
        }
      }
    });
  }

  ngOnInit(): void {
  }

  private mapPlayerInfo(unmapped: UnmappedPlayerInfo[]): PlayerInfo {
    return new PlayerInfo(
      unmapped[0].CompanyName,
      unmapped[0].UserName,
      unmapped[0].FirstName,
      unmapped[0].LastName,
      unmapped[0].email,
      unmapped[0].CountryName,
      unmapped[0].Balance,
      unmapped[0].currencyID,
      unmapped[0].LanguageName,
    )
  }

  private mapPlayerGraphInfo(unmapped: UnmappedPlayerGraphInfo[]): number[] {
    const results: number[] = [];
    unmapped.forEach(item => results.push(item.SessionBalance));
    return results;
  }

  private mapPlayerTicketInfo(unmapped: UnmappedPlayerTicketsInfo[], balances: number[]): PlayerTicketInfo[] {
    const results: PlayerTicketInfo[] = [];
    this.bets = unmapped.length;
    unmapped.forEach((item, index) => {
      this.stakeTotal += item.Bet;
      this.winTotal += item.Paid;
      if (item.TicketType === 3 || item.TicketType === 2){
        this.freeBets += 1;
        this.chartHighlightData.push(balances[index]);
      }else{
        this.chartHighlightData.push(null);
      }
      const date = this.utilsService.timestampToString(item.CreatedTime);
      results.push(new PlayerTicketInfo(
        date,
        item.RoundID,
        item.TicketStatus,
        item.Bet,
        item.Paid,
        item.Multiplier,
        item.CurrencyID,
        item.BetEUR,
        item.PaidEur,
        item.TicketType,
      ))
    });
    this.ggr = this.stakeTotal - this.winTotal;
    return results;
  }

  public formatMoney(currency: string = '', value?: number) {
    return value ? value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2}) + ' ' + currency : '0.00 ' + currency;
  }

  public formatInteger(value: number): any {
    return new Intl.NumberFormat().format(value);
  }

  private initOptions(): ChartOptions {
    return {
      series: [
        {
          name: 'Session balance',
          data: this.chartData
        },
        {
          name: 'Free bets',
          data: this.chartHighlightData
        }
      ],
      chart: {
        type: 'area',
        height: 309,
        width: '99%',
        offsetX: -3,
        zoom: {
          enabled: true
        },
        animations: {
          enabled: false
        },
        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: false,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      fill: {
        type: 'gradient'
      },
      title: {
        text: "",
        align: 'left'
      },
      subtitle: {
        text: "",
        align: 'left'
      },
      labels: [],
      tooltip: this.themeService.getDefaultTooltip(),
      xaxis: {
        tickPlacement: 'on',
        tickAmount: 6,
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          showDuplicates: false,
          style: {
            colors: this.themeService.getDefaultText()
          }
        }
      },
      yaxis: {
        opposite: false,
        labels: {
          style: {
            colors: this.themeService.getDefaultText()
          }
        }
      },
      legend: {
        horizontalAlign: 'left',
        labels: {
          colors: this.themeService.getDefaultText()
        },
      },
      responsive: [

      ],
      colors: this.themeService.getDefaultColors()
    };
  }

  private showReports(): void {
    if (this.columns.length === 0){
      this.columns = this.dataSource.data[0].getFields();
    }
    this.showTable = true;
  }

  private hideReports(): void {
    this.showTable = false;
    this.dataSource = new MatTableDataSource<PlayerTicketInfo>([]);
  }

  public alignHeaderCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].align(column);
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }

  public refreshData() {
    forkJoin([
      this.promoManagementService.getPlayerGraphInfo(this.data.element.id, this.data.dateFrom, this.data.dateTo),
      this.promoManagementService.getPlayerTicketInfo(this.data.element.id, this.data.dateFrom, this.data.dateTo)])
      .pipe(map(([unmappedPlayerGraph, unmappedPlayerTicket]) => {
        this.chartData = this.mapPlayerGraphInfo(unmappedPlayerGraph);
        this.chartOptions = this.initOptions();
        this.showChart = Promise.resolve(true);
        this.playerTicketInfo = this.mapPlayerTicketInfo(unmappedPlayerTicket, this.chartData);
        this.translationColumns = this.promoManagementService.getPlayerTicketTranslations();
        this.dataSource = new MatTableDataSource(this.playerTicketInfo);
        this.dataSource.sort = this.sort;
        if (this.dataSource.data.length === 0){
          this.hideReports();
        }else{
          this.showReports();
        }
      })).subscribe(
      () => {
        this.ngZone.onStable.pipe(first()).subscribe(() => {
          this.loadingService.incrementLoadingSteps();
        });
      },
      error => this.handlingService.error('Error fetching player information', error)
    );
  }

  public cellBackground(element: PlayerTicketInfo, column: string): string {
    if (column === 'Multiplier'){
      if (element.multiplier >= 10 && element.multiplier < 100) {
        return '#ffeb3b'
      }else if (element.multiplier >= 100){
        return this.currentTheme !== 'light' ? '#cf6679' : '#b00020'
      }
    }
    return ''
  }

  public rowBackground(element: PlayerTicketInfo): string {
    if (element.ticketStatus === 2){
      return '#cf6679';
    }else if (element.ticketStatus === 3) {
      return '#a5d6a7'
    }
    return '';
  }

  public specialTicket(element: PlayerTicketInfo): boolean {
    return element.ticketType === 2 || element.ticketType === 3;
  }
}

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  tooltip: ApexTooltip;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  fill: any;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
  responsive: ApexResponsive[];
  colors: any[]
};
