import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {TournamentAward} from "../../../models/tournaments/tournament-award.model";
import {fromEvent} from "rxjs";
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";
import {ChatRoom} from "../../../models/chat-rooms/chat-room.interface";

const FIRST = 'st';
const SECOND = 'nd';
const THIRD = 'rd';
const ELSE = 'th';
const NONE = '';

@Component({
  selector: 'app-award-item',
  templateUrl: './award-item.component.html',
  styleUrls: ['./award-item.component.scss']
})
export class AwardItemComponent implements OnInit, OnChanges, AfterViewInit {

  @Input('currency') currency: string = '';
  @Input('award') award: TournamentAward | null = null;
  @ViewChild('input') input!: ElementRef;
  public placeSuffix: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.award){
      this.placeSuffix = this.initPlaceSuffix(this.award.place);
    }
  }

  private initPlaceSuffix(place: number): string {
    return place > 3 ? ELSE : place === 1 ? FIRST : place === 2 ? SECOND : place === 3 ? THIRD : NONE;
  }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement,'keyup')
      .pipe(
        debounceTime(150),
        tap(() => {
          if (this.award){
            this.award.award = +this.input.nativeElement.value;
          }
        })
      )
      .subscribe();
  }

}
