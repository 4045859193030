import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {TournamentCompany} from "../../../models/tournaments/tournament-company.model";
import {ConfigurationsService} from "../../../services/configurations/configurations.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {CompanySimple} from "../../../models/configurations/company-simple.interface";
import {MatSelectionListChange} from "@angular/material/list";
import {Tournament} from "../../../models/tournaments/tournament.model";
import {TournamentsService} from "../../../services/tournaments/tournaments.service";

@Component({
  selector: 'app-company-editor-dialog',
  templateUrl: './company-editor-dialog.component.html',
  styleUrls: ['./company-editor-dialog.component.scss']
})
export class CompanyEditorDialogComponent implements OnInit {

  public allCompanies: CompanySimple[] = [];
  public form: FormGroup;

  constructor(private dialogRef: MatDialogRef<CompanyEditorDialogComponent>,
              private formBuilder: FormBuilder,
              private configurationsService: ConfigurationsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private tournamentsService: TournamentsService,
              @Inject(MAT_DIALOG_DATA) public inputData: TournamentWithCompanies) {

    this.form = this.formBuilder.group({
      displayedCompanies: [[]],
      addedCompanies: [new Set<CompanySimple>()],
      deletedCompanies: [new Set<CompanySimple>()],
      filterText: [''],
    });

    this.fetchCompanies();
  }

  ngOnInit(): void {
  }

  private fetchCompanies(): void {
    this.loadingService.setLoadingSteps(1);
    this.configurationsService.getSimpleCompanies().subscribe(newData => {
      this.allCompanies = newData;
      this.displayedCompanies?.setValue(this.initSelectedCompanies(newData));
    },error => {
      this.handlingService.error('Error while fetching available companies', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    });
  }

  public async submit() {
    const result = await Promise.all([
      this.sendAddingCompanies(Array.from(this.addedCompanies?.value)),
      this.sendDeletingCompanies(Array.from(this.deletedCompanies?.value))
    ])
      .then(() => {
        this.handlingService.success('Tournament companies successfully updated');
        return true;
      })
      .catch(error => {
        this.handlingService.error('Failed to update tournament some companies', error);
        return false;
      })
    this.dialogRef.close({data: result});
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  private initSelectedCompanies(allCompanies: CompanySimple[]) {
    if (this.inputData.companies.length === 0){
      return [];
    }
    const selected: CompanySimple[] = [];
    for (const inputCompany of this.inputData.companies) {
      for (const company of allCompanies) {
        if (inputCompany.id === company.CompanyID){
          selected.push(company);
        }
      }
    }
    return selected;
  }

  public get filterText() {
    return this.form.get('filterText');
  }

  public get displayedCompanies() {
    return this.form.get('displayedCompanies');
  }

  public get addedCompanies() {
    return this.form.get('addedCompanies');
  }

  public get deletedCompanies() {
    return this.form.get('deletedCompanies');
  }

  public selectionChanged(event: MatSelectionListChange) {
    const company = event.options[0];
    if (this.companyExists(company.value)){
      if (!company.selected){
        this.deletedCompanies?.value.add(company.value);
        this.addedCompanies?.value.delete(company.value);
      }else{
        this.deletedCompanies?.value.delete(company.value);
      }
    }else{
      if (!company.selected){
        this.addedCompanies?.value.delete(company.value);
      }else{
        this.addedCompanies?.value.add(company.value);
        this.deletedCompanies?.value.delete(company.value);
      }
    }
  }

  private companyExists(company: CompanySimple) {
    return Array.from(this.inputData.companies).some(item => item.id === company.CompanyID);
  }

  private sendAddingCompanies(companies: CompanySimple[]) {
    return Promise.all(companies.map((item: CompanySimple) => this.tournamentsService.addCompanyToTournament(this.inputData.tournament.id, item)))
  }

  private sendDeletingCompanies(companies: CompanySimple[]) {
    return Promise.all(companies.map((item: CompanySimple) => this.tournamentsService.deleteCompanyFromTournament(this.inputData.tournament.id, item)));
  }
}

interface TournamentWithCompanies {
  tournament: Tournament
  companies: TournamentCompany[]
}
