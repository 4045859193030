import {Component, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ConfigCompany} from "../../../../../models/configurations/company-config/overview/config-company.model";
import {ConfigurationsService} from "../../../../../services/configurations/configurations.service";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {AddCompanyDialogComponent} from "../add-company-dialog/add-company-dialog.component";
import {EditCompanyDialogComponent} from "../edit-company-dialog/edit-company-dialog.component";
import {HandlingService} from "../../../../../services/global-handling/handling.service";
import {LoadingService} from "../../../../../services/loading/loading.service";
import {LocalizationService} from "../../../../../services/localization/localization.service";
import {
  CompanyAddResult
} from "../../../../../models/configurations/company-config/overview/company-add-result.interface";
import {RolesService} from "../../../../../services/roles/roles.service";
import {RoleState} from "../../../../../models/roles/role-state.enum";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-companies-table',
  templateUrl: './companies-table.component.html',
  styleUrls: ['./companies-table.component.scss']
})
export class CompaniesTableComponent implements OnInit, OnChanges  {

  public dataSource: MatTableDataSource<ConfigCompany> = new MatTableDataSource<ConfigCompany>([]);
  public companies: ConfigCompany[] = [];
  public columns: string[] = [];
  public translationColumns: string[] = [];
  public selected: ConfigCompany | null = null;

  public privileges: boolean = true;

  @ViewChild(MatTable, {static:true}) table!: MatTable<ConfigCompany>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(private configurationsService: ConfigurationsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private rolesService: RolesService,
              private localizationService: LocalizationService,
              private dialog: MatDialog) {
    this.translationColumns = localizationService.getCompanyConfigurationsTableTranslations();
    this.privileges = this.rolesService.checkRole(RoleState.OWNER);
    this.columns = [];
    this.refreshData();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {}

  public addCompany(): void {
    this.openAddingDialog();
  }

  public editCompany(): void {
    if (this.selected) {
      this.openEditingDialog();
    }else {
      this.handlingService.error('Please select a company');
    }
  }

  public selectCompany(company: ConfigCompany | null): void {
    this.selected = company;
  }

  public deselectAll(): void {
    this.selected = null;
  }

  public refreshData(): void {
    this.selected = null;
    this.loadingService.setLoadingSteps(1);

    this.configurationsService.getCompanies().subscribe(newData => {
      this.dataSource.data = this.configurationsService.companyInputMapper(newData);
      this.dataSource.sort = this.sort;
      this.columns = this.dataSource.data[0].getFields();
      this.table.renderRows();
    },error => {
      this.handlingService.error('Error while fetching users', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    });
  }

  private openAddingDialog(): void {
    const dialogRef = this.dialog.open(AddCompanyDialogComponent, {
      width: '860px',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.configurationsService.addCompany(result.data).subscribe(value => {
          const id = (<CompanyAddResult>value).CompanyID;
          if ((<CompanyAddResult>value).ErrorCode > 0) {
            return this.handlingService.error('Error while adding company');
          }
          result.data.id = id;
          this.dataSource.data.push(result.data);
          this.selectCompany(result.data);
          this.refreshElement(result.data);
          this.table.renderRows();
          this.handlingService.success(`Company with id ${id} successfully added. Password for user ${result.data.adminUsername} is ${(<CompanyAddResult>value).Password}`);
        },error => {
          this.handlingService.error('Error while adding company', error);
        });
      }
    })
  }

  private openEditingDialog(): void {
    const dialogRef = this.dialog.open(EditCompanyDialogComponent, {
      width: '860px',
      data: this.selected,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.configurationsService.updateCompany(result.data).subscribe(value => {
          this.handlingService.success('Company successfully updated');
          // @ts-ignore sry, trust me its there
          result.data.platformName = value.PlatformName;
        },error => {
          this.handlingService.error('Error while updating company', error);
        }, () => {
          this.refreshElement(result.data);
          this.table.renderRows();
        });
      }
    })
  }

  public formatData(data: ConfigCompany, column: string): any {
    return data.format(column);
  }

  public alignHeaderCell(column: string): string {
    return this.dataSource.data[0].align(column);
  }

  private refreshElement(element: ConfigCompany): ConfigCompany | null {
    if(this.selected){
      let company = new ConfigCompany(
        element.id,
        element.companyName,
        element.platformName,
        element.timeOffset,
        element.useDST,
        element.nonBillingPeriod,
        element.platformID,
        element.extData,
        element.customization,
        element.chatGroupID,
        element.active,
        element.billingType,
        element.billingPerc,
        element.eomfx,
        element.useJackpotL1,
        element.isolated,
        element.delUnusedPromo,
        element.delUnusedFreeTickets,
        element.fixedCurrency,
        element.description,
        element.promoWinToBonus,
        element.adminUsername,
        element.adminEmail,
        element.payoutDescription
      )
      let index = this.dataSource.data.indexOf(this.selected);
      this.dataSource.data.splice(index, 1);
      this.dataSource.data.splice(index, 0, company);
      this.dataSource.data = this.dataSource.data;
      return company;
    }
    return null;
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }

}
