<div mat-dialog-content class="profile-header d-flex justify-content-end">
  <button mat-icon-button class="closeBtn" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="round-logo">
  <i class="bi bi-person-circle round-logo-icon"></i>
</div>

<div class="profile-body container d-flex justify-content-center" mat-dialog-content>
  <mat-tab-group [selectedIndex]="tab">
    <mat-tab>
      <div class="container profile-tab">
        <div class="row d-flex justify-content-center p-0 m-0">
          <div class="round-border profile-item mt-4">
            <div class="d-flex align-items-center">
              <div class="profile-item-round-logo d-flex justify-content-center align-items-center ms-2">
                <i class="bi bi-person-badge-fill profile-item-icon"></i>
              </div>
              <div class="col">
                <label class="ms-3 mt-2">{{ 'configurations.user.usernameLabel' | transloco }}</label>
                <p class="card-title ms-3">{{credentials?.username}}</p>
              </div>
            </div>
          </div>
          <div class="round-border profile-item my-4">
            <div class="d-flex align-items-center">
              <div class="profile-item-round-logo d-flex justify-content-center align-items-center ms-2 mt-1">
                <i class="bi bi-building profile-item-icon"></i>
              </div>
              <div class="col">
                <label class="ms-3 mt-2">{{ 'configurations.user.companyLabel' | transloco }}</label>
                <p class="card-title ms-3">{{company?.CompanyName}}</p>
              </div>
            </div>
          </div>
          <div class="round-border profile-item">
            <div class="d-flex align-items-center">
              <div class="profile-item-round-logo d-flex justify-content-center align-items-center ms-2">
                <i class="bi bi-diagram-2-fill profile-item-icon"></i>
              </div>
              <div class="col">
                <label class="ms-3 mt-2">{{ 'configurations.user.roleLabel' | transloco }}</label>
                <p class="card-title ms-3">{{credentials?.role}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center p-0 mx-4 mt-4 pt-2">
          <button class="changePassButton" mat-stroked-button color="primary" (click)="changeTab()">{{ 'profileDialog.changePasswordButton' | transloco }}</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <div class="container profile-tab">
        <div class="row d-flex justify-content-center p-0 m-0 pb-1">
          <form [formGroup]="passwordForm" autocomplete="off">
            <div class="container">
              <div class="row d-flex justify-content-center py-4">
                <p class="component-subtitle">{{ 'profileDialog.changePasswordTitle' | transloco }}</p>
              </div>
              <div class="row pb-1 justify-content-center">
                <mat-form-field appearance="standard">
                  <mat-label>{{ 'configurations.user.passwordLabel' | transloco }}</mat-label>
                  <input matInput [type]="hidePass ? 'password' : 'text'" formControlName="password" style="font-size: 16px">
                  <button mat-icon-button matSuffix (click)="hidePass = !hidePass" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePass">
                    <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-error *ngIf="password!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Password' } }}</mat-error>
                  <mat-error *ngIf="password!.errors?.minlength">{{ 'errorMessages.minlength' | transloco: { name: 'Password' } }}</mat-error>
                </mat-form-field>
              </div>
              <div class="row pb-1 justify-content-center">
                <mat-form-field appearance="standard">
                  <mat-label>{{ 'configurations.user.passwordConfirmation' | transloco }}</mat-label>
                  <input matInput [type]="hidePassRe ? 'password' : 'text'" formControlName="passwordRepeat" style="font-size: 16px">
                  <button mat-icon-button matSuffix (click)="hidePassRe = !hidePassRe" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassRe">
                    <mat-icon>{{hidePassRe ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-error *ngIf="passwordRepeat!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Password confirmation' } }}</mat-error>
                  <mat-error *ngIf="passwordRepeat!.errors?.minlength">{{ 'errorMessages.minlength' | transloco: { name: 'Password confirmation' } }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
        <div class="row d-flex justify-content-end mx-4 mt-4 pt-4">
          <button class="actionBtn" mat-stroked-button color="accent" (click)="changeTab()">{{ 'buttons.cancelButton' | transloco }}</button>
          <button class="actionBtn" mat-raised-button color="primary" (click)="changePassword()" [disabled]="!passwordForm.valid">{{ 'buttons.saveButton' | transloco }}</button>
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>

</div>
