<form [formGroup]="settingsForm" (ngSubmit)="submitForm()">
  <div class="container pb-2">
    <div class="row">
      <div class="flex-container">
        <h2 mat-dialog-title style="font-size: 24px; font-weight: lighter;">{{ 'reports.playersReport.settings.title' | transloco }}</h2>
      </div>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="container" style="overflow-y: hidden">
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline" style="font-size: 16px;">
          <mat-label>{{ 'reports.playersReport.settings.orderBy' | transloco }}</mat-label>
          <mat-select formControlName="orderBy">
            <mat-option [value]="orderBySession">{{ 'reports.playersReport.columns.sessionsCount' | transloco }}</mat-option>
            <mat-option [value]="orderByBets">{{ 'reports.playersReport.columns.bets' | transloco }}</mat-option>
            <mat-option [value]="orderByStake">{{ 'reports.playersReport.columns.stakeEur' | transloco }}</mat-option>
            <mat-option [value]="orderByGgr">{{ 'reports.playersReport.columns.ggr' | transloco }}</mat-option>
          </mat-select>
          <mat-error *ngIf="orderBy!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Order by field' } }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <div class="col">
          <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
            <mat-label>{{ 'reports.playersReport.settings.minTickets' | transloco }}</mat-label>
            <input matInput type="number" min="0" formControlName="minTickets">
            <mat-error *ngIf="minTickets!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Min tickets' } }}</mat-error>
            <mat-error *ngIf="minTickets!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Min tickets' } }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
            <mat-label>{{ 'reports.playersReport.settings.topResults' | transloco }}</mat-label>
            <input matInput type="number" min="1" formControlName="topResults">
            <mat-error *ngIf="topResults!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Top results' } }}</mat-error>
            <mat-error *ngIf="topResults!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Top results' } }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row my-0 justify-content-center">
        <div class="col">
          <mat-radio-group class="radio-group" formControlName="promoCredits">
            <mat-label class="pb-2">{{ 'reports.playersReport.settings.promoCredits.title' | transloco }}</mat-label>
            <mat-radio-button class="radio-button" [value]="promoCreditsNo">{{ 'reports.playersReport.settings.promoCredits.no' | transloco }}</mat-radio-button>
            <mat-radio-button class="radio-button" [value]="promoCreditsDm">{{ 'reports.playersReport.settings.promoCredits.dm' | transloco }}</mat-radio-button>
            <mat-radio-button class="radio-button" [value]="promoCreditsYes">{{ 'reports.playersReport.settings.promoCredits.yes' | transloco }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col">
          <mat-radio-group class="radio-group" formControlName="freeTickets">
            <mat-label class="pb-2">{{ 'reports.playersReport.settings.freeTickets.title' | transloco }}</mat-label>
            <mat-radio-button class="radio-button" [value]="freeTicketsNo">{{ 'reports.playersReport.settings.freeTickets.no' | transloco }}</mat-radio-button>
            <mat-radio-button class="radio-button" [value]="freeTicketsDm">{{ 'reports.playersReport.settings.freeTickets.dm' | transloco }}</mat-radio-button>
            <mat-radio-button class="radio-button" [value]="freeTicketsYes">{{ 'reports.playersReport.settings.freeTickets.yes' | transloco }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-1 mb-2">
    <div mat-dialog-actions class="d-flex flex-row-reverse w-100 mt-2">
      <button class="px-4 ms-2" type="submit" mat-flat-button color="primary" [disabled]="!settingsForm.valid">{{ 'buttons.saveButton' | transloco }}</button>
      <button class="px-3" type="button" mat-stroked-button color="accent" (click)="onNoClick()">{{ 'buttons.cancelButton' | transloco }}</button>
    </div>
  </div>

</form>
