import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CashbackReport} from "../../../../models/reports/cashback/cashback-report.model";
import {CashbackDetailsReport} from "../../../../models/reports/cashback/cashback-details-report.model";
import {ReportsService} from "../../../../services/reports/reports.service";
import {HandlingService} from "../../../../services/global-handling/handling.service";

@Component({
  selector: 'app-cashback-details-report',
  templateUrl: './cashback-details-report.component.html',
  styleUrls: ['./cashback-details-report.component.scss']
})
export class CashbackDetailsReportComponent implements OnChanges {

  @Input('element') element: CashbackReport | null = null;
  @Input('expandedElement') expandedElement: CashbackReport | null = null;
  @Input('companyId') companyId: number | null = null;
  public details: CashbackDetailsReport[] = [];
  public columns: string[] = [];

  constructor(private reportsService: ReportsService,
              private handlingService: HandlingService,) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((this.element && this.expandedElement && this.companyId) && (this.element === this.expandedElement)){
      this.reportsService.getCashbackDetailsReport(this.expandedElement.date, this.expandedElement.currencyId, this.companyId)
        .subscribe(data => {
          this.details = data;
          this.columns = this.details.length > 0 ? this.details[0].columnInfo.map(item => item.key) : [];
        }, error => {
          this.handlingService.error('Error while fetching Cashback details data', error);
        });
    }
  }

  public alignment(column: string): string {
    if (this.details.length < 1){
      return '';
    }
    const columnMetaData = this.details[0].columnInfo.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.align;
  }

  public format(element: CashbackDetailsReport, column: string): any {
    const columnMetaData = element.columnInfo.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.format;
  }

  public translation(column: string): string {
    if (this.details.length < 1){
      return '';
    }
    const columnMetaData = this.details[0].columnInfo.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.column;
  }

}
