import { Component, OnInit, ViewChild} from '@angular/core';
import {ConfigurationsService} from "../../../../services/configurations/configurations.service";
import {ConfigCurrency} from "../../../../models/configurations/company-config/currency/config-currency.model";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {CompanySimple} from "../../../../models/configurations/company-simple.interface";
import {HandlingService} from "../../../../services/global-handling/handling.service";
import {MatDialog} from "@angular/material/dialog";
import {EditCurrenciesDialogComponent} from "./edit-currencies-dialog/edit-currencies-dialog.component";
import {SwapCurrenciesDialogComponent} from "./swap-currencies-dialog/swap-currencies-dialog.component";
import {LoadingService} from "../../../../services/loading/loading.service";
import {CurrencyAction} from "../../../../models/configurations/company-config/currency/currency-action.enum";
import {LocalizationService} from "../../../../services/localization/localization.service";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-currencies-config',
  templateUrl: './currencies-config.component.html',
  styleUrls: ['./currencies-config.component.scss']
})
export class CurrenciesConfigComponent implements OnInit{

  @ViewChild(MatTable) table!: MatTable<ConfigCurrency>;
  public dataSource!: MatTableDataSource<ConfigCurrency>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  public companies: CompanySimple[] = [];
  public selectedCompany: CompanySimple | null = null;

  public showTable = false;
  public disableEdit = true;
  public columns: string[] = [];
  public selectedCurrency: ConfigCurrency | null = null;

  public defaultCompany: CompanySimple = {CompanyID: -1, CompanyName: 'Default values'};

  public games = [
    {text: 'Rocketman', value: 101},
    {text: 'Fast Balls', value: 102},
    {text: 'Gold Mines', value: 103},
    {text: 'Raccoon Rush', value: 104},
  ];

  public currentGame = this.games[0];

  constructor(private configurationsService: ConfigurationsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private localizationService: LocalizationService,
              private dialog: MatDialog) {

    this.fetchCompanies();
  }

  ngOnInit(): void {}

  private fetchCompanies(): void {
    this.loadingService.setLoadingSteps(1);
    this.configurationsService.getSimpleCompanies().subscribe(newData => {
      this.companies = newData;
      if(this.companies.length === 1){
        this.setSelectedCompany(this.companies[0]);
      }
    },error => {
      this.handlingService.error('Error while fetching available companies', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    });
  }

  private fetchCurrencies(id?: number): void {
    this.loadingService.setLoadingSteps(1);
    this.configurationsService.getCompanyCurrencies(this.currentGame.value, id).subscribe(newData => {
      const mapped = this.configurationsService.currencyInputMapper(newData);
      this.dataSource = new MatTableDataSource<ConfigCurrency>(mapped);
      this.dataSource.sort = this.sort;

      if (this.dataSource.data.length === 0){
        this.hideReports();
      }else{
        this.showReports();
      }

    },error => {
      this.handlingService.error('Error while fetching company currencies', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  public setSelectedCompany(company: CompanySimple) {
    this.selectedCompany = company;
    let id = company === null ? -1 : company.CompanyID;
    this.disableEdit = id <= -1;
    this.fetchCurrencies(id);
  }

  public setSelectedGame(game: any) {
    this.currentGame = game;
    if (this.selectedCompany) {
      this.fetchCurrencies(this.selectedCompany.CompanyID)
    }
  }

  public swapCurrencies(): void {
    if(this.selectedCompany && this.selectedCompany.CompanyID !== -1){
      this.openSwappingDialog();
    }else{
      this.handlingService.error('Please select a company');
    }
  }

  public editCurrencies(): void {
    if(this.selectedCurrency){
      if(this.selectedCompany && this.selectedCompany.CompanyID !== -1){
        this.openEditingDialog();
      }else{
        this.handlingService.error("Please make sure company is selected. You can't edit default currencies, only company ones.");
      }
    }else{
      this.handlingService.error('Please make sure currency is selected');
    }
  }

  public selectCurrency(currency: ConfigCurrency): void {
    this.selectedCurrency = currency;
  }

  private openSwappingDialog(): void {
    const dialogRef = this.dialog.open(SwapCurrenciesDialogComponent, {
      width: '1240px',
      data: {
        companyId: this.selectedCompany?.CompanyID,
        gameId: this.currentGame.value,
        currencies: this.dataSource.data
      },
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.data && this.selectedCompany){
        this.table.renderRows();
        this.configurationsService.updateCurrencies(result.data, this.currentGame.value).subscribe(value => {
          const mapped = this.configurationsService.currencyInputMapper(value);
          this.handlingService.success('Currencies successfully updated');
          this.dataSource.data = mapped;
          if (this.dataSource.data.length > 0){
            this.showReports();
          }
          this.dataSource.data = this.dataSource.data;
        },error => {
          this.handlingService.error('Error while updating currencies', error);
        })
      }else if (result.canceled){
        this.dataSource.data = result.canceled;
      }
    })
  }

  private openEditingDialog(): void {
    const dialogRef = this.dialog.open(EditCurrenciesDialogComponent, {
      width: '504px',
      data: {
        currency: this.selectedCurrency,
        gameId: this.currentGame.value
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && this.selectedCompany){
        result.data.action = CurrencyAction.EDIT;
        let jsonData = JSON.stringify([result.data])
        this.configurationsService.updateCurrencies(jsonData, this.currentGame.value).subscribe(() => {
          this.handlingService.success('Currency successfully updated');
        },error => {
          this.handlingService.error('Error while updating currency', error);
        }, () => {
          this.refreshElement(result.data)
          this.table.renderRows();
        })
      }
    })
  }

  private refreshElement(element: ConfigCurrency): void {
    if(this.selectedCurrency){
      let jackpot = new ConfigCurrency(
        element.companyId,
        element.currencyId,
        element.defaultBet,
        element.minBet,
        element.maxBet,
        element.maxWin,
        element.minBetCombination,
        element.predef1,
        element.predef2,
        element.predef3,
        element.predef4,
        element.predef5,
        element.predef6
      )
      let index = this.dataSource.data.indexOf(this.selectedCurrency);
      this.dataSource.data.splice(index, 1);
      this.dataSource.data.splice(index, 0, jackpot);
      this.dataSource.data = this.dataSource.data;
    }
  }

  public translation(column: string): string {
    if (this.dataSource.data.length < 1){
      return '';
    }
    const columnMetaData = this.dataSource.data[0].columnData.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.column;
  }

  public sortHeader(column: string): string {
    if (this.dataSource.data.length < 1){
      return '';
    }
    const columnMetaData = this.dataSource.data[0].columnData.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.field;
  }

  public alignment(column: string): string {
    if (this.dataSource.data.length < 1){
      return '';
    }
    const columnMetaData = this.dataSource.data[0].columnData.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.align;
  }

  public format(element: ConfigCurrency, column: string): any {
    const columnMetaData = element.columnData.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.format;
  }

  private showReports(): void {
    this.columns = this.dataSource.data[0].columnData
      .filter(item => item.show ? item.show(this.currentGame.value) : true)
      .map(item => item.key);
    this.showTable = true;
  }

  private hideReports(): void {
    this.showTable = false;
    this.dataSource = new MatTableDataSource<ConfigCurrency>([]);
  }

}
