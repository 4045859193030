<div class="row d-flex justify-content-between" mat-dialog-title>
  <h2 class="w-50 ps-0">Player info: {{playerInfo?.username}}</h2>
  <button mat-stroked-button color="primary" class="w-auto action-btn" (click)="refreshData()">
    <!--        <mat-icon>refresh</mat-icon>-->
    {{ 'configurations.promoManagement.playerInfo.loadBets' | transloco }}
  </button>
</div>
<mat-dialog-content class="my-dialog">
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col flex-column max-width">
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.username' | transloco}}</label>
            <p class="card-title">{{playerInfo?.username}}</p>
          </div>
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.firstName' | transloco}}</label>
            <p class="card-title">{{playerInfo?.firstName}}</p>
          </div>
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.lastName' | transloco}}</label>
            <p class="card-title">{{playerInfo?.lastName}}</p>
          </div>
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.email' | transloco}}</label>
            <p class="card-title">{{playerInfo?.email}}</p>
          </div>
        </div>
        <div class="col flex-column max-width">
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.company' | transloco}}</label>
            <p class="card-title">{{playerInfo?.company}}</p>
          </div>
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.country' | transloco}}</label>
            <p class="card-title">{{playerInfo?.country}}</p>
          </div>
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.currency' | transloco}}</label>
            <p class="card-title">{{playerInfo?.currencyId}}</p>
          </div>
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.language' | transloco}}</label>
            <p class="card-title">{{playerInfo?.language}}</p>
          </div>
        </div>
        <div class="col flex-column max-width">
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.bets' | transloco}}</label>
            <p class="card-title">{{formatInteger(bets)}}</p>
          </div>
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.totalStake' | transloco}}</label>
            <p class="card-title">{{formatMoney(playerInfo?.currencyId, stakeTotal)}}</p>
          </div>
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.totalWin' | transloco}}</label>
            <p class="card-title">{{formatMoney(playerInfo?.currencyId, winTotal)}}</p>
          </div>
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.ggr' | transloco}}</label>
            <p class="card-title">{{formatMoney(playerInfo?.currencyId, ggr)}}</p>
          </div>
        </div>
        <div class="col flex-column max-width">
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.dateFrom' | transloco}}</label>
            <p class="card-title">{{data.dateFrom}}</p>
          </div>
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.dateTo' | transloco}}</label>
            <p class="card-title">{{data.dateTo}}</p>
          </div>
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.freeBets' | transloco}}</label>
            <p class="card-title">{{formatInteger(freeBets)}}</p>
          </div>
          <div>
            <label class="mt-1">{{'configurations.promoManagement.playerInfo.walletBalance' | transloco}}</label>
            <p class="card-title">{{formatMoney(playerInfo?.currencyId, playerInfo?.balance)}}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="w-96 round-border mt-3 ml-1">
          <p class="dashboard-title">Player session balance</p>
          <div class="dashboard5" *ngIf="showChart | async">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [xaxis]="chartOptions.xaxis"
              [stroke]="chartOptions.stroke"
              [dataLabels]="chartOptions.dataLabels"
              [yaxis]="chartOptions.yaxis"
              [labels]="chartOptions.labels"
              [legend]="chartOptions.legend"
              [title]="chartOptions.title"
              [subtitle]="chartOptions.subtitle"
              [responsive]="chartOptions.responsive"
              [tooltip]="chartOptions.tooltip"
              [colors]="chartOptions.colors"
            ></apx-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="max-height-table-profile-info">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8 mt-0" matSort [hidden]="!showTable">
          <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
            <th mat-header-cell
                class="header-cell"
                *matHeaderCellDef
                mat-sort-header="{{headerCell(column)}}"
                arrowPosition="after"
                [ngStyle]="{'text-align': alignHeaderCell(column)}"
                [class.header-end]="alignHeaderCell(column) === 'end'"
                [class.header-start]="alignHeaderCell(column) === 'normal'">
              {{ translationColumns[i] | transloco }}
            </th>
            <td mat-cell *matCellDef="let element"
                [class.special-ticket]="specialTicket(element)"
                [ngStyle]="{
                  'text-align': element.align(column),
                  'background-color': cellBackground(element, column)
                  }">
              {{element.format(column)}}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
          <tr mat-row *matRowDef="let element; let index = dataIndex; columns: columns;" [ngStyle]="{'background-color': rowBackground(element)}">
          </tr>
        </table>
      </div>
    </div>
  </div>
</mat-dialog-content>
