export class Chart3Data {

  stakePreviewMonth: number
  stakePrediction: number
  previewStartDate: string
  previewEndDate: string
  startDate: string
  endDate: string

  constructor(stakePreviewMonth: number,
              stakePrediction: number,
              previewStartDate: string,
              previewEndDate: string,
              startDate: string,
              endDate: string) {
    this.stakePreviewMonth = stakePreviewMonth;
    this.stakePrediction = stakePrediction;
    this.previewStartDate = previewStartDate;
    this.previewEndDate = previewEndDate;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
