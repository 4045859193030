import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {RocketmanRoundReportDetails} from "../../../../../models/reports/round/rocketman-round-report-details.model";
import {ReportsService} from "../../../../../services/reports/reports.service";
import {HandlingService} from "../../../../../services/global-handling/handling.service";
import {
  UnmappedRocketmanRoundReportDetails
} from "../../../../../models/reports/round/unmapped/unmapped-rocketman-round-report-details.interface";
import {TicketStatuses} from "../../../../../models/utils/ticket-statuses.enum";
import {TicketTypes} from "../../../../../models/utils/ticket-types.enum";
import {Games} from "../../../../../models/utils/games.enum";

@Component({
  selector: 'app-round-report-details',
  templateUrl: './game-report-details.component.html',
  styleUrls: ['./game-report-details.component.scss']
})
export class GameReportDetailsComponent implements OnInit, OnChanges {

  @Input('elementID') elementID?: number | null;
  @Input('expandedElementID') expandedElementID?: number | null;

  public gameReportDetails: RocketmanRoundReportDetails[] = [];
  public translationColumns: string[] = [];
  public columns: string[] = []

  constructor(private reportsService: ReportsService,
              private errorHandlingService: HandlingService) {
    this.translationColumns = reportsService.getRocketmanRoundReportDetailsTranslationColumns();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.elementID === this.expandedElementID){
      if(this.elementID){
        this.reportsService.getRoundDetailsReport(this.elementID, Games.ROCKET_MAN).subscribe(newData => {
          this.gameReportDetails = this.mapData(newData);
          if(this.gameReportDetails[0]) {
            this.columns = this.gameReportDetails[0].getFields();
          }
        },error => {
          this.errorHandlingService.error('Error while fetching round details report data', error);
        });
      }
    }
  }

  private mapData(unmapped: UnmappedRocketmanRoundReportDetails[]): RocketmanRoundReportDetails[] {
    let data: RocketmanRoundReportDetails[] = [];
    unmapped.forEach(item => {
      const ticketStatus = item.TicketStatus + ' - ' + this.getTicketStatusByValue(item.TicketStatus);
      const ticketType = item.TicketType + ' - ' + this.getTicketTypeByValue(item.TicketType);
      data.push(
        new RocketmanRoundReportDetails(
          ticketStatus,
          ticketType,
          item.Bet,
          item.CurrencyID,
          item.Multiplier,
          item.Win,
          item.JackPot,
          item.Paid));
    })
    return data;
  }

  public alignHeaderCell(column: string): string {
    return this.gameReportDetails[0].align(column);
  }

  private getTicketStatusByValue(value: number) {
    const index = Object.values(TicketStatuses).indexOf(value as unknown as TicketStatuses);
    return Object.keys(TicketStatuses)[index];
  }

  private getTicketTypeByValue(value: number) {
    const index = Object.values(TicketTypes).indexOf(value as unknown as TicketTypes);
    return Object.keys(TicketTypes)[index];
  }

}
