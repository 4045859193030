import { Component, OnInit } from '@angular/core';
import {LoadingService} from "../../services/loading/loading.service";
import {MobileService} from "../../services/mobile/mobile.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public mobileDevice: boolean = false;

  constructor(public loadingService: LoadingService, private mobileService: MobileService) {
    let loadingSteps = 3;

    this.mobileDevice = mobileService.getMobileDevice();
    if (!this.mobileDevice){
      loadingSteps += 1;
    }
    this.loadingService.setLoadingSteps(loadingSteps);
  }

  ngOnInit(): void {}

}
