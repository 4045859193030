import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ChatRoomMessage} from "../../../models/chat-rooms/chat-room-message.interface";
import {ChatRoomsService} from "../../../services/chat-rooms/chat-rooms.service";
import {ChatRoomUserBanOption} from "../../../models/chat-rooms/chat-room-user-ban-option.interface";
import {ChatRoomProfaneMessage} from "../../../models/chat-rooms/chat-room-profane-message.model";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {MatDialog} from "@angular/material/dialog";
import {CustomDialogComponent} from "../../dialogs/custom-dialog/custom-dialog.component";
import {UtilsService} from "../../../services/utils/utils.service";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-chat-room-user-panel',
  templateUrl: './chat-room-user-panel.component.html',
  styleUrls: ['./chat-room-user-panel.component.scss']
})
export class ChatRoomUserPanelComponent implements OnInit, OnChanges {

  @Input("message") message: ChatRoomMessage | null = null;
  public banOptions: ChatRoomUserBanOption[] = [
    {text: '1 day', value: 1},
    {text: '2 days', value: 2},
    {text: '5 days', value: 5},
    {text: 'Indefinitely', value: 0}];
  public selectedBanOption: ChatRoomUserBanOption = this.banOptions[0];
  public messageHistoryDate: string;
  public messageHistory: ChatRoomProfaneMessage[] = [];
  public selectedMessage: ChatRoomProfaneMessage | null = null;
  public showPanel: boolean = false;

  constructor(private chatRoomService: ChatRoomsService,
              private utilsService: UtilsService,
              private handlingService: HandlingService,
              private dialog: MatDialog,
              private translocoService: TranslocoService) {
    this.messageHistoryDate = this.utilsService.getFirstDateInMonth();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.message){
      // Change for UserID instead of username
      this.fetchData(this.message.messageId, this.messageHistoryDate);

      setTimeout(() => {
        this.showPanel = true;
      }, 10);
    }
  }

  public selectBanOption(option: ChatRoomUserBanOption): void {
    this.selectedBanOption = option;
  }

  public changeDate(date: any): void {
    this.messageHistoryDate = new Date(date).toLocaleDateString("sv-SE");
    this.fetchData(this.message!.messageId, this.messageHistoryDate);
  }

  private fetchData(messageId: string, date: string): void{
    this.chatRoomService.getMessageHistoryAlt(messageId, date).subscribe(value => {
      this.messageHistory = this.chatRoomService.mapMessageHistory(value);
    },error => {
      this.handlingService.error("Error fetching user message history", error);
    });
  }

  public changeInput(input: HTMLInputElement): void {
    this.messageHistoryDate = this.utilsService.getFirstDateInMonth();
  }

  public messageSelected(message: ChatRoomMessage | ChatRoomProfaneMessage): void {
    if (message instanceof ChatRoomProfaneMessage){
      this.selectedMessage = message;
    }
  }

  public banUser(): void {
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      width: '350px',
      data: {
        title: `Ban user ${this.message?.username}`,
        icon: 'remove_moderator',
        message: `Are you sure you want to ban user ${this.message?.username} for ${this.selectedBanOption.text}?`,
        action: 'Ban'
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.result){
        this.chatRoomService.banUserAlt(this.message!.messageId, this.selectedBanOption.value).subscribe(value => {

        },error => {
          this.handlingService.error(`Error while banning user ${this.message?.username}`, error);
        }, () =>{
          const currentChatRoom = localStorage.getItem("selectedChatRoom") as string | null;
          const message =
            `${this.message?.username} ${this.translocoService.translate('chatRooms.banningOptions.banned')} ${this.selectedBanOption.value !== 0 ? this.translocoService.translate('chatRooms.banningOptions.for') : ""}
            ${this.selectedBanOption.value === 0 ? this.translocoService.translate('chatRooms.banningOptions.indefinitely').toLowerCase() : this.selectedBanOption.text.toLowerCase()}`;

          this.chatRoomService.sendNewMessage(message, 0, 2, currentChatRoom as string)
        });
      }
    });
  }

}
