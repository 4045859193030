import {ReportColumns} from "../report-columns.interface";

export class PromoReport {
  promoDate: string
  promoCount: number
  promoCredits: number
  promoPlayers: number
  freeTicketsCount: number
  freeTicketsMoney: number
  freePlayers: number
  jackpotCount: number
  jackpotMoney: number
  rocketpotCount: number
  rocketpotMoney: number
  boosterpotCount: number
  boosterpotMoney: number
  tournamentCount: number
  tournamentMoney: number

  columnInfo: ReportColumns[]

  constructor(promoDate: string, promoCount: number, promoCredits: number, promoPlayers: number, freeTicketsCount: number, freeTicketsMoney: number, freePlayers: number, jackpotCount: number, jackpotMoney: number, rocketpotCount: number, rocketpotMoney: number, boosterpotCount: number, boosterpotMoney: number, tournamentCount: number, tournamentMoney: number) {
    this.promoDate = promoDate;
    this.promoCount = promoCount;
    this.promoCredits = promoCredits;
    this.promoPlayers = promoPlayers;
    this.freeTicketsCount = freeTicketsCount;
    this.freeTicketsMoney = freeTicketsMoney;
    this.freePlayers = freePlayers;
    this.jackpotCount = jackpotCount;
    this.jackpotMoney = jackpotMoney;
    this.rocketpotCount = rocketpotCount;
    this.rocketpotMoney = rocketpotMoney;
    this.boosterpotCount = boosterpotCount;
    this.boosterpotMoney = boosterpotMoney;
    this.tournamentCount = tournamentCount;
    this.tournamentMoney = tournamentMoney;

    this.columnInfo = [
      {key: 'Date', info: {field: 'promoDate', total: this.promoDate, format: this.formatString(this.promoDate), align: 'normal'}},
      {key: 'Players', info: {field: 'promoPlayers', total: this.promoPlayers, format: this.formatInteger(this.promoPlayers), align: 'end'}},
      {key: 'Count', info: {field: 'promoCount', total: this.promoCount, format: this.formatInteger(this.promoCount), align: 'end'}},
      {key: 'Credits', info: {field: 'promoCredits', total: this.promoCredits, format: this.formatMoney(this.promoCredits), align: 'end'}},
      {key: 'Free players', info: {field: 'freePlayers', total: this.freePlayers, format: this.formatInteger(this.freePlayers), align: 'end'}},
      {key: 'Free ticket count', info: {field: 'freeTicketsCount', total: this.freeTicketsCount, format: this.formatInteger(this.freeTicketsCount), align: 'end'}},
      {key: 'Free ticket money', info: {field: 'freeTicketsMoney', total: this.freeTicketsMoney, format: this.formatMoney(this.freeTicketsMoney), align: 'end'}},
      {key: 'Jackpot count', info: {field: 'jackpotCount', total: this.jackpotCount, format: this.formatInteger(this.jackpotCount), align: 'end'}},
      {key: 'Jackpot money', info: {field: 'jackpotMoney', total: this.jackpotMoney, format: this.formatMoney(this.jackpotMoney), align: 'end'}},
      {key: 'Rocketpot count', info: {field: 'rocketpotCount', total: this.rocketpotCount, format: this.formatInteger(this.rocketpotCount), align: 'end'}},
      {key: 'Rocketpot money', info: {field: 'rocketpotMoney', total: this.rocketpotMoney, format: this.formatMoney(this.rocketpotMoney), align: 'end'}},
      {key: 'Boosterpot count', info: {field: 'boosterpotCount', total: this.boosterpotCount, format: this.formatInteger(this.boosterpotCount), align: 'end'}},
      {key: 'Boosterpot money', info: {field: 'boosterpotMoney', total: this.boosterpotMoney, format: this.formatMoney(this.boosterpotMoney), align: 'end'}},
      {key: 'Tournament count', info: {field: 'tournamentCount', total: this.tournamentCount, format: this.formatInteger(this.tournamentCount), align: 'end'}},
      {key: 'Tournament money', info: {field: 'tournamentMoney', total: this.tournamentMoney, format: this.formatMoney(this.tournamentMoney), align: 'end'}},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public total(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.total;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatString(string: string): string {
    return string;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }
}
