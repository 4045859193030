<div class="row d-flex justify-content-between">
  <div class="col d-flex align-items-center pb-3">
    <p class="place-number">{{award?.place}}</p>
    <p class="place-suffix">{{placeSuffix}}</p>
    <p class="place-string">place</p>
  </div>
  <div class="col">
    <mat-form-field appearance="outline">
      <mat-label>Amount</mat-label>
      <input matInput type="number" class="" placeholder="0" #input [value]="award?.award?.toString()">
      <span matSuffix>{{currency}}</span>
    </mat-form-field>
  </div>
</div>
