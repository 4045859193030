import {CurrencyAction} from "./currency-action.enum";
import {ReportColumns} from "../../../reports/report-columns.interface";
import {ColumnMetaData} from "../../../utils/column-meta-data.interface";

export class ConfigCurrency {
  action: CurrencyAction
  companyId: number
  currencyId: string
  minBet: number
  minBetCombination: number
  defaultBet: number
  maxBet: number
  maxWin: number
  predef1: string
  predef2: string
  predef3: string
  predef4: string
  predef5: string
  predef6: string

  columnInfo: ReportColumns[]
  columnData: ColumnMetaData[]

  constructor(companyId: number, currencyId: string, defaultBet: number, minBet: number, maxBet: number, maxWin: number, minBetCombination: number, predef1: string, predef2: string, predef3: string, predef4: string, predef5: string, predef6: string, action?: CurrencyAction) {
    this.action = CurrencyAction.DEFAULT;
    this.companyId = companyId;
    this.currencyId = currencyId;
    this.minBet = minBet;
    this.minBetCombination = minBetCombination;
    this.defaultBet = defaultBet;
    this.maxBet = maxBet;
    this.maxWin = maxWin;
    this.predef1 = predef1;
    this.predef2 = predef2;
    this.predef3 = predef3;
    this.predef4 = predef4;
    this.predef5 = predef5;
    this.predef6 = predef6;

    if (action){
      this.action = action;
    }

    this.columnInfo = [
      {key: 'Currency', info: {field: 'currencyId', total: 0, format: this.currencyId, align: 'normal'}},
      {key: 'Min bet', info: {field: 'minBet', total: 0, format: this.formatMoney(this.minBet), align: 'end'}},
      {key: 'Default bet', info: {field: 'defaultBet', total: 0, format: this.formatMoney(this.defaultBet), align: 'end'}},
      {key: 'Max bet', info: {field: 'maxBet', total: 0, format: this.formatMoney(this.maxBet), align: 'end'}},
      {key: 'Max win', info: {field: 'maxWin', total: 0, format: this.formatMoney(this.maxWin), align: 'end'}},
      {key: 'Pre-set 1', info: {field: 'predef1', total: 0, format: this.formatString(this.predef1), align: 'end'}},
      {key: 'Pre-set 2', info: {field: 'predef2', total: 0, format: this.formatString(this.predef2), align: 'end'}},
      {key: 'Pre-set 3', info: {field: 'predef3', total: 0, format: this.formatString(this.predef3), align: 'end'}},
      {key: 'Pre-set 4', info: {field: 'predef4', total: 0, format: this.formatString(this.predef4), align: 'end'}},
      {key: 'Pre-set 5', info: {field: 'predef5', total: 0, format: this.formatString(this.predef5), align: 'end'}},
      {key: 'Pre-set 6', info: {field: 'predef6', total: 0, format: this.formatString(this.predef6), align: 'end'}},
    ]

    this.columnData = [
      {key: 'currency', column: 'configurations.company.currencies.tableColumns.currency', field: 'currencyId', total: 0, format: this.currencyId, align: 'normal'},
      {key: 'min-bet', column: 'configurations.company.currencies.tableColumns.minBet', field: 'minBet', total: 0, format: this.formatMoney(this.minBet), align: 'end'},
      {key: 'default-bet', column: 'configurations.company.currencies.tableColumns.defaultBet', field: 'defaultBet', total: 0, format: this.formatMoney(this.defaultBet), align: 'end'},
      {key: 'min-bet-combination', column: 'configurations.company.currencies.tableColumns.minBetCombination', field: 'minBetCombination', total: 0, format: this.formatMoney(this.minBetCombination), align: 'end', show: this.showMinBetCombination},
      {key: 'max-bet', column: 'configurations.company.currencies.tableColumns.maxBet', field: 'maxBet', total: 0, format: this.formatMoney(this.maxBet), align: 'end'},
      {key: 'max-win', column: 'configurations.company.currencies.tableColumns.maxWin', field: 'maxWin', total: 0, format: this.formatMoney(this.maxWin), align: 'end'},
      {key: 'pre-set-1', column: 'configurations.company.currencies.tableColumns.preset1', field: 'predef1', total: 0, format: this.formatString(this.predef1), align: 'end'},
      {key: 'pre-set-2', column: 'configurations.company.currencies.tableColumns.preset2', field: 'predef2', total: 0, format: this.formatString(this.predef2), align: 'end'},
      {key: 'pre-set-3', column: 'configurations.company.currencies.tableColumns.preset3', field: 'predef3', total: 0, format: this.formatString(this.predef3), align: 'end'},
      {key: 'pre-set-4', column: 'configurations.company.currencies.tableColumns.preset4', field: 'predef4', total: 0, format: this.formatString(this.predef4), align: 'end'},
      {key: 'pre-set-5', column: 'configurations.company.currencies.tableColumns.preset5', field: 'predef5', total: 0, format: this.formatString(this.predef5), align: 'end', show: this.showPredefField},
      {key: 'pre-set-6', column: 'configurations.company.currencies.tableColumns.preset6', field: 'predef6', total: 0, format: this.formatString(this.predef6), align: 'end', show: this.showPredefField},
    ]
  }

  public get(key: string): any {
    if (key === 'Currency'){
      return this.currencyId;
    }else if (key === 'Min bet'){
      return this.minBet;
    }else if (key === 'Default bet'){
      return this.defaultBet;
    }else if (key === 'Max bet'){
      return this.maxBet;
    }else if (key === 'Max win'){
      return this.maxWin;
    }else if (key === 'Pre-set 1'){
      return this.predef1;
    }else if (key === 'Pre-set 2'){
      return this.predef2;
    }else if (key === 'Pre-set 3'){
      return this.predef3;
    }else if (key === 'Pre-set 4'){
      return this.predef4;
    }else if (key === 'Pre-set 5'){
      return this.predef5;
    }else if (key === 'Pre-set 6'){
      return this.predef6;
    }
    return null;
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): any {
    return money.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 4 })
  }

  public formatString(value: string): any {
    let number = +value;
    return this.formatMoney(number);
  }

  public showMinBetCombination(gameId: number): boolean {
    return gameId === 102;
  }

  public showPredefField(gameId: number): boolean {
    return gameId === 101;
  }
}
