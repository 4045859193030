import {Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges} from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from "@angular/material-moment-adapter";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import * as moment from 'moment';
import {Moment} from "moment";
import {MatFormFieldAppearance} from "@angular/material/form-field";

export const MY_FORMATS = {
  parse: {
    dateInput: ['YYYY-MM-DD'],
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ],
})
export class DatePickerComponent implements OnInit, OnChanges {

  public default: any | null = null;
  @Input('init') init: string | null = null;
  @Input('placeholder') placeholder: string = '';
  @Output() date = new EventEmitter<string | null>();
  @Input('appearance') appearance: MatFormFieldAppearance = 'standard';
  @Input('max-width') maxWidth: number = 150;

  constructor(private _adapter: DateAdapter<any>) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.init){
      this.default = moment(this.init);
    }
  }

  public changeDate(date: any): void {
    if (date === null) {
      return this.date.emit(null);
    }
    let selected = date.format("YYYY-MM-DD");
    if (selected !== 'Invalid date'){
      this.date.emit(selected)
    }else{
      this.date.emit(null);
    }
  }

}
