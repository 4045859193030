import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChatRoom} from "../../../models/chat-rooms/chat-room.interface";

@Component({
  selector: 'app-chat-room-item',
  templateUrl: './chat-room-item.component.html',
  styleUrls: ['./chat-room-item.component.scss']
})
export class ChatRoomItemComponent implements OnInit {

  @Input("item") item!: ChatRoom
  @Output() room = new EventEmitter<ChatRoom | null>();

  public matRippleCentered: boolean = true;
  public matRippleUnbounded: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  public itemClicked(room: ChatRoom): void {
    this.room.emit(room);
    localStorage.setItem("selectedChatRoom",room.name)
  }
}
