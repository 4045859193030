export class ChatRoomProfaneMessage {
  userId: number
  username: string
  messageId: number
  message: string
  profane: boolean

  constructor(userId: number, username: string, messageId: number, message: string, profane: boolean) {
    this.userId = userId;
    this.username = username;
    this.messageId = messageId;
    this.message = message;
    this.profane = profane;
  }
}
