<h2 class="component-title">{{ 'reports.roundReport.games.raccoonrush.title' | transloco }}</h2>
<hr>

<div class="frame round-border">
    <div class="row d-flex align-items-center">
        <div class="ps-3 pe-0 w-auto">
            <app-date-picker [placeholder]="'Date'" (date)="setDate($event)" [init]="date"></app-date-picker>
        </div>
<!--        <div class="ps-3 pe-0 w-auto mb-1">-->
<!--            <mat-checkbox class="checkbox" color="primary" style="font-size: 16px" [(ngModel)]="withTickets">{{ 'buttons.withTicketsButton' | transloco }}</mat-checkbox>-->
<!--        </div>-->
        <div class="ps-3 pe-0 w-auto mb-1">
            <button mat-stroked-button color="primary" (click)="refreshData()">
                <!--        <mat-icon>refresh</mat-icon>-->
                {{ 'buttons.refreshDataButton' | transloco }}
            </button>
        </div>
        <div class="ps-3 pe-3 w-auto mb-1">
            <button mat-stroked-button color="accent" (click)="downloadExcel()">
                <mat-icon>file_download</mat-icon>
                {{ 'buttons.excelButton' | transloco }}
            </button>
        </div>
    </div>

    <div class="mat-elevation-z8 max-height-table" [hidden]="!showTable">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mt-0" matSort>

            <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
                <th mat-header-cell
                    class="header-cell"
                    *matHeaderCellDef
                    mat-sort-header="{{headerCell(column)}}"
                    arrowPosition="after"
                    [ngStyle]="{'text-align': alignHeaderCell(column)}"
                    [class.header-end]="alignHeaderCell(column) === 'end'"
                    [class.header-start]="alignHeaderCell(column) === 'normal'">
                    {{ translationColumns[i] | transloco }}
                </th>
                <td mat-cell class="table-overflow-text" *matCellDef="let element" [ngStyle]="{'text-align': element?.align(column)}"> {{element?.format(column)}} </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell class="expanded-row" *matCellDef="let element" [attr.colspan]="columns.length">
                    <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <app-raccoonrush-round-report-details [elementID]="element?.id" [expandedElementID]="expandedElement?.id"></app-raccoonrush-round-report-details>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; let index = dataIndex; columns: columns;"
                class="element-row"
                [class.clicked-row]="expandedElement === element"
                [ngClass]="{alternating: index % 2 === 0}"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </table>
        <mat-paginator class="paginator"
                       [pageSizeOptions]="[10, 50, 100, 200, 300]"
                       showFirstLastButtons
                       (page)="pageChanged($event)">
        </mat-paginator>
    </div>

</div>





