import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {IndecentUser} from "../../../models/chat-rooms/indecent-user.model";
import {LoadingService} from "../../../services/loading/loading.service";
import {LocalizationService} from "../../../services/localization/localization.service";
import {MatDialog} from "@angular/material/dialog";
import {ChatRoomsService} from "../../../services/chat-rooms/chat-rooms.service";
import {UnmappedIndecentUser} from "../../../models/chat-rooms/unmapped/unmapped-indecent-user.interface";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {UserHistoryDialogComponent} from "../user-history-dialog/user-history-dialog.component";
import * as moment from "moment";
import {ConfigUser} from "../../../models/configurations/user-config/config-user.model";
import {UtilsService} from "../../../services/utils/utils.service";

@Component({
  selector: 'app-indecent-users-report',
  templateUrl: './indecent-users-report.component.html',
  styleUrls: ['./indecent-users-report.component.scss']
})
export class IndecentUsersReportComponent implements OnInit {

  public dataSource!: MatTableDataSource<IndecentUser>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<ConfigUser>;

  public showTable = false;
  public columns: string[] = [];
  public translationColumns: string[] = [];
  public selected: IndecentUser | null = null;
  public date: string | null = null;

  constructor(private chatRoomService: ChatRoomsService,
              private utilsService: UtilsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private localizationService: LocalizationService,
              private dialog: MatDialog) {
    this.date = utilsService.getLastThirtyDays();
    this.translationColumns = localizationService.getIndecentUsersTableTranslations();

    this.fetchReports(this.date);
  }

  ngOnInit(): void {
  }

  private fetchReports(date: string): void {
    this.loadingService.setLoadingSteps(1);
    this.chatRoomService.getIndecentUsers(date).subscribe(value => {
      this.dataSource = new MatTableDataSource(this.mapData(value));
      this.dataSource.sort = this.sort;

      if (this.dataSource.data.length === 0){
        this.hideReports();
      }else{
        this.showReports();
      }
    }, error => {
      this.handlingService.error('Error while fetching indecent users', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  private mapData(users: UnmappedIndecentUser[]): IndecentUser[] {
    let result: IndecentUser[] = [];
    users.forEach(unmapped => {
      const date = unmapped.BanUntilTime ? this.utilsService.convertToUtc(unmapped.BanUntilTime, "YYYY-MM-DD") : '';
      result.push(
        new IndecentUser(
          unmapped.UserID,
          unmapped.UserName,
          unmapped.ChatBan,
          date,
          unmapped.CompanyName,
          unmapped.ProfaneCount,
          unmapped.TotalMessage,
          unmapped.ProfanePerce)
      )
    })
    return result;
  }

  public checkUserMessageHistory(): void {
    if (this.selected){
      const dialogRef = this.dialog.open(UserHistoryDialogComponent, {
        width: '600px',
        data: this.selected,
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.banned && this.selected){
          this.refreshElement(this.selected, result.banned, result.days)
        }
      });
    }else{
      this.handlingService.error('Please select a user');
    }
  }

  public selectUser(user: IndecentUser): void {
    this.selected = user;
  }

  public refreshData(): void {
    if (this.date){
      this.selected = null;
      this.fetchReports(this.date);
    }else{
      this.handlingService.error('Please select a date');
    }
  }

  private showReports(): void {
    if (this.columns.length === 0){
      this.columns = this.dataSource.data[0].getFields();
    }
    this.showTable = true;
  }

  private hideReports(): void {
    this.showTable = false;
    this.dataSource = new MatTableDataSource<IndecentUser>([]);
  }

  public alignHeaderCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].align(column);
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }

  private refreshElement(element: IndecentUser, banned: boolean, days: number): void {
    if(this.selected){
      let user = new IndecentUser(
        element.id,
        element.username,
        banned,
        moment().add(days, 'days').format('YYYY-MM-DD'),
        element.company,
        element.profanitiesCount,
        element.messageCount,
        element.profanitiesPercent
      )
      let index = this.dataSource.data.indexOf(this.selected);
      this.dataSource.data.splice(index, 1);
      this.dataSource.data.splice(index, 0, user);
      this.dataSource._updateChangeSubscription();
    }
  }


}
