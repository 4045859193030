<div class="page-wrap d-flex flex-row align-items-center" style="min-height: 100vh;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12 text-center">
        <span class="display-1 d-block">404</span>
        <div class="mb-4 lead">{{ '404page.title' | transloco }}</div>
        <a [routerLink]="['/home']" class="btn btn-link">{{ '404page.backButton' | transloco }}</a>
      </div>
    </div>
  </div>
</div>
