import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Currency} from "../../../models/reports/exchange-rates/currency.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {UtilsService} from "../../../services/utils/utils.service";
import {ReportsService} from "../../../services/reports/reports.service";
import {Tournament} from "../../../models/tournaments/tournament.model";
import * as moment from "moment";
import {TournamentsService} from "../../../services/tournaments/tournaments.service";

@Component({
  selector: 'app-edit-tournament',
  templateUrl: './edit-tournament.component.html',
  styleUrls: ['./edit-tournament.component.scss']
})
export class EditTournamentComponent implements OnInit {

  public editingForm: FormGroup;
  public currencies: Currency[] = [];

  public currency: Currency | null = null;
  public displayCurrency: string = 'Currency'

  public tournamentTypes: string[] = [
    '1 - The sum of winning multipliers',
    '2 - The sum of winning multipliers minus the losing ones',
    '3 - Max multiplier',
    '4 - The sum of bet points according to the minimum bet',
    '5 - Max Multiplier per round'
  ];

  public hoursBefore: any[] = [
    {text: 'Immediately', value: 0},
    {text: '1 hour', value: 1},
    {text: '2 hours', value: 2},
    {text: '3 hours', value: 3},
    {text: '4 hours', value: 4},
    {text: '5 hours', value: 5},
    {text: '6 hours', value: 6},
    {text: '7 hours', value: 7},
    {text: '8 hours', value: 8},
    {text: '9 hours', value: 9},
    {text: '10 hours', value: 10},
    {text: '11 hours', value: 11},
    {text: '12 hours', value: 12},
    {text: '13 hours', value: 13},
    {text: '14 hours', value: 14},
    {text: '15 hours', value: 15},
    {text: '16 hours', value: 16},
    {text: '17 hours', value: 17},
    {text: '18 hours', value: 18},
    {text: '19 hours', value: 19},
    {text: '20 hours', value: 20},
    {text: '21 hours', value: 21},
    {text: '22 hours', value: 22},
    {text: '23 hours', value: 23},
    {text: '24 hours', value: 24},
  ]

  constructor(private dialogRef: MatDialogRef<EditTournamentComponent>,
              private formBuilder: FormBuilder,
              private handlingService: HandlingService,
              private tournamentsService: TournamentsService,
              private utilsService: UtilsService,
              private reportsService: ReportsService,
              @Inject(MAT_DIALOG_DATA) public tournament: Tournament) {

    this.editingForm = this.formBuilder.group({
      tournamentName: [this.tournament.tournamentName, [Validators.required, Validators.minLength(3)]],
      currency: [null, Validators.required],
      fromDate: [this.initDateTime(this.tournament.fromDateTime, DateOrTime.DATE), Validators.required],
      fromTime: [this.initDateTime(this.tournament.fromDateTime, DateOrTime.TIME), Validators.required],
      toDate: [this.initDateTime(this.tournament.toDateTime, DateOrTime.DATE), Validators.required],
      toTime: [this.initDateTime(this.tournament.toDateTime, DateOrTime.TIME), Validators.required],
      tournamentType: [this.tournament.tournamentType, Validators.required],
      minBet: [this.tournament.minBet, [Validators.required, Validators.min(0)]],
      tournamentDescription: [this.tournament.tournamentDescription],
      showBefore: [this.tournament.showBefore, [Validators.required, Validators.min(0), Validators.max(24)]]
    });

    this.reportsService.getCurrencies().subscribe(
      currencies => {
        this.currencies = currencies;
        if (this.currencies.length === 0) {
          return this.handlingService.error('No currencies found');
        }
        this.setCurrency(this.findCurrency(this.tournament.currencyId, this.currencies));
      },
      error => {
        handlingService.error('Error fetching available currencies', error);
      }
    )
  }

  public submitForm() {
    if (this.editingForm.invalid) {
      return;
    }

    let startDate = this.editingForm.get('fromDate')?.value;
    let endDate = this.editingForm.get('toDate')?.value;
    if (startDate > endDate) {
      [startDate, endDate] = [endDate, startDate];
      this.editingForm.get('fromDate')?.setValue(startDate);
      this.editingForm.get('toDate')?.setValue(endDate);
    }

    const startDateTime = this.mapDateTime(this.editingForm.get('fromDate')?.value, this.editingForm.get('fromTime')?.value);
    const endDateTime = this.mapDateTime(this.editingForm.get('toDate')?.value, this.editingForm.get('toTime')?.value);

    this.tournament.tournamentName = this.editingForm.get('tournamentName')?.value;
    this.tournament.currencyId = this.editingForm.get('currency')?.value.CurrencyID;
    this.tournament.fromDateTime = startDateTime;
    this.tournament.toDateTime = endDateTime;
    this.tournament.tournamentType = this.editingForm.get('tournamentType')?.value;
    this.tournament.minBet = this.editingForm.get('minBet')?.value;
    this.tournament.tournamentDescription = this.editingForm.get('tournamentDescription')?.value;
    this.tournament.showBefore = this.editingForm.get('showBefore')?.value;

    this.dialogRef.close({data: this.tournament});
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  public setCurrency(currency: any){
    this.displayCurrency = currency === null ? 'Currency' : currency.CurrencyID;
    this.currency = currency;
    this.editingForm.get('currency')?.setValue(this.currency);
  }

  private mapDateTime(date: string, time: string): string {
    return this.utilsService.mapDateTime(date, time);
  }

  public setStartDate(date: string | null): void {
    this.editingForm.get('fromDate')?.setValue(date);
  }
  public setEndDate(date: string | null): void {
    this.editingForm.get('toDate')?.setValue(date);
  }
  public setStartTime(time: string | null): void {
    this.editingForm.get('fromTime')?.setValue(time);
  }
  public setEndTime(time: string | null): void {
    this.editingForm.get('toTime')?.setValue(time);
  }

  private findCurrency(currencyId: string, currencies: Currency[]): Currency | null {
    const currency = currencies.find(item => item.CurrencyID === currencyId);
    return currency ? currency : null;
  }

  private initDateTime(dateTime: string, picker: DateOrTime) {
    const dateTimeSplit = dateTime.split(" ");
    return picker === DateOrTime.DATE ? dateTimeSplit[0] : dateTimeSplit[1];
  }

}

enum DateOrTime {
  'DATE',
  'TIME'
}
