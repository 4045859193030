<h2 class="component-title">{{ 'chatRooms.indecentUsersTitle' | transloco }}</h2>
<hr>
<div class="frame round-border">
  <div class="row d-flex align-items-center">
    <div class="ps-2 pe-0 w-auto mb-2 ms-1">
      <button mat-stroked-button color="primary" (click)="checkUserMessageHistory()">
        <mat-icon>history</mat-icon>
        {{ 'buttons.checkMessageHistoryButton' | transloco }}
      </button>
    </div>
    <div class="ps-3 pe-3 w-auto mb-2">
      <button mat-stroked-button color="accent" (click)="refreshData()">
        <!--        <mat-icon>refresh</mat-icon>-->
        {{ 'buttons.refreshDataButton' | transloco }}
      </button>
    </div>
  </div>
  <div class="max-height-table">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8 mt-0" matSort [hidden]="!showTable">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
        <th mat-header-cell
            class="header-cell"
            *matHeaderCellDef
            mat-sort-header="{{headerCell(column)}}"
            arrowPosition="after"
            [ngStyle]="{'text-align': alignHeaderCell(column)}"
            [class.header-end]="alignHeaderCell(column) === 'end'"
            [class.header-start]="alignHeaderCell(column) === 'normal'">
          {{ translationColumns[i] | transloco }}
        </th>
        <td mat-cell *matCellDef="let element" class="overflow-text" [ngStyle]="{'text-align': element.align(column)}"> {{element.format(column)}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr mat-row *matRowDef="let element; let index = dataIndex; columns: columns;"
          (click)="selectUser(element)"
          class="element-row"
          [class.clicked-row]="selected === element"
          [ngClass]="{alternating: index % 2 === 0}">
      </tr>
    </table>
  </div>
</div>
