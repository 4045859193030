import {Component, OnInit, ViewChild} from '@angular/core';
import {ReportsService} from "../../../services/reports/reports.service";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {UnmappedPlatforms} from "../../../models/reports/platform-summary/unmapped/unmapped-platforms.interface";
import {Platform} from "../../../models/reports/platform-summary/platforms.model";
import {
  UnmappedPlatformSummaryReport
} from "../../../models/reports/platform-summary/unmapped/unmapped-platform-summary-report.interface";
import {PlatformSummaryReport} from "../../../models/reports/platform-summary/platform-summary-report.model";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {PercentageTotal} from "../../../models/reports/percentage-total.interface";
import {UtilsService} from "../../../services/utils/utils.service";
import {RolesService} from "../../../services/roles/roles.service";
import {RoleState} from "../../../models/roles/role-state.enum";

@Component({
  selector: 'app-platform-summary-report',
  templateUrl: './platform-summary-report.component.html',
  styleUrls: ['./platform-summary-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PlatformSummaryReportComponent implements OnInit {

  public dataSource!: MatTableDataSource<PlatformSummaryReport>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  public platforms: Platform[] = [];
  public platform: Platform | null = null;
  public displayPlatform: string = 'Platform';

  public showTable = false;
  public columns: string[] = [];
  public translationColumns: string[] = [];

  public expandedElement: PlatformSummaryReport | null = null;

  public startDate: string | null = null;
  public endDate: string | null  = null;

  private percentageTotal: PercentageTotal;

  public owner: boolean = false;

  constructor(private reportsService: ReportsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private utilsService: UtilsService,
              private rolesService: RolesService) {
    this.owner = this.rolesService.isRole(RoleState.OWNER);
    this.startDate = utilsService.getFirstDateInMonth();
    this.endDate = utilsService.getTodaysDate();
    this.translationColumns = reportsService.getPlatformSummaryReportTranslationColumns();
    this.fetchPlatforms();
    this.percentageTotal = {stake: 0, result: 0, multiplier: 100};
  }

  public refreshData(): void {
    if (this.startDate && this.endDate && this.platform){
      if (this.startDate > this.endDate) {
        [this.startDate, this.endDate] = [this.endDate, this.startDate];
      }
      this.fetchReports(this.startDate, this.endDate, this.platform.platformId);
    }else {
      this.handlingService.error('Please make sure start, end date and platform are selected');
    }
  }

  public setPlatform(platform: any){
    this.displayPlatform = platform === null ? 'Platform' : platform.platformName;
    this.platform = platform;
  }

  public setStartDate(date: string | null): void {
    this.startDate = date;
  }
  public setEndDate(date: string | null): void {
    this.endDate = date;
  }

  private fetchPlatforms(): void {
    this.loadingService.setLoadingSteps(1);
    this.reportsService.getPlatforms().subscribe(value => {
      this.platforms = this.mapPlatforms(value);
      this.setPlatform(this.platforms[0]);
      this.fetchReports(this.startDate!, this.endDate!, this.platform!.platformId);
    }, error => {
      this.handlingService.error('Error while fetching platforms', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    });
  }

  private fetchReports(fromDate: string, toDate: string, platformId: number): void {
    this.reportsService.getPlatformSummaryReports(fromDate, toDate, platformId).subscribe(value => {
      this.dataSource = new MatTableDataSource<PlatformSummaryReport>(this.mapPlatformSummaryReport(value));
      this.dataSource.sort = this.sort;

      if (this.dataSource.data.length === 0){
        this.hideReports();
      }else{
        this.showReports();
      }
    }, error => {
      this.handlingService.error('Error while fetching platform summary report data', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  private mapPlatforms(unmapped: UnmappedPlatforms[]): Platform[] {
    const result: Platform[] = [];
    unmapped.forEach(item => {
      result.push(new Platform(item.PlatformID, item.PlatformName))
    });
    return result;
  }

  private mapPlatformSummaryReport(unmapped: UnmappedPlatformSummaryReport[]): PlatformSummaryReport[] {
    const result: PlatformSummaryReport[] = [];
    unmapped.forEach(item => {
      result.push(new PlatformSummaryReport(
        item.Bets,
        item.CompanyID,
        item.CompanyName,
        item.PercEUR,
        item.ResultInEUR,
        item.StakeInEUR,
        item.WinInEUR,
        item.Wins
      ))
    });
    return result;
  }

  ngOnInit(): void {
  }

  public alignHeaderCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].align(column);
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }

  private showReports(): void {
    if (this.columns.length === 0){
      this.columns = this.dataSource.data[0].getFields();
    }
    this.showTable = true;
  }

  private hideReports(): void {
    this.showTable = false;
    this.dataSource = new MatTableDataSource<PlatformSummaryReport>([]);
  }

  public total(column: string): string | number {
    if(column === this.columns[0]){
      return 'Total';
    }else if(column === this.columns[1]) {
      return '';
    }else if(column === this.columns[3]) { //stake in eur

      let result = this.sumData(column);
      this.percentageTotal.stake = result;
      return this.totalFormat(result, column);

    }else if(column === this.columns[6]) { //result in eur

      let result = this.sumData(column);
      this.percentageTotal.result = result;
      return this.totalFormat(result, column);

    } else if(column === this.columns[7]) { //percentage in eur formula

      let result = (this.percentageTotal.result * this.percentageTotal.multiplier) / this.percentageTotal.stake;

      if (isNaN(result)){ result = 0; }

      return this.totalFormat(result, column);

    } else {
      let result = this.sumData(column);
      return this.totalFormat(result, column);
    }
  }

  private sumData(column: string): number {
    let result: number = 0;
    this.dataSource.data.map(report => {
      result += report.total(column);
    })
    return result;
  }

  private totalFormat(result: number, column: string): any {
    if (column === this.columns[2] ||
      column === this.columns[4]){
      return this.utilsService.formatInteger(result);
    }else{
      return this.utilsService.formatMoney(result);
    }
  }

}
