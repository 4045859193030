<div class="frame round-border">
  <h2 style="font-size: 24px; font-weight: lighter;">{{ 'configurations.user.userProfile.title' | transloco }}</h2>
  <hr>
  <form [formGroup]="profileForm" (ngSubmit)="submitForm()">
    <div class="container">
      <div class="row">
        <div class="col flex-column d-flex">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'configurations.user.usernameLabel' | transloco }}</mat-label>
            <input matInput formControlName="username" [value]="user?.username" style="font-size: 16px">
            <mat-error *ngIf="username!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Username' } }}</mat-error>
            <mat-error *ngIf="username!.errors?.minlength">{{ 'errorMessages.minlength' | transloco: { name: 'Username' } }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'configurations.user.emailLabel' | transloco }}</mat-label>
            <input matInput formControlName="email" [value]="user?.email" style="font-size: 16px">
            <mat-error *ngIf="email!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'E-mail' } }}</mat-error>
            <mat-error *ngIf="email!.errors?.email">{{ 'errorMessages.email' | transloco: { name: 'E-mail' } }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <div>
            <label class="mt-2">{{'configurations.user.companyLabel' | transloco}}</label>
            <p class="card-title overflow">{{user.companyName}}</p>
          </div>
          <div>
            <label class="mt-4">{{'configurations.user.roleLabel' | transloco}}</label>
            <p class="card-title">{{user.userRole}}</p>
          </div>
        </div>
      </div>
      <div class="row flex-row-reverse">
        <button mat-stroked-button color="primary" type="submit" class="w-150 mx-1" [disabled]="!profileForm.valid">
          {{ 'buttons.saveButton' | transloco }}
        </button>
        <button mat-stroked-button color="accent" type="button" class="w-150 mx-1" (click)="resetForm()">
          {{ 'buttons.cancelButton' | transloco }}
        </button>
      </div>
    </div>
  </form>
</div>

