import {Component, Inject, OnInit} from '@angular/core';
import {Tournament} from "../../../models/tournaments/tournament.model";
import {TournamentAward} from "../../../models/tournaments/tournament-award.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup} from "@angular/forms";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {TournamentsService} from "../../../services/tournaments/tournaments.service";

@Component({
  selector: 'app-award-editor-dialog',
  templateUrl: './award-editor-dialog.component.html',
  styleUrls: ['./award-editor-dialog.component.scss']
})
export class AwardEditorDialogComponent implements OnInit {

  public form: FormGroup;

  constructor(private dialogRef: MatDialogRef<AwardEditorDialogComponent>,
              private formBuilder: FormBuilder,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private tournamentsService: TournamentsService,
              @Inject(MAT_DIALOG_DATA) public inputData: TournamentWithAwards) {
    this.form = this.formBuilder.group({
      awards: [this.inputData.awards.map(item => new TournamentAward(item.tournamentId, item.place, item.award))]
    });
  }

  ngOnInit(): void {
  }

  public addAward() {
    const awards: TournamentAward[] = this.awards?.value;
    if (awards.length > 0) {
      const lastAward: TournamentAward = awards.slice(-1)[0];
      awards.push(new TournamentAward(this.inputData.tournament.id, lastAward.place + 1, 0));
      this.awards?.setValue(awards);
    }else {
      awards.push(new TournamentAward(this.inputData.tournament.id, 1, 0));
      this.awards?.setValue(awards);
    }
  }

  public deleteAward() {
    const awards: TournamentAward[] = this.awards?.value;
    awards.pop();
    this.awards?.setValue(awards);
  }

  public async submit() {
    const awards: TournamentAward[] = this.awards?.value;
    const addingArray: TournamentAward[] = [];
    const deletingArray: TournamentAward[] = [];
    const updatingArray: TournamentAward[] = [];
    for (const award of awards) {
      const found = this.inputData.awards.find(item => item.place === award.place);
      if (found){
        if (found.award !== award.award){
          updatingArray.push(award);
        }
      }else{
        addingArray.push(award);
      }
    }
    for (const award of this.inputData.awards) {
      const found = awards.find(item => item.place === award.place);
      if (!found) {
        deletingArray.push(award);
      }
    }
    const result = await Promise.all([
      this.sendAddingAwards(addingArray),
      this.sendUpdatingAwards(updatingArray),
      this.sendDeletingAwards(deletingArray)
    ])
      .then(() => {
        this.handlingService.success('Tournament awards successfully updated');
        return true;
      })
      .catch(error => {
        this.handlingService.error('Failed to update some tournament awards', error);
        return false;
      })
    this.dialogRef.close({data: result});
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public get awards() {
    return this.form.get('awards');
  }

  private sendAddingAwards(awards: TournamentAward[]) {
    return Promise.all(awards.map((item: TournamentAward) => this.tournamentsService.addAwardToTournament(item)));
  }

  private sendUpdatingAwards(awards: TournamentAward[]) {
    return Promise.all(awards.map((item: TournamentAward) => this.tournamentsService.updateAwardFromTournament(item)));
  }

  private sendDeletingAwards(awards: TournamentAward[]) {
    return Promise.all(awards.map((item: TournamentAward) => this.tournamentsService.deleteAwardFromTournament(item)));
  }

}

interface TournamentWithAwards {
  tournament: Tournament
  awards: TournamentAward[]
}
