import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SummaryReportDetails} from "../../../../models/reports/summary/summary-report-details.interface";
import {ReportsService} from "../../../../services/reports/reports.service";
import {HandlingService} from "../../../../services/global-handling/handling.service";
import {SummaryReportRocketmanDetails} from "../../../../models/reports/summary/summary-report-rocketman-details.model";
import {UnmappedSummaryReportDetails} from "../../../../models/reports/summary/unmapped/unmapped-summary-report-details.interface";
import {NavigationService} from "../../../../services/navigation/navigation.service";
import {Games} from "../../../../models/utils/games.enum";
import {Routes} from "../../../../models/utils/routes.enum";

@Component({
  selector: 'app-summary-report-details',
  templateUrl: './summary-report-details.component.html',
  styleUrls: ['./summary-report-details.component.scss']
})
export class SummaryReportDetailsComponent implements OnInit, OnChanges {

  @Input('elementCompanyID') elementCompanyId? : number | null;
  @Input('expandedElementCompanyID') expandedElementCompanyId? : number | null;
  @Input('startDate') startDate?: string | null;
  @Input('endDate') endDate?: string | null;
  @Input('currentGameId') currentGameId: number = 0;

  public summaryReportDetails: SummaryReportDetails[] = [];
  public columns: string[] = []
  public translationColumns: string[] = [];

  constructor(private reportsService: ReportsService,
              private handlingService: HandlingService) {
    this.translationColumns = reportsService.getSummaryReportDetailsTranslationColumns();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.elementCompanyId === this.expandedElementCompanyId){
      if(this.startDate && this.endDate && this.elementCompanyId){
        this.reportsService.getSummaryDetailsReport(this.elementCompanyId, this.startDate, this.endDate, this.currentGameId).subscribe(newData => {
          this.summaryReportDetails = this.mapData(newData);
          if(this.summaryReportDetails[0] && this.columns.length === 0){
            this.columns = this.summaryReportDetails[0].getFields();
          }
        },error => {
          this.handlingService.error('Error while fetching summary details report data', error);
        });
      }
    }
  }

  private mapData(unmapped: UnmappedSummaryReportDetails[]): SummaryReportDetails[]{
    let data: SummaryReportDetails[] = []
    unmapped.forEach(item => {
      data.push(
        new SummaryReportRocketmanDetails(
          item.CurrencyID,
          item.Bets,
          item.Stake,
          item.Wins,
          item.WinMoney,
          item.Result,
          item.Perc))
    })
    return data;
  }

  public alignHeaderCell(column: string): string {
    return this.summaryReportDetails[0].align(column);
  }

}
