import {ReportColumns} from "../../../reports/report-columns.interface";

export class ConfigJackpot {
  id: number
  companyName: string
  levelID: number
  jackPotName: string
  jackPotPercentage: number
  jackPotReservePercentage: number
  jackPotMinValue: number
  jackPotMaxValue: number
  jackPotActive: boolean

  columnInfo: ReportColumns[]

  constructor(id: number, companyName: string, levelID: number, jackPotName: string, jackPotPercentage: number, jackPotReservePercentage: number, jackPotMinValue: number, jackPotMaxValue: number, jackPotActive: boolean) {
    this.id = id;
    this.companyName = companyName;
    this.levelID = levelID;
    this.jackPotName = jackPotName;
    this.jackPotPercentage = jackPotPercentage;
    this.jackPotReservePercentage = jackPotReservePercentage;
    this.jackPotMinValue = jackPotMinValue;
    this.jackPotMaxValue = jackPotMaxValue;
    this.jackPotActive = jackPotActive;

    this.columnInfo = [
      {key: 'ID', info: {field: 'id', total: 0, format: this.id, align: 'normal'}},
      {key: 'Company name', info: {field: 'companyName', total: 0, format: this.companyName, align: 'normal'}},
      {key: 'Level', info: {field: 'levelID', total: 0, format: this.levelID, align: 'normal'}},
      {key: 'Name', info: {field: 'jackPotName', total: 0, format: this.jackPotName, align: 'normal'}},
      {key: 'Percentage', info: {field: 'jackPotPercentage', total: 0, format: this.formatTwoDecimals(this.jackPotPercentage), align: 'end'}},
      {key: 'Reserve percentage', info: {field: 'jackPotReservePercentage', total: 0, format: this.formatTwoDecimals(this.jackPotReservePercentage), align: 'end'}},
      {key: 'Min value', info: {field: 'jackPotMinValue', total: 0, format: this.formatMoney(this.jackPotMinValue), align: 'end'}},
      {key: 'Max value', info: {field: 'jackPotMaxValue', total: 0, format: this.formatMoney(this.jackPotMaxValue), align: 'end'}},
      {key: 'Active', info: {field: 'jackPotActive', total: 0, format: this.jackPotActive, align: 'end'}},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }

  public formatTwoDecimals(integer: number): string {
    return integer.toFixed(2);
  }
}
