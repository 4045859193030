import { Injectable } from '@angular/core';
import {OrderBy} from "../../models/utils/order-by.interface";
import {Utils} from "../../models/utils/utils.model";
import {UtcTimeOffset} from "../../models/configurations/company-config/utc-time-offset.model";
import {TimeOffset} from "../../models/configurations/company-config/time-offset.interface";
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class UtilsService extends Utils{

  public readonly utcTimes = new UtcTimeOffset();

  constructor() {
    super();
  }

  public getOrderByList(): OrderBy[] {
    return [
      {orderByText: 'Sessions', orderByValue: 1},
      {orderByText: 'Bets', orderByValue: 2},
      {orderByText: 'Stake', orderByValue: 3},
      {orderByText: 'GGR', orderByValue: 4},
    ]
  }

  public getUtcTimeOffsets(): TimeOffset[] {
    return this.utcTimes.getTimeOffsets();
  }

  public getUtcStartOffset(): TimeOffset {
    return this.utcTimes.getByValue(0);
  }

  public convertToUtc(date: Date, format: string): string {
    return moment.utc(date).format(format);
  }

  public getTodaysDate(): string {
    return moment.utc().format("YYYY-MM-DD");
  }

  public getFirstDateInMonth(): string {
    return moment().startOf('month').format('YYYY-MM-DD');
  }

  public getLastThirtyDays(): string {
    return moment().subtract(30, 'days').format('YYYY-MM-DD');
  }

  public getUTCTime(): string {
    let utcISO = new Date().toISOString();
    return utcISO.slice(utcISO.indexOf('T') + 1, utcISO.lastIndexOf('.'));
  }

  public getUtcTimeNow(): string {
    return moment().utc().format("HH:mm");
  }

  public getEndOfDayTime(): string {
    return moment().utc().endOf('day').format("HH:mm");
  }

  public convertToString(date: Date, format: string = 'YYYY-MM-DD HH:mm:ss'): string {
    return moment(date).format(format);
  }

  public convertFromLocalToUtc(datetime: string, format: string = 'YYYY-MM-DD HH:mm:ss'): string {
    return moment(datetime).utc().format(format);
  }

  public convertFromUtcToLocal(datetime: string, format: string = 'YYYY-MM-DD HH:mm:ss'): string {
    return moment(datetime).local().format(format);
  }

  public timestampToString(timestamp: string, format: string = 'YYYY-MM-DD HH:mm:ss'): string {
    return moment(timestamp).utc().format(format);
  }

  public mapDateTime(date: string, time: string): string {
    return this.getCharCount(time, ':') > 1 ? date + ' ' + time : date + ' ' + time + ':00';
  }

  private getCharCount(string: string, char: string): number {
    return string.split(char).length - 1;
  }
}
