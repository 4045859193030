export class Chart2Data {

  bets: number
  players: number
  stake: number
  win: number
  hold: number
  holdPerc: number

  constructor(bets: number, players: number, stake: number, win: number, hold: number, holdPerc: number) {
    this.bets = bets;
    this.players = players;
    this.stake = stake;
    this.win = win;
    this.hold = hold;
    this.holdPerc = holdPerc;
  }
}
