<h2 class="component-title">{{ 'reports.promoPlayersReport.title' | transloco }}</h2>
<hr>

<div class="frame round-border">
  <div class="row d-flex align-items-center">
    <div class="ps-3 pe-0 w-auto">
      <app-date-picker [placeholder]="'Start date'" (date)="setStartDate($event)" [init]="startDate"></app-date-picker>
    </div>
    <div class="ps-3 pe-0 w-auto">
      <app-date-picker [placeholder]="'End date'" (date)="setEndDate($event)" [init]="endDate"></app-date-picker>
    </div>
    <div class="ps-3 pe-0 w-auto">
      <mat-form-field appearance="standard" class="select-input" style="font-size: 16px">
        <mat-label>{{'configurations.company.sidenav' | transloco }}</mat-label>
        <mat-select [(value)]="company" (selectionChange)="setSelectedCompany(company)">
          <mat-option [value]="null">{{'buttons.currencyButton' | transloco }}</mat-option>
          <mat-option [value]="comp" *ngFor="let comp of companies">
            {{comp.CompanyName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ps-3 pe-0 w-auto mb-1">
      <button mat-stroked-button color="primary" (click)="refreshData()">
        <!--        <mat-icon>refresh</mat-icon>-->
        {{ 'buttons.refreshDataButton' | transloco }}
      </button>
    </div>
    <div class="ps-3 pe-3 w-auto mb-1">
      <button mat-stroked-button color="accent" (click)="downloadExcel()">
        <mat-icon>file_download</mat-icon>
        {{ 'buttons.excelButton' | transloco }}
      </button>
    </div>
  </div>
  <div class="max-height-table">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mt-0" matSort [hidden]="!showTable">

      <ng-container matColumnDef="{{column}}" *ngFor="let column of topColumns; let i = index">
        <th mat-header-cell
            class="header-cell two-rows-header"
            *matHeaderCellDef
            [ngStyle]="{'text-align': 'center'}"
            [colSpan]="topColumnSpans[i]"
            [class.border-right]="borderRight(column)">
          {{ topTranslationColumns[i] | transloco }}
        </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'text-align': element.align(column)}"> {{element.format(column)}} </td>
        <td mat-footer-cell class="footer-cell" *matFooterCellDef [ngStyle]="{'text-align': alignHeaderCell(column)}"> {{total(column)}} </td>
      </ng-container>

      <ng-container matColumnDef="{{column}}" *ngFor="let column of bottomColumns; let i = index">
        <th mat-header-cell
            class="header-cell two-rows-header"
            *matHeaderCellDef
            mat-sort-header="{{headerCell(column)}}"
            arrowPosition="after"
            [ngStyle]="{'text-align': alignHeaderCell(column)}"
            [class.header-end]="alignHeaderCell(column) === 'end'"
            [class.header-start]="alignHeaderCell(column) === 'normal'"
            [class.border-right]="borderRight(column)">
          {{ bottomTranslationColumns[i] | transloco }}
        </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'text-align': element.align(column)}"> {{element.format(column)}} </td>
        <td mat-footer-cell class="footer-cell no-border" *matFooterCellDef [ngStyle]="{'text-align': alignHeaderCell(column)}"> {{total(column)}} </td>
      </ng-container>

      <tr mat-header-row class="custom-top-header-height" *matHeaderRowDef="topColumns; sticky: true"></tr>
      <tr mat-header-row class="custom-bottom-header-height" *matHeaderRowDef="bottomColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let element; let index = dataIndex; columns: bottomColumns;"
          class="element-row"
          [ngClass]="{alternating: index % 2 === 0}">
      </tr>
      <tr mat-footer-row *matFooterRowDef="bottomColumns"></tr>
    </table>
    <mat-paginator class="paginator" [length]="totalData"
                   [pageSizeOptions]="[10, 20, 50, 100]"
                   showFirstLastButtons
                   [ngStyle]="{'display': showTable ? 'block': 'none'}"></mat-paginator>
  </div>
</div>
