import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {UnmappedTournament} from "../../models/tournaments/unmapped/unmapped-tournament.interface";
import {HttpClient} from "@angular/common/http";
import {Tournament} from "../../models/tournaments/tournament.model";
import {InsertTournamentDto} from "../../models/tournaments/insert-tournament-dto.interface";
import {UpdateTournamentDto} from "../../models/tournaments/update-tournament-dto.interface";
import {UnmappedTournamentCompany} from "../../models/tournaments/unmapped/unmapped-tournament-company.interface";
import {UnmappedTournamentAward} from "../../models/tournaments/unmapped/unmapped-tournament-award.interface";
import {CompanySimple} from "../../models/configurations/company-simple.interface";
import {TournamentAward} from "../../models/tournaments/tournament-award.model";
import {UnmappedSimpleTournament} from "../../models/tournaments/unmapped/unmapped-simple-tournament.interface";
import {UnmappedTournamentResult} from "../../models/tournaments/unmapped/unmapped-tournament-result.interface";

import {environment} from "../../../environments/environment";
import * as moment from "moment";
import {Moment} from "moment/moment";
const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class TournamentsService {

  constructor(private http: HttpClient) { }

  public getTournaments(allTournaments: number): Observable<UnmappedTournament[]> {
    const url = baseUrl.concat('tournaments/show');
    return this.http.get<UnmappedTournament[]>(url, {
      params: {
        allTournaments: allTournaments
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public addTournament(tournament: Tournament): Observable<UnmappedTournament> {
    const url = baseUrl.concat('tournaments/insert');
    return this.http.post<UnmappedTournament>(url, this.tournamentInsertOutputMapper(tournament), {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  private tournamentInsertOutputMapper(tournament: Tournament): InsertTournamentDto {
    return {
      tournamentName: tournament.tournamentName,
      currencyId: tournament.currencyId,
      fromDate: tournament.fromDateTime,
      toDate: tournament.toDateTime,
      tournamentType: parseInt(tournament.tournamentType.charAt(0)),
      minBet: tournament.minBet,
      tournamentDescription: tournament.tournamentDescription,
      showBefore: tournament.showBefore
    }
  }

  public updateTournament(tournament: Tournament): Observable<UnmappedTournament> {
    const url = baseUrl.concat('tournaments/update');
    return this.http.put<UnmappedTournament>(url, this.tournamentUpdateOutputMapper(tournament), {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  private tournamentUpdateOutputMapper(tournament: Tournament): UpdateTournamentDto {
    return {
      id: tournament.id,
      tournamentName: tournament.tournamentName,
      currencyId: tournament.currencyId,
      fromDate: tournament.fromDateTime,
      toDate: tournament.toDateTime,
      tournamentType: parseInt(tournament.tournamentType.charAt(0)),
      minBet: tournament.minBet,
      tournamentDescription: tournament.tournamentDescription,
      showBefore: tournament.showBefore
    }
  }

  public deleteTournament(tournament: Tournament): Observable<UnmappedTournament> {
    const url = baseUrl.concat('tournaments/delete');
    return this.http.delete<UnmappedTournament>(url, {
      params: {
        id: tournament.id
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getTournamentCompanies(tournamentId: number): Observable<UnmappedTournamentCompany[]> {
    const url = baseUrl.concat('tournaments/companies');
    return this.http.get<UnmappedTournamentCompany[]>(url, {
      params: {
        tournamentId: tournamentId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getTournamentAwards(tournamentId: number): Observable<UnmappedTournamentAward[]> {
    const url = baseUrl.concat('tournaments/awards');
    return this.http.get<UnmappedTournamentAward[]>(url, {
      params: {
        tournamentId: tournamentId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public addCompanyToTournament(tournamentId: number, company: CompanySimple): Promise<CompanySimple> {
    const url = baseUrl.concat('tournaments/insert-company');
    return this.http.post<CompanySimple>(url, {
      tournamentId: tournamentId,
      companyId: company.CompanyID
    }, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise()
  }

  public deleteCompanyFromTournament(tournamentId: number, company: CompanySimple): Promise<CompanySimple> {
    const url = baseUrl.concat('tournaments/delete-company');
    return this.http.delete<CompanySimple>(url, {
      params: {
        tournamentId: tournamentId,
        companyId: company.CompanyID
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise()
  }

  public addAwardToTournament(award: TournamentAward): Promise<UnmappedTournamentAward> {
    const url = baseUrl.concat('tournaments/insert-award');
    return this.http.post<UnmappedTournamentAward>(url, {
      tournamentId: award.tournamentId,
      place: award.place,
      placeAward: award.award
    }, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise()
  }

  public updateAwardFromTournament(award: TournamentAward): Promise<UnmappedTournamentAward> {
    const url = baseUrl.concat('tournaments/update-award');
    return this.http.put<UnmappedTournamentAward>(url, {
      tournamentId: award.tournamentId,
      place: award.place,
      placeAward: award.award
    }, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise()
  }

  public deleteAwardFromTournament(award: TournamentAward): Promise<UnmappedTournamentAward> {
    const url = baseUrl.concat('tournaments/delete-award');
    return this.http.delete<UnmappedTournamentAward>(url, {
      params: {
        tournamentId: award.tournamentId,
        place: award.place
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise()
  }

  public announceTournament(tournamentId: number): Observable<UnmappedTournament> {
    const url = baseUrl.concat('tournaments/announce');
    return this.http.get<UnmappedTournament>(url, {
      params: {
        tournamentId: tournamentId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getSimpleTournaments(): Observable<UnmappedSimpleTournament[]> {
    const url = baseUrl.concat('tournaments/show-simple');
    return this.http.get<UnmappedSimpleTournament[]>(url, {
      params: {
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }


  public getTournamentResults(tournamentId: number): Observable<UnmappedTournamentResult[]> {
    const url = baseUrl.concat('tournaments/results');
    return this.http.get<UnmappedTournamentResult[]>(url, {
      params: {
        tournamentId: tournamentId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getTournamentsTranslationColumns(): string[] {
    return [
      'tournaments.manageTournaments.tournamentsColumns.id',
      'tournaments.manageTournaments.tournamentsColumns.tournamentName',
      'tournaments.manageTournaments.tournamentsColumns.currencyId',
      'tournaments.manageTournaments.tournamentsColumns.fromDate',
      'tournaments.manageTournaments.tournamentsColumns.toDate',
      'tournaments.manageTournaments.tournamentsColumns.tournamentType',
      'tournaments.manageTournaments.tournamentsColumns.minBet',
      'tournaments.manageTournaments.tournamentsColumns.tournamentStatus',
      'tournaments.manageTournaments.tournamentsColumns.tournamentDescription',
    ]
  }

  public getTournamentCompaniesTranslationColumns(): string[] {
    return [
      'tournaments.manageTournaments.tournamentCompaniesColumns.companyName'
    ]
  }

  public getTournamentAwardsTranslationColumns(): string[] {
    return [
      'tournaments.manageTournaments.tournamentAwardsColumns.place',
      'tournaments.manageTournaments.tournamentAwardsColumns.award'
    ]
  }

  public getTournamentResultTranslationColumns(): string[] {
    return [
      'tournaments.tournamentResults.columns.tournamentId',
      'tournaments.tournamentResults.columns.playerId',
      'tournaments.tournamentResults.columns.username',
      'tournaments.tournamentResults.columns.place',
      'tournaments.tournamentResults.columns.placeAward',
      'tournaments.tournamentResults.columns.currencyId',
      'tournaments.tournamentResults.columns.points',
      'tournaments.tournamentResults.columns.externalId',
    ]
  }

  public validDateTimes(startDateTime: string, endDateTime: string): boolean {
    return moment(startDateTime).isBefore(endDateTime);
  }

}
