<h2 class="component-title">{{ 'configurations.company.currencies.title' | transloco }}</h2>
<hr>
<div class="frame round-border w-96">
  <div class="row d-flex align-items-center">
    <div class="ps-3 pe-0 w-auto">
      <mat-form-field appearance="standard" class="select-input" style="font-size: 16px">
        <mat-label>{{'buttons.games' | transloco }}</mat-label>
        <mat-select [(ngModel)]="currentGame" (valueChange)="setSelectedGame($event)">
          <mat-option [value]="game" *ngFor="let game of games">
            {{game.text}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ps-3 pe-0 w-auto">
      <mat-form-field appearance="standard" style="font-size: 16px">
        <mat-label>{{ 'configurations.company.sidenav' | transloco }}</mat-label>
        <mat-select [(value)]="selectedCompany" (valueChange)="setSelectedCompany($event)">
          <mat-option [value]="defaultCompany">{{'buttons.companySelectButton' | transloco }}</mat-option>
          <mat-option [value]="company" *ngFor="let company of companies">
            {{company.CompanyName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ps-3 pe-0 w-auto">
      <button mat-stroked-button color="primary" (click)="swapCurrencies()">
        <mat-icon>compare_arrows</mat-icon>
        {{ 'buttons.alterCurrenciesButton' | transloco }}
      </button>
    </div>
    <div class="ps-3 pe-0 w-auto">
      <button mat-stroked-button color="primary" (click)="editCurrencies()" [disabled]="disableEdit">
        <mat-icon>edit</mat-icon>
        {{ 'buttons.editCurrenciesButton' | transloco }}
      </button>
    </div>
  </div>
  <div class="max-height-table">
    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8 mt-0" [hidden]="!showTable">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
        <th mat-header-cell
            class="header-cell"
            *matHeaderCellDef
            mat-sort-header="{{sortHeader(column)}}"
            arrowPosition="after"
            [ngStyle]="{'text-align': alignment(column)}">
          {{ translation(column) | transloco }}
        </th>
        <td mat-cell class="cell" *matCellDef="let element" [ngStyle]="{'text-align': alignment(column)}"> {{format(element, column)}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; let i = index; columns: columns;"
          (click)="selectCurrency(row)"
          class="element-row"
          [class.clicked-row]="row === selectedCurrency"
          [ngClass]="{alternating: i % 2 === 0}"></tr>
    </table>
  </div>
</div>


