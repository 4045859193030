import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ReportsService} from "../../../../services/reports/reports.service";
import {HandlingService} from "../../../../services/global-handling/handling.service";
import {LoadingService} from "../../../../services/loading/loading.service";
import {UtilsService} from "../../../../services/utils/utils.service";
import {ExcelService} from "../../../../services/excel/excel.service";
import {Games} from "../../../../models/utils/games.enum";
import {RaccoonRushRoundReport} from "../../../../models/reports/round/raccoonrush-round-report.model";
import {
  UnmappedRaccoonRushRoundReport
} from "../../../../models/reports/round/unmapped/unmapped-raccoonrush-round-report.interface";

@Component({
  selector: 'app-raccoonrush-round-report',
  templateUrl: './raccoonrush-round-report.component.html',
  styleUrls: ['./raccoonrush-round-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RaccoonRushRoundReportComponent implements OnInit {

  public dataSource = new MatTableDataSource<RaccoonRushRoundReport>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public showTable = false;
  public columns: string[] = [];
  public gameReports: RaccoonRushRoundReport[] = [];
  public translationColumns: string[] = [];
  public expandedElement: RaccoonRushRoundReport | null = null;

  public withTickets: boolean = true;
  public date: string | null = null;

  constructor(private reportsService: ReportsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private utilsService: UtilsService,
              private excelService: ExcelService) {
    this.translationColumns = reportsService.getRaccoonRushRoundReportTranslationColumns();
    this.date = utilsService.getTodaysDate();
    this.fetchReports(this.date, 1, 10);
  }

  ngOnInit(): void {}

  private fetchReports(date: string, page: number, pageRows: number): void {
    this.loadingService.setLoadingSteps(1);
    this.reportsService.getRoundReports(date, page, pageRows, this.withTickets, Games.GOLD_MINES).subscribe(data => {
      this.gameReports = this.mapData(data);
      if (this.gameReports.length === 0){
        this.hideReports();
        return;
      }
      this.showReports();
      this.gameReports.length = this.gameReports[0].totalCount;
      this.dataSource = new MatTableDataSource<RaccoonRushRoundReport>(this.gameReports);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.initSortingSystem();
      this.setDate(date);
    }, error => {
      this.handlingService.error('Error while fetching round report data', error);
    }, () => {
      return this.loadingService.incrementLoadingSteps();
    })
  }

  private mapData(unmapped: UnmappedRaccoonRushRoundReport[]): RaccoonRushRoundReport[] {
    let data: RaccoonRushRoundReport[] = [];
    unmapped.forEach(item => {
      const date = this.utilsService.timestampToString(item.RoundOpen);
      data.push(
        new RaccoonRushRoundReport(
          item.ID,
          date,
          item.Bets,
          item.StakeInEUR,
          item.Wins,
          item.WinInEUR,
          item.Hold,
          item.TotalCount,
          item.TotalPages));
    })
    return data;
  }

  private showReports(): void {
    if (this.columns.length === 0){
      this.columns = this.gameReports[0].getFields();
    }
    this.showTable = true;
  }

  private hideReports(): void {
    this.showTable = false;
    this.gameReports = [];
  }

  public setDate(date: string | null): void {
    this.date = date;
  }

  public refreshData(){
    if (this.date){
      if(this.paginator){
        this.paginator.pageSize = 10;
        this.paginator.pageIndex = 0;
      }
      this.fetchReports(this.date, 1, 10);
    }else {
      this.handlingService.error('Please select a date');
    }
  }

  public downloadExcel(): void {
    let data = this.reportsService.raccoonrushRoundReportExcelMapper(this.gameReports);
    const name = 'Gold Mines - Round report - ' + this.utilsService.getTodaysDate();
    this.excelService.exportAsExcelFile(data, name);
  }

  public alignHeaderCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].align(column);
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }

    return this.dataSource.data[0].field(column);
  }

  public getNextData(date: string, size: number, page: number, pageRows: number): void {
    this.loadingService.setLoadingSteps(1);
    this.reportsService.getRoundReports(date, page, pageRows, this.withTickets, Games.GOLD_MINES).subscribe(data => {
      this.gameReports.length = size;
      this.gameReports.push(...this.mapData(data));
      this.gameReports.length = this.gameReports[0].totalCount;
      this.dataSource = new MatTableDataSource<RaccoonRushRoundReport>(this.gameReports);

      this.dataSource._updateChangeSubscription();
      this.resetSort();
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.initSortingSystem();
    }, error => {
      this.handlingService.error('Error while fetching round report data', error);
    }, () => {
      return this.loadingService.incrementLoadingSteps();
    })
  }

  public pageChanged(event: any): void {
    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;

    let previousSize = pageSize * pageIndex;

    let date = this.date === null ? this.utilsService.convertToUtc(new Date(), "YYYY-MM-DD") : this.date;
    this.getNextData(date, previousSize, pageIndex + 1, pageSize);
  }

  private resetSort() {
    this.sort.sort({id: '', start: 'asc', disableClear: false});
  }

  private getCurrentPageData(data: any[]): any[] {
    if (!this.paginator) {
      return data;
    }
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.slice(startIndex, startIndex + this.paginator.pageSize);
  }

  private initSortingSystem(): void {
    //Overriding sorting function to only use data from current page
    this.dataSource.sortData = (data, sort: MatSort) => {
      // Getting data for current page
      data = this.getCurrentPageData(data);

      const active = sort.active;
      const direction = sort.direction;
      if (!active || direction == '') { return data; }

      return data.sort((a, b) => {
        let valueA = this.dataSource.sortingDataAccessor(a, active);
        let valueB = this.dataSource.sortingDataAccessor(b, active);

        // If there are data in the column that can be converted to a number,
        // it must be ensured that the rest of the data
        // is of the same type so as not to order incorrectly.
        const valueAType = typeof valueA;
        const valueBType = typeof valueB;

        if (valueAType !== valueBType) {
          if (valueAType === 'number') { valueA += ''; }
          if (valueBType === 'number') { valueB += ''; }
        }

        // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
        // one value exists while the other doesn't. In this case, existing value should come last.
        // This avoids inconsistent results when comparing values to undefined/null.
        // If neither value exists, return 0 (equal).
        let comparatorResult = 0;
        if (valueA != null && valueB != null) {
          // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
          if (valueA > valueB) {
            comparatorResult = 1;
          } else if (valueA < valueB) {
            comparatorResult = -1;
          }
        } else if (valueA != null) {
          comparatorResult = 1;
        } else if (valueB != null) {
          comparatorResult = -1;
        }

        return comparatorResult * (direction == 'asc' ? 1 : -1);
      });
    }

    // Overriding paging function to return complete data array. Data was already sliced in sortData function.
    this.dataSource._pageData = (data: any[]): any[] => {
      return data;
    }
  }

}
