import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {UtilsService} from "../../../services/utils/utils.service";

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnInit, OnChanges {

  @Input('gameTitle') gameTitle: string = '';
  @Input('title') title: string = '';
  @Input('data') data: any;
  @Input('time') time: string = '';

  constructor(private utilsService: UtilsService) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.data) {
      if (this.data === -1){ this.data = 'N/A'; return;  }
      this.data = this.utilsService.formatMoney(this.data);
    }
  }

}
