<div class="container px-0">
  <div class="row">
    <div class="col-7">
      <div class="row max-row-length">
        <p class="user-label">{{ 'chatRooms.columns.username' | transloco }}</p>
        <p class="user-name">{{user.username}}</p>
      </div>
      <div class="row mt-2 max-row-length">
        <p class="user-label">{{ 'chatRooms.columns.companyName' | transloco }}</p>
        <p class="user-name">{{user.company}}</p>
      </div>
    </div>
    <div class="col pt-2">
      <div class="row d-flex justify-content-between">
        <p class="user-label">{{ 'chatRooms.columns.profaneCount' | transloco }}</p>
        <p class="count-label">{{user.profanitiesCount}}</p>
      </div>
      <div class="row d-flex justify-content-between">
        <p class="user-label">{{ 'chatRooms.columns.totalMessages' | transloco }}</p>
        <p class="count-label">{{user.messageCount}}</p>
      </div>
      <div class="row d-flex justify-content-between">
        <p class="user-label w-auto">{{ 'chatRooms.columns.profanePerc' | transloco }}</p>
        <p class="count-label">{{user.profanitiesPercent}}</p>
      </div>
      <div class="row d-flex justify-content-between">
        <p class="user-label w-auto">{{ 'chatRooms.columns.banUntil' | transloco }}</p>
        <p class="count-label" *ngIf="showBannedUntil">{{banUntil | date:'dd.MM.':"+0000"}}</p>
        <p class="count-label" *ngIf="!showBannedUntil">/</p>
      </div>
    </div>
  </div>
  <hr>
  <div class="row d-flex justify-content-center">
    <div class="row d-flex justify-content-between">
      <p class="user-label mx-1 mb-0 pt-1 px-0 w-75" style="margin-top: 7px">{{ 'chatRooms.chatRoomMessageHistory' | transloco }}</p>
    </div>
    <div class="row">
      <div class="col flex-column messages justify-content-start px-1">
        <ng-container *ngFor="let item of messageHistory">
          <app-chat-room-message [item]="item" [selected]="selectedMessage" (message)="messageSelected($event)" [maxWidth]="450"></app-chat-room-message>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-end mt-3">
    <button class="ban-btn ms-1 me-0 mt-2" type="button" mat-raised-button color="warn" (click)="banUser()">{{ 'buttons.banButton' | transloco }} {{user.username}}</button>
    <button class="mt-2" type="button" mat-icon-button color="warn" [matMenuTriggerFor]="banOptionsMenu"><mat-icon color="warn">more_vert</mat-icon></button>
    <mat-menu #banOptionsMenu="matMenu">
      <button mat-menu-item (click)="selectBanOption(option)" *ngFor="let option of banOptions">{{option.text}}</button>
    </mat-menu>
  </div>
</div>
