import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-chat-room-username-dialog',
  templateUrl: './chat-room-username-dialog.component.html',
  styleUrls: ['./chat-room-username-dialog.component.scss']
})
export class ChatRoomUsernameDialogComponent implements OnInit {

  public form: FormGroup;

  constructor(private dialogRef: MatDialogRef<ChatRoomUsernameDialogComponent>,
              private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public input: string) {

    this.form = this.formBuilder.group({
      username: [input, [Validators.required, Validators.minLength(3)]]
    });
  }

  ngOnInit(): void {
  }

  public submitForm() {
    if (this.form.invalid) {
      return;
    }

    const username = this.form.get('username')?.value;
    this.dialogRef.close({data: username});
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public get username() {
    return this.form.get('username');
  }

}
