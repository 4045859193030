<div class="container w-96 mb-1 px-0 margin-x">
  <div class="row row-gap">
    <div class="col-auto d-flex justify-content-center mx-2 p-0 mb-2">
      <app-dashboard-card2 [data]="data.players" [title]="'Players'" [iconClass]="'bi bi-people'"></app-dashboard-card2>
    </div>
    <div class="col-auto d-flex justify-content-center mx-2 p-0 mb-2">
      <app-dashboard-card2 [data]="data.bets" [title]="'Bets'" [iconClass]="'bi bi-dice-6'"></app-dashboard-card2>
    </div>
    <div class="col-auto d-flex justify-content-center mx-2 p-0 mb-2">
      <app-dashboard-card2 [data]="data.stake" [title]="'Stake'" [iconClass]="'bi bi-cash-coin'"></app-dashboard-card2>
    </div>
    <div class="col-auto d-flex justify-content-center mx-2 p-0 mb-2">
      <app-dashboard-card2 [data]="data.win" [title]="'Win'" [iconClass]="'bi bi-suit-spade'"></app-dashboard-card2>
    </div>
    <div class="col-auto d-flex justify-content-center mx-2 p-0 mb-2">
      <app-dashboard-card2 [data]="data.hold" [title]="'Hold'" [iconClass]="'bi bi-piggy-bank'"></app-dashboard-card2>
    </div>
    <div class="col-auto d-flex justify-content-center mx-2 p-0 mb-2">
      <app-dashboard-card2 [data]="data.holdPerc" [title]="'Percent'" [iconClass]="'bi bi-percent'"></app-dashboard-card2>
    </div>
  </div>
</div>



