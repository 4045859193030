import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {BehaviorSubject} from "rxjs";
import {ApexTheme, ApexTooltip} from "ng-apexcharts";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private static readonly DARK_THEME_CLASS = 'dark-theme';
  private static readonly DARK_THEME_LIGHT = 'light';
  private static readonly DARK_THEME_DARK = 'dark';

  private theme = new BehaviorSubject<string>('dark');
  public themeObs = this.theme.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document) {

    let localTheme = localStorage.getItem('theme');
    let containsDarkTheme = this.document.documentElement.classList.contains(ThemeService.DARK_THEME_CLASS);

    if (localTheme){
      if (localTheme === ThemeService.DARK_THEME_DARK){
        if (!containsDarkTheme){
          this.selectDarkTheme();
        }
      } else {
        this.selectLightTheme();
      }
    }else{
      if (!containsDarkTheme){
        this.selectDarkTheme();
      }
    }
  }

  public selectDarkTheme(): void {
    this.document.documentElement.classList.add(ThemeService.DARK_THEME_CLASS);
    this.theme.next(ThemeService.DARK_THEME_DARK);
    this.setLocalTheme(ThemeService.DARK_THEME_DARK);
  }

  public selectLightTheme(): void {
    this.document.documentElement.classList.remove(ThemeService.DARK_THEME_CLASS);
    this.theme.next(ThemeService.DARK_THEME_LIGHT);
    this.setLocalTheme(ThemeService.DARK_THEME_LIGHT);
  }

  public getCurrentTheme(): string {
    return this.theme.value;
  }

  private setLocalTheme(theme: string){
    localStorage.setItem('theme', theme);
  }

  public getLightTheme(): ApexTheme {
    return {
      monochrome: {
        enabled: true,
        color: '#6002ee',
        shadeTo: ThemeService.DARK_THEME_LIGHT,
        shadeIntensity: 0.65
      }
    }
  }

  public getDarkTheme(): ApexTheme {
    return {
      monochrome: {
        enabled: true,
        color: '#bb86fc',
        shadeTo: ThemeService.DARK_THEME_LIGHT,
        shadeIntensity: 0.65
      }
    }
  }

  public getLightTooltip(): ApexTooltip {
    return {
      theme: ThemeService.DARK_THEME_LIGHT
    };
  }

  public getDarkTooltip(): ApexTooltip {
    return {
      theme: ThemeService.DARK_THEME_DARK
    };
  }

  public getLightText(): string {
    return '#000000';
  }

  public getDarkText(): string {
    return '#ffffff';
  }

  public getDefaultTheme(): ApexTheme {
    if (this.theme.value !== ThemeService.DARK_THEME_LIGHT){
      return this.getDarkTheme();
    }else{
      return this.getLightTheme();
    }
  }

  public getDefaultTooltip(): ApexTooltip {
    if (this.theme.value !== ThemeService.DARK_THEME_LIGHT){
      return this.getDarkTooltip();
    }else{
      return this.getLightTooltip();
    }
  }

  public getDefaultText(): string {
    if (this.theme.value !== ThemeService.DARK_THEME_LIGHT){
      return this.getDarkText();
    }else{
      return this.getLightText();
    }
  }

  public getDefaultColors(): string[] {
    if (this.theme.value !== ThemeService.DARK_THEME_LIGHT){
      return ['#bb86fc', '#86FCF6'];
    }else{
      return ['#6002ee', '#02EED6'];
    }
  }

  public getTextColor(): string {
    return this.theme.value !== ThemeService.DARK_THEME_LIGHT ? '#3700b3' : '#6002ee';
  }

  public getColors(): string[] {
    if (this.theme.value !== ThemeService.DARK_THEME_LIGHT){
      return [
        '#d1acfd',
        '#009a77',
        '#b073fc',
        '#00c7ab',
        '#8526fa',
        '#d4f6f2',];
    }else {
      return [
        '#d4bff9',
        '#0b7d7d',
        '#7e3ff2',
        '#009b9e',
        '#3d00e0',
        '#29cace',];
    }
  }
}
