import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfigurationsService} from "../../../../services/configurations/configurations.service";
import {ConfigUser} from "../../../../models/configurations/user-config/config-user.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HandlingService} from "../../../../services/global-handling/handling.service";
import {RolesService} from "../../../../services/roles/roles.service";
import {RoleState} from "../../../../models/roles/role-state.enum";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '90px',
        visibility: 'visible'
      })),
      state('closed', style({
        height: '0px',
        visibility: 'hidden'
      })),
      transition('* => *', [
        animate('0.15s')
      ]),
    ]),
  ],
})
export class EditUserDialogComponent implements OnInit {

  private emailRegex: string = '^\\S+@\\S+\\.\\S{2,4}$';

  public editingMyself: boolean = false;
  public hidePass: boolean = false;
  public editingForm: FormGroup;

  public owner: boolean = false;

  public advanced: boolean = false;

  constructor(private dialogRef: MatDialogRef<EditUserDialogComponent>,
              private configurationsService: ConfigurationsService,
              private handlingService: HandlingService,
              private formBuilder: FormBuilder,
              private rolesService: RolesService,
              @Inject(MAT_DIALOG_DATA) public user: ConfigUser) {
    if (configurationsService.getCredentials().username === user.username){
      this.editingMyself = true;
    }

    this.owner = rolesService.checkRole(RoleState.OWNER);

    this.editingForm = this.formBuilder.group({
      username: [{value: this.user.username, disabled: true}, [Validators.required, Validators.minLength(3)]],
      password: [this.user.password],
      company: [{value: this.user.companyName, disabled: true}, Validators.required],
      email: [this.user.email, [Validators.required, Validators.email]],
      comment: [this.user.comment],
      role: [{value: this.user.userRole, disabled: true}, Validators.required],
      lockedOut: [this.user.isLockedOut, Validators.required],
      resetSecretCode: [false]
    });
  }

  ngOnInit(): void {
  }

  public submitForm(){
    if (this.editingForm.invalid){ return; }
    if(this.user.username !== this.editingForm.get('username')?.value ||
      this.user.companyName !== this.editingForm.get('company')?.value ||
      this.user.userRole !== this.editingForm.get('role')?.value){
      this.handlingService.error("That action isn't allowed!");
      return this.dialogRef.close();
    }
    let newPassword = this.editingForm.get('password')?.value.toString().trim();
    this.user.password = newPassword.length > 0 ? newPassword : '';
    this.user.email = this.editingForm.get('email')?.value;
    this.user.comment = this.editingForm.get('comment')?.value;
    this.user.isLockedOut = this.editingForm.get('lockedOut')?.value;

    this.user.resetSecretCode = !!(this.owner && this.editingForm.get('resetSecretCode')?.value);

    this.dialogRef.close({data: this.user});
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public get username() {
    return this.editingForm.get('username');
  }
  public get password() {
    return this.editingForm.get('password');
  }
  public get company() {
    return this.editingForm.get('company');
  }
  public get email() {
    return this.editingForm.get('email');
  }
  public get comment() {
    return this.editingForm.get('comment');
  }
  public get role() {
    return this.editingForm.get('role');
  }
  public get lockedOut() {
    return this.editingForm.get('lockedOut');
  }
  public get resetSecretCode() {
    return this.editingForm.get('resetSecretCode');
  }

}
