import {ReportColumns} from "../report-columns.interface";

export class CurrencyReport {
  currencyId: string
  betCount: number
  playerCount: number
  betInCurrency: number
  betEur: number
  winCount: number
  winInCurrency: number
  winEur: number
  paidCount: number
  paidInCurrency: number
  paidEur: number

  columnInfo: ReportColumns[]


  constructor(currencyId: string,
              betCount: number,
              playerCount: number,
              betInCurrency: number,
              betEur: number,
              winCount: number,
              winInCurrency: number,
              winEur: number,
              paidCount: number,
              paidInCurrency: number,
              paidEur: number) {
    this.currencyId = currencyId;
    this.betCount = betCount;
    this.playerCount = playerCount;
    this.betInCurrency = betInCurrency;
    this.betEur = betEur;
    this.winCount = winCount;
    this.winInCurrency = winInCurrency;
    this.winEur = winEur;
    this.paidCount = paidCount;
    this.paidInCurrency = paidInCurrency;
    this.paidEur = paidEur;

    this.columnInfo = [
      {key: 'Currency', info: {field: 'currencyId', total: this.currencyId, format: this.formatString(this.currencyId), align: 'normal'}},
      {key: 'Bets', info: {field: 'betCount', total: this.betCount, format: this.formatInteger(this.betCount), align: 'end'}},
      {key: 'Player Count', info: {field: 'playerCount', total: this.playerCount, format: this.formatInteger(this.playerCount), align: 'end'}},
      {key: 'Bet In Currency', info: {field: 'betInCurrency', total: 0, format: this.formatMoney(this.betInCurrency), align: 'end'}},
      {key: 'Bet In EUR', info: {field: 'betEur', total: this.betEur, format: this.formatMoney(this.betEur), align: 'end'}},
      {key: 'Win Count', info: {field: 'winCount', total: this.winCount, format: this.formatInteger(this.winCount), align: 'end'}},
      {key: 'Wins In Currency', info: {field: 'winInCurrency', total: 0, format: this.formatMoney(this.winInCurrency), align: 'end'}},
      {key: 'Wins In EUR', info: {field: 'winEur', total: this.winEur, format: this.formatMoney(this.winEur), align: 'end'}},
      {key: 'Paid Count', info: {field: 'paidCount', total: this.paidCount, format: this.formatInteger(this.paidCount), align: 'end'}},
      {key: 'Paid In Currency', info: {field: 'paidInCurrency', total: 0, format: this.formatMoney(this.paidInCurrency), align: 'end'}},
      {key: 'Paid In EUR', info: {field: 'paidEur', total: this.paidEur, format: this.formatMoney(this.paidEur), align: 'end'}}
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public total(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.total;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatString(string: string): string {
    return string;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }
}
