import {ReportColumns} from "../reports/report-columns.interface";

export class IndecentUser {
  id: number
  username: string
  banned: boolean
  bannedUntil: string
  company: string
  profanitiesCount: number
  messageCount: number
  profanitiesPercent: number

  columnInfo: ReportColumns[]


  constructor(id: number, username: string, banned: boolean, bannedUntil: string, company: string, profanitiesCount: number, messageCount: number, profanitiesPercent: number) {
    this.id = id;
    this.username = username;
    this.banned = banned;
    this.bannedUntil = bannedUntil;
    this.company = company;
    this.profanitiesCount = profanitiesCount;
    this.messageCount = messageCount;
    this.profanitiesPercent = profanitiesPercent;

    this.columnInfo = [
      {key: 'ID', info: {field: 'id', total: this.id, format: this.id, align: 'normal'}},
      {key: 'Username', info: {field: 'username', total: this.username, format: this.formatString(this.username), align: 'normal'}},
      {key: 'Banned', info: {field: 'banned', total: 0, format: this.banned, align: 'center'}},
      {key: 'Banned until', info: {field: 'bannedUntil', total: 0, format: this.formatString(this.bannedUntil), align: 'center'}},
      {key: 'Company', info: {field: 'company', total: this.company, format: this.formatString(this.company), align: 'normal'}},
      {key: 'Profanities', info: {field: 'profanitiesCount', total: this.profanitiesCount, format: this.formatInteger(this.profanitiesCount), align: 'end'}},
      {key: 'Messages', info: {field: 'messageCount', total: this.messageCount, format: this.formatInteger(this.messageCount), align: 'end'}},
      {key: 'Profanity %', info: {field: 'profanitiesPercent', total: this.profanitiesPercent, format: this.formatInteger(this.profanitiesPercent), align: 'end'}}
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public total(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.total;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatString(string: string): string {
    return string;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }
}
