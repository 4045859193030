import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TranslationResponse} from "../../models/utils/translation-response.interface";
import {Observable} from "rxjs";

import {environment} from "../../../environments/environment";
const translateApiKey = environment.translateApiKey;

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private url: string = 'https://translation.googleapis.com/language/translate/v2';

  constructor(private http: HttpClient) {
  }

  public translate(text: string): Observable<TranslationResponse> {
    return this.http.post<TranslationResponse>(this.url + '?key=' + translateApiKey, {
      q: text,
      target: "en"
    });
  }
}
