import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rocketman-jackpot-config',
  templateUrl: './rocketman-jackpot-config.component.html',
  styleUrls: ['./rocketman-jackpot-config.component.scss']
})
export class RocketmanJackpotConfigComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
