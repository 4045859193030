import { Pipe, PipeTransform } from '@angular/core';
import {CompanySimple} from "../models/configurations/company-simple.interface";

@Pipe({
  name: 'companyFilter'
})
export class CompanyFilterPipe implements PipeTransform {

  transform(companies: any[], filterString: string) {
    if (companies.length === 0 || filterString === ''){
      return companies;
    }
    const filtered: CompanySimple[] = [];
    for (const company of companies) {
      if (company.CompanyName.toLowerCase().includes(filterString.toLowerCase())){
        filtered.push(company);
      }
    }
    return filtered;
  }

}
