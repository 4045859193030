export class Chart1Data {

  levelID: number
  jackPotName: string
  jackPotValue: number
  gameName: string

  constructor(gameName: string, levelID: number, jackPotName: string, jackPotValue: number) {
    this.gameName = gameName;
    this.levelID = levelID;
    this.jackPotName = jackPotName;
    this.jackPotValue = jackPotValue;
  }
}
