import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PlayerAuditDetails} from "../../../models/player-audit/player-audit-details.model";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {LocalizationService} from "../../../services/localization/localization.service";
import {PlayerAuditService} from "../../../services/player-audit/player-audit.service";
import {
  UnmappedPlayerAuditDetails
} from "../../../models/player-audit/unmapped/unmapped-player-audit-details.interface";
import {UtilsService} from "../../../services/utils/utils.service";

@Component({
  selector: 'app-player-audit-details',
  templateUrl: './player-audit-details.component.html',
  styleUrls: ['./player-audit-details.component.scss']
})
export class PlayerAuditDetailsComponent implements OnInit, OnChanges {

  @Input('playerId') playerId!: number | undefined;
  @Input('ticketId') ticketId!: number | undefined;
  @Input('expandedPlayerId') expandedPlayerId!: number | undefined;
  @Input('expandedTicketId') expandedTicketId!: number | undefined;
  @Input('gameId') gameId: number = 101;

  public details: PlayerAuditDetails[] = [];
  public columns: string[] = [];
  public translationColumns: string[] = [];

  constructor(private handlingService: HandlingService,
              private localizationService: LocalizationService,
              private playerAuditService: PlayerAuditService,
              private utilsService: UtilsService) {
    this.translationColumns = this.localizationService.getPlayerAuditDetailsTranslations();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.playerId === this.expandedPlayerId && this.ticketId === this.expandedTicketId){
      if (this.playerId && this.ticketId){
        this.playerAuditService.getPlayerAuditDetails(this.playerId, this.ticketId, this.gameId)
          .subscribe(value => {
            this.details = this.mapData(value);
            if(this.details[0] && this.columns.length === 0){
              this.columns = this.details[0].getFields();
            }
        }, error => {
            this.handlingService.error('Error while fetching player audit details data', error);
          });
      }
    }
  }

  private mapData(unmapped: UnmappedPlayerAuditDetails[]): PlayerAuditDetails[] {
    let result: PlayerAuditDetails[] = [];
    unmapped.forEach(item => {
      const activityTime = this.utilsService.timestampToString(item.ActivityTime);
      result.push(
        new PlayerAuditDetails(item.TicketID, item.Source, item.ID, item.Activity, activityTime)
      )
    });
    return result;
  }

  public alignHeaderCell(column: string): string {
    return this.details[0].align(column);
  }

}
