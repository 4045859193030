import { Injectable, Inject, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEventPattern, Subscription } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {

  private renderer: Renderer2;
  private darkScheme = '(prefers-color-scheme:dark)';
  private subscriptionToColorScheme!: Subscription;

  constructor(@Inject(DOCUMENT) private _doc: any, private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  private prefersColorScheme$ = fromEventPattern(handler => window.matchMedia(this.darkScheme).addEventListener('change', handler as EventListener)).pipe(
    pluck('matches')
  );

  getFavicon() {
    return this._doc.querySelector("link[rel*='icon']");
  }

  setFavicon(iconURL: string, altIconURL?: string) {
    const link = this.getFavicon() || this.renderer.createElement('link');
    let currentLinkHref = iconURL;

    if (altIconURL) {
      this.subscribeToChangesInTheme(link, iconURL, altIconURL);

      if (window.matchMedia(this.darkScheme).matches) {
        currentLinkHref = altIconURL;
      }
    }

    this.appendLinkTag(link, currentLinkHref);
  }

  private subscribeToChangesInTheme(link: any, iconURL: string, altIconURL: string) {
    this.subscriptionToColorScheme = this.prefersColorScheme$.subscribe(isDarkTheme => {
      if (isDarkTheme) {
        this.appendLinkTag(link, altIconURL);
      } else {
        this.appendLinkTag(link, iconURL);
      }
    });
  }

  private appendLinkTag(link: any, iconURL: string) {
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = iconURL;
    const head = this._doc.getElementsByTagName('head')[0];
    this.renderer.appendChild(head, link);
  }

  ngOnDestroy() {
    if (this.subscriptionToColorScheme) {
      this.subscriptionToColorScheme.unsubscribe();
    }
  }
}
