<table mat-table [dataSource]="details" class="mat-elevation-z8">

  <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
    <th mat-header-cell colspan="3" class="child-header-cell" *matHeaderCellDef [ngStyle]="{'text-align': alignment(column)}"> {{ translation(column) | transloco }} </th>
    <td mat-cell colspan="3" class="child-table-cell" *matCellDef="let element" [ngStyle]="{'text-align': alignment(column)}"> {{format(element, column)}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"></tr>
</table>
