<div class="d-flex w-100 h-100 align-items-center justify-content-center">
  <div class="frame round-border align-items-center justify-content-center">
    <form [formGroup]="postLoginForm" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <img class="" src="../../../assets/logo.png" alt="" width="110" [routerLink]="''" style="cursor: pointer;">
        </div>
        <div class="col-12 mb-4 pb-2 d-flex justify-content-center">
          <h2 class="login-text two-fa-title" style="font-size: 28px; font-weight: lighter;">{{ 'postLogin.title' | transloco }}</h2>
        </div>
        <div class="col-12 mb-3 d-flex justify-content-center inputField">
          <input class="box login-text" matInput moveToNext autocomplete="off" type="search" min="0" max="9" [maxLength]="1" formControlName="digit1">
          <input class="box login-text" matInput moveToNext autocomplete="off" type="search" min="0" max="9" [maxLength]="1" formControlName="digit2">
          <input class="box login-text" matInput moveToNext autocomplete="off" type="search" min="0" max="9" [maxLength]="1" formControlName="digit3">
          <input class="box login-text" matInput moveToNext autocomplete="off" type="search" min="0" max="9" [maxLength]="1" formControlName="digit4">
          <input class="box login-text" matInput moveToNext autocomplete="off" type="search" min="0" max="9" [maxLength]="1" formControlName="digit5">
          <input class="box login-text" matInput moveToNext autocomplete="off" type="search" min="0" max="9" [maxLength]="1" formControlName="digit6">
        </div>
        <div class="col-12 mb-3 d-flex inputField justify-content-center" >
          <button type="reset" mat-stroked-button color="accent">Reset form</button>
        </div>
      </div>
      <div class="row spacer">
      </div>
      <div class="row align-self-center">
        <div class="col-12 mb-4 d-flex justify-content-center">
          <button class="buttonLogin" mat-raised-button color="primary" type="submit" [disabled]="!postLoginForm.valid">{{ 'postLogin.continueButton' | transloco }}</button>
        </div>
      </div>
    </form>
  </div>
  <div class="frame round-border align-items-center justify-content-center" *ngIf="qrCode">
    <div class="col-12 mb-1 d-flex justify-content-center">
      <h2 class="login-text" style="font-size: 18px; font-weight: lighter; text-align: center;">{{ 'postLogin.welcomeMsg' | transloco }}</h2>
    </div>
    <div class="col-12 mb-1 d-flex justify-content-center">
      <qr-code [value]="qrCode" [size]="250" [background]="qrBackground" [foreground]="qrForeground"></qr-code>
    </div>
    <div class="col-12 mt-4 pt-2 d-flex justify-content-center">
      <button class="w-100 mx-4" mat-stroked-button color="primary" (click)="openInstructions()">{{ 'buttons.instructions' | transloco }}</button>
    </div>
  </div>
</div>
