import { Component } from '@angular/core';
import {FaviconService} from "./services/favicon/favicon.service";

const faviconLight = '../assets/favicon_light.ico';
const faviconDark = '../assets/favicon_dark.ico';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'backoffice';

  constructor(private faviconService: FaviconService) {
    faviconService.setFavicon(faviconLight, faviconDark);
  }
}
