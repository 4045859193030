import {ReportColumns} from "../../report-columns.interface";

export class ConfigMeteor {
  ID: number;
  CompanyID: number;
  HoursActiveFrom: number;
  HoursActiveTo: number;
  OffHoursCoef: number;
  Name: string;
  Currency: string;
  Amount: number;
  Players: number;
  MinutesMin: number;
  MinutesMax: number;
  date: any;
  columnInfo: ReportColumns[];

  constructor(
    ID: number,
    CompanyID: number,
    HoursActiveFrom: number,
    HoursActiveTo: number,
    OffHoursCoef: number,
    Name: string,
    Currency: string,
    Amount: number,
    Players: number,
    MinutesMin: number,
    MinutesMax: number,
    NextTriggerTime: string,
  ) {
    this.ID =  ID;
    this.CompanyID = CompanyID;
    this.HoursActiveFrom = HoursActiveFrom;
    this.HoursActiveTo = HoursActiveTo;
    this.OffHoursCoef = OffHoursCoef;
    this.Name = Name;
    this.Currency = Currency;
    this.Amount = Amount;
    this.Players = Players;
    this.MinutesMin = MinutesMin;
    this.MinutesMax = MinutesMax;
    this.date = NextTriggerTime

    this.columnInfo = [
      {key: 'ID', info: {field: 'ID', total: 0, format: this.ID, align: 'left'}},
      {key: 'CompanyID', info: {field: 'CompanyID', total: 0, format: this.CompanyID, align: 'center'}},
      {key: 'HoursActiveFrom', info: {field: 'HoursActiveFrom', total: 0, format: this.HoursActiveFrom, align: 'center'}},
      {key: 'HoursActiveTo', info: {field: 'HoursActiveTo', total: 0, format: this.HoursActiveTo, align: 'center'}},
      {key: 'OffHoursCoef', info: {field: 'OffHoursCoef', total: 0, format: this.OffHoursCoef, align: 'center'}},
      {key: 'Name', info: {field: 'Name', total: 0, format: this.Name, align: 'center'}},
      {key: 'Currency', info: {field: 'Currency', total: 0, format: this.Currency, align: 'center'}},
      {key: 'Amount', info: {field: 'Amount', total: 0, format: this.Amount, align: 'center'}},
      {key: 'Players', info: {field: 'Players', total: 0, format: this.Players, align: 'center'}},
      {key: 'MinutesMin', info: {field: 'MinutesMin', total: 0, format: this.MinutesMin, align: 'center'}},
      {key: 'MinutesMax', info: {field: 'MinutesMax', total: 0, format: this.MinutesMax, align: 'center'}},
      {key: 'NextTriggerTime', info: {field: 'NextTriggerTime', total: 0, format: this.date, align: 'right'}},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];

    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }
}
