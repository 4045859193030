import {ReportColumns} from "../report-columns.interface";

export class RaccoonRushRoundReportDetails {
  Bet: number
  BetEUR: number
  FieldPos: number
  Odd: number
  Win: number
  WinEUR: number
  columnInfo: ReportColumns[];

  constructor(Bet: number, BetEUR: number, FieldPos: number, Odd: number, Win: number, WinEUR: number) {
    this.Bet = Bet;
    this.BetEUR = BetEUR;
    this.FieldPos = FieldPos;
    this.Odd = Odd;
    this.Win = Win;
    this.WinEUR = WinEUR;

    this.columnInfo = [
      {key: 'Bet', info: {field: 'bet', total: this.Bet, format: this.formatInteger(this.Bet), align: 'center'}},
      {key: 'BetEUR', info: {field: 'BetEUR', total: this.BetEUR, format: this.formatInteger(this.BetEUR), align: 'center'}},
      {key: 'FieldPos', info: {field: 'FieldPos', total: this.FieldPos, format: this.formatMoney(this.FieldPos), align: 'end'}},
      {key: 'Odd', info: {field: 'Odd', total: this.Odd, format: this.formatMoney(this.Odd), align: 'end'}},
      {key: 'Win', info: {field: 'Win', total: this.Win, format: this.formatMoney(this.Win), align: 'end'}},
      {key: 'WinEUR', info: {field: 'WinEUR', total: this.WinEUR, format: this.formatMoney(this.WinEUR), align: 'end'}}
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatInteger(integer: number): string {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }

  public formatString(string: string): string {
    return string;
  }

  public formatTwoDecimals(integer: number): string {
    return integer.toFixed(2);
  }

}
