<div class="round-border dashboard-card">
  <div class="d-flex align-items-center h-100">
    <div class="d-flex flex-column justify-content-start align-items-start ps-3">
      <p class="mb-0 game-title">{{gameTitle}}</p>
      <p class="dashboard-card-data">{{data}}</p>
    </div>
    <div class="col">
      <div class="ms-4 mt-2">
        <p class="dashboard-card-title">{{title}}</p>
        <label class="dashboard-card-time">{{time}}</label>
      </div>
    </div>
  </div>
</div>
