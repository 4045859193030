import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {LoginService} from "../../../services/login/login.service";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {RolesService} from "../../../services/roles/roles.service";
import {ConfigurationsService} from "../../../services/configurations/configurations.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ThemeService} from "../../../services/theme/theme.service";

@Component({
  selector: 'app-post-login',
  templateUrl: './post-login.component.html',
  styleUrls: ['./post-login.component.scss']
})
export class PostLoginComponent implements OnInit {

  private regex: string = '^\\d+(\\.\\d+)?$';
  public qrCode: string | null = null;

  public postLoginForm: FormGroup;
  private failedCount = 0;
  public instructions = '';

  public qrBackground: string = '#ffffff';
  public qrForeground: string = '#000000'

  constructor(private formBuilder: FormBuilder,
              private route: Router,
              public rolesService: RolesService,
              public configurationService: ConfigurationsService,
              public loadingService: LoadingService,
              private loginService: LoginService,
              private handlingService: HandlingService,
              private snackBar: MatSnackBar,
              private themeService: ThemeService) {
    if (themeService.getCurrentTheme() !== 'light'){
      this.qrBackground = '#3d3d3d';
      this.qrForeground = '#ffffff';
    }

    this.postLoginForm = this.formBuilder.group({
      digit1: ['', [Validators.required, Validators.pattern(this.regex)]],
      digit2: ['', [Validators.required, Validators.pattern(this.regex)]],
      digit3: ['', [Validators.required, Validators.pattern(this.regex)]],
      digit4: ['', [Validators.required, Validators.pattern(this.regex)]],
      digit5: ['', [Validators.required, Validators.pattern(this.regex)]],
      digit6: ['', [Validators.required, Validators.pattern(this.regex)]],
    })
    if (loginService.secretCode && loginService.secretCode.length > 0){
      this.qrCode = 'otpauth://totp/' + loginService.username + '?secret=' + loginService.secretCode;
      this.instructions = 'Download Google Authenticator App from the following link: https://bit.ly/3AgWWol\n' +
        'Open the app and Add an account.\n' +
        'Scan the QR code or manually type the following secret code "' + loginService.secretCode + '", add a username and pick a time based type of key.'
    }
  }

  ngOnInit(): void {
  }

  public openInstructions(): void {
    this.snackBar.open(this.instructions, 'Got it', {horizontalPosition: 'center', verticalPosition: 'bottom', panelClass: ['snackbar']})
  }

  public submitForm(): void {
    if (this.postLoginForm.invalid){ return; }
    let code = this.combineCode();
    this.twoFactorAuthentication(code);
  }

  private combineCode(): string {
    return this.postLoginForm.get('digit1')?.value +
      this.postLoginForm.get('digit2')?.value +
      this.postLoginForm.get('digit3')?.value +
      this.postLoginForm.get('digit4')?.value +
      this.postLoginForm.get('digit5')?.value +
      this.postLoginForm.get('digit6')?.value
  }

  private twoFactorAuthentication(code: string): void {
    this.loadingService.setLoadingSteps(1);

    if(!localStorage.getItem('token')) { return this.loginService.logout(); }

    this.loginService.twoFactorAuthentication(code).subscribe(credentials => {
      if(!credentials.authRequired){
        localStorage.setItem('token', credentials.token)
        this.loginService.twoFactorPassed = true;
        this.loginService.twoFactorNeeded = false;
        this.route.navigate(['/home']).then(() => {})
        this.configurationService.setCredentials(credentials);
      }
    },error => {
      if (error.status === 401){
        this.failedCount += 1;
        if (this.failedCount >= 2){
          this.handlingService.error(
            "Invalid or expired authentication code. " +
            "If same code doesn't work multiple times, there could be a problem with synchronization with google servers, " +
            "best to wait for next code.");
        }else {
          this.handlingService.error('Invalid or expired authentication code');
        }
      }
    }, () => {
      this.loadingService.incrementLoadingSteps();
    });
  }

  public get digit1() { return this.postLoginForm.get('digit1'); }
  public get digit2() { return this.postLoginForm.get('digit2'); }
  public get digit3() { return this.postLoginForm.get('digit3'); }
  public get digit4() { return this.postLoginForm.get('digit4'); }
  public get digit5() { return this.postLoginForm.get('digit5'); }
  public get digit6() { return this.postLoginForm.get('digit6'); }

  public cleanForm(): void {
    this.postLoginForm.reset();
  }

}
