import {ReportColumns} from "../report-columns.interface";

export class JackpotHistoryReport {
  company: string
  game: string
  player: string
  winTime: string
  jackpotLevel: string
  roundId: number
  winEur: string
  winLocalCurrency: string

  columnInfo: ReportColumns[]

  constructor(company: string, game: string, player: string, winTime: string, jackpotLevel: string, roundId: number, winEur: number, winLocalCurrency: number, currency: string) {
    this.company = company;
    this.game = game;
    this.player = player;
    this.winTime = winTime;
    this.jackpotLevel = jackpotLevel;
    this.roundId = roundId;
    this.winEur = winEur + ' EUR';
    this.winLocalCurrency = winLocalCurrency + ' ' + currency;

    this.columnInfo = [
      {key: 'Company', info: {field: 'company', total: this.company, format: this.formatString(this.company), align: 'normal', optional: true}},
      {key: 'Game', info: {field: 'game', total: this.game, format: this.formatString(this.game), align: 'normal'}},
      {key: 'Player', info: {field: 'player', total: this.player, format: this.formatString(this.player), align: 'normal'}},
      {key: 'Win time', info: {field: 'winTime', total: this.winTime, format: this.formatString(this.winTime), align: 'normal'}},
      {key: 'Level', info: {field: 'jackpotLevel', total: this.jackpotLevel, format: this.formatString(this.jackpotLevel), align: 'normal'}},
      {key: 'Round ID', info: {field: 'roundId', total: this.roundId, format: this.roundId, align: 'end'}},
      {key: 'Win EUR', info: {field: 'winEur', total: this.winEur, format: this.formatString(this.winEur), align: 'end'}},
      {key: 'Win local currency', info: {field: 'winLocalCurrency', total: this.winLocalCurrency, format: this.formatString(this.winLocalCurrency), align: 'end'}}
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public total(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.total;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatString(string: string): string {
    return string;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }
}
