import {Injectable, OnDestroy} from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Credentials} from "../../models/user/credentials.model";

import {environment} from "../../../environments/environment";
const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class LoginService implements OnDestroy{

  twoFactorPassed: boolean = false;
  twoFactorNeeded: boolean = false;
  secretCode: string | null = null;
  username: string | null = null;

  constructor(private http: HttpClient, private router: Router) {
  }

  ngOnDestroy(): void {
  }

  public autoLogin(token: string, device: string): Observable<Credentials> {
    const url = baseUrl.concat('users/login/by/id');
    return this.http.post<Credentials>(url, {
      info: device
    }, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
  }

  public login(info: string, username: string, password: string): Observable<Credentials>{
    const url = baseUrl.concat('users/login');
    return this.http.post<Credentials>(url, {
      username: username,
      password: password,
      info: info
    })
  }

  public twoFactorAuthentication(code: string): Observable<Credentials> {
    const url = baseUrl.concat('users/two-factor');
    return this.http.post<Credentials>(url, {
      token: localStorage.getItem('token'),
      code: code
    })
  }

  public logout(){
    localStorage.removeItem('token');
    if (this.router.url !== baseUrl){
      this.router.navigate(['']).then(() => {});
    }
  }

}
