import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MobileService {

  private mobileDevice = new BehaviorSubject(false);
  public mobileDeviceObs = this.mobileDevice.asObservable();

  constructor() {
    if (window.screen.availWidth <= 600){
      this.mobileDevice.next(true);
    }else{
      this.mobileDevice.next(false);
    }
  }

  public getMobileDevice(): boolean {
    return this.mobileDevice.value;
  }

}
