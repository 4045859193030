import {AfterViewInit, Component, ElementRef, ViewChild, ViewContainerRef} from '@angular/core';
import {NavigationItem} from "../../../models/navigation-item.model";
import {NavigationService} from "../../../services/navigation/navigation.service";
import {RolesService} from "../../../services/roles/roles.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {Router} from "@angular/router";
import {ConfigurationsService} from "../../../services/configurations/configurations.service";
import {LoginService} from "../../../services/login/login.service";
import {MobileService} from "../../../services/mobile/mobile.service";
import {SidenavItems} from "../../../models/utils/sidenav-items.interface";
import {Routes} from "../../../models/utils/routes.enum";
import {changelog} from "../../../../assets/changelog.properties";
import {Version} from "../../../models/utils/version.interface";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements AfterViewInit {

  today: number = Date.now();

  public loading = false;
  public target = 0;

  @ViewChild('sidenav') sidenav: ElementRef | undefined;
  @ViewChild("window", {read: ViewContainerRef}) component: ViewContainerRef | undefined;

  public sidenavItems: SidenavItems[] = [];

  public mobileDevice: boolean = false;
  public changelog: Version[] = changelog;

  public demo: boolean = false;

  constructor(public configurationsService: ConfigurationsService,
              private navigationService: NavigationService,
              public rolesService: RolesService,
              public loadingService: LoadingService,
              public loginService: LoginService,
              private mobileService: MobileService,
              private handlingService: HandlingService,
              public router: Router) {
    this.demo = !environment.production;

    this.changelog = changelog.slice(-3).reverse();
    this.mobileDevice = this.mobileService.getMobileDevice();

    setInterval(() => {this.today = Date.now()}, 1);

    localStorage.setItem('reload', 'true');

    loadingService.obsMaxLoadingSteps.subscribe(value => {
      this.loading = true;
      this.target = value;
    });
    loadingService.obsLoadingSteps.subscribe(value => {
      if (value >= this.target){
        this.loading = false;
      }
    });
    this.configurationsService.reloadCredentials();

    for (let i = 0; i < this.navigationService.getSidenavItemsLength(); i++) {
      let items = this.navigationService.getSidenavItems(i);
      let approval = items.map(item => this.checkItemAvailability(item));
      this.sidenavItems.push({items: items, approval: approval});
    }
  }

  ngAfterViewInit(): void {
    let currentItem = this.findAvailableGroup();

    if (this.sidenav && this.component) {
      this.navigationService.setSidenav(this.sidenav);
      this.navigationService.setWindowContainer(this.component);
      //Small timeout is needed to allow angular to load the containerView before assigning new window to it
      setTimeout(() => {
        if (currentItem) {
          this.navigationService.setWindow(currentItem.getWindow());
        }else{
          this.loadingService.incrementLoadingSteps();
        }
        if (this.mobileDevice){
          this.navigationService.toggleSidenav();
          this.navigationService.setSidenavMode(this.navigationService.SIDENAV_PUSH);
        }
      }, 5);
    }
  }

  private checkMobile(itemMobile: boolean): boolean {
    return this.mobileDevice ? itemMobile : true;
  }

  public checkArray(array: boolean[]): boolean {
    let result = false;
    array.forEach(item => {
      if (item){
        result = true;
        return
      }
    });
    return result;
  }

  private findAvailableGroup(): NavigationItem | null {
    let result: NavigationItem | null = null;
    for (let i = 0; i < this.sidenavItems.length; i++) {
      result = this.findAvailableItem(this.sidenavItems[i].items);
      if (result){
        break
      }
    }
    return result;
  }

  private findAvailableItem(array: NavigationItem[]): NavigationItem | null {
    for (let i = 0; i < array.length; i++) {
      if (this.checkItemAvailability(array[i])){
        if (array[i].getChildren().length > 0){
          return this.findAvailableItem(array[i].getChildren())
        }else{
          return array[i]
        }
      }
    }
    return null;
  }

  private checkItemAvailability(item: NavigationItem): boolean {
    const roleCheck = this.rolesService.checkRouteAvailability(item.getRoute());
    const mobileCheck = this.checkMobile(item.getMobile());
    const platformCheck = this.configurationsService.checkPlatform();
    return item.getRoute() === Routes.PLATFORM_SUMMARY_REPORT ? platformCheck : roleCheck && mobileCheck;
  }

  public versionDetails(version: Version) {
    this.handlingService.custom("Release " + version.version, version.changes, 'timeline');
  }

}
