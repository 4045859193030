import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatTableDataSource} from "@angular/material/table";
import {FinancialReport} from "../../../../models/reports/financial/financial-report.model";
import {MatSort} from "@angular/material/sort";
import {PercentageTotal} from "../../../../models/reports/percentage-total.interface";
import {ReportsService} from "../../../../services/reports/reports.service";
import {HandlingService} from "../../../../services/global-handling/handling.service";
import {LoadingService} from "../../../../services/loading/loading.service";
import {UtilsService} from "../../../../services/utils/utils.service";
import {ExcelService} from "../../../../services/excel/excel.service";
import {
  UnmappedFinancialReport
} from "../../../../models/reports/financial/unmapped/unmapped-financial-report.interface";

@Component({
  selector: 'app-raccoonrush-game-report',
  templateUrl: './raccoonrush-game-report.component.html',
  styleUrls: ['./raccoonrush-game-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RaccoonRushGameReportComponent implements OnInit {
  public dataSource!: MatTableDataSource<FinancialReport>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  public showTable = false;
  public columns: string[] = [];
  public translationColumns: string[] = [];
  public expandedElement: FinancialReport | null = null;

  public startDate: string | null = null;
  public endDate: string | null  = null;

  private percentageTotal: PercentageTotal;

  constructor(private reportsService: ReportsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private utilsService: UtilsService,
              private excelService: ExcelService) {
    this.translationColumns = reportsService.getFinancialReportTranslationColumns();

    this.startDate = utilsService.getFirstDateInMonth();
    this.endDate = utilsService.getTodaysDate();

    this.fetchReports(this.startDate, this.endDate);

    this.percentageTotal = {stake: 0, result: 0, multiplier: 100};
  }

  ngOnInit(): void {}

  private fetchReports(startDate: string, endDate: string): void {
    this.loadingService.setLoadingSteps(1);
    this.reportsService.getFinancialReports(startDate, endDate, 'raccoonrush').subscribe(data => {
      this.dataSource = new MatTableDataSource(this.mapData(data));
      this.dataSource.sort = this.sort;
      this.dataSource.sort.sortChange.subscribe(() => this.expandedElement = null);

      if (this.dataSource.data.length === 0){
        this.hideReports();
      }else{
        this.showReports();
      }

    }, error => {
      this.handlingService.error('Error while fetching raccoonrush game report data', error)
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  private mapData(unmapped: UnmappedFinancialReport[]): FinancialReport[]{
    let data: FinancialReport[] = [];
    unmapped.forEach(item => {
      const date = this.utilsService.timestampToString(item.dateId, "YYYY-MM-DD");
      data.push(
        new FinancialReport(
          date,
          item.Bets,
          item.PlayerCount,
          item.StakeInEUR,
          item.Wins,
          item.WinInEUR,
          item.ResultInEUR,
          item.PercEUR));
    })
    return data;
  }

  private showReports(): void {
    if (this.columns.length === 0){
      this.columns = this.dataSource.data[0].getFields();
    }
    this.showTable = true;
  }

  private hideReports(): void {
    this.showTable = false;
    this.dataSource = new MatTableDataSource<FinancialReport>([]);
  }

  public total(column: string): string | number {
    if(column === this.columns[0]){
      return 'Total';
    }else if(column === this.columns[3]) { //stake in eur

      let result = this.sumData(column);
      this.percentageTotal.stake = result;
      return this.totalFormat(result, column);

    }else if(column === this.columns[6]) { //result in eur

      let result = this.sumData(column);
      this.percentageTotal.result = result;
      return this.totalFormat(result, column);

    } else if(column === this.columns[7]) { //percentage in eur formula

      let result = (this.percentageTotal.result * this.percentageTotal.multiplier) / this.percentageTotal.stake;

      if (isNaN(result)){ result = 0; }

      return this.totalFormat(result, column);

    } else {
      let result = this.sumData(column);
      return this.totalFormat(result, column);
    }
  }

  private sumData(column: string): number {
    let result: number = 0;
    this.dataSource.data.map(report => {
      result += report.total(column);
    })
    return result;
  }

  private totalFormat(result: number, column: string): any {
    if (column === this.columns[1] ||
      column === this.columns[2] ||
      column === this.columns[4]){
      return this.utilsService.formatInteger(result);
    }else{
      return this.utilsService.formatMoney(result);
    }
  }

  public setStartDate(date: string | null): void {
    this.startDate = date;
  }

  public setEndDate(date: string | null): void {
    this.endDate = date;
  }

  public refreshData(): void {
    if (this.startDate && this.endDate){
      if (this.startDate > this.endDate) {
        [this.startDate, this.endDate] = [this.endDate, this.startDate];
      }
      this.fetchReports(this.startDate, this.endDate);
    }else {
      this.handlingService.error('Please select start & end date');
    }
  }

  public downloadExcel(): void {
    let data = this.reportsService.financialReportExcelMapper(this.dataSource.data);
    const name = 'RaccoonRush game report - ' + this.utilsService.getTodaysDate();
    this.excelService.exportAsExcelFile(data, name);
  }

  public alignHeaderCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].align(column);
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }
}
