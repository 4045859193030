import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {FormControl, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";

@Component({
  selector: 'app-time-picker-input',
  templateUrl: './time-picker-input.component.html',
  styleUrls: ['./time-picker-input.component.scss']
})
export class TimePickerInputComponent implements OnInit, OnChanges {

  @Input('default') time: string | null = null;
  @Output() timeEmitter = new EventEmitter<string | null>();
  @Input('appearance') appearance: MatFormFieldAppearance = 'standard';
  @Input('placeholder') placeholder: string = '';
  @Input('max-width') maxWidth: number = 100;

  public matcher = new MyErrorStateMatcher();
  public timeFormControl = new FormControl(this.time, [Validators.required, Validators.pattern('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')]);

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.time){
      this.timeFormControl.setValue(this.time.slice(0, 5));
    }
  }

  public setTime(): void {
    if (!this.timeFormControl.invalid){
      this.timeEmitter.emit(this.timeFormControl.value);
    }
  }

}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
