import {ReportColumns} from "../report-columns.interface";

export class PlatformSummaryReport {
  bets: number
  companyId: number
  companyName: string
  percentEur: number
  resultInEur: number
  stakeInEur: number
  winInEur: number
  wins: number

  columnInfo: ReportColumns[]


  constructor(bets: number, companyId: number, companyName: string, percentEur: number, resultInEur: number, stakeInEur: number, winInEur: number, wins: number) {
    this.bets = bets;
    this.companyId = companyId;
    this.companyName = companyName;
    this.percentEur = percentEur;
    this.resultInEur = resultInEur;
    this.stakeInEur = stakeInEur;
    this.winInEur = winInEur;
    this.wins = wins;

    this.columnInfo = [
      {key: 'Company ID', info: {field: 'companyId', total: 0, format: this.companyId, align: 'normal'}},
      {key: 'Company Name', info: {field: 'companyName', total: 0, format: this.companyName, align: 'normal'}},
      {key: 'Bets', info: {field: 'bets', total: this.bets, format: this.formatInteger(this.bets), align: 'end'}},
      {key: 'Stake In EUR', info: {field: 'stakeInEur', total: this.stakeInEur, format: this.formatMoney(this.stakeInEur), align: 'end'}},
      {key: 'Wins', info: {field: 'wins', total: this.wins, format: this.formatInteger(this.wins), align: 'end'}},
      {key: 'Wins In EUR', info: {field: 'winInEur', total: this.winInEur, format: this.formatMoney(this.winInEur), align: 'end'}},
      {key: 'Result', info: {field: 'resultInEur', total: this.resultInEur, format: this.formatMoney(this.resultInEur), align: 'end'}},
      {key: 'Percentage', info: {field: 'percentEur', total: this.percentEur, format: this.formatMoney(this.percentEur), align: 'end'}},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public total(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.total;
        return;
      }
    })
    return result;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2});
  }
}
