<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="container pb-2">
    <div class="row">
      <div class="flex-container">
        <h2 mat-dialog-title style="font-size: 24px; font-weight: lighter;">{{ 'tournaments.manageTournaments.companiesEditorDialog.title' | transloco}}</h2>
        <span class="spacer"></span>
      </div>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="container" style="overflow-y: hidden">
      <mat-form-field class="w-100">
        <input type="text" placeholder="Filter companies" aria-label="Filter companies" matInput formControlName="filterText">
      </mat-form-field>
      <mat-selection-list formControlName="displayedCompanies" (selectionChange)="selectionChanged($event)">
        <mat-list-option *ngFor="let company of allCompanies | companyFilter: filterText?.value" [value]="company">
          {{company.CompanyName}}
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>

  <div class="container my-2 pe-2">
    <div mat-dialog-actions class="d-flex flex-row-reverse w-100">
      <button class="px-4 ms-2" type="submit" mat-flat-button color="primary" [disabled]="!form.valid">{{ 'buttons.saveButton' | transloco }}</button>
      <button class="px-3" type="button" mat-stroked-button color="accent" (click)="onNoClick()">{{ 'buttons.cancelButton' | transloco }}</button>
    </div>
  </div>
</form>
