import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ReportsService} from "../../../../../services/reports/reports.service";
import {HandlingService} from "../../../../../services/global-handling/handling.service";
import {Games} from "../../../../../models/utils/games.enum";
import {TicketStatuses} from "../../../../../models/utils/ticket-statuses.enum";
import {TicketTypes} from "../../../../../models/utils/ticket-types.enum";
import {GoldminesRoundReportDetails} from "../../../../../models/reports/round/goldmines-round-report-details.model";
import {
  UnmappedGoldminesRoundReportDetails
} from "../../../../../models/reports/round/unmapped/unmapped-goldmines-round-report-details.interface";

@Component({
  selector: 'app-goldmines-round-report-details',
  templateUrl: './goldmines-round-report-details.component.html',
  styleUrls: ['./goldmines-round-report-details.component.scss']
})
export class GoldminesRoundReportDetailsComponent implements OnInit, OnChanges {

  @Input('elementID') elementID?: number | null;
  @Input('expandedElementID') expandedElementID?: number | null;

  public gameReportDetails: GoldminesRoundReportDetails[] = [];
  public translationColumns: string[] = [];
  public columns: string[] = []

  constructor(private reportsService: ReportsService,
              private errorHandlingService: HandlingService) {
    this.translationColumns = reportsService.getGoldminesRoundReportDetailsTranslationColumns();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.elementID === this.expandedElementID){
      if(this.elementID){
        this.reportsService.getRoundDetailsReport(this.elementID, Games.GOLD_MINES).subscribe(newData => {
          this.gameReportDetails = this.mapData(newData);
          if(this.gameReportDetails[0]) {
            this.columns = this.gameReportDetails[0].getFields();
          }
        },error => {
          this.errorHandlingService.error('Error while fetching round details report data', error);
        });
      }
    }
  }

  private mapData(unmapped: UnmappedGoldminesRoundReportDetails[]): GoldminesRoundReportDetails[] {
    let data: GoldminesRoundReportDetails[] = [];
    unmapped.forEach(item => {
      data.push(
        new GoldminesRoundReportDetails(
          item.Bet,
          item.BetEUR,
          item.FieldPos,
          item.Odd,
          item.Win,
          item.WinEUR
        ));
    })
    return data;
  }

  private trimComma(value: string): string {
    return value.endsWith(',') ? value.slice(0, -1) : value;
  }

  public alignHeaderCell(column: string): string {
    return this.gameReportDetails[0].align(column);
  }

  private getTicketStatusByValue(value: number) {
    const index = Object.values(TicketStatuses).indexOf(value as unknown as TicketStatuses);
    return Object.keys(TicketStatuses)[index];
  }

  private getTicketTypeByValue(value: number) {
    const index = Object.values(TicketTypes).indexOf(value as unknown as TicketTypes);
    return Object.keys(TicketTypes)[index];
  }

}
