<form>
  <mat-form-field class="timepicker" [style.max-width]="maxWidth + 'px'" [appearance]="appearance">
    <mat-label>{{placeholder}}</mat-label>
    <input class="timepicker-input"
           type="text"
           placeholder="HH:MM"
           matInput
           timeFormat
           maxlength="5"
           onfocus="this.select()"
           [formControl]="timeFormControl"
           [errorStateMatcher]="matcher"
           (input)="setTime()">
    <mat-error *ngIf="timeFormControl.hasError('pattern')">
      Non valid time format (HH:MM)
    </mat-error>
  </mat-form-field>
</form>
