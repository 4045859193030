import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HandlingService} from "../../services/global-handling/handling.service";
import {ConfigurationsService} from "../../services/configurations/configurations.service";
import {RolesService} from "../../services/roles/roles.service";
import {LoadingService} from "../../services/loading/loading.service";
import {Router} from "@angular/router";
import {LoginService} from "../../services/login/login.service";

@Component({
  selector: 'app-two-factor-sign-up',
  templateUrl: './two-factor-sign-up.component.html',
  styleUrls: ['./two-factor-sign-up.component.scss']
})
export class TwoFactorSignUpComponent implements OnInit {

  public signUpForm: FormGroup;
  private emailRegex: string = '^\\S+@\\S+\\.\\S{2,4}$';

  constructor(private handlingService: HandlingService,
              private formBuilder: FormBuilder,
              public rolesService: RolesService,
              public loadingService: LoadingService,
              private route: Router) {
    this.signUpForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]]
    })
  }

  ngOnInit(): void {
  }

  public submitForm(){
    let info = {
      firstName: this.firstName?.value,
      lastName: this.lastName?.value,
      email: this.email?.value
    }
    //todo call email service
    this.route.navigate(['']).then(() => {});
    this.handlingService.success('Successfully sent two-factor sign up request. Please await the confirmation email with further instructions');
  }

  public get firstName() {
    return this.signUpForm.get('firstName');
  }
  public get lastName() {
    return this.signUpForm.get('lastName');
  }
  public get email() {
    return this.signUpForm.get('email');
  }

}
