import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {PlayersReport} from "../../../models/reports/players/players-report.model";
import {ReportsService} from "../../../services/reports/reports.service";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {ExcelService} from "../../../services/excel/excel.service";
import {CompanySimple} from "../../../models/configurations/company-simple.interface";
import {Router} from "@angular/router";
import {PlayersReportSettings} from "../../../models/reports/players/players-report-settings.interface";
import {PlayersReportOrderBy} from "../../../models/reports/players/players-report-order-by.enum";
import {PlayersReportPromoCredits} from "../../../models/reports/players/players-report-promo-credits.enum";
import {PlayersReportFreeTickets} from "../../../models/reports/players/players-report-free-tickets.enum";
import {UnmappedPlayersReport} from "../../../models/reports/players/unmapped-players-report.interface";
import {MatDialog} from "@angular/material/dialog";
import {PlayerReportSettingsDialogComponent} from "./player-report-settings-dialog/player-report-settings-dialog.component";
import {RolesService} from "../../../services/roles/roles.service";
import {RoleState} from "../../../models/roles/role-state.enum";
import {UtilsService} from "../../../services/utils/utils.service";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-players-report',
  templateUrl: './players-report.component.html',
  styleUrls: ['./players-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PlayersReportComponent implements OnInit {

  public dataSource!: MatTableDataSource<PlayersReport>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  public companies: CompanySimple[] = [];
  public company: CompanySimple | null = null;
  public displayCompany: string = 'Company';

  public settings: PlayersReportSettings;

  public showTable = false;
  public columns: string[] = [];
  public translationColumns: string[] = [];

  public expandedElement: PlayersReport | null = null;

  public startDate: string | null = null;
  public endDate: string | null  = null;

  public noCompanies = false;
  public oneCompany = false;

  constructor(private reportsService: ReportsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private excelService: ExcelService,
              private rolesService: RolesService,
              private utilsService: UtilsService,
              private dialog: MatDialog) {
    this.translationColumns = reportsService.getPlayersReportTranslationColumns();

    this.startDate = utilsService.getFirstDateInMonth();
    this.endDate = utilsService.getTodaysDate();

    this.settings = this.initSettings(this.startDate, this.endDate);

    this.fetchCompanies(this.startDate, this.endDate);
  }

  ngOnInit(): void {
  }

  private fetchCompanies(start: string, end: string): void {
    this.loadingService.setLoadingSteps(1);
    this.reportsService.getActiveCompanies(start, end).subscribe(value => {
      this.companies = value;
      if (this.checkCompanyCount(value)) {
        if(this.rolesService.checkRole(RoleState.OWNER)){
          return this.handlingService.error("There aren't any active companies to display players report from");
        }else{
          return this.handlingService.error("You are not a part of any active company");
        }
      }
      this.setCompany(this.companies[0]);
      this.fetchReports();
    }, error => {
      this.handlingService.error('Error while fetching active companies', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  private fetchReports(): void {
    this.reportsService.getPlayersReport(this.settings).subscribe(data => {
      this.dataSource = new MatTableDataSource(this.mapData(data));
      this.dataSource.sort = this.sort;

      if (this.dataSource.data.length === 0){
        this.hideReports();
      }else{
        this.showReports();
      }

    }, error => {
      this.handlingService.error('Error while fetching players report data', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  public refreshData(): void {
    if (this.startDate && this.endDate && this.settings.companyId !== -1){
      if (this.startDate > this.endDate) {
        [this.startDate, this.endDate] = [this.endDate, this.startDate];
      }
      this.fetchReports();
    }else {
      this.handlingService.error('Please make sure start, end date and company are selected');
    }
  }

  private mapData(unmapped: UnmappedPlayersReport[]): PlayersReport[]{
    let data: PlayersReport[] = [];
    unmapped.forEach(item => {
      data.push(
        new PlayersReport(
          item.ID,
          item.Username,
          item.FirstName,
          item.LastName,
          item.SessionsCount,
          item.Bets,
          item.StakeEUR,
          item.WinEUR,
          item.GGR));
    })
    return data;
  }

  private initSettings(start: string, end: string): PlayersReportSettings {
    return {
      companyId: -1,
      fromDate: start,
      toDate: end,
      orderBy: PlayersReportOrderBy.SESSION_COUNT,
      minTickets: 0,
      topResults: 50,
      promoCredits: PlayersReportPromoCredits.DM_PROMO_CREDITS,
      freeTickets: PlayersReportFreeTickets.DM_FREE_TICKETS
    }
  }

  public openSettings(): void {
    this.dialog.open(PlayerReportSettingsDialogComponent, {
      width: '505px',
      data: this.settings,
      autoFocus: false
    });
  }

  public setCompany(company: any){
    this.settings.companyId = company === null ? -1 : company.CompanyID;
    this.displayCompany = company === null ? 'Company' : company.CompanyID;
    this.company = company;
  }

  public setStartDate(date: string | null): void {
    this.startDate = date;
    if (date){
      this.settings.fromDate = date;
    }
  }
  public setEndDate(date: string | null): void {
    this.endDate = date;
    if (date){
      this.settings.toDate = date;
    }
  }

  public downloadExcel(): void {
    let data = this.reportsService.playersReportExcelMapper(this.dataSource.data);
    const name = 'Players report - ' + this.utilsService.getTodaysDate();
    this.excelService.exportAsExcelFile(data, name);
  }

  private showReports(): void {
    if (this.columns.length === 0){
      this.columns = this.dataSource.data[0].getFields();
    }
    this.showTable = true;
  }

  private hideReports(): void {
    this.showTable = false;
    this.dataSource = new MatTableDataSource<PlayersReport>([]);
  }

  public alignHeaderCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].align(column);
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }

  private checkCompanyCount(companies: CompanySimple[]): boolean {
    if (companies.length > 0){
      if (companies.length === 1){
        this.oneCompany = true;
      }
      return false;
    }else{
      this.noCompanies = true;
      return true;
    }
  }

}
