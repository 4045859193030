<div (click)="messageClicked(item)" class="message-item" [ngStyle]="{'width':msgSize}"
     [class.chat-room-select]="item?.messageId === selected?.messageId">
  <div class="row">
    <p class="min-width-p" style="font-size: 13px;">{{item.username}}</p>
  </div>
  <div class="row">
    <p class="min-width-p" *ngIf="!specialMessage">{{item.message}}</p>
    <mat-icon class="gif-icon" *ngIf="specialMessage">gif</mat-icon>
  </div>
  <div class="row d-flex flex-row-reverse justify-content-between" *ngIf="messageType === 'message'">
    <p class="w-max min-width-p" style="text-align: end; font-size: 12px;" *ngIf="!sendingState">{{time}}</p>
    <p class="w-max min-width-p" style="text-align: end; font-size: 12px;" *ngIf="sendingState">Sending...</p>
    <u class="w-max" (click)="translateMessage()" *ngIf="!translated && !specialMessage">Translate</u>
    <u class="w-max" (click)="showOriginal()" *ngIf="translated && !specialMessage">Hide translation</u>
  </div>
  <div class="row d-flex flex-row-reverse justify-content-between" *ngIf="messageType === 'profane'">
    <mat-icon class="w-min" *ngIf="!profane" style="font-size: 24px;">gpp_good</mat-icon>
    <mat-icon class="w-min" color="warn" *ngIf="profane" style="font-size: 24px;">gpp_bad</mat-icon>
    <u class="w-max mt-1" (click)="translateMessage()" *ngIf="!translated && !specialMessage">Translate</u>
    <u class="w-max mt-1" (click)="showOriginal()" *ngIf="translated && !specialMessage">Hide translation</u>
  </div>
  <div class="row d-flex" *ngIf="translated">
    <p class="min-width-p" *ngIf="!specialMessage && translated">{{translatedMessage}}</p>
  </div>
</div>
