import {ReportColumns} from "../reports/report-columns.interface";

export class PlayerAuditDetails {
  ticketId: number
  source: string
  id: number
  activity: string
  activityTime: string

  columnInfo: ReportColumns[]

  constructor(ticketId: number, source: string, id: number, activity: string, activityTime: string) {
    this.ticketId = ticketId;
    this.source = source;
    this.id = id;
    this.activity = activity;
    this.activityTime = activityTime;

    this.columnInfo = [
      {key: 'Ticket ID', info: {field: 'ticketId', total: this.ticketId, format: this.ticketId, align: 'normal'}},
      {key: 'Source', info: {field: 'source', total: this.source, format: this.formatString(source), align: 'normal'}},
      {key: 'Id', info: {field: 'id', total: this.id, format: this.id, align: 'normal'}},
      {key: 'Activity', info: {field: 'activity', total: this.activity, format: this.formatString(this.activity), align: 'normal'}},
      {key: 'Activity time', info: {field: 'activityTime', total: this.activityTime, format: this.formatString(this.activityTime), align: 'normal'}},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatString(string: string): string {
    return string;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }
}
