import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RolesService} from "../../services/roles/roles.service";
import {Router} from "@angular/router";
import {LoginService} from "../../services/login/login.service";
import {HandlingService} from "../../services/global-handling/handling.service";
import {LoadingService} from "../../services/loading/loading.service";
import {ConfigurationsService} from "../../services/configurations/configurations.service";
import {Credentials} from "../../models/user/credentials.model";
import {ThemeService} from "../../services/theme/theme.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;

  public hidePass: boolean = true;

  constructor(private handlingService: HandlingService,
              public configurationService: ConfigurationsService,
              private formBuilder: FormBuilder,
              public rolesService: RolesService,
              public loadingService: LoadingService,
              private route: Router,
              private loginService: LoginService,
              private themeService: ThemeService,) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    })
    localStorage.removeItem('reload');
    this.autoLogin();
  }

  ngOnInit(): void {
  }

  public submitForm(): void {
    if (this.loginForm.invalid){ return; }
    let device = localStorage.getItem('device');
    if (!device){ device = ''; }

    this.loadingService.setLoadingSteps(1);
    this.loginService.login(
      device,
      this.loginForm.get('username')?.value,
      this.loginForm.get('password')?.value).
        subscribe(credentials => {
      this.navigateUser(credentials);
    },() => {
      this.handlingService.error('Incorrect username or password')
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  private autoLogin(): void {
    this.loadingService.setLoadingSteps(1);

    let token = localStorage.getItem('token');
    let device = localStorage.getItem('device');
    if (!token){ return; }
    if (!device){ device = ''; }

    this.loginService.autoLogin(token, device).subscribe(value => {
      this.navigateUser(value);
    }, () => {
      localStorage.removeItem('token');
      return;
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  private navigateUser(credentials: Credentials): void {
    if(localStorage.getItem('token') === null){
      localStorage.setItem('token', credentials.token);
    }
    localStorage.setItem('device', credentials.info);

    if (credentials.authRequired){
      this.loginService.secretCode = credentials.secretCode;
      this.loginService.username = credentials.username;
      this.loginService.twoFactorNeeded = true;
      this.route.navigate(['/post-login']).then(() => {})
    }else{
      this.loginService.twoFactorPassed = true;
      this.route.navigate(['/home']).then(() => {})
      this.configurationService.setCredentials(credentials);
    }
  }

  public get username() {
    return this.loginForm.get('username');
  }
  public get password() {
    return this.loginForm.get('password');
  }

}
