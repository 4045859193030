import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors, ValidatorFn } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { ConfigUser } from "../../../../models/configurations/user-config/config-user.model";
import { ConfigurationsService } from "../../../../services/configurations/configurations.service";
import { RolesService } from "../../../../services/roles/roles.service";
import { CompanySimple } from "../../../../models/configurations/company-simple.interface";
import { HandlingService } from "../../../../services/global-handling/handling.service";


export const usernamePasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const username = control.get('username')?.value;
  const password = control.get('password') as FormControl;

  if (username && password.value && username === password.value) {
    // Set the error directly on the password control
    password.setErrors({ usernamePasswordMatch: true });
    return null;  // Return null for the form group error
  } else {
    // If validation passes, clear the error from the password control
    if (password.hasError('usernamePasswordMatch')) {
      password.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
    return null;
  }
};

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {


  private emailRegex: string = '^\\S+@\\S+\\.\\S{2,4}$';

  public hidePass: boolean = false;
  public addingForm: FormGroup;
  public companies: CompanySimple[] = [];
  public roles: string[] = [];

  constructor(private dialogRef: MatDialogRef<AddUserDialogComponent>,
              private formBuilder: FormBuilder,
              private configurationsService: ConfigurationsService,
              private rolesService: RolesService,
              private handlingService: HandlingService) {

    configurationsService.getSimpleCompanies().subscribe(newData => {
      this.companies = newData;
    }, error => {
      handlingService.error('Error fetching available companies', error);
    });

    this.roles = rolesService.getAvailableRoles();

    this.addingForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9\\W]).{8,}$')]],
      company: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      comment: [''],
      role: ['', Validators.required],
    }, { validators: usernamePasswordValidator });

    this.addingForm.get('role')?.valueChanges.subscribe(value => {
      this.addingForm.get('company')?.updateValueAndValidity();
    })
  }

  ngOnInit(): void {
  }

  public submitForm() {
    if (this.addingForm.invalid) { return; }
    let newUser = new ConfigUser(0,
      this.addingForm.get('username')?.value,
      this.addingForm.get('password')?.value,
      this.addingForm.get('email')?.value,
      this.addingForm.get('comment')?.value,
      false,
      this.addingForm.get('company')?.value.CompanyID,
      this.addingForm.get('company')?.value.CompanyName,
      this.addingForm.get('role')?.value,
      false);

    let selectedRole = this.addingForm.get('role')?.value;
    if (selectedRole === 'Admin' && selectedRole === 'Support') {
      newUser.isUsing2FA = true;
    }

    this.dialogRef.close({ data: newUser });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public get username() {
    return this.addingForm.get('username');
  }
  public get password() {
    return this.addingForm.get('password');
  }
  public get company() {
    return this.addingForm.get('company');
  }
  public get email() {
    return this.addingForm.get('email');
  }
  public get comment() {
    return this.addingForm.get('comment');
  }
  public get role() {
    return this.addingForm.get('role');
  }
  public get passwordQuestion() {
    return this.addingForm.get('passwordQuestion');
  }
  public get passwordAnswer() {
    return this.addingForm.get('passwordAnswer');
  }

}

function requiredIfValidator(predicate: any) {
  return ((formControl: any) => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return Validators.required(formControl);
    }
    return null;
  })
}
