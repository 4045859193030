<form [formGroup]="form" (ngSubmit)="submitForm()">
  <div class="container pb-2">
    <div class="row">
      <div class="flex-container">
        <h2 mat-dialog-title style="font-size: 24px; font-weight: lighter;">{{ 'chatRooms.sendToCompanyDialog.title' | transloco }}</h2>
      </div>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="container" style="overflow-y: hidden">
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
          <mat-label>{{ 'chatRooms.sendToCompanyDialog.company' | transloco }}</mat-label>
          <mat-select formControlName="company">
            <mat-option [value]="company" *ngFor="let company of companies">
              {{company.CompanyName}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="company!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Company' } }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row my-0 justify-content-center">
        <mat-form-field appearance="outline" style="font-size: 16px;">
          <mat-label>{{ 'chatRooms.sendToCompanyDialog.message' | transloco }}</mat-label>
          <textarea matInput class="message-area" formControlName="message" type="text"></textarea>
          <mat-error *ngIf="message!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Message' } }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="container mb-2">
    <div mat-dialog-actions class="d-flex flex-row-reverse w-100">
      <button class="px-4 ms-2" type="submit" mat-flat-button color="primary" [disabled]="!form.valid">{{ 'buttons.sendButton' | transloco }}</button>
      <button class="px-3" type="button" mat-stroked-button color="accent" (click)="onNoClick()">{{ 'buttons.cancelButton' | transloco }}</button>
    </div>
  </div>
</form>
