import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UtilsService} from "../../../services/utils/utils.service";

@Component({
  selector: 'app-dashboard-card2',
  templateUrl: './dashboard-card2.component.html',
  styleUrls: ['./dashboard-card2.component.scss']
})
export class DashboardCard2Component implements OnInit, OnChanges {

  @Input('data') data: any = '0.00';
  @Input('title') title: string = '';
  @Input('iconClass') iconClass: string = '';
  public dataSize = {'font-size': '20px'};

  constructor(private utilsService: UtilsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data === null){
      this.data = this.formatData(0);
      this.dataSize["font-size"] = this.fontSize();
    }else{
      this.data = this.formatData(this.data);
      this.dataSize["font-size"] = this.fontSize();
    }
  }

  ngOnInit(): void {
  }

  /*
    let len = this.data.length
    if len === 10 or less -> 24px
    if len === 12 -> 22px
    if len === 13 -> 20px
    if len === 14 -> 18px
   */
  public fontSize(): string {
    let result = '24px'
    if (this.data){
      let pixel = 24;
      let len = this.data.length
      if (len > 10) {
        pixel = pixel - (2 * (len - 11) + 1);
      }
      result = pixel + 'px';
    }
    return result;
  }

  private formatData(number: number): any {
    if (this.title){
      if (this.title === 'Bets' || this.title === 'Players'){
        return this.utilsService.formatInteger(number);
      }else{
        return this.data === null ? number + '.00' : this.utilsService.formatMoney(number);
      }
    }else{
      return number;
    }
  }

}
