import {Component, OnChanges, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {ConfigurationsService} from "../../../../../services/configurations/configurations.service";
import {HandlingService} from "../../../../../services/global-handling/handling.service";
import {LoadingService} from "../../../../../services/loading/loading.service";
import {LocalizationService} from "../../../../../services/localization/localization.service";
import {MatDialog} from "@angular/material/dialog";
import {AddMeteorShowerDialogComponent} from "../add-meteor-shower-dialog/add-meteor-shower.component";
import {EditMeteorShowerDialogComponent} from "../edit-meteor-shower-dialog/edit-meteor-shower.component";
import {ConfigMeteor} from "../../../../../models/reports/bonusing/meteors-config/config-meteors.model";

@Component({
  selector: 'app-meteor-shower-table',
  templateUrl: './meteor-shower-table.component.html',
  styleUrls: ['./meteor-shower-table.component.scss']
})
export class MeteorShowerTableComponent implements OnInit, OnChanges {
  public columns: string[] = [];
  public translationColumns: string[] = [];
  public selected: ConfigMeteor | null = null;

  @ViewChild(MatTable) table!: MatTable<ConfigMeteor>;
  public dataSource!: MatTableDataSource<ConfigMeteor>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(private configurationsService: ConfigurationsService, private handlingService: HandlingService, private loadingService: LoadingService, localizationService: LocalizationService, private dialog: MatDialog) {
    this.translationColumns = localizationService.getMeteorConfigurationsTableTranslations();
    this.columns = [];

    this.refreshData();
  }

  ngOnInit(): void {}

  ngOnChanges() {}

  public addMeteor(): void {
    this.openAddingDialog();
  }

  public editMeteor(): void {
    if(this.selected){
      this.openEditingDialog();
    }else{
      this.handlingService.error('Please select a meteor');
    }
  }

  public deleteMeteor(): void {
    confirm("Are you sure you want to delete meteor?");

    if(this.selected){
      this.configurationsService.deleteMeteor(this.selected).subscribe(() => {
        this.handlingService.success('Meteor successfully deleted');

        this.refreshData()
        this.table.renderRows();
      },error => {
        this.handlingService.error('Error while delete meteor', error);
      });
    }else{
      this.handlingService.error('Please select a meteor');
    }

  }

  public selectMeteor(meteor: ConfigMeteor): void {
    this.selected = meteor;
  }

  public refreshData(): void {
    this.selected = null;
    // this.loadingService.setLoadingSteps(1);

    this.configurationsService.getMeteors().subscribe(newData => {
      const mapped = this.configurationsService.meteorInputMapper(newData);

      this.dataSource = new MatTableDataSource<ConfigMeteor>(mapped);
      this.dataSource.sort = this.sort;

      if(mapped.length > 0){
        this.columns = this.dataSource.data[0].getFields();
      }else{
        this.columns = [];
      }
    },
        error => this.handlingService.error('Error while fetching meteors', error),
      () => this.loadingService.incrementLoadingSteps())
  }

  public deselectAll(): void {
    this.selected = null;
  }

  private openEditingDialog(): void {
    const dialogRef = this.dialog.open(EditMeteorShowerDialogComponent, {
      width: '505px',
      data: this.selected,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        delete result.data.NextTriggerTime;

        this.configurationsService.updateMeteor(result.data).subscribe(() => {
          this.handlingService.success('Meteor successfully updated');
          },
          error => {
          this.handlingService.error('Error while updating meteor', error)
          },
          () => {
          this.refreshData()
        });
      }
    })
  }

  private openAddingDialog(): void {
    const dialogRef = this.dialog.open(AddMeteorShowerDialogComponent, {
      width: '505px',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.configurationsService.addMeteor(result.data).subscribe(() => {
          // this.dataSource.data.push(result.data);
          // this.dataSource.sort = this.sort;
          this.refreshData()
          this.handlingService.success('Meteore successfully added');
        },error => {
          this.handlingService.error('Error while adding user', error);
        });
      }
    })
  }

  public alignHeaderCell(column: string): string {
    return this.dataSource.data[0].align(column);
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }
}
