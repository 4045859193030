import {Component, OnInit} from '@angular/core';
import {RolesService} from "../../../../services/roles/roles.service";
import {RoleState} from "../../../../models/roles/role-state.enum";
import {HandlingService} from "../../../../services/global-handling/handling.service";
import {ConfigurationsService} from "../../../../services/configurations/configurations.service";
import {ConfigCompany} from "../../../../models/configurations/company-config/overview/config-company.model";
import {LoadingService} from "../../../../services/loading/loading.service";

@Component({
  selector: 'app-company-config',
  templateUrl: './company-config.component.html',
  styleUrls: ['./company-config.component.scss']
})
export class CompanyConfigComponent implements OnInit {

  public owner: boolean = false;
  public admin: boolean = false;
  public companies: ConfigCompany[] = [];

  constructor(private rolesService: RolesService,
              private configurationsService: ConfigurationsService,
              private errorHandlingService: HandlingService,
              private loadingService: LoadingService) {
    if (rolesService.checkRole(RoleState.SUPPORT)){
      this.owner = true;
    }else{
      this.admin = true;
    }
    loadingService.setLoadingSteps(1);

    configurationsService.getCompanies().subscribe(newData => {
      this.companies = configurationsService.companyInputMapper(newData);
    },error => {
      errorHandlingService.error('Error while fetching companies', error);
    }, () => {
      loadingService.incrementLoadingSteps();
    })
  }

  ngOnInit(): void {
  }

}
