<table mat-table [dataSource]="summaryReportDetails" class="mat-elevation-z8">

  <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
    <th mat-header-cell class="child-header-cell" *matHeaderCellDef [ngStyle]="{'text-align': alignHeaderCell(column)}"> {{ translationColumns[i] | transloco }} </th>
    <td mat-cell class="child-table-cell" *matCellDef="let element" [ngStyle]="{'text-align': element.align(column)}"> {{element.format(column)}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"></tr>
</table>
