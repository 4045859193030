import {ReportColumns} from "../report-columns.interface";

export class PromoPlayerReport {
  playerId: number
  username: string
  promoCreditsCount: number
  promoCreditsAmount: number
  freeBetsCount: number
  freeBetsMoney: number
  jackpotsCount: number
  jackpotsMoney: number
  tournamentsCount: number
  tournamentsMoney: number
  totalBonus: number
  currencyId: string
  totalData: number

  columnInfo: ReportColumns[]

  constructor(playerId: number, username: string, promoCreditsCount: number, promoCreditsAmount: number, freeBetsCount: number, freeBetsMoney: number, jackpotsCount: number, jackpotsMoney: number, tournamentsCount: number, tournamentsMoney: number, totalBonus: number, currencyId: string, totalData: number) {
    this.playerId = playerId;
    this.username = username;
    this.promoCreditsCount = promoCreditsCount;
    this.promoCreditsAmount = promoCreditsAmount;
    this.freeBetsCount = freeBetsCount;
    this.freeBetsMoney = freeBetsMoney;
    this.jackpotsCount = jackpotsCount;
    this.jackpotsMoney = jackpotsMoney;
    this.tournamentsCount = tournamentsCount;
    this.tournamentsMoney = tournamentsMoney;
    this.totalBonus = totalBonus;
    this.currencyId = currencyId;
    this.totalData = totalData;

    this.columnInfo = [
      {key: 'Player ID', info: {field: 'playerId', total: this.playerId, format: this.playerId, align: 'normal'}},
      {key: 'Username', info: {field: 'username', total: this.username, format: this.formatString(this.username), align: 'normal'}},
      {key: 'Promo credits count', info: {field: 'promoCreditsCount', total: this.promoCreditsCount, format: this.formatInteger(this.promoCreditsCount), align: 'end'}},
      {key: 'Promo credits amount', info: {field: 'promoCreditsAmount', total: this.promoCreditsAmount, format: this.formatMoney(this.promoCreditsAmount), align: 'end'}},
      {key: 'Free bets count', info: {field: 'freeBetsCount', total: this.freeBetsCount, format: this.formatInteger(this.freeBetsCount), align: 'end'}},
      {key: 'Free bets money', info: {field: 'freeBetsMoney', total: this.freeBetsMoney, format: this.formatMoney(this.freeBetsMoney), align: 'end'}},
      {key: 'Jackpot count', info: {field: 'jackpotsCount', total: this.jackpotsCount, format: this.formatInteger(this.jackpotsCount), align: 'end'}},
      {key: 'Jackpot money', info: {field: 'jackpotsMoney', total: this.jackpotsMoney, format: this.formatMoney(this.jackpotsMoney), align: 'end'}},
      {key: 'Tournaments count', info: {field: 'tournamentsCount', total: this.tournamentsCount, format: this.formatInteger(this.tournamentsCount), align: 'end'}},
      {key: 'Tournaments money', info: {field: 'tournamentsMoney', total: this.tournamentsMoney, format: this.formatMoney(this.tournamentsMoney), align: 'end'}},
      {key: 'Total bonus', info: {field: 'totalBonus', total: this.totalBonus, format: this.formatMoney(this.totalBonus), align: 'end'}},
      {key: 'Currency ID', info: {field: 'currencyId', total: this.currencyId, format: this.formatString(this.currencyId), align: 'center'}},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public total(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.total;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatString(string: string): string {
    return string;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }
}
