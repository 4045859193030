import {ReportColumns} from "../reports/report-columns.interface";
import {TournamentCompany} from "./tournament-company.model";
import {TournamentAward} from "./tournament-award.model";

export class Tournament {
  id: number
  tournamentName: string
  currencyId: string
  fromDateTime: string
  toDateTime: string
  tournamentType: string
  minBet: number
  tournamentStatusNumber: number
  tournamentStatus: string
  tournamentDescription: string
  showBefore: number

  columnInfo: ReportColumns[]

  constructor(id: number,
              tournamentName: string,
              currencyId: string,
              fromDateTime: string,
              toDateTime: string,
              tournamentType: string,
              minBet: number,
              tournamentStatusNumber: number,
              tournamentStatus: string,
              tournamentDescription: string,
              showBefore: number) {
    this.id = id;
    this.tournamentName = tournamentName;
    this.currencyId = currencyId;
    this.fromDateTime = fromDateTime;
    this.toDateTime = toDateTime;
    this.tournamentType = tournamentType;
    this.minBet = minBet;
    this.tournamentStatusNumber = tournamentStatusNumber;
    this.tournamentStatus = tournamentStatus;
    this.tournamentDescription = tournamentDescription;
    this.showBefore = showBefore;

    this.columnInfo = [
      {key: 'ID', info: {field: 'id', total: this.id, format: this.id, align: 'normal'}},
      {key: 'Tournament name', info: {field: 'tournamentName', total: this.tournamentName, format: this.formatString(this.tournamentName), align: 'normal'}},
      {key: 'Currency', info: {field: 'currencyId', total: this.currencyId, format: this.formatString(this.currencyId), align: 'center'}},
      {key: 'From date', info: {field: 'fromDateTime', total: this.fromDateTime, format: this.formatString(this.fromDateTime), align: 'normal'}},
      {key: 'To date', info: {field: 'toDateTime', total: this.toDateTime, format: this.formatString(this.toDateTime), align: 'normal'}},
      {key: 'Tournament type', info: {field: 'tournamentType', total: this.tournamentType, format: this.formatString(this.tournamentType), align: 'normal'}},
      {key: 'Min bet', info: {field: 'minBet', total: this.minBet, format: this.formatMoney(this.minBet), align: 'center'}},
      {key: 'Tournament status', info: {field: 'tournamentStatus', total: this.tournamentStatus, format: this.formatString(this.tournamentStatus), align: 'normal'}},
      {key: 'Tournament description', info: {field: 'tournamentDescription', total: this.tournamentDescription, format: this.formatString(this.tournamentDescription), align: 'normal'}},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public total(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.total;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatString(string: string): string {
    return string;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }
}
