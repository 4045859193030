import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ConfigUser} from "../../../../models/configurations/user-config/config-user.model";
import {ConfigurationsService} from "../../../../services/configurations/configurations.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HandlingService} from "../../../../services/global-handling/handling.service";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnChanges {

  @Input('user') user!: ConfigUser;
  public profileForm: FormGroup;

  constructor(private configurationsService: ConfigurationsService,
              private handlingService: HandlingService,
              private formBuilder: FormBuilder) {

    this.profileForm = this.formBuilder.group({
      username: [this.user?.username, [Validators.required, Validators.minLength(3)]],
      email: [this.user?.email, [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(){
    if(this.user){
      this.resetForm();
    }
  }

  public submitForm(){
    if (this.profileForm.invalid){ return this.resetForm(); }
    this.user.username = this.profileForm.get('username')?.value;
    this.user.email = this.profileForm.get('email')?.value;
    this.configurationsService.updateUser(this.user).subscribe(() => {
      this.handlingService.success('User successfully updated');
    },error => {
      this.handlingService.error('Error while updating user', error);
    });
  }

  public resetForm(){
    this.profileForm.get('username')?.setValue(this.user.username);
    this.profileForm.get('email')?.setValue(this.user.email);
  }

  public get username() {
    return this.profileForm.get('username');
  }
  public get email() {
    return this.profileForm.get('email');
  }

}
