import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChatRoomsService} from "../../../services/chat-rooms/chat-rooms.service";
import {ChatRoom} from "../../../models/chat-rooms/chat-room.interface";
import {CompanyMessageDialogComponent} from "../company-message-dialog/company-message-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ChatRoomUsernameDialogComponent} from "../chat-room-username-dialog/chat-room-username-dialog.component";

@Component({
  selector: 'app-chat-rooms',
  templateUrl: './chat-rooms.component.html',
  styleUrls: ['./chat-rooms.component.scss']
})
export class ChatRoomsComponent implements OnInit, OnDestroy {

  public chatRooms: ChatRoom[] = [];
  public currentChatRoom: ChatRoom | null = null;
  public messagePanelSize: string = '840px';
  public onlineCounter: number = 0;

  private sortBy: ChatRoomSortBy = ChatRoomSortBy.ONLINE;
  private sortDirection: ChatRoomSortDirection = ChatRoomSortDirection.DESC;

  public changeNameHover: boolean = false;
  public chatUsername: string = '';

  constructor(private chatRoomService: ChatRoomsService,
              private dialog: MatDialog) {
    chatRoomService.initChatroomUsername();
    chatRoomService.chatUsernameObs.subscribe(value => this.chatUsername = value);
    chatRoomService.initChatRooms();
  }

  ngOnInit(): void {
    this.chatRoomService.chatRoomsObs.subscribe(value => {
      this.chatRooms = this.sortChatRooms(value);
      this.onlineCounter = this.chatRooms.reduce((previousValue, currentValue) => previousValue + currentValue.online, 0);
    })
  }

  ngOnDestroy() {
    this.chatRoomService.closeSocket();
  }

  public chatRoomChanged(room: ChatRoom | null): void {
    this.currentChatRoom = room;
  }

  public changeWidthSize(value: boolean): void {
    setTimeout(() => {
      this.messagePanelSize = value ? '1105px' : '840px';
    });
  }

  public aToZ() {
    this.sortBy = ChatRoomSortBy.NAME;
    this.sortDirection = ChatRoomSortDirection.ASC;
    this.chatRooms = this.sortChatRooms();
  }

  public zToA() {
    this.sortBy = ChatRoomSortBy.NAME;
    this.sortDirection = ChatRoomSortDirection.DESC;
    this.chatRooms = this.sortChatRooms();
  }

  public onlineAsc() {
    this.sortBy = ChatRoomSortBy.ONLINE;
    this.sortDirection = ChatRoomSortDirection.ASC;
    this.chatRooms = this.sortChatRooms();
  }

  public onlineDesc() {
    this.sortBy = ChatRoomSortBy.ONLINE;
    this.sortDirection = ChatRoomSortDirection.DESC;
    this.chatRooms = this.sortChatRooms();
  }

  private sortChatRooms(rooms?: ChatRoom[]) {
    rooms = rooms ? rooms : this.chatRooms;
    return rooms.sort((a,b) => {
      const item1 = this.sortBy === ChatRoomSortBy.NAME ? a.name.toLowerCase() : a.online;
      const item2 = this.sortBy === ChatRoomSortBy.NAME ? b.name.toLowerCase() : b.online;
      if (item1 > item2){
        return this.sortDirection === ChatRoomSortDirection.ASC ? 1 : -1;
      }
      if (item1 < item2) {
        return this.sortDirection === ChatRoomSortDirection.ASC ? -1 : 1;
      }
      return 0;
    })
  }

  public changeChatUsername() {
    const dialogRef = this.dialog.open(ChatRoomUsernameDialogComponent, {
      width: '300px',
      data: this.chatRoomService.getChatroomUsername(),
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.data){
        this.chatRoomService.setChatroomUsername(result.data);
      }
    })
  }

}

export enum ChatRoomSortBy {
  'NAME' = 'name',
  'ONLINE' = 'online'
}

export enum ChatRoomSortDirection {
  'ASC',
  'DESC'
}
