import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-goldmines-jackpot-config',
  templateUrl: './goldmines-jackpot-config.component.html',
  styleUrls: ['./goldmines-jackpot-config.component.scss']
})
export class GoldminesJackpotConfigComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
