<div class="container w-96 round-border">
  <p class="dashboard-title">{{ 'dashboard.dashboard5.title' | transloco: { date: date } }}</p>
  <div class="dashboard5" *ngIf="showChart | async">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [stroke]="chartOptions.stroke"
      [dataLabels]="chartOptions.dataLabels"
      [yaxis]="chartOptions.yaxis"
      [labels]="chartOptions.labels"
      [legend]="chartOptions.legend"
      [title]="chartOptions.title"
      [subtitle]="chartOptions.subtitle"
      [responsive]="chartOptions.responsive"
      [theme]="chartOptions.theme"
      [tooltip]="chartOptions.tooltip"
    ></apx-chart>
  </div>
</div>


