<div class="row mb-3">
  <div class="col">
    <div class="row d-flex justify-content-center mb-1">
      <mat-icon style="font-size: 40px; width: 40px; height: 40px; padding: 0">{{data.icon}}</mat-icon>
    </div>
    <div class="row d-flex justify-content-center">
      <h2 class="d-flex justify-content-center" mat-dialog-title style="font-size: 24px; font-weight: lighter; text-align: center">{{ data.title }}</h2>
    </div>
  </div>
</div>
<div class="row d-flex justify-content-center mb-3">
  <div class="col d-flex justify-content-center" mat-dialog-content>
    <p style="font-size: 14px; text-align: center">{{ data.message }}</p>
  </div>
</div>
<div mat-dialog-actions class="row pb-4">
  <div class="col d-flex justify-content-center">
    <button class="w-75" mat-raised-button color="warn" type="button" (click)="onOkayClick()">{{data.action}}</button>
  </div>
</div>


