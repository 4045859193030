import {Component, OnInit, ViewChild} from '@angular/core';
import {TournamentsService} from "../../../services/tournaments/tournaments.service";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {UnmappedSimpleTournament} from "../../../models/tournaments/unmapped/unmapped-simple-tournament.interface";
import {UnmappedTournamentResult} from "../../../models/tournaments/unmapped/unmapped-tournament-result.interface";
import {TournamentResult} from "../../../models/tournaments/tournament-result.model";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-tournament-results',
  templateUrl: './tournament-results.component.html',
  styleUrls: ['./tournament-results.component.scss']
})
export class TournamentResultsComponent implements OnInit {

  public tournaments: UnmappedSimpleTournament[] = [];
  public tournament: UnmappedSimpleTournament | null = null;
  public dataSource!: MatTableDataSource<TournamentResult>;
  public showTable = false;
  public columns: string[] = [];
  public translationColumns: string[] = [];
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(private tournamentsService: TournamentsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService) {
    this.translationColumns = this.tournamentsService.getTournamentResultTranslationColumns();
    this.getSimpleTournaments();
  }

  ngOnInit(): void {
  }

  public refreshData() {
    if (this.tournament){
      this.getTournamentResults(this.tournament.ID);
    }else {
      this.handlingService.error('Select a tournament');
    }
  }

  private getSimpleTournaments() {
    this.loadingService.setLoadingSteps(1);
    this.tournamentsService.getSimpleTournaments().subscribe(value => {
      this.tournaments = value;
      if (value.length > 0){
        this.tournament = value[0];
        this.getTournamentResults(this.tournament.ID);
      }
    }, error => {
      return this.handlingService.error('Error fetching tournaments', error);
    }, () => this.loadingService.incrementLoadingSteps())
  }

  private getTournamentResults(tournamentId: number) {
    this.loadingService.setLoadingSteps(1);
    this.tournamentsService.getTournamentResults(tournamentId).subscribe(value => {
      this.dataSource = new MatTableDataSource<TournamentResult>(this.mapTournamentResults(value));
      this.dataSource.sort = this.sort;
      if (this.dataSource.data.length === 0){
        this.hideReports();
      }else{
        this.showReports();
      }
    }, error => {
      return this.handlingService.error('Error fetching tournament results', error);
    }, () => this.loadingService.incrementLoadingSteps())
  }

  private mapTournamentResults(unmapped: UnmappedTournamentResult[]): TournamentResult[] {
    return unmapped.map(item => new TournamentResult(item.TournamentID, item.PlayerID, item.UserName, item.Place, item.PlaceAward, item.CurrencyID, item.Points, item.ExternalID));
  }

  private showReports(): void {
    if (this.columns.length === 0){
      this.columns = this.dataSource.data[0].getFields();
    }
    this.showTable = true;
  }

  private hideReports(): void {
    this.showTable = false;
    this.dataSource = new MatTableDataSource<TournamentResult>([]);
  }

  public setTournament(tournament: UnmappedSimpleTournament | null) {
    this.tournament = tournament;
  }

  public alignHeaderCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].align(column);
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }

}
