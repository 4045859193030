import {Component, OnInit, ViewChild} from '@angular/core';
import {CompanySimple} from "../../models/configurations/company-simple.interface";
import {OrderBy} from "../../models/utils/order-by.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../services/utils/utils.service";
import {ConfigurationsService} from "../../services/configurations/configurations.service";
import {HandlingService} from "../../services/global-handling/handling.service";
import {PromoManagementService} from "../../services/promo-management/promo-management.service";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {PromoManagement} from "../../models/configurations/promo-management/promo-management.model";
import {
  UnmappedPromoManagement
} from "../../models/configurations/promo-management/unmapped/unmapped-promo-management.interface";
import {LoadingService} from "../../services/loading/loading.service";
import {MatDialog} from "@angular/material/dialog";
import {PlayerInfoDialogComponent} from "./player-info-dialog/player-info-dialog.component";
import { MatIconRegistry } from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-promo-management',
  templateUrl: './promo-management.component.html',
  styleUrls: ['./promo-management.component.scss']
})
export class PromoManagementComponent implements OnInit {

  public dataSource!: MatTableDataSource<PromoManagement>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatTable, {static:true}) table!: MatTable<PromoManagement>;

  public showTable = false;
  public columns: string[] = [];
  public translationColumns: string[] = [];
  public expandedElement: PromoManagement | null = null;

  public companyList: CompanySimple[] = [];
  public orderByList: OrderBy[] = [];
  public searchForm: FormGroup;
  public promoCreditsForm: FormGroup;
  public freeBetsForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private configurationsService: ConfigurationsService,
              private handlingService: HandlingService,
              private utilsService: UtilsService,
              private loadingService: LoadingService,
              private promoManagementService: PromoManagementService,
              private dialog: MatDialog,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    this.matIconRegistry
      .addSvgIcon('crown', this.domSanitizer
      .bypassSecurityTrustResourceUrl('../../../assets/crown.svg'));

    this.orderByList = this.utilsService.getOrderByList();
    this.translationColumns = promoManagementService.getPromoManagementTranslations();

    configurationsService.getSimpleCompanies().subscribe(newData => {
      this.companyList = newData;
    },error => {
      handlingService.error('Error fetching available companies', error);
    });

    this.searchForm = this.formBuilder.group({
      company: [null, {validators: [Validators.required], initialValueIsDefault: true}],
      startDate: [utilsService.getFirstDateInMonth(), {validators: [Validators.required], initialValueIsDefault: true}],
      endDate: [utilsService.getTodaysDate(), {validators: [Validators.required], initialValueIsDefault: true}],
      orderBy: [this.orderByList[0], {validators: [Validators.required], initialValueIsDefault: true}],
      minTickets: [0, {validators: [Validators.required, Validators.min(0)], initialValueIsDefault: true}],
      top: [50, {validators: [Validators.required, Validators.min(1)], initialValueIsDefault: true}],
      playerId: [{value: null, disabled: false}],
      games: [{value: null, disabled: false}]
    });

    this.promoCreditsForm = this.formBuilder.group({
      amount: [0, [Validators.required, Validators.min(0)]],
      minCashout: [0, [Validators.required, Validators.min(0)]]
    });

    this.freeBetsForm = this.formBuilder.group({
      betCount: [0, [Validators.required, Validators.min(1)]],
      betAmount: [0, [Validators.required, Validators.min(0)]],
    })
  }

  ngOnInit(): void {
  }

  private fetchPromoManagementData() {
    if (this.searchForm.invalid) {
      return this.handlingService.error('Please fill out all required fields');
    }

    const company = this.searchForm.get('company')?.value;
    let startDate = this.searchForm.get('startDate')?.value;
    let endDate = this.searchForm.get('endDate')?.value;
    const orderBy = this.searchForm.get('orderBy')?.value;
    const minTickets = this.searchForm.get('minTickets')?.value;
    const top = this.searchForm.get('top')?.value;
    const playerId = this.searchForm.get('playerId')?.value;
    const games = this.searchForm.get('games')?.value;

    if (startDate > endDate) {
      [startDate, endDate] = [endDate, startDate];
      this.searchForm.get('startDate')?.setValue(startDate);
      this.searchForm.get('endDate')?.setValue(endDate);
    }

    this.loadingService.setLoadingSteps(1);
    this.promoManagementService.getPromoManagement(company.CompanyID, startDate, endDate, orderBy.orderByValue, minTickets, top, playerId, games)
      .subscribe(value => {
        this.dataSource = new MatTableDataSource(this.mapResponse(value));
        this.dataSource.sort = this.sort;
        if (this.dataSource.data.length === 0){
          this.hideReports();
        }else{
          this.showReports();
        }
      }, error => {
        this.handlingService.error('Error while fetching promo management data', error)
      }, () => {
        this.loadingService.incrementLoadingSteps();
      });
  }

  private mapResponse(unmapped: UnmappedPromoManagement[]): PromoManagement[]{
    let data: PromoManagement[] = [];
    unmapped.forEach(item => {
      data.push(this.mapData(item));
    })
    return data;
  }

  private mapData(unmapped: UnmappedPromoManagement): PromoManagement {
    return new PromoManagement(
      unmapped.ID,
      unmapped.Username,
      unmapped.FirstName,
      unmapped.LastName,
      unmapped.SessionsCount,
      unmapped.Bets,
      unmapped.StakeEUR,
      unmapped.WinEUR,
      unmapped.GGR,
      unmapped.GGRPerc,
      unmapped.PromoCredits,
      unmapped.PromoCurrencyID,
      unmapped.FreeTicketsCount,
      unmapped.FreeTicketsMoney,
      unmapped.FreeTicketsCurrencyID,
      unmapped.PlayerLevel,
      unmapped.MinCashoutMultiplierForBonus
    )
  }

  public playerIdChange(playerId: number | null) {
    if (playerId){
      this.searchForm.get('games')?.disable();
    }else{
      this.searchForm.get('games')?.enable();
    }
  }

  public gamesChange(games: number | null) {
    if (games){
      this.searchForm.get('playerId')?.disable();
    }else{
      this.searchForm.get('playerId')?.enable();
    }
  }

  public submitSearch() {
    this.fetchPromoManagementData();
  }

  public clearSearch() {
    this.searchForm.reset();
    this.setStartDate(this.utilsService.getFirstDateInMonth());
    this.setEndDate(this.utilsService.getTodaysDate());
  }

  public addPromoCredits() {
    if (!this.expandedElement){
      return this.handlingService.error('Please select the player');
    }
    if (this.promoCreditsForm.invalid){
      return this.handlingService.error('Please fill out all required fields');
    }
    if (this.expandedElement.promoCredits > 0){
      return this.handlingService.error('This player already has promo credits');
    }
    const promoCredits = this.promoCreditsForm.get('amount')?.value;
    const minCashout = this.promoCreditsForm.get('minCashout')?.value;
    this.promoManagementService.addPromoCredits(this.expandedElement.id, promoCredits, this.expandedElement.creditCurrency, minCashout)
      .subscribe(
        value => {
          this.expandedElement!.promoCredits = value[0].PromoCredits;
          this.expandedElement!.minCashout = value[0].MinCashoutMultiplierForBonus;
          this.refreshElement();
          this.table.renderRows();
        },
        error => this.handlingService.error("Error trying to add promo credits.", error));
  }

  public clearPromoCreditsForm() {
    this.promoCreditsForm.reset();
    if (this.expandedElement){
      this.promoCreditsForm.get('minCashout')?.setValue(this.expandedElement.minCashout);
    }else{
      this.promoCreditsForm.get('minCashout')?.setValue(0);
    }
  }

  public addFreeBets() {
    if (this.expandedElement) {
      if (this.freeBetsForm.valid) {
        const betCount = this.freeBetsForm.get('betCount')?.value;
        const betAmount = this.freeBetsForm.get('betAmount')?.value;
        this.promoManagementService.addFreeTickets(this.expandedElement.id, betCount, betAmount, this.expandedElement.creditCurrency)
          .subscribe(
            value => {
              this.expandedElement!.freeMoney = value[0].FreeTicketsMoney;
              this.expandedElement!.freeTickets = value[0].FreeTicketsCount;
              this.refreshElement();
            },
            error => this.handlingService.error("Error trying to add free bets.", error));
      } else {
        this.handlingService.error('Please fill out all required fields');
      }
    } else {
      this.handlingService.error('Please select the player');
    }
  }

  public clearFreeBetsForm() {
    this.freeBetsForm.reset();
  }

  public getPlayerInfo() {
    if (this.expandedElement) {
      this.openPlayerInfoDialog();
    } else {
      this.handlingService.error('Please select the player');
    }
  }

  private openPlayerInfoDialog() {
    this.dialog.open(PlayerInfoDialogComponent, {
      width: '1400px',
      height : 'auto',
      data: {
        element: this.expandedElement,
        dateFrom: this.startDate?.value,
        dateTo: this.endDate?.value,
      },
      autoFocus: false
    });
  }

  public setStartDate(date: string | null): void {
    this.searchForm.get('startDate')?.setValue(date);
  }
  public setEndDate(date: string | null): void {
    this.searchForm.get('endDate')?.setValue(date);
  }

  public get company() {return this.searchForm.get('company');}
  public get startDate() {return this.searchForm.get('startDate');}
  public get endDate() {return this.searchForm.get('endDate');}
  public get orderBy() {return this.searchForm.get('orderBy');}
  public get minTickets() {return this.searchForm.get('minTickets');}
  public get top() {return this.searchForm.get('top');}
  public get playerId() {return this.searchForm.get('playerId');}
  public get games() {return this.searchForm.get('games');}
  public get amount() {return this.promoCreditsForm.get('amount');}
  public get betCount() {return this.freeBetsForm.get('betCount');}
  public get betAmount() {return this.freeBetsForm.get('betAmount');}

  private showReports(): void {
    if (this.columns.length === 0){
      this.columns = this.dataSource.data[0].getFields();
    }
    this.showTable = true;
  }

  private hideReports(): void {
    this.showTable = false;
    this.dataSource = new MatTableDataSource<PromoManagement>([]);
  }

  public alignHeaderCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].align(column);
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }

  private refreshElement() {
    if (this.expandedElement) {
      const index = this.dataSource.data.indexOf(this.expandedElement);
      const oldElement = this.dataSource.data[index];
      const newElement = new PromoManagement(
        oldElement.id,
        oldElement.username,
        oldElement.firstName,
        oldElement.lastName,
        oldElement.sessions,
        oldElement.bets,
        oldElement.stakeEur,
        oldElement.winEur,
        oldElement.ggr,
        oldElement.percentage,
        oldElement.promoCredits,
        oldElement.creditCurrency,
        oldElement.freeTickets,
        oldElement.freeMoney,
        oldElement.freeCurrency,
        oldElement.playerLevel,
        oldElement.minCashout);
      this.dataSource.data.splice(index, 1);
      this.dataSource.data.splice(index, 0, newElement);
      this.dataSource.data = this.dataSource.data;
    }
  }

  public checkPlayerLevel(playerLevel: number) {
    return playerLevel === 2;
  }

  public makePlayerAVip() {
    if (this.expandedElement) {
      const id = this.expandedElement.id;
      this.promoManagementService.makePlayerAVip(id).subscribe(() => {
        if (!this.expandedElement) {
          const found = this.dataSource.data.find(item => item.id === id);
          this.expandedElement = found ? found : null;
        }
        if (this.expandedElement){
          this.expandedElement.playerLevel = 2;
        }
        this.dataSource.data = this.dataSource.data;
      }, error => {
        this.handlingService.error("Error occurred while promoting the player to VIP status", error);
      });
    }else{
      this.handlingService.error("Please select a player");
    }
  }

  public playerClicked(element: PromoManagement){
    this.expandedElement = this.expandedElement === element ? null : element;
    if (this.expandedElement){
      this.promoCreditsForm.get('minCashout')?.setValue(this.expandedElement.minCashout);
    }
  }
}
