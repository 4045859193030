import {Component, OnInit, ViewChild} from '@angular/core';
import {Chart3Data} from "../../../models/dashboard/chart3-data.model";
import {DashboardService} from "../../../services/dashboard/dashboard.service";
import {
  ApexChart,
  ChartComponent,
  ApexAxisChartSeries,
  ApexXAxis,
  ApexDataLabels,
  ApexPlotOptions, ApexTheme, ApexTooltip, ApexYAxis,
} from 'ng-apexcharts';
import {UnmappedDashboard3Data} from "../../../models/dashboard/unmapped/unmapped-dashboard3-data.interface";
import {LoadingService} from "../../../services/loading/loading.service";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {ThemeService} from "../../../services/theme/theme.service";

@Component({
  selector: 'app-dashboard-chart3',
  templateUrl: './dashboard-chart3.component.html',
  styleUrls: ['./dashboard-chart3.component.scss']
})
export class DashboardChart3Component implements OnInit {

  public showChart!: Promise<boolean>;

  public data!: Chart3Data
  @ViewChild('barChart') chart!: ChartComponent;
  public chartOptions!: ChartOptions


  public percentChange: {change: string, increase: boolean} = {change: '', increase: false};

  constructor(private dashboardService: DashboardService,
              private handlingService: HandlingService,
              public loadingService: LoadingService,
              private themeService: ThemeService) {

    themeService.themeObs.subscribe(newTheme => {
      if (this.chartOptions){
        if (newTheme !== 'light'){
          this.chartOptions.theme = themeService.getDarkTheme();
          this.chartOptions.tooltip = themeService.getDarkTooltip();
          this.chartOptions.xaxis.labels!.style!.colors = themeService.getDarkText();
          this.chartOptions.yaxis.labels!.style!.colors = themeService.getDarkText();
        }else{
          this.chartOptions.theme = themeService.getLightTheme();
          this.chartOptions.tooltip = themeService.getLightTooltip();
          this.chartOptions.xaxis.labels!.style!.colors = themeService.getLightText();
          this.chartOptions.yaxis.labels!.style!.colors = themeService.getLightText();
        }
      }
    })
  }

  private calculatePercentageChange(): any {
    if (this.data.stakePreviewMonth === 0 || this.data.stakePrediction === 0){
      return {change: '0%', increase: false};
    }
    let change = this.roundNumber(100 * ((this.data.stakePrediction - this.data.stakePreviewMonth)/this.data.stakePreviewMonth));
    if (change > 0) {
      return {change: '+'+change+'%', increase: true};
    }else {
      return {change: change+'%', increase: false};
    }
  }

  private roundNumber(number: number): number {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }

  ngOnInit(): void {
    this.dashboardService.getChart3Data().subscribe(newData => {
      this.data = this.mapData(newData);
      this.chartOptions = this.initOption();
      this.showChart = Promise.resolve(true);
      this.percentChange = this.calculatePercentageChange();
    },error => {
      this.handlingService.error('Trouble fetching data for dashboard 3', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    });
  }

  private mapData(unmapped: UnmappedDashboard3Data[]): Chart3Data {
    let formatter = new Intl.DateTimeFormat("en-US", { month: 'short', day: 'numeric' });
    return new Chart3Data(
      unmapped[0].StakePreviewMonth,
      unmapped[0].StakePrediction,
      formatter.format(new Date(unmapped[0].PreviewStartDate)),
      formatter.format(new Date(unmapped[0].PreviewEndDate)),
      formatter.format(new Date(unmapped[0].StartDate)),
      formatter.format(new Date(unmapped[0].EndDate)))
  }

  private initOption(): any {
    return {
      series: [
        {
          name: 'Projection',
          data: this.formatData()
        }
      ],
      chart: {
        type: 'bar',
        height: 510,
        width: '100%',
        offsetX: -5,
        animations: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      dataLabels: {
        enabled: true,
        formatter: (val: string | number | number[], opts?: any) => {
          return val.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
        }
      },
      tooltip: this.themeService.getDefaultTooltip(),
      xaxis: {
        categories: this.initLegend(),
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: false,
          style: {
            colors: this.themeService.getDefaultText()
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: this.themeService.getDefaultText()
          }
        }
      },
      theme: this.themeService.getDefaultTheme()
    }!
  }


  private formatData(): number[] {
    let ary: number[] = []

    if (this.isInitialized(this.data.stakePreviewMonth) &&
      this.isInitialized(this.data.stakePrediction)){
      ary.push(this.data.stakePreviewMonth, this.data.stakePrediction);
    }

    return ary;
  }

  private isInitialized(value: number): boolean {
    let result = false;
    if (value !== undefined && value !== null){
      result = true;
    }
    return result;
  }

  private initLegend(): any[] {
    let data: any[] = [];
    data.push(['Stake previous month', '(' + this.data.previewStartDate + ' - ' + this.data.previewEndDate + ')']);
    data.push(['Stake Prediction', '(' + this.data.startDate + ' - ' + this.data.endDate + ')']);
    return data;
  }

}

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  tooltip: ApexTooltip;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  theme: ApexTheme;
};
