import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ReportsService} from "../../../../services/reports/reports.service";
import {HandlingService} from "../../../../services/global-handling/handling.service";
import {CurrencyDetailsReport} from "../../../../models/reports/currency/currency-details-report.model";
import {
  UnmappedCurrencyDetailsReport
} from "../../../../models/reports/currency/unmapped/unmapped-currency-details-report.interface";

@Component({
  selector: 'app-currency-report-details',
  templateUrl: './currency-report-details.component.html',
  styleUrls: ['./currency-report-details.component.scss']
})
export class CurrencyReportDetailsComponent implements OnInit, OnChanges {

  @Input('dateFrom') dateFrom?: string | null;
  @Input('dateTo') dateTo?: string | null;
  @Input('elementCurrency') elementCurrency?: string | null;
  @Input('expandedCurrency') expandedCurrency?: string | null;

  public detailsReports: CurrencyDetailsReport[] = [];
  public columns: string[] = []
  public translationColumns: string[] = [];

  constructor(private reportsService: ReportsService,
              private handlingService: HandlingService) {
    this.translationColumns = reportsService.getCurrencyReportDetailsTranslationColumns();
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.elementCurrency === this.expandedCurrency){
      if(this.dateFrom && this.dateTo && this.elementCurrency){
        this.reportsService.getCurrencyDetailsReport(this.dateFrom, this.dateTo, this.elementCurrency).subscribe(newData => {
          this.detailsReports = this.mapData(newData);
          if(this.detailsReports[0] && this.columns.length === 0){
            this.columns = this.detailsReports[0].getFields();
          }
        },error => {
          this.handlingService.error('Error while fetching financial details report data', error);
        });
      }
    }
  }

  private mapData(unmapped: UnmappedCurrencyDetailsReport[]): CurrencyDetailsReport[]{
    let data: CurrencyDetailsReport[] = []
    unmapped.forEach(item => {
      data.push(
        new CurrencyDetailsReport(
          item.CompanyName,
          item.Bets,
          item.PlayerCount,
          item.Stake,
          item.StakeEUR,
          item.Wins,
          item.WinMoney,
          item.WinMoneyEUR,
          item.Result,
          item.ResultEUR,
          item.Perc))
    })
    return data;
  }

  public alignHeaderCell(column: string): string {
    return this.detailsReports[0].align(column);
  }

}
