import {Component, Inject, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfigurationsService} from "../../../../../services/configurations/configurations.service";
import {HandlingService} from "../../../../../services/global-handling/handling.service";
import {RolesService} from "../../../../../services/roles/roles.service";
import {RoleState} from "../../../../../models/roles/role-state.enum";
import {ConfigMeteor} from "../../../../../models/reports/bonusing/meteors-config/config-meteors.model";
import {Currency} from "../../../../../models/reports/exchange-rates/currency.model";
import {ReportsService} from "../../../../../services/reports/reports.service";
import {CompanySimple} from "../../../../../models/configurations/company-simple.interface";

@Component({
  selector: 'app-edit-meteor-shower-dialog',
  templateUrl: './edit-meteor-shower.component.html',
  styleUrls: ['./edit-meteor-shower.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '90px',
        visibility: 'visible'
      })),
      state('closed', style({
        height: '0px',
        visibility: 'hidden'
      })),
      transition('* => *', [
        animate('0.15s')
      ]),
    ]),
  ],
})
export class EditMeteorShowerDialogComponent implements OnInit {
  public editingMyself: boolean = false;
  public editingForm: FormGroup;
  public owner: boolean = false;
  public currencies : Currency[] = [];
  public companies: CompanySimple[] = [];

  // init
  public company:number = this.meteor.CompanyID;
  public currencyCurrency:string = this.meteor.Currency;

  constructor(private dialogRef: MatDialogRef<EditMeteorShowerDialogComponent>,
              reportService: ReportsService,
              configurationsService: ConfigurationsService,
              handlingService: HandlingService,
              private formBuilder: FormBuilder,
              rolesService: RolesService,
              @Inject(MAT_DIALOG_DATA) public meteor: ConfigMeteor) {


    configurationsService.getSimpleCompanies().subscribe(newData => this.companies = newData, error => handlingService.error('Error fetching available companies', error));
    reportService.getCurrencies().subscribe(newData => this.currencies = this.sortCurrencies(newData), error => handlingService.error('Error fetching available currencies', error));

    // @TODO
    // this.editingMyself = true;
    // if (configurationsService.getCredentials().username === user.username){
    //   this.editingMyself = true;
    // }

    this.owner = rolesService.checkRole(RoleState.OWNER);

    this.editingForm = this.formBuilder.group({
      id: [this.meteor.ID],
      companyId: [this.company, [Validators.required]],
      hoursActiveFrom: [this.meteor.HoursActiveFrom, [Validators.required,Validators.min(0)]],
      hoursActiveTo: [this.meteor.HoursActiveTo, [Validators.required,Validators.min(0)]],
      offHoursCoef: [this.meteor.OffHoursCoef, [Validators.required,Validators.min(0)]],
      name: [this.meteor.Name, Validators.required],
      currency: [this.currencyCurrency, [Validators.required,Validators.min(3)]],
      amount: [this.meteor.Amount, [Validators.required,Validators.min(0)]],
      players: [this.meteor.Players, [Validators.required,Validators.min(0)]],
      minutesMin: [this.meteor.MinutesMin, [Validators.required,Validators.min(0)]],
      minutesMax: [this.meteor.MinutesMax, [Validators.required,Validators.min(0)]],
    });
  }

  ngOnInit(): void {}

  public submitForm(){
    if (!this.editingForm.invalid) {
      let updatedMeteor = new ConfigMeteor(
        this.editingForm.get("id")?.value,
        this.editingForm.get("companyId")?.value,
        this.editingForm.get("hoursActiveFrom")?.value,
        this.editingForm.get("hoursActiveTo")?.value,
        this.editingForm.get("offHoursCoef")?.value,
        this.editingForm.get("name")?.value,
        this.editingForm.get("currency")?.value,
        this.editingForm.get("amount")?.value,
        this.editingForm.get("players")?.value,
        this.editingForm.get("minutesMin")?.value,
        this.editingForm.get("minutesMax")?.value,
        this.editingForm.get("nextTriggerTime")?.value
      );

      this.dialogRef.close({data: updatedMeteor});
    }


    // if(this.user.username !== this.editingForm.get('username')?.value ||
    //   this.user.companyName !== this.editingForm.get('company')?.value ||
    //   this.user.userRole !== this.editingForm.get('role')?.value){
    //   this.handlingService.error("That action isn't allowed!");
    //   return this.dialogRef.close();
    // }
    // let newPassword = this.editingForm.get('password')?.value.toString().trim();
    // this.user.password = newPassword.length > 0 ? newPassword : '';
    // this.user.email = this.editingForm.get('email')?.value;
    // this.user.comment = this.editingForm.get('comment')?.value;
    // this.user.isLockedOut = this.editingForm.get('lockedOut')?.value;
    //
    // this.user.resetSecretCode = !!(this.owner && this.editingForm.get('resetSecretCode')?.value);


  }

  public sortCurrencies(arr:Currency[]) {
    return arr.sort((a, b) => {
      if (a.CurrencyID < b.CurrencyID) return -1;
      if (a.CurrencyID > b.CurrencyID) return 1;
      return 0;
    });
  }

  public setCompany(company: { value: number; }){
    this.editingForm.patchValue({ companyId: company.value })
  }

  public setCurrency(currency:any){
    this.editingForm.patchValue({ currency: currency.value })
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public get companyId() {
    return this.editingForm.get('companyId');
  }

  public get hoursActiveFrom() {
    return this.editingForm.get('hoursActiveFrom');
  }

  public get hoursActiveTo() {
    return this.editingForm.get('hoursActiveTo');
  }

  public get offHoursCoef() {
    return this.editingForm.get('offHoursCoef');
  }

  public get name() {
    return this.editingForm.get('name');
  }

  public get currency() {
    return this.editingForm.get('currency');
  }

  public get amount() {
    return this.editingForm.get('amount');
  }

  public get players() {
    return this.editingForm.get('players');
  }

  public get minutesMin() {
    return this.editingForm.get('minutesMin');
  }

  public get minutesMax() {
    return this.editingForm.get('minutesMax');
  }
}
