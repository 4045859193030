import {Component, OnInit, ViewChild} from '@angular/core';
import {ReportsService} from "../../../services/reports/reports.service";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {PromoReport} from "../../../models/reports/promo/promo-report.model";
import {UnmappedPromoReport} from "../../../models/reports/promo/unmapped/unmapped-promo-report.interface";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ExcelService} from "../../../services/excel/excel.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {UtilsService} from "../../../services/utils/utils.service";

@Component({
  selector: 'app-promo-report',
  templateUrl: './promo-report.component.html',
  styleUrls: ['./promo-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PromoReportComponent implements OnInit {

  public dataSource!: MatTableDataSource<PromoReport>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  public showTable = false;
  public columns: string[] = [];
  public promoReports: PromoReport[] = [];
  public translationColumns: string[] = [];
  public expandedElement: PromoReport | null = null;

  public withTickets: boolean = true;
  public startDate: string | null = null;
  public endDate: string | null  = null;

  constructor(private reportsService: ReportsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private utilsService: UtilsService,
              private excelService: ExcelService) {
    this.translationColumns = reportsService.getPromoReportBottomTranslationColumns();

    this.startDate = utilsService.getFirstDateInMonth();
    this.endDate = utilsService.getTodaysDate();

    this.fetchReports(this.startDate, this.endDate);
  }

  ngOnInit(): void {}

  private fetchReports(startDate: string, endDate: string): void {
    this.loadingService.setLoadingSteps(1);
    this.reportsService.getPromoReports(startDate, endDate, this.withTickets).subscribe(data => {
      this.dataSource = new MatTableDataSource(this.mapData(data));
      this.dataSource.sort = this.sort;

      if (this.dataSource.data.length === 0){
        this.hideReports();
      }else{
        this.showReports();
      }
    }, error => {
      this.handlingService.error('Error while fetching promo report data', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  private mapData(unmapped: UnmappedPromoReport[]): PromoReport[] {
    let data: PromoReport[] = [];
    unmapped.forEach(item => {
      data.push(
        new PromoReport(
          this.utilsService.timestampToString(item.PromoDate, "YYYY-MM-DD"),
          item.PromoCount,
          item.PromoCredits,
          item.PromoPlayers,
          item.FreeTicketsCount,
          item.FreeTicketsMoney,
          item.FreePlayers,
          item.JackpotCount,
          item.JackpotMoney,
          item.RocketpotCount,
          item.RocketpotMoney,
          item.BoosterpotCount,
          item.BoosterpotMoney,
          item.TournamentCount,
          item.TournamentMoney))
    })
    return data;
  }

  private showReports(): void {
    if (this.columns.length === 0){
      this.columns = this.dataSource.data[0].getFields();
    }
    this.showTable = true;
  }

  private hideReports(): void {
    this.showTable = false;
    this.dataSource = new MatTableDataSource<PromoReport>([]);
  }

  public setStartDate(date: string | null): void {
    this.startDate = date;
  }
  public setEndDate(date: string | null): void {
    this.endDate = date;
  }

  public total(column: string): string | number {
    if(column === this.columns[0]){
      return 'Total';
    }else {
      let result = 0;
      this.dataSource.data.map(report => {
        result += report.total(column);
      })
      return this.totalFormat(result, column);
    }
  }

  public refreshData(){
    if (this.startDate && this.endDate){
      if (this.startDate > this.endDate) {
        [this.startDate, this.endDate] = [this.endDate, this.startDate];
      }
      this.fetchReports(this.startDate, this.endDate);
    }else {
      this.handlingService.error('Please select start & end date');
    }
  }

  public downloadExcel(): void {
    let data = this.reportsService.promoReportExcelMapper(this.dataSource.data);
    const name = 'Promo report - ' + this.utilsService.getTodaysDate();
    this.excelService.exportAsExcelFile(data, name);
  }

  public alignHeaderCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].align(column);
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }

  private totalFormat(result: number, column: string): any {
    if (column === this.columns[1] ||
        column === this.columns[2] ||
        column === this.columns[4] ||
        column === this.columns[5] ||
        column === this.columns[7] ||
        column === this.columns[9] ||
        column === this.columns[11] ||
      column === this.columns[13]){
      return this.utilsService.formatInteger(result);
    }else{
      return this.utilsService.formatMoney(result);
    }
  }

  public border(column: string): boolean {
    return column === this.columns[1] || column === this.columns[4] || column === this.columns[7] || column === this.columns[13];
  }

}
