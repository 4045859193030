import {ReportColumns} from "../reports/report-columns.interface";

export class TournamentResult {
  tournamentId: number
  playerId: number
  username: string
  place: number
  placeAward: number
  currencyId: string
  points: number
  externalId: string
  columnInfo: ReportColumns[]

  constructor(tournamentId: number, playerId: number, username: string, place: number, placeAward: number, currencyId: string, points: number, externalId: string) {
    this.tournamentId = tournamentId;
    this.playerId = playerId;
    this.username = username;
    this.place = place;
    this.placeAward = placeAward;
    this.currencyId = currencyId;
    this.points = points;
    this.externalId = externalId;

    this.columnInfo = [
      {key: 'Tournament ID', info: {field: 'tournamentId', total: this.tournamentId, format: this.tournamentId, align: 'normal'}},
      {key: 'Player ID', info: {field: 'playerId', total: this.playerId, format: this.playerId, align: 'normal'}},
      {key: 'Username', info: {field: 'username', total: this.username, format: this.formatString(this.username), align: 'normal'}},
      {key: 'Place', info: {field: 'place', total: this.place, format: this.formatInteger(this.place), align: 'normal'}},
      {key: 'Place award', info: {field: 'placeAward', total: this.placeAward, format: this.formatMoney(this.placeAward), align: 'center'}},
      {key: 'Currency', info: {field: 'currencyId', total: this.currencyId, format: this.formatString(this.currencyId), align: 'center'}},
      {key: 'Points', info: {field: 'points', total: this.points, format: this.formatMoney(this.points), align: 'end'}},
      {key: 'External ID', info: {field: 'externalId', total: this.externalId, format: this.formatString(this.externalId), align: 'normal'}},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public total(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.total;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatString(string: string): string {
    return string;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }
}
