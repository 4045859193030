import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ChatRoom} from "../../../models/chat-rooms/chat-room.interface";
import {ChatRoomMessage} from "../../../models/chat-rooms/chat-room-message.interface";
import {ChatRoomsService} from "../../../services/chat-rooms/chat-rooms.service";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {ChatRoomProfaneMessage} from "../../../models/chat-rooms/chat-room-profane-message.model";
import {MatDialog} from "@angular/material/dialog";
import {CompanyMessageDialogComponent} from "../company-message-dialog/company-message-dialog.component";
import {RolesService} from "../../../services/roles/roles.service";
import {RoleState} from "../../../models/roles/role-state.enum";
import {ConfigurationsService} from "../../../services/configurations/configurations.service";

@Component({
  selector: 'app-chat-room-panel',
  templateUrl: './chat-room-panel.component.html',
  styleUrls: ['./chat-room-panel.component.scss']
})
export class ChatRoomPanelComponent implements OnInit, OnChanges {

  @Input("item") item: ChatRoom | null = null;
  public newMessage: string = '';
  public sendToAll: boolean = false;
  public selectedMessage: ChatRoomMessage | null = null;
  @Output() changeSize = new EventEmitter<boolean>();
  public isOwner: boolean = false;
  public companyId: number = 0;

  public messageLevel: MessageLevel;
  public availableMessageLevels: MessageLevel[];

  constructor(private chatRoomService: ChatRoomsService,
              private handlingService: HandlingService,
              private configurationService: ConfigurationsService,
              private rolesService: RolesService,
              private dialog: MatDialog) {
    this.isOwner = rolesService.isRole(RoleState.OWNER);
    this.companyId = configurationService.getCompanyId();
    this.availableMessageLevels = this.initMessageLevels();
    this.messageLevel = this.availableMessageLevels[0];
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.item){
      if (this.selectedMessage !== null){
        this.messageDeselected();
      }
    }
  }

  public sendMessage(){
    //No message
    if (this.newMessage.trim().length === 0){
      return;
    }
    //No chatroom selected, nor send to all clicked
    if (!this.item && !this.sendToAll){
      return this.handlingService.error('No chat room selected');
    }else{
      let message = this.newMessage.trim();
      let room = this.sendToAll ? '' : this.item?.name;
      this.chatRoomService.sendNewMessage(message, this.companyId, this.messageLevel.value, room);
      this.newMessage = '';
    }
  }

  public messageSelected(message: ChatRoomMessage | ChatRoomProfaneMessage): void {
    if (message instanceof ChatRoomMessage){
      this.changeSize.emit(true);
      this.selectedMessage = message;
    }
  }

  public messageDeselected(): void {
    this.changeSize.emit(false);
    this.selectedMessage = null;
  }

  public deleteMessages(): void {
    if (this.item && this.selectedMessage) {
      this.chatRoomService.deleteMessage(this.selectedMessage, this.item);
      this.messageDeselected();
    }
  }

  public sendToCompany(): void {
    const dialogRef = this.dialog.open(CompanyMessageDialogComponent, {
      width: '370px',
      data: this.newMessage,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.company !== undefined && result.message !== undefined){
        this.companyId = result.company;
        this.newMessage = result.message;
        const message = this.newMessage.trim();
        const room = this.sendToAll ? '' : this.item?.name;
        this.chatRoomService.sendNewMessage(message, this.companyId, this.messageLevel.value, room);
        this.newMessage = '';
      }
    })
  }

  private initMessageLevels(): MessageLevel[] {
    const allMessageLevels: MessageLevel[] = [
      {value: 1, text: 'System'},
      {value: 2, text: 'Admin'},
      {value: 3, text: 'Operator'},
    ]
    if (this.rolesService.isRole(RoleState.OWNER)) {
      return allMessageLevels;
    }else if (this.rolesService.isRole(RoleState.ADMIN)) {
      return allMessageLevels.slice(1);
    } else {
      return allMessageLevels.slice(2);
    }
  }

  public changeMessageLevel(newLevel: MessageLevel) {
    this.messageLevel = newLevel;
  }

}


interface MessageLevel {
  value: number
  text: string
}
