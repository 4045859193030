<form [formGroup]="addingForm" (ngSubmit)="submitForm()">
  <div class="container pb-2">
    <div class="row">
      <div class="flex-container">
        <h2 mat-dialog-title style="font-size: 24px; font-weight: lighter;">{{ 'configurations.company.overview.addCompanyDialog.title' | transloco }}</h2>
      </div>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="container" style="overflow-y: hidden">
      <div class="row">
        <div class="col" id="leftSide">

          <div class="row my-0 justify-content-center">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'configurations.company.overview.companyNameLabel' | transloco }}</mat-label>
              <input matInput formControlName="companyName" [value]="" style="font-size: 16px">
              <mat-error *ngIf="companyName!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Company name' } }}</mat-error>
              <mat-error *ngIf="companyName!.errors?.minlength">{{ 'errorMessages.minlength' | transloco: { name: 'Company name' } }}</mat-error>
            </mat-form-field>
          </div>

          <div class="row my-0 justify-content-center">
            <div class="col">
              <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
                <mat-label>{{ 'configurations.company.overview.timeOffsetLabel' | transloco }}</mat-label>
                <mat-select formControlName="timeOffset">
                  <mat-option [value]="offset" *ngFor="let offset of timeOffsets">
                    {{offset.code}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="timeOffset!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Time offset' } }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
                <mat-label>{{ 'configurations.company.overview.platformIDLabel' | transloco }}</mat-label>
                <mat-select formControlName="platform">
                  <mat-option [value]="platform" (click)="platformChanged(platform)" *ngFor="let platform of platforms">
                    {{platform.platformID}} - {{platform.platformName}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="platform!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Platform ID' } }}</mat-error>
                <mat-error *ngIf="platform!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Platform ID' } }}</mat-error>
                <mat-error *ngIf="platform!.errors?.max">{{ 'errorMessages.max' | transloco: { name: 'Platform ID', value: 10000 } }}</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row my-0 justify-content-center">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'configurations.company.overview.extDataLabel' | transloco }}</mat-label>
              <input matInput formControlName="extData" [value]="" style="font-size: 16px">
              <mat-error *ngIf="extData!.errors">Non valid JSON</mat-error>
            </mat-form-field>
          </div>

          <div class="row my-0 justify-content-center">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'configurations.company.overview.customizationLabel' | transloco }}</mat-label>
              <input matInput autocompleteOff formControlName="customization" [value]="" style="font-size: 16px">
              <mat-error *ngIf="customization!.errors">Non valid JSON</mat-error>
            </mat-form-field>
          </div>

          <div class="row my-0 justify-content-center">
            <div class="col">
              <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
                <mat-label>{{ 'configurations.company.overview.billingTypeLabel' | transloco }}</mat-label>
                <mat-select formControlName="billingType">
                  <mat-option [value]="type" *ngFor="let type of billingTypes">
                    {{type.text}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="billingType!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Billing type' } }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
                <mat-label>{{ 'configurations.company.overview.billingPercLabel' | transloco }}</mat-label>
                <input matInput type="number" min="0" formControlName="billingPerc">
                <mat-error *ngIf="billingPerc!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Billing percentage' } }}</mat-error>
                <mat-error *ngIf="billingPerc!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Billing percentage' } }}</mat-error>
                <mat-error *ngIf="billingPerc!.errors?.max">{{ 'errorMessages.max' | transloco: { name: 'Billing percentage', value: 50 } }}</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row my-0 justify-content-center">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'configurations.company.overview.adminUsernameLabel' | transloco }}</mat-label>
              <input matInput formControlName="adminUsername" [value]="" style="font-size: 16px">
              <mat-error *ngIf="adminUsername!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Admin username' } }}</mat-error>
              <mat-error *ngIf="adminUsername!.errors?.minlength">{{ 'errorMessages.minlength' | transloco: { name: 'Admin username' } }}</mat-error>
            </mat-form-field>
          </div>

          <div class="row my-0 justify-content-center">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'configurations.company.overview.adminEmailLabel' | transloco }}</mat-label>
              <input matInput type="email" formControlName="adminEmail" [value]="" style="font-size: 16px">
              <mat-error *ngIf="adminEmail!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Admin email' } }}</mat-error>
              <mat-error *ngIf="adminEmail!.errors?.email">{{ 'errorMessages.email' | transloco }}</mat-error>
            </mat-form-field>
          </div>

          <div class="row my-0 justify-content-center">
            <div class="col">
              <mat-form-field appearance="outline" style="font-size: 16px;" class="half-form-field">
                <mat-label>{{ 'configurations.company.overview.delUnusedPromoLabel' | transloco }}</mat-label>
                <input matInput type="number" min="0" max="366" formControlName="delUnusedPromo" autocomplete="off">
                <span matSuffix>days</span>
                <mat-error *ngIf="delUnusedPromo!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Delete unused promo field' } }}</mat-error>
                <mat-error *ngIf="delUnusedPromo!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Delete unused promo field' } }}</mat-error>
                <mat-error *ngIf="delUnusedPromo!.errors?.max">{{ 'errorMessages.max' | transloco: { name: 'Delete unused promo field', value: 10000 } }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline" style="font-size: 16px;" class="half-form-field">
                <mat-label>{{ 'configurations.company.overview.delUnusedFreeTicketsLabel' | transloco }}</mat-label>
                <input matInput type="number" min="0" max="366" formControlName="delUnusedFreeTickets" autocomplete="off">
                <span matSuffix>days</span>
                <mat-error *ngIf="delUnusedFreeTickets!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Delete unused free ticket field' } }}</mat-error>
                <mat-error *ngIf="delUnusedFreeTickets!.errors?.min">{{ 'errorMessages.min' | transloco: { name: 'Delete unused free ticket field' } }}</mat-error>
                <mat-error *ngIf="delUnusedFreeTickets!.errors?.max">{{ 'errorMessages.max' | transloco: { name: 'Delete unused free ticket field', value: 50 } }}</mat-error>
              </mat-form-field>
            </div>
          </div>

        </div>
        <div class="col-5" id="rightSide">
          <div class="row mb-1">
            <label for="nonBillingPeriodInput" style="font-weight: lighter; font-size: 16px; padding-bottom: 4px">{{ 'configurations.company.overview.nonBillingPeriodLabel' | transloco }}</label>
            <mat-card class="calendar-card mat-elevation-z2" id="nonBillingPeriodInput" (click)="setCalendarClicked()">
              <mat-calendar
                [minDate]="min"
                [maxDate]="max"
                [selected]="currentDate"
                (selectedChange)="billingPeriodDateChanged($event)"></mat-calendar>
            </mat-card>
            <div *ngIf="currentDate === null && calendarClicked">
              <small class="text-danger">{{ 'errorMessages.date' | transloco }}</small>
            </div>
          </div>
          <div class="row mt-3 justify-content-center">
            <label for="descriptionInput" style="font-weight: lighter; font-size: 16px; padding-bottom: 4px">{{ 'configurations.company.overview.descriptionLabel' | transloco }}</label>
            <mat-form-field id="descriptionInput" appearance="outline" style="font-size: 16px;">
              <textarea matInput formControlName="description" style="resize: none; height: 145px"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row my-0 justify-content-center">
        <a (click)="advanced = !advanced" style="width: 140px; cursor: pointer; text-decoration: underline">Advanced options</a>
      </div>
      <div class="row" [@openClose]="advanced ? 'open' : 'closed'" *ngIf="advanced">
        <div class="col d-flex flex-column">
          <mat-checkbox class="checkbox py-1" color="primary" style="font-size: 16px" formControlName="isolated">
            {{ 'configurations.company.overview.isolatedLabel' | transloco }}
          </mat-checkbox>
          <mat-checkbox class="checkbox pb-1" color="primary" style="font-size: 16px" formControlName="useDST">
            {{ 'configurations.company.overview.useDSTLabel2' | transloco }}
          </mat-checkbox>
          <mat-checkbox class="checkbox pb-1" color="primary" style="font-size: 16px" formControlName="eomfx">
            {{ 'configurations.company.overview.eomfxLabel' | transloco }}
          </mat-checkbox>
        </div>
        <div class="col d-flex flex-column align-items-end">
          <mat-checkbox class="checkbox py-1" color="primary" style="font-size: 16px" labelPosition="before" formControlName="fixedCurrency">
            {{ 'configurations.company.overview.fixedCurrencyLabel' | transloco }}
          </mat-checkbox>
          <mat-checkbox class="checkbox pb-1" color="primary" style="font-size: 16px" labelPosition="before" formControlName="promoWinToBonus">
            {{ 'configurations.company.overview.promoWinToBonusLabel' | transloco }}
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>

  <div class="container mb-2 pe-3">
    <div mat-dialog-actions class="d-flex flex-row-reverse w-100 mt-2">
      <button class="px-4 ms-2" type="submit" mat-flat-button color="primary" [disabled]="!addingForm.valid">{{ 'buttons.saveButton' | transloco }}</button>
      <button class="px-3" type="button" mat-stroked-button color="accent" (click)="onNoClick()">{{ 'buttons.cancelButton' | transloco }}</button>
    </div>
  </div>
</form>
