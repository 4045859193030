const STR_ZERO = '0.00';
const INT_ZERO = '0';

export abstract class Utils {

  public formatInteger(value?: number): string {
    return value ? value.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0}) : INT_ZERO;
  }

  public formatMoney(value?: number): string {
    return value ? value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2}) : STR_ZERO;
  }
}
