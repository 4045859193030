<div class="frame round-border">
  <div class="row d-flex align-items-center my-2">
    <div class="ps-2 ms-1 pe-0 w-auto mb-2">
      <button mat-stroked-button color="primary" (click)="addMeteor()">
        <mat-icon>add</mat-icon>
        {{ 'buttons.addMeteor' | transloco }}
      </button>
    </div>
    <div class="ps-2 ms-1 pe-0 w-auto mb-2">
      <button mat-stroked-button color="primary" (click)="editMeteor()">
        <mat-icon>edit</mat-icon>
        {{ 'buttons.editMeteor' | transloco }}
      </button>
    </div>
    <div class="ps-2 ms-1 pe-0 w-auto mb-2">
      <button mat-stroked-button color="warn" (click)="deleteMeteor()">
        <mat-icon>delete</mat-icon>
        {{ 'buttons.deleteMeteor' | transloco }}
      </button>
    </div>

    <div class="ps-2 ms-1 pe-0 w-auto mb-2">
      <button mat-stroked-button color="accent" (click)="deselectAll()">
        <mat-icon>remove_circle_outline</mat-icon>
        {{ 'buttons.deselectButton' | transloco }}
      </button>
    </div>
    <div class="ps-2 ms-1 pe-0 w-auto mb-2">
      <button mat-stroked-button color="accent" (click)="refreshData()">
        <mat-icon>refresh</mat-icon>
        {{ 'buttons.refreshDataButton' | transloco }}
      </button>
    </div>
  </div>
  <div class="max-height-table">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mt-0" matSort>
      <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
        <th mat-header-cell
            class="header-cell"
            *matHeaderCellDef
            mat-sort-header="{{headerCell(column)}}"
            arrowPosition="after"
            [ngStyle]="{'text-align': alignHeaderCell(column)}">
          {{ translationColumns[i] | transloco }}
        </th>
        {{columns.length > 0 ? (<td  mat-cell class="cell" *matCellDef="let element" [ngStyle]="{'text-align': element.align(column)}" > {{element.format(column)}} </td>) : null}}

      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; let i = index; columns: columns;"
          (click)="selectMeteor(row)"
          class="element-row"
          [class.clicked-row]="row === selected"
          [ngClass]="{alternating: i % 2 === 0}"></tr>
    </table>
  </div>

</div>
