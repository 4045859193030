import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NavigationItem} from "../../../models/navigation-item.model";
import {NavigationService} from "../../../services/navigation/navigation.service";
import {RolesService} from "../../../services/roles/roles.service";
import {LocalizationService} from "../../../services/localization/localization.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfigurationsService} from "../../../services/configurations/configurations.service";
import {Routes} from "../../../models/utils/routes.enum";

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class NavigationItemComponent implements OnInit {

  public expanded: boolean = false;
  public approved: boolean | undefined = undefined;
  public name: string = ''

  @Input() item!: NavigationItem;
  @Input() depth!: number;

  constructor(public navigationService: NavigationService,
              public rolesService: RolesService,
              public configurationsService: ConfigurationsService,
              private localizationService: LocalizationService,
              private infoBar: MatSnackBar) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit(): void {
    this.approved = this.rolesService.checkRouteAvailability(this.item.getRoute());
    this.name = this.item.getDisplayName();
    this.localizationService.obsIndex.subscribe(() => {
      if(this.item){
        this.name = this.localizationService.getDisplayName(this.item.getDisplayName());
      }
    })
  }

  public onItemSelected(item: NavigationItem) {
    if (!item.getChildren() || !item.getChildren().length) {
      this.navigationService.setCurrentRoute(item.getRoute());
      this.navigationService.setWindow(item.getWindow());
    }
    if (item.getChildren() && item.getChildren().length) {
      this.expanded = !this.expanded;
    }
  }

  public openInfo(): void {
    this.infoBar.open(this.item.getBetaInfo(), 'Got it', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['snackbar']
    });
  }

  public checkChild(item: NavigationItem): boolean {
    const platformCheck = this.configurationsService.checkPlatform();
    const roleCheck = this.rolesService.checkRouteAvailability(item.getRoute());
    return item.getRoute() === Routes.PLATFORM_SUMMARY_REPORT ? platformCheck && roleCheck : roleCheck;
  }

}
