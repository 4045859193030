import {ReportColumns} from "../report-columns.interface";

export class RaccoonRushRoundReport {
  id: number
  roundOpen: string
  bets: number
  stakeInEUR: number
  wins: number
  winsInEUR: number
  hold: number
  totalCount: number
  totalPages: number

  columnInfo: ReportColumns[]

  constructor(id: number, roundOpen: string, bets: number, stakeInEUR: number, wins: number, winsInEUR: number, hold: number, totalCount: number, totalPages: number) {
    this.id = id;
    this.roundOpen = roundOpen;
    this.bets = bets;
    this.stakeInEUR = stakeInEUR;
    this.wins = wins;
    this.winsInEUR = winsInEUR;
    this.hold = hold;
    this.totalCount = totalCount;
    this.totalPages = totalPages;

    this.columnInfo = [
      {key: 'ID', info: {field: 'id', total: this.id, format: this.id, align: 'normal'}},
      {key: 'Round open', info: {field: 'roundOpen', total: this.roundOpen, format: this.formatString(this.roundOpen), align: 'normal'}},
      // {key: 'Round numbers', info: {field: 'roundNumbers', total: this.roundNumbers, format: this.formatString(this.roundNumbers), align: 'normal'}},
      {key: 'Bet', info: {field: 'bets', total: this.bets, format: this.formatInteger(this.bets), align: 'end'}},
      {key: 'Stake In EUR', info: {field: 'stakeInEUR', total: this.stakeInEUR, format: this.formatMoney(this.stakeInEUR), align: 'end'}},
      {key: 'Wins', info: {field: 'wins', total: this.wins, format: this.formatInteger(this.wins), align: 'end'}},
      {key: 'Wins In EUR', info: {field: 'winsInEUR', total: this.winsInEUR, format: this.formatMoney(this.winsInEUR), align: 'end'}},
      {key: 'Hold', info: {field: 'hold', total: this.hold, format: this.formatMoney(this.hold), align: 'end'}}
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];

    this.columnInfo.forEach(value => {
        result.push(value.key);
    })

    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public total(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.total;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatId(integer: number): number {
    return integer;
  }

  public formatString(string: string): string {
    return string;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }

  public formatTwoDecimals(integer: number): string {
    return integer.toFixed(2);
  }
}
