import {ColumnMetaData} from "../../utils/column-meta-data.interface";
import {Injectable} from "@angular/core";
import {Mapper} from "../../utils/mapper.interface";
import {UnmappedCashbackPlayerReport} from "./unmapped/unmapped-cashback-player-report.interface";

export class CashbackPlayerReport {
  id: number
  username: string
  externalId: number
  bet: number
  value: number
  currencyId: string

  columnInfo: ColumnMetaData[]

  constructor(id: number, username: string, externalId: number, bet: number, value: number, currencyId: string) {
    this.id = id;
    this.username = username;
    this.externalId = externalId;
    this.bet = bet;
    this.value = value;
    this.currencyId = currencyId;

    this.columnInfo = [
      {key: 'id', column: 'reports.cashbackPlayerReport.columns.id', field: 'id', total: 0, format: this.formatString(this.id.toString()), align: 'normal'},
      {key: 'username', column: 'reports.cashbackPlayerReport.columns.username', field: 'username', total: 0, format: this.formatString(this.username), align: 'normal'},
      {key: 'external-id', column: 'reports.cashbackPlayerReport.columns.externalId', field: 'externalId', total: 0, format: this.formatString(this.externalId.toString()), align: 'normal'},
      {key: 'bet', column: 'reports.cashbackPlayerReport.columns.bet', field: 'bet', total: this.bet, format: this.formatMoney(this.bet), align: 'end'},
      {key: 'value', column: 'reports.cashbackPlayerReport.columns.value', field: 'value', total: this.value, format: this.formatMoney(this.value), align: 'end'},
      {key: 'currency-id', column: 'reports.cashbackPlayerReport.columns.currency', field: 'currencyId', total: 0, format: this.formatString(this.currencyId), align: 'end'},
    ]
  }

  public formatString(string: string): any {
    if (string === null){
      return '';
    }
    return string;
  }

  public formatInteger(integer: number): any {
    if (integer === null){
      return '';
    }
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    if (money === null){
      return '';
    }
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }
}

@Injectable({providedIn: "root"})
export class CashbackPlayerReportMapper implements Mapper<CashbackPlayerReport> {
  public map(unmapped: UnmappedCashbackPlayerReport): CashbackPlayerReport {
    return new CashbackPlayerReport(
      unmapped.PlayerID,
      unmapped.Username,
      unmapped.ExternalID,
      unmapped.BetValue,
      unmapped.CashBackValue,
      unmapped.CurrencyID
    );
  }
}
