import {TimeOffset} from "./time-offset.interface";

export class UtcTimeOffset {

  private times: TimeOffset[] = [
    {code: 'UTC−12:00', value: -12},
    {code: 'UTC−11:00', value: -11},
    {code: 'UTC−10:00', value: -10},
    {code: 'UTC−09:30', value: -9.5},
    {code: 'UTC−09:00', value: -9},
    {code: 'UTC−08:00', value: -8},
    {code: 'UTC−07:00', value: -7},
    {code: 'UTC−06:00', value: -6},
    {code: 'UTC−05:00', value: -5},
    {code: 'UTC−04:00', value: -4},
    {code: 'UTC−03:30', value: -3.5},
    {code: 'UTC−03:00', value: -3},
    {code: 'UTC−02:00', value: -2},
    {code: 'UTC−01:00', value: -1},
    {code: 'UTC±00:00', value: 0},
    {code: 'UTC+01:00', value: 1},
    {code: 'UTC+02:00', value: 2},
    {code: 'UTC+03:00', value: 3},
    {code: 'UTC+03:30', value: 3.5},
    {code: 'UTC+04:00', value: 4},
    {code: 'UTC+04:30', value: 4.5},
    {code: 'UTC+05:00', value: 5},
    {code: 'UTC+05:30', value: 5.5},
    {code: 'UTC+05:45', value: 5.75},
    {code: 'UTC+06:00', value: 6},
    {code: 'UTC+06:30', value: 6.5},
    {code: 'UTC+07:00', value: 7},
    {code: 'UTC+08:00', value: 8},
    {code: 'UTC+08:45', value: 8.75},
    {code: 'UTC+09:00', value: 9},
    {code: 'UTC+09:30', value: 9.5},
    {code: 'UTC+10:00', value: 10},
    {code: 'UTC+10:30', value: 10.5},
    {code: 'UTC+11:00', value: 11},
    {code: 'UTC+12:00', value: 12},
    {code: 'UTC+12:45', value: 12.75},
    {code: 'UTC+13:00', value: 13},
    {code: 'UTC+14:00', value: 14},
  ]

  public getTimeOffsets(): TimeOffset[] {
    return this.times;
  }

  public getTimeCodes(): string[] {
    let result: string[] = []
    this.times.forEach(time => {
      result.push(time.code)
    })
    return result
  }

  public getValue(code: string): number {
    let result: number = 0;
    this.times.filter(time => {
      if (time.code === code){
        result = time.value;
        return;
      }
    })
    return result
  }

  public get(code: string): TimeOffset {
    let result: TimeOffset = {code: 'UTC±00:00', value: 0};
    this.times.forEach(time => {
      if(code === time.code){
        result = time;
        return;
      }
    })
    return result;
  }

  public getByValue(value: number): TimeOffset {
    let result: TimeOffset = {code: 'UTC±00:00', value: 0};
    this.times.forEach(time => {
      if(value === time.value){
        result = time;
        return;
      }
    })
    return result;
  }
}


