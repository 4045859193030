import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private maxLoadingSteps = new BehaviorSubject<number>(1);
  public obsMaxLoadingSteps = this.maxLoadingSteps.asObservable();
  private loadingSteps = new BehaviorSubject<number>(0);
  public obsLoadingSteps = this.loadingSteps.asObservable();

  constructor() {}

  public setLoadingSteps(value: number): void {
    if (value >= 1){
      this.maxLoadingSteps.next(value);
    }
  }

  public incrementLoadingSteps(): void {
    let current = this.loadingSteps.getValue();
    this.loadingSteps.next(current + 1)
  }

}
