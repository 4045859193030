import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PlayersReportSettings} from "../../../../models/reports/players/players-report-settings.interface";
import {PlayersReportOrderBy} from "../../../../models/reports/players/players-report-order-by.enum";
import {PlayersReportFreeTickets} from "../../../../models/reports/players/players-report-free-tickets.enum";
import {PlayersReportPromoCredits} from "../../../../models/reports/players/players-report-promo-credits.enum";

@Component({
  selector: 'app-player-report-settings-dialog',
  templateUrl: './player-report-settings-dialog.component.html',
  styleUrls: ['./player-report-settings-dialog.component.scss']
})
export class PlayerReportSettingsDialogComponent implements OnInit {

  public settingsForm: FormGroup;

  public orderBySession: PlayersReportOrderBy = PlayersReportOrderBy.SESSION_COUNT;
  public orderByBets: PlayersReportOrderBy = PlayersReportOrderBy.BET_COUNT;
  public orderByStake: PlayersReportOrderBy = PlayersReportOrderBy.STAKE;
  public orderByGgr: PlayersReportOrderBy = PlayersReportOrderBy.GGR

  public promoCreditsNo: PlayersReportPromoCredits = PlayersReportPromoCredits.NO_PROMO_CREDITS;
  public promoCreditsDm: PlayersReportPromoCredits = PlayersReportPromoCredits.DM_PROMO_CREDITS;
  public promoCreditsYes: PlayersReportPromoCredits = PlayersReportPromoCredits.YES_PROMO_CREDITS;

  public freeTicketsNo: PlayersReportFreeTickets = PlayersReportFreeTickets.NO_FREE_TICKETS;
  public freeTicketsDm: PlayersReportFreeTickets = PlayersReportFreeTickets.DM_FREE_TICKETS;
  public freeTicketsYes: PlayersReportFreeTickets = PlayersReportFreeTickets.YES_FREE_TICKETS;

  constructor(private dialogRef: MatDialogRef<PlayerReportSettingsDialogComponent>,
              private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: PlayersReportSettings) {

    this.settingsForm = this.formBuilder.group({
      orderBy: [this.data.orderBy, [Validators.required]],
      minTickets: [this.data.minTickets, [Validators.required, Validators.min(0)]],
      topResults: [this.data.topResults, [Validators.required, Validators.min(1), Validators.max(200)]],
      promoCredits: [this.data.promoCredits, [Validators.required]],
      freeTickets: [this.data.freeTickets, [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  public submitForm() {
    if (this.settingsForm.invalid) {
      return;
    }
    this.data.orderBy = this.settingsForm.get('orderBy')?.value;
    this.data.minTickets = this.settingsForm.get('minTickets')?.value;
    this.data.topResults = this.settingsForm.get('topResults')?.value;
    this.data.promoCredits = this.settingsForm.get('promoCredits')?.value;
    this.data.freeTickets = this.settingsForm.get('freeTickets')?.value;

    this.dialogRef.close({data: this.data});
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public get orderBy() {
    return this.settingsForm.get('orderBy');
  }
  public get minTickets() {
    return this.settingsForm.get('minTickets');
  }
  public get topResults() {
    return this.settingsForm.get('topResults');
  }
  public get promoCredits() {
    return this.settingsForm.get('promoCredits');
  }
  public get freeTickets() {
    return this.settingsForm.get('freeTickets');
  }
}
