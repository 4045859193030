import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {ConfigurationsService} from "../../services/configurations/configurations.service";
import {HandlingService} from "../../services/global-handling/handling.service";
import {LoginService} from "../../services/login/login.service";
import {CompanySimple} from "../../models/configurations/company-simple.interface";
import {Credentials} from "../../models/user/credentials.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

//Icons
//account_circle
//person
//business
//supervisor_account

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss']
})
export class ProfileDialogComponent implements OnInit {

  public tab: number = 0;

  public company: CompanySimple | null = null;
  public credentials: Credentials | null = null;

  public hidePass: boolean = false;
  public hidePassRe: boolean = false;
  public passwordForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<ProfileDialogComponent>,
              private configurationsService: ConfigurationsService,
              private handlingService: HandlingService,
              public loginService: LoginService,
              private formBuilder: FormBuilder) {
    this.credentials = configurationsService.getCredentials();

    configurationsService.getSimpleCompanies().subscribe(value => {
      if (!value) { return this.unsuccessful(); }
      value.forEach(item => {
        if (item.CompanyID === this.credentials?.companyId){
          this.company = item;
          return;
        }
      })
    }, error => {
      this.unsuccessful(error);
    })

    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(3)]],
      passwordRepeat: ['', [Validators.required, Validators.minLength(3)]],
    })
  }

  public changeTab(){
    this.tab = this.tab === 1 ? 0 : 1;
  }

  ngOnInit(): void {
  }

  private unsuccessful(error?: any): void {
    this.handlingService.error('Error loading profile', error);
    this.dialogRef.close();
  }

  public changePassword(): void {
    if (this.passwordForm.invalid){ return; }
    let password = this.passwordForm.get('password')?.value;
    let passwordRepeat = this.passwordForm.get('passwordRepeat')?.value;

    if (password !== passwordRepeat){
      return this.handlingService.error("Passwords don't match");
    }
    this.configurationsService.updateUserPassword(password).subscribe(() => {
      this.changeTab();
    }, error => {
      this.handlingService.error('Error updating user password', error);
    })
  }

  public get password() {
    return this.passwordForm.get('password');
  }

  public get passwordRepeat() {
    return this.passwordForm.get('passwordRepeat');
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

}
