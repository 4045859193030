import {ReportColumns} from "../../reports/report-columns.interface";

export class PromoManagement {
  id: number
  username: string
  firstName: string
  lastName: string
  sessions: number
  bets: number
  stakeEur: number
  winEur: number
  ggr: number
  percentage: number
  promoCredits: number
  creditCurrency: string
  freeTickets: number
  freeMoney: number
  freeCurrency: string
  playerLevel: number
  minCashout: number

  columnInfo: ReportColumns[]

  constructor(id: number, username: string, firstName: string, lastName: string, sessions: number, bets: number, stakeEur: number, winEur: number, ggr: number, percentage: number, promoCredits: number, creditCurrency: string, freeTickets: number, freeMoney: number, freeCurrency: string, playerLevel: number, minCashout: number) {
    this.id = id;
    this.username = username;
    this.firstName = firstName;
    this.lastName = lastName;
    this.sessions = sessions;
    this.bets = bets;
    this.stakeEur = stakeEur;
    this.winEur = winEur;
    this.ggr = ggr;
    this.percentage = percentage;
    this.promoCredits = promoCredits;
    this.creditCurrency = creditCurrency;
    this.freeTickets = freeTickets;
    this.freeMoney = freeMoney;
    this.freeCurrency = freeCurrency;
    this.playerLevel = playerLevel;
    this.minCashout = minCashout;

    this.columnInfo = [
      {key: 'VIP', info: {field: 'playerLevel', total: 0, format: this.playerLevel, align: 'normal'}},
      {key: 'ID', info: {field: 'id', total: this.id, format: this.id, align: 'normal'}},
      {key: 'Username', info: {field: 'username', total: this.username, format: this.formatString(this.username), align: 'normal'}},
      {key: 'First name', info: {field: 'firstName', total: this.firstName, format: this.formatString(this.firstName), align: 'normal'}},
      {key: 'Last name', info: {field: 'lastName', total: this.lastName, format: this.formatString(this.lastName), align: 'normal'}},
      {key: 'Session count', info: {field: 'sessions', total: this.sessions, format: this.formatInteger(this.sessions), align: 'end'}},
      {key: 'Bets', info: {field: 'bets', total: this.bets, format: this.formatInteger(this.bets), align: 'end'}},
      {key: 'Stake EUR', info: {field: 'stakeEur', total: this.stakeEur, format: this.formatMoney(this.stakeEur), align: 'end'}},
      {key: 'Win EUR', info: {field: 'winEur', total: this.winEur, format: this.formatMoney(this.winEur), align: 'end'}},
      {key: 'GGR', info: {field: 'ggr', total: this.ggr, format: this.formatMoney(this.ggr), align: 'end'}},
      {key: 'Percentage', info: {field: 'percentage', total: this.percentage, format: this.formatMoney(this.percentage), align: 'end'}},
      {key: 'Promo credits', info: {field: 'promoCredits', total: this.promoCredits, format: this.formatMoney(this.promoCredits), align: 'end'}},
      {key: 'Credit currency', info: {field: 'creditCurrency', total: this.creditCurrency, format: this.formatString(this.creditCurrency), align: 'end'}},
      {key: 'Free tickets', info: {field: 'freeTickets', total: this.freeTickets, format: this.formatInteger(this.freeTickets), align: 'end'}},
      {key: 'Free money', info: {field: 'freeMoney', total: this.freeMoney, format: this.formatMoney(this.freeMoney), align: 'end'}},
      {key: 'Free currency', info: {field: 'freeCurrency', total: this.freeCurrency, format: this.formatString(this.freeCurrency), align: 'end'}},
      {key: 'Min cash out', info: {field: 'minCashout', total: this.minCashout, format: this.formatMoney(this.minCashout), align: 'end'}},
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public total(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.total;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatString(string: string): string {
    return string;
  }

  public formatInteger(integer: number): any {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }
}
