<div class="container w-96 round-border">
  <div class="row d-flex justify-content-between px-1 align-items-center">
    <p class="dashboard-title" style="width: auto; padding-right: 0;" *ngIf="betsBool">{{ 'dashboard.dashboard4.title' | transloco: { date: date } }}</p>
    <p class="dashboard-title" style="width: auto; padding-right: 0;" *ngIf="!betsBool">{{ 'dashboard.dashboard4.title2' | transloco: { date: date } }}</p>
    <button class="me-2 ms-2 w-auto" mat-stroked-button color="accent" *ngIf="betsBool" (click)="changeData()">{{ 'dashboard.dashboard4.stakeButton' | transloco }}</button>
    <button class="me-2 ms-2 w-auto" mat-stroked-button color="accent" *ngIf="!betsBool" (click)="changeData()">{{ 'dashboard.dashboard4.betsButton' | transloco }}</button>
  </div>

  <div class="dashboard4" *ngIf="showChart | async">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [labels]="chartOptions.labels"
      [plotOptions]="chartOptions.plotOptions"
      [dataLabels]="chartOptions.dataLabels"
      [responsive]="chartOptions.responsive"
      [legend]="chartOptions.legend"
      [tooltip]="chartOptions.tooltip"
      [colors]="chartOptions.colors"
    ></apx-chart>
  </div>
</div>


