import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ReportsService} from "../../../services/reports/reports.service";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {LoadingService} from "../../../services/loading/loading.service";
import {UtilsService} from "../../../services/utils/utils.service";
import {ExcelService} from "../../../services/excel/excel.service";
import {PromoPlayerReport} from "../../../models/reports/promo-player/promo-player-report.model";
import {MatTableDataSource} from "@angular/material/table";
import {CompanySimple} from "../../../models/configurations/company-simple.interface";
import {ConfigurationsService} from "../../../services/configurations/configurations.service";
import {tap} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-promo-player-report',
  templateUrl: './promo-player-report.component.html',
  styleUrls: ['./promo-player-report.component.scss']
})
export class PromoPlayerReportComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public showTable = false;
  public topColumns: string[] = [
    "top-id",
    "top-username",
    "top-promoCredits",
    "top-freeBets",
    "top-jackpots",
    "top-tournaments",
    "top-totalBonus",
    "top-currency"
  ];
  public bottomColumns: string[] = [];
  public topTranslationColumns: string[] = [];
  public bottomTranslationColumns: string[] = [];
  public topColumnSpans: number[] = [1, 1, 2, 2, 2, 2, 1, 1];
  public dataSource!: MatTableDataSource<PromoPlayerReport>;

  public companies: CompanySimple[] = [];
  public company: CompanySimple | null = null;
  public startDate: string | null = null;
  public endDate: string | null  = null;

  public totalData: number = 0;

  constructor(private reportsService: ReportsService,
              private configurationsService: ConfigurationsService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private utilsService: UtilsService,
              private excelService: ExcelService) {
    this.topTranslationColumns = reportsService.getPromoPlayerReportTopTranslationColumns();
    this.bottomTranslationColumns = reportsService.getPromoPlayerReportBottomTranslationColumns();
    this.startDate = utilsService.getFirstDateInMonth();
    this.endDate = utilsService.getTodaysDate();
    this.fetchCompanies();
  }

  private fetchCompanies(): void {
    this.loadingService.setLoadingSteps(1);
    this.configurationsService.getSimpleCompanies().subscribe(newData => {
      this.companies = newData;
      if (this.companies.length > 0){
        this.setSelectedCompany(this.companies[0]);
      }
      this.fetchReports(1, 100);
    },error => {
      this.handlingService.error('Error while fetching available companies', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    });
  }

  private fetchReports(page: number, rows: number): void {
    if (!this.startDate || !this.endDate){
      return this.handlingService.error('Please select start & end date');
    }
    if (this.startDate > this.endDate) {
      [this.startDate, this.endDate] = [this.endDate, this.startDate];
    }
    if (!this.company){
      return this.handlingService.error('Please select the company');
    }

    this.loadingService.setLoadingSteps(1);
    this.reportsService.getPromoPlayerReports(
      this.startDate,
      this.endDate,
      this.company.CompanyID,
      page,
      rows)
    .subscribe(value => {
      this.dataSource = new MatTableDataSource(value);
      this.dataSource.sort = this.sort;

      if (this.dataSource.data.length === 0){
        this.hideReports();
      }else{
        this.totalData = value[0].totalData;
        this.showReports();
      }
    }, error => {
      this.handlingService.error('Error while fetching promo report data', error);
    }, () => {
      this.loadingService.incrementLoadingSteps();
    })
  }

  public setSelectedCompany(company: CompanySimple | null) {
    this.company = company;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.paginator.page
      .pipe(
        tap(() => this.fetchReports(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize))
      )
      .subscribe();
  }

  public refreshData(): void {
    this.fetchReports(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize);
  }

  public downloadExcel(): void {
    let data = this.reportsService.promoPlayerReportExcelMapper(this.dataSource.data);
    const name = 'Promo player report - ' + this.utilsService.getTodaysDate();
    this.excelService.exportAsExcelFile(data, name);
  }

  private showReports(): void {
    if (this.bottomColumns.length === 0){
      this.bottomColumns = this.dataSource.data[0].getFields();
    }
    this.showTable = true;
  }

  private hideReports(): void {
    this.showTable = false;
    this.dataSource = new MatTableDataSource<PromoPlayerReport>([]);
  }

  public setStartDate(date: string | null): void {
    this.startDate = date;
  }
  public setEndDate(date: string | null): void {
    this.endDate = date;
  }

  public alignHeaderCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'center';
    }
    return this.dataSource.data[0].align(column);
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'center';
    }
    return this.dataSource.data[0].field(column);
  }

  public borderRight(column: string): boolean {
    if (column.startsWith("top")){
      return column !== this.topColumns[7];
    }else{
      return column === this.bottomColumns[0] ||
        column === this.bottomColumns[1] ||
        column === this.bottomColumns[3] ||
        column === this.bottomColumns[5] ||
        column === this.bottomColumns[7] ||
        column === this.bottomColumns[9] ||
        column === this.bottomColumns[10]
    }
  }

  public total(column: string): string {
    if(column === this.bottomColumns[0]){
      return 'Total';
    }else {
      let result = 0;
      this.dataSource.data.map(report => {
        result += report.total(column);
      })
      return this.totalFormat(result, column);
    }
  }

  private totalFormat(result: number, column: string): any {
    if (column === this.bottomColumns[2] ||
        column === this.bottomColumns[4] ||
        column === this.bottomColumns[6] ||
        column === this.bottomColumns[8]
    ){
      return this.utilsService.formatInteger(result);
    }else if (column === this.bottomColumns[1] ||
              column === this.bottomColumns[11]) {
      return ''
    }else{
      return this.utilsService.formatMoney(result);
    }
  }

}
