<div class="frame round-border">
  <h2 style="font-size: 24px; font-weight: lighter;">{{ 'configurations.company.overview.companyProfile.title' | transloco }}</h2>
  <hr>
  <form [formGroup]="companyForm" (ngSubmit)="submitForm()">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="row my-0 justify-content-center">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'configurations.company.overview.companyNameLabel' | transloco }}</mat-label>
              <input matInput formControlName="companyName" [value]="company?.companyName" style="font-size: 16px">
              <mat-error *ngIf="companyName!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Company name' } }}</mat-error>
              <mat-error *ngIf="companyName!.errors?.minlength">{{ 'errorMessages.minlength' | transloco: { name: 'Company name' } }}</mat-error>
            </mat-form-field>
          </div>
          <div class="row my-0 justify-content-center">
            <mat-form-field appearance="outline" style="font-size: 16px;" class="small-form-field">
              <mat-label>{{ 'configurations.company.overview.timeOffsetLabel' | transloco }}</mat-label>
              <mat-select formControlName="timeOffset">
                <mat-option [value]="offset" *ngFor="let offset of timeOffsets">
                  {{offset.code}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="timeOffset!.errors?.required">{{ 'errorMessages.required' | transloco: { name: 'Time offset' } }}</mat-error>
            </mat-form-field>
          </div>

          <div class="row my-0 justify-content-center">
            <mat-checkbox class="checkbox pb-1" color="primary" style="font-size: 16px" formControlName="useDST">
              {{ 'configurations.company.overview.useDSTLabel2' | transloco }}
            </mat-checkbox>
          </div>

        </div>
        <div class="col rightSide">
          <label for="nonBillingPeriodInput" style="font-size: 16px; padding-bottom: 4px; padding-left: 12px">{{ 'configurations.company.overview.nonBillingPeriodLabel' | transloco }}</label>
          <div *ngIf="nonBillingPeriodDate !== null">
            <mat-card class="calendar-card" id="nonBillingPeriodInput" style="cursor: not-allowed; pointer-events:none;">
              <mat-calendar
                class="non-editable"
                style="cursor: not-allowed"
                [startAt]="nonBillingPeriodDate"
                [selected]="nonBillingPeriodDate"
                (selectedChange)="billingPeriodDateChanged($event)"></mat-calendar>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row-reverse w-100 mt-3 mb-1 pe-2">
      <button class="px-4 ms-2" type="submit" mat-flat-button color="primary" [disabled]="!companyForm.valid">{{ 'buttons.saveButton' | transloco }}</button>
      <button class="px-3" type="button" mat-stroked-button color="accent" (click)="resetForm()">{{ 'buttons.cancelButton' | transloco }}</button>
    </div>
  </form>
</div>
