import {ReportColumns} from "../../reports/report-columns.interface";

export class ConfigUser {
  id: number
  username: string
  password: string
  email: string
  comment: string
  isLockedOut: boolean
  companyId: number
  companyName: string
  userRole: string
  isUsing2FA: boolean
  resetSecretCode: boolean

  columnInfo: ReportColumns[]

  constructor(id: number,
              username: string,
              password: string,
              email: string,
              comment: string,
              isLockedOut: boolean,
              companyId: number,
              companyName: string,
              userRole: string,
              isUsing2FA: boolean) {
    this.id = id;
    this.username = username;
    this.password = password;
    this.email = email;
    this.comment = comment;
    this.isLockedOut = isLockedOut;
    this.companyId = companyId;
    this.companyName = companyName;
    this.userRole = userRole;
    this.isUsing2FA = isUsing2FA;
    this.resetSecretCode = false;

    this.columnInfo = [
      {key: 'ID', info: {field: 'id', total: 0, format: this.id, align: 'normal'}},
      {key: 'Username', info: {field: 'username', total: 0, format: this.username, align: 'normal'}},
      {key: 'Email', info: {field: 'email', total: 0, format: this.email, align: 'normal'}},
      {key: 'Comment', info: {field: 'comment', total: 0, format: this.comment, align: 'normal'}},
      {key: 'Is locked out', info: {field: 'isLockedOut', total: 0, format: this.isLockedOut, align: 'end'}},
      {key: 'Company', info: {field: 'companyName', total: 0, format: this.companyName, align: 'end'}},
      {key: 'User role', info: {field: 'userRole', total: 0, format: this.userRole, align: 'end'}},
      {key: 'Is using 2FA', info: {field: 'isUsing2FA', total: 0, format: this.isUsing2FA, align: 'end'}}
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }

  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }
}
