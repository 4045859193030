import {Injectable} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ErrorDialogComponent} from "../../components/dialogs/error-dialog/error-dialog.component";
import {SuccessDialogComponent} from "../../components/dialogs/success-dialog/success-dialog.component";
import {LoginService} from "../login/login.service";
import {LoadingService} from "../loading/loading.service";
import {CustomDialogComponent} from "../../components/dialogs/custom-dialog/custom-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class HandlingService{

  constructor(private dialog: MatDialog, private loginService: LoginService, private loadingService: LoadingService) { }

  public error(message: string, error?: any): void {
    if (error){
      this.loadingService.incrementLoadingSteps();
    }

    if (error?.status === 401 || error?.status === 403){
      return this.loginService.logout();
    }

    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      width: '350px',
      data: message
    });

    dialogRef.afterClosed().subscribe(() => {
    })
  }

  public custom(title: string, message: string, icon = 'warning'): void {
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      width: '350px',
      data: {
        title: title,
        icon: icon,
        message: message,
        action: 'Okay'
      }
    });

    dialogRef.afterClosed().subscribe(() => {
    })
  }

  public success(message: string): void {

    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      width: '350px',
      data: message
    });

    dialogRef.afterClosed().subscribe(() => {
    })
  }


}
