export class PlayerInfo {
  company: string
  username: string
  firstName: string
  lastName: string
  email: string
  country: string
  balance: number
  currencyId: string
  language: string

  constructor(company: string, username: string, firstName: string, lastName: string, email: string, country: string, balance: number, currencyId: string, language: string) {
    this.company = company;
    this.username = username;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.country = country;
    this.balance = balance;
    this.currencyId = currencyId;
    this.language = language;
  }
}
