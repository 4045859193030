<h2 class="component-title">{{ 'reports.promoReport.title' | transloco }}</h2>
<hr>

<div class="frame round-border">
  <div class="row d-flex align-items-center">
    <div class="ps-3 pe-0 w-auto">
      <app-date-picker [placeholder]="'Start date'" (date)="setStartDate($event)" [init]="startDate"></app-date-picker>
    </div>
    <div class="ps-3 pe-0 w-auto">
      <app-date-picker [placeholder]="'End date'" (date)="setEndDate($event)" [init]="endDate"></app-date-picker>
    </div>
    <div class="ps-3 pe-0 w-auto mb-1">
      <mat-checkbox class="checkbox" color="primary" style="font-size: 16px" [(ngModel)]="withTickets">{{ 'buttons.withTicketsButton' | transloco }}</mat-checkbox>
    </div>
    <div class="ps-3 pe-0 w-auto mb-1">
      <button mat-stroked-button color="primary" (click)="refreshData()">
        <!--        <mat-icon>refresh</mat-icon>-->
        {{ 'buttons.refreshDataButton' | transloco }}
      </button>
    </div>
    <div class="ps-3 pe-3 w-auto mb-1">
      <button mat-stroked-button color="accent" (click)="downloadExcel()">
        <mat-icon>file_download</mat-icon>
        {{ 'buttons.excelButton' | transloco }}
      </button>
    </div>
  </div>
  <div class="max-height-table">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8 mt-0" matSort [hidden]="!showTable">

      <ng-container matColumnDef="top-date">
        <th mat-header-cell
            class="header-cell two-rows-header border-right"
            [ngStyle]="{'text-align': 'center'}"
            *matHeaderCellDef>
          {{ 'reports.promoReport.columnsTop.date' | transloco }}
        </th>
      </ng-container>

      <ng-container matColumnDef="top-promo-credits">
        <th mat-header-cell
            class="header-cell two-rows-header border-left border-right"
            [ngStyle]="{'text-align': 'center'}"
            *matHeaderCellDef colspan="3">
          {{ 'reports.promoReport.columnsTop.promoCredits' | transloco }}
        </th>
      </ng-container>

      <ng-container matColumnDef="top-free-bets">
        <th mat-header-cell
            class="header-cell two-rows-header border-left border-right"
            [ngStyle]="{'text-align': 'center'}"
            *matHeaderCellDef colspan="3">
          {{ 'reports.promoReport.columnsTop.freeBets' | transloco }}
        </th>
      </ng-container>

      <ng-container matColumnDef="top-jackpots">
        <th mat-header-cell
            class="header-cell two-rows-header border-left border-right"
            [ngStyle]="{'text-align': 'center'}"
            *matHeaderCellDef colspan="6">
          {{ 'reports.promoReport.columnsTop.jackpots' | transloco }}
        </th>
      </ng-container>

      <ng-container matColumnDef="top-tournaments">
        <th mat-header-cell
            class="header-cell two-rows-header border-left"
            [ngStyle]="{'text-align': 'center'}"
            *matHeaderCellDef colspan="2">
          {{ 'reports.promoReport.columnsTop.tournaments' | transloco }}
        </th>
      </ng-container>

      <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
        <th mat-header-cell
            class="header-cell two-rows-header"
            *matHeaderCellDef
            mat-sort-header="{{headerCell(column)}}"
            arrowPosition="after"
            [ngStyle]="{'text-align': alignHeaderCell(column)}"
            [class.header-end]="alignHeaderCell(column) === 'end'"
            [class.header-start]="alignHeaderCell(column) === 'normal'"
            [class.border-left]="border(column)">
          {{ translationColumns[i] | transloco }}
        </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'text-align': element.align(column)}"> {{element.format(column)}} </td>
        <td mat-footer-cell class="footer-cell" *matFooterCellDef [ngStyle]="{'text-align': alignHeaderCell(column)}"> {{total(column)}} </td>
      </ng-container>


      <ng-container matColumnDef="expandedDetail">
        <td mat-cell class="expanded-row" *matCellDef="let element" [attr.colspan]="columns.length">
          <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <!--          Future details component goes here -->
          </div>
        </td>
      </ng-container>

      <tr mat-header-row class="custom-top-header-height" *matHeaderRowDef="['top-date', 'top-promo-credits', 'top-free-bets', 'top-jackpots', 'top-tournaments']"></tr>
      <tr mat-header-row class="custom-bottom-header-height" *matHeaderRowDef="columns; sticky: true"></tr>
      <tr mat-row *matRowDef="let element; let index = dataIndex; columns: columns;"
          class="element-row"
          [class.clicked-row]="expandedElement === element"
          [ngClass]="{alternating: index % 2 === 0}"
          (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      <tr mat-footer-row *matFooterRowDef="columns"></tr>
    </table>
  </div>
</div>





