export class Platform {
  platformID: number
  platformName: string
  extData: string
  billingType: number
  billingPercentage: number

  constructor(platformID: number, platformName: string, extData: string, billingType: number, billingPercentage: number) {
    this.platformID = platformID;
    this.platformName = platformName;
    this.extData = extData;
    this.billingType = billingType;
    this.billingPercentage = billingPercentage;
  }
}
