import {Component, OnInit, ViewChild} from '@angular/core';
import {Chart4Data} from "../../../models/dashboard/chart4-data.model";
import {DashboardService} from "../../../services/dashboard/dashboard.service";

import {ApexChart, ApexNonAxisChartSeries, ApexResponsive, ApexTooltip, ChartComponent,} from 'ng-apexcharts';
import {UnmappedDashboard4Data} from "../../../models/dashboard/unmapped/unmapped-dashboard4-data.interface";
import {LoadingService} from "../../../services/loading/loading.service";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {ThemeService} from "../../../services/theme/theme.service";
import {Chart4Type} from "../../../models/dashboard/chart4-type.enum";
import {UtilsService} from "../../../services/utils/utils.service";

@Component({
  selector: 'app-dashboard-chart4',
  templateUrl: './dashboard-chart4.component.html',
  styleUrls: ['./dashboard-chart4.component.scss']
})
export class DashboardChart4Component implements OnInit {

  public showChart!: Promise<boolean>;
  public date: string = '';

  public data: Chart4Data[] = [];
  @ViewChild('donutChart') chart!: ChartComponent;
  public chartOptions!: ChartOptions;
  public betsBool;

  private betsData: ChartData[] = [];
  private stakeData: ChartData[] = [];

  constructor(private dashboardService: DashboardService,
              private handlingService: HandlingService,
              public loadingService: LoadingService,
              private themeService: ThemeService,
              private utilsService: UtilsService) {
    this.betsBool = dashboardService.getDashboardPieChartType();

    themeService.themeObs.subscribe(newTheme => {
      if (this.chartOptions){
        if (newTheme !== 'light'){
          this.chartOptions.colors = this.themeService.getColors();
          this.chartOptions.tooltip = themeService.getDarkTooltip();
          this.chartOptions.legend.labels.colors = themeService.getDarkText();
        }else{
          this.chartOptions.colors = this.themeService.getColors();
          this.chartOptions.tooltip = themeService.getLightTooltip();
          this.chartOptions.legend.labels.colors = themeService.getLightText();
        }
      }
    })

    this.init().then(result => {
      this.betsData = result[0].map(unmapped => {return {text: unmapped.CurrencyID, value: unmapped.Bets}});
      this.stakeData = result[1].map(unmapped => {return {text: unmapped.CurrencyID, value: +unmapped.StakeEUR.toFixed(2)}});
      this.chartOptions = this.initOptions();
      this.date = this.utilsService.getTodaysDate();
      this.showChart = Promise.resolve(true);
      this.loadingService.incrementLoadingSteps();
    }).catch(error => this.handlingService.error('Trouble fetching data for dashboard 4', error));
  }

  ngOnInit(): void {}

  public initOptions(): any {
    return {
      series: this.getData(),
      chart: {
        type: 'donut',
        width: '100%',
        offsetY: 35,
        animations: {
          enabled: false
        }
      },
      labels: this.getLabels(),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 250
            },
            legend: {
              position: 'bottom'
            }
          }
        },
        {
          breakpoint: 577,
          options: {
            chart: {
              width: 300
            },
            legend: {
              position: 'bottom'
            }
          }
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              width: 330
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ],
      colors: this.themeService.getColors(),
      tooltip: this.themeService.getDefaultTooltip(),
      plotOptions: {
        pie: {
          customScale: 1,
          donut: {
            size: '45%'
          }
        }
      },
      dataLabels: {
        enabled: true
      },
      legend: {
        position: 'bottom',
        labels: {
          colors: this.themeService.getDefaultText()
        }
      }
    };
  }

  public changeData(): void {
    if (this.betsBool){
      this.chartOptions.series = this.stakeData.map(element => element.value);
      this.chartOptions.labels = this.stakeData.map(element => element.text);
      this.betsBool = false;
      this.dashboardService.setDashboardPieChartType(Chart4Type.STAKE);
    }else{
      this.chartOptions.series = this.betsData.map(element => element.value);
      this.chartOptions.labels = this.betsData.map(element => element.text);
      this.betsBool = true;
      this.dashboardService.setDashboardPieChartType(Chart4Type.BETS);
    }
  }

  private async init() {
    return Promise.all([
      this.dashboardService.getChart4Data(4), //bets
      this.dashboardService.getChart4Data(42) //stake
    ]);
  }

  private getData(): number[] {
    if (this.betsBool){
      return this.betsData.map(element => element.value);
    }else{
      return this.stakeData.map(element => element.value);
    }
  }

  private getLabels(): string[] {
    if (this.betsBool){
      return this.betsData.map(element => element.text);
    }else{
      return this.stakeData.map(element => element.text);
    }
  }

}

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  colors: string[];
  tooltip: ApexTooltip;
  plotOptions: any;
  dataLabels: any;
  legend: any;
};

export interface ChartData {
  text: string
  value: number
}
