import {Component, OnInit} from '@angular/core';
import {Chart2Data} from "../../../models/dashboard/chart2-data.model";
import {DashboardService} from "../../../services/dashboard/dashboard.service";
import {UnmappedDashboard2Data} from "../../../models/dashboard/unmapped/unmapped-dashboard2-data.interface";
import {HandlingService} from "../../../services/global-handling/handling.service";
import {LoadingService} from "../../../services/loading/loading.service";

@Component({
  selector: 'app-dashboard-chart2',
  templateUrl: './dashboard-chart2.component.html',
  styleUrls: ['./dashboard-chart2.component.scss']
})
export class DashboardChart2Component implements OnInit {

  public data: Chart2Data = new Chart2Data(0,0,0,0,0,0);

  constructor(private dashboardService: DashboardService,
              private handlingService: HandlingService,
              private loadingService: LoadingService) {
    dashboardService.getChart2Data().subscribe(newData => {
      this.data = this.mapData(newData);
    },error => {
      this.handlingService.error('Trouble fetching data for dashboard 2', error);
    }, () => {
      loadingService.incrementLoadingSteps();
    })
  }

  ngOnInit(): void {
  }

  private mapData(unmapped: UnmappedDashboard2Data[]): Chart2Data{
    let stake = unmapped[0].Stake === null ? 0 : unmapped[0].Stake
    let win = unmapped[0].Win === null ? 0 : unmapped[0].Win
    return new Chart2Data(
      unmapped[0].Bets,
      unmapped[0].Players,
      stake,
      win,
      unmapped[0].Hold,
      unmapped[0].HoldPerc
    );
  }

}
