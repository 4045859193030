<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="container pb-2">
    <div class="row">
      <div class="flex-container">
        <h2 mat-dialog-title style="font-size: 24px; font-weight: lighter;">{{ 'tournaments.manageTournaments.awardsEditorDialog.title' | transloco}}</h2>
        <span class="spacer"></span>
      </div>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="container" style="overflow-y: hidden">
      <ng-container *ngFor="let award of awards?.value">
        <app-award-item [award]="award" [currency]="inputData.tournament.currencyId"></app-award-item>
      </ng-container>
      <mat-divider></mat-divider>
      <div class="row flex-row-reverse">
        <button mat-icon-button color="warn" type="button" (click)="deleteAward()">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button color="primary" type="button" (click)="addAward()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="container my-2 pe-2">
    <div mat-dialog-actions class="d-flex flex-row-reverse w-100">
      <button class="px-4 ms-2" type="submit" mat-flat-button color="primary" [disabled]="!form.valid">{{ 'buttons.saveButton' | transloco }}</button>
      <button class="px-3" type="button" mat-stroked-button color="accent" (click)="onNoClick()">{{ 'buttons.cancelButton' | transloco }}</button>
    </div>
  </div>
</form>
