import {ReportColumns} from "../report-columns.interface";

export class FastballsRoundReportDetails {

  ticketStatus: string
  ticketType: string
  bet: number
  currencyID: string
  numbers: string
  odd6: number
  odd7: number
  odd8: number
  odd9: number
  odd10: number
  odd11: number
  odd12: number
  win: number
  jackpot: number
  paid: number

  columnInfo: ReportColumns[];

  constructor(ticketStatus: string,
              ticketType: string,
              bet: number,
              currencyID: string,
              numbers: string,
              odd6: number,
              odd7: number,
              odd8: number,
              odd9: number,
              odd10: number,
              odd11: number,
              odd12: number,
              win: number,
              jackpot: number,
              paid: number) {
    this.ticketStatus = ticketStatus;
    this.ticketType = ticketType;
    this.bet = bet;
    this.currencyID = currencyID;
    this.numbers = numbers;
    this.odd6 = odd6;
    this.odd7 = odd7;
    this.odd8 = odd8;
    this.odd9 = odd9;
    this.odd10 = odd10;
    this.odd11 = odd11;
    this.odd12 = odd12;
    this.win = win;
    this.jackpot = jackpot;
    this.paid = paid;

    this.columnInfo = [
      {key: 'Ticket status', info: {field: 'ticketStatus', total: this.ticketStatus, format: this.ticketStatus, align: 'normal'}},
      {key: 'Ticket type', info: {field: 'ticketType', total: this.ticketType, format: this.ticketType, align: 'normal'}},
      {key: 'Bet', info: {field: 'bet', total: this.bet, format: this.formatInteger(this.bet), align: 'center'}},
      {key: 'Currency', info: {field: 'currencyID', total: this.currencyID, format: this.formatString(this.currencyID), align: 'center'}},
      {key: 'Numbers', info: {field: 'numbers', total: this.numbers, format: this.formatString(this.numbers), align: 'normal'}},
      {key: 'Odd 6', info: {field: 'odd6', total: this.odd6, format: this.formatMoney(this.odd6), align: 'end'}},
      {key: 'Odd 7', info: {field: 'odd7', total: this.odd7, format: this.formatMoney(this.odd7), align: 'end'}},
      {key: 'Odd 8', info: {field: 'odd8', total: this.odd8, format: this.formatMoney(this.odd8), align: 'end'}},
      {key: 'Odd 9', info: {field: 'odd9', total: this.odd9, format: this.formatMoney(this.odd9), align: 'end'}},
      {key: 'Odd 10', info: {field: 'odd10', total: this.odd10, format: this.formatMoney(this.odd10), align: 'end'}},
      {key: 'Odd 11', info: {field: 'odd11', total: this.odd11, format: this.formatMoney(this.odd11), align: 'end'}},
      {key: 'Odd 12', info: {field: 'odd12', total: this.odd12, format: this.formatMoney(this.odd12), align: 'end'}},
      {key: 'Win', info: {field: 'win', total: this.win, format: this.formatMoney(this.win), align: 'end'}},
      {key: 'Jackpot', info: {field: 'jackpot', total: this.jackpot, format: this.formatMoney(this.jackpot), align: 'end'}},
      {key: 'Paid', info: {field: 'paid', total: this.paid, format: this.formatMoney(this.paid), align: 'end'}}
    ]
  }

  public getFields(): string[] {
    let result: string[] = [];
    this.columnInfo.forEach(value => {
      result.push(value.key);
    })
    return result;
  }

  public field(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.field;
        return;
      }
    })
    return result;
  }

  public format(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.format;
        return;
      }
    })
    return result;
  }



  public align(key: string): any {
    let result: any = 0;
    this.columnInfo.forEach(value => {
      if (value.key === key){
        result = value.info.align;
        return;
      }
    })
    return result;
  }

  public formatInteger(integer: number): string {
    return new Intl.NumberFormat().format(integer);
  }

  public formatMoney(money: number): string {
    return money.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})
  }

  public formatString(string: string): string {
    return string;
  }

  public formatTwoDecimals(integer: number): string {
    return integer.toFixed(2);
  }

}
