import {ChatRoomMessage} from "./chat-room-message.interface";

export class ChatRoom {
  public name: string
  public messages: ChatRoomMessage[]
  public online: number

  constructor(name: string, messages: ChatRoomMessage[], online: number) {
    this.name = name;
    this.messages = messages;
    this.online = online;
  }


}
