import {Injectable} from '@angular/core';
import {Currency} from "../../models/reports/exchange-rates/currency.model";
import {HttpClient} from "@angular/common/http";
import {UnmappedFinancialReport} from "../../models/reports/financial/unmapped/unmapped-financial-report.interface";
import {Observable, of} from "rxjs";
import {UnmappedGameDetails} from "../../models/reports/financial/unmapped/unmapped-game-details.interface";
import {UnmappedExchangeRate} from "../../models/reports/exchange-rates/unmapped/unmapped-exchange-rate.interface";
import {UnmappedPromoReport} from "../../models/reports/promo/unmapped/unmapped-promo-report.interface";
import {FinancialReport} from "../../models/reports/financial/financial-report.model";
import {FinancialReportExcel} from "../../models/reports/financial/financial-report-excel.interface";
import {RocketmanRoundReportExcel} from "../../models/reports/round/rocketman-round-report-excel.interface";
import {RocketmanRoundReport} from "../../models/reports/round/rocketman-round-report.model";
import {PromoReport} from "../../models/reports/promo/promo-report.model";
import {PromoReportExcel} from "../../models/reports/promo/promo-report-excel.interface";
import {ExchangeRate} from "../../models/reports/exchange-rates/exchange-rate.model";
import {ExchangeRatesExcel} from "../../models/reports/exchange-rates/exchange-rates-excel.interface";
import {UnmappedSummaryReport} from "../../models/reports/summary/unmapped/unmapped-summary-report.interface";
import {
  UnmappedSummaryReportDetails
} from "../../models/reports/summary/unmapped/unmapped-summary-report-details.interface";
import {SummaryReport} from "../../models/reports/summary/summary-report.model";
import {SummaryReportExcel} from "../../models/reports/summary/summary-report-excel.interface";
import {PlayersReportSettings} from "../../models/reports/players/players-report-settings.interface";
import {UnmappedPlayersReport} from "../../models/reports/players/unmapped-players-report.interface";
import {CompanySimple} from "../../models/configurations/company-simple.interface";
import {PlayersReport} from "../../models/reports/players/players-report.model";
import {PlayersReportExcel} from "../../models/reports/players/players-report-excel.interface";
import {UnmappedPlatforms} from "../../models/reports/platform-summary/unmapped/unmapped-platforms.interface";
import {
  UnmappedPlatformSummaryReport
} from "../../models/reports/platform-summary/unmapped/unmapped-platform-summary-report.interface";
import {UnmappedCurrencyReport} from "../../models/reports/currency/unmapped/unmapped-currency-report.interface";
import {
  UnmappedCurrencyDetailsReport
} from "../../models/reports/currency/unmapped/unmapped-currency-details-report.interface";
import {CurrencyReport} from "../../models/reports/currency/currency-report.model";
import {CurrencyReportExcel} from "../../models/reports/currency/currency-report-excel.interface";
import {Games} from "../../models/utils/games.enum";
import {FastballsRoundReport} from "../../models/reports/round/fastballs-round-report.model";
import {FastballsRoundReportExcel} from "../../models/reports/round/fastballs-round-report-excel.interface";
import {GoldminesRoundReport} from "../../models/reports/round/goldmines-round-report.model";
import {GoldminesRoundReportExcel} from "../../models/reports/round/goldmines-round-report-excel.interface";
import {RaccoonRushRoundReport} from "../../models/reports/round/raccoonrush-round-report.model";
import {RaccoonRushRoundReportExcel} from "../../models/reports/round/raccoonrush-round-report-excel.interface";
import {UnmappedPlayersDetailsReport} from "../../models/reports/players/unmapped-players-details-report.interface";

import {environment} from "../../../environments/environment";
import {JackpotHistoryReport} from "../../models/reports/jackpot-history/jackpot-history-report.model";
import {
  UnmappedJackpotHistoryReport
} from "../../models/reports/jackpot-history/unmapped-jackpot-history-report.interface";
import {catchError, finalize, map, shareReplay} from "rxjs/operators";
import {UtilsService} from "../utils/utils.service";
import {UnmappedPromoPlayerReport} from "../../models/reports/promo-player/unmapped-promo-player-report.interface";
import {PromoPlayerReport} from "../../models/reports/promo-player/promo-player-report.model";
import {PromoPlayerReportExcel} from "../../models/reports/promo-player/promo-player-report-excel.interface";
import {UnmappedCashbackReport} from "../../models/reports/cashback/unmapped/unmapped-cashback-report.interface";
import {
  UnmappedCashbackDetailsReport
} from "../../models/reports/cashback/unmapped/unmapped-cashback-details-report.interface";
import {
  UnmappedCashbackPlayerReport
} from "../../models/reports/cashback/unmapped/unmapped-cashback-player-report.interface";
import {
  UnmappedCashbackPlayerDetailsReport
} from "../../models/reports/cashback/unmapped/unmapped-cashback-player-details-report.interface";
import {CashbackReport, CashbackReportMapper} from "../../models/reports/cashback/cashback-report.model";
import {
  CashbackDetailsReport,
  CashbackDetailsReportMapper
} from "../../models/reports/cashback/cashback-details-report.model";
import {
  CashbackPlayerReport,
  CashbackPlayerReportMapper
} from "../../models/reports/cashback/cashback-player-report.model";
import {
  CashbackPlayerDetailsReport,
  CashbackPlayerDetailsReportMapper
} from "../../models/reports/cashback/cashback-player-details-report.model";
import {LoadingService} from "../loading/loading.service";
const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient,
              private utilsService: UtilsService,
              private loadingService: LoadingService,
              private cashbackReportMapper: CashbackReportMapper,
              private cashbackDetailsReportMapper: CashbackDetailsReportMapper,
              private cashbackPlayerReportMapper: CashbackPlayerReportMapper,
              private cashbackPlayerDetailsReportMapper: CashbackPlayerDetailsReportMapper) { }

  public getSummaryReports(start: string, end: string, gameId: number): Observable<UnmappedSummaryReport[]>{
    const url = baseUrl.concat('summary-report/all');
    return this.http.get<UnmappedSummaryReport[]>(url, {
      params: {
        dateFrom: start,
        dateTo: end,
        gameId: gameId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getSummaryDetailsReport(id: number, start: string, end: string, gameId: number): Observable<UnmappedSummaryReportDetails[]>{
    const url = baseUrl.concat('summary-report/details');
    return this.http.get<UnmappedSummaryReportDetails[]>(url, {
      params: {
        companyId: id,
        dateFrom: start,
        dateTo: end,
        gameId: gameId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public summaryReportExcelMapper(reports: SummaryReport[]): SummaryReportExcel[]{
    let result: SummaryReportExcel[] = []
    reports.forEach(value => {
      result.push({
        CompanyID: value.companyID,
        CompanyName: value.companyName,
        Bets: value.bets,
        StakeInEUR: value.stakeInEUR,
        Wins: value.wins,
        WinInEUR: value.winInEUR,
        ResultInEUR: value.resultInEUR,
        Percentage: value.percEUR
      })
    })
    return result;
  }

  public getFinancialReports(start: string, end: string, game: string = ''): Observable<UnmappedFinancialReport[]>{
    const url = baseUrl.concat('daily-report/all');
    return this.http.get<UnmappedFinancialReport[]>(url, {
      params: {
        dateFrom: start,
        dateTo: end,
        game: game
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getFinancialDetailsReport(date: string, game: string = ''): Observable<UnmappedGameDetails[]>{
    const url = baseUrl.concat('daily-report/details');
    return this.http.get<UnmappedGameDetails[]>(url, {
      params: {
        forDate: date,
        game: game
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public financialReportExcelMapper(reports: FinancialReport[]): FinancialReportExcel[]{
    let result: FinancialReportExcel[] = []
    reports.forEach(value => {
      result.push({
        Date: value.dateId,
        Bets: value.bets,
        Players: value.playerCount,
        StakeInEUR: value.stateInEUR,
        Wins: value.wins,
        WinInEUR: value.winInEUR,
        ResultInEUR: value.resultInEUR,
        Percentage: value.percEUR
      })
    })
    return result;
  }

  public getCurrencyReports(start: string, end: string): Observable<UnmappedCurrencyReport[]>{
    const url = baseUrl.concat('currency-report/all');
    return this.http.get<UnmappedCurrencyReport[]>(url, {
      params: {
        dateFrom: start,
        dateTo: end
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getCurrencyDetailsReport(start: string, end: string, currencyId: string): Observable<UnmappedCurrencyDetailsReport[]>{
    const url = baseUrl.concat('currency-report/details');
    return this.http.get<UnmappedCurrencyDetailsReport[]>(url, {
      params: {
        dateFrom: start,
        dateTo: end,
        currencyId: currencyId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public currencyReportExcelMapper(reports: CurrencyReport[]): CurrencyReportExcel[]{
    let result: CurrencyReportExcel[] = []
    reports.forEach(value => {
      result.push({
        Currency: value.currencyId,
        BetCount: value.betCount,
        PlayerCount: value.playerCount,
        BetInCurrency: value.betInCurrency,
        BetInEUR: value.betEur,
        WinCount: value.winCount,
        WinInCurrency: value.winInCurrency,
        WinInEUR: value.winEur,
        PaidCount: value.paidCount,
        PaidInCurrency: value.paidInCurrency,
        PaidInEUR: value.paidEur
      })
    })
    return result;
  }

  public getRoundReports(date: string, page: number, pageRows: number, bool: boolean, game: Games): Observable<any[]> {
    let withTickets = 0;
    if (bool){ withTickets = 1; }
    const url = baseUrl.concat('round-report').concat(game).concat('/all');
    return this.http.get<any[]>(url, {
      params: {
        forDate: date,
        justWithTickets: withTickets,
        page: page,
        pageRows: pageRows
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getRoundDetailsReport(roundID: number, game: Games): Observable<any[]> {
    const url = baseUrl.concat('round-report').concat(game).concat('/details');
    return this.http.get<any[]>(url, {
      params: {
        roundId: roundID
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public rocketmanRoundReportExcelMapper(reports: RocketmanRoundReport[]): RocketmanRoundReportExcel[]{
    let result: RocketmanRoundReportExcel[] = []
    reports.forEach(value => {
      result.push({
        ID: value.id,
        RoundOpen: value.roundOpen,
        Multiplier: value.multiplier,
        Bets: value.bets,
        StakeInEUR: value.stakeInEUR,
        Wins: value.wins,
        WinInEUR: value.winsInEUR,
        Hold: value.hold
      })
    })
    return result;
  }

  public fastballsRoundReportExcelMapper(reports: FastballsRoundReport[]): FastballsRoundReportExcel[]{
    let result: FastballsRoundReportExcel[] = []
    reports.forEach(value => {
      result.push({
        ID: value.id,
        RoundOpen: value.roundOpen,
        RoundNumbers: value.roundNumbers,
        Bets: value.bets,
        StakeInEUR: value.stakeInEUR,
        Wins: value.wins,
        WinInEUR: value.winsInEUR,
        Hold: value.hold
      })
    })
    return result;
  }

  public goldminesRoundReportExcelMapper(reports: GoldminesRoundReport[]): GoldminesRoundReportExcel[]{
    let result: GoldminesRoundReportExcel[] = []
    reports.forEach(value => {
      result.push({
        ID: value.id,
        RoundOpen: value.roundOpen,
        Bets: value.bets,
        StakeInEUR: value.stakeInEUR,
        Wins: value.wins,
        WinInEUR: value.winsInEUR,
        Hold: value.hold
      })
    })
    return result;
  }

  public raccoonrushRoundReportExcelMapper(reports: RaccoonRushRoundReport[]): RaccoonRushRoundReportExcel[]{
    let result: RaccoonRushRoundReportExcel[] = []
    reports.forEach(value => {
      result.push({
        ID: value.id,
        RoundOpen: value.roundOpen,
        Bets: value.bets,
        StakeInEUR: value.stakeInEUR,
        Wins: value.wins,
        WinInEUR: value.winsInEUR,
        Hold: value.hold
      })
    })
    return result;
  }

  public getExchangeRates(start: string, end: string, currency: string): Observable<UnmappedExchangeRate[]>{
    const url = baseUrl.concat('currency/exchange-rates');
    return this.http.get<UnmappedExchangeRate[]>(url, {
      params: {
        currencyId: currency,
        fromDate: start,
        toDate: end
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public exchangeRatesExcelMapper(reports: ExchangeRate[]): ExchangeRatesExcel[] {
    let result: ExchangeRatesExcel[] = []
    reports.forEach(value => {
      result.push({
        ExchangeDateTime: value.exchangeDateTime,
        ExchangeRate: value.exchangeRate
      })
    })
    return result;
  }

  public getCashbackReports(start: string, end: string, companyId: number): Observable<CashbackReport[]>{
    this.loadingService.setLoadingSteps(1);
    const url = baseUrl.concat('cashback-report/all');
    return this.http.get<UnmappedCashbackReport[]>(url, {
      params: {
        dateFrom: start,
        dateTo: end,
        companyId: companyId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).pipe(
      map((response: UnmappedCashbackReport[]) => response.map(item => this.cashbackReportMapper.map(item))),
      shareReplay(),
      finalize(() => this.loadingService.incrementLoadingSteps())
    )
  }

  public getCashbackDetailsReport(date: string, currency: string, companyId: number): Observable<CashbackDetailsReport[]>{
    this.loadingService.setLoadingSteps(1);
    const url = baseUrl.concat('cashback-report/details');
    return this.http.get<UnmappedCashbackDetailsReport[]>(url, {
      params: {
        forDate: date,
        currencyId: currency,
        companyId: companyId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).pipe(
      map((response: UnmappedCashbackDetailsReport[]) => response.map(item => this.cashbackDetailsReportMapper.map(item))),
      shareReplay(),
      finalize(() => this.loadingService.incrementLoadingSteps())
    )
  }

  public getCashbackPlayerReports(start: string, end: string, companyId: number): Observable<CashbackPlayerReport[]>{
    this.loadingService.setLoadingSteps(1);
    const url = baseUrl.concat('cashback-report/player');
    return this.http.get<UnmappedCashbackPlayerReport[]>(url, {
      params: {
        dateFrom: start,
        dateTo: end,
        companyId: companyId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).pipe(
      map((response: UnmappedCashbackPlayerReport[]) => response.map(item => this.cashbackPlayerReportMapper.map(item))),
      shareReplay(),
      finalize(() => this.loadingService.incrementLoadingSteps())
    )
  }

  public getCashbackPlayerDetailsReport(start: string, end: string, player: number): Observable<CashbackPlayerDetailsReport[]>{
    this.loadingService.setLoadingSteps(1);
    const url = baseUrl.concat('cashback-report/player-details');
    return this.http.get<UnmappedCashbackPlayerDetailsReport[]>(url, {
      params: {
        dateFrom: start,
        dateTo: end,
        playerId: player
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).pipe(
      map((response: UnmappedCashbackPlayerDetailsReport[]) => response.map(item => this.cashbackPlayerDetailsReportMapper.map(item))),
      shareReplay(),
      finalize(() => this.loadingService.incrementLoadingSteps())
    )
  }

  public getSimpleCompanies(): Observable<CompanySimple[]> {
    this.loadingService.setLoadingSteps(1);
    const url = baseUrl.concat('company/show');
    return this.http.get<CompanySimple[]>(url, {
      params: {
        forComboBox: 1
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).pipe(
      finalize(() => this.loadingService.incrementLoadingSteps())
    )
  }

  public getPromoReports(start: string, end: string, bool: boolean): Observable<UnmappedPromoReport[]> {
    let withTickets = 0;
    if (bool){ withTickets = 1; }
    const url = baseUrl.concat('promo-report/all');
    return this.http.get<UnmappedPromoReport[]>(url, {
      params: {
        fromDate: start,
        toDate: end,
        justWithTickets: withTickets
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public promoReportExcelMapper(reports: PromoReport[]): PromoReportExcel[] {
    let result: PromoReportExcel[] = []
    reports.forEach(value => {
      result.push({
        Date: value.promoDate,
        Count: value.promoCount,
        Credits: value.promoCredits,
        Players: value.promoPlayers,
        FreeTicketsCount: value.freeTicketsCount,
        FreeTicketsMoney: value.freeTicketsMoney,
        FreePlayers: value.freePlayers,
        JackpotCount: value.jackpotCount,
        JackpotMoney: value.jackpotMoney,
        RocketpotCount: value.rocketpotCount,
        RocketpotMoney: value.rocketpotMoney,
        BoosterpotCount: value.boosterpotCount,
        BoosterpotMoney: value.boosterpotMoney,
        TournamentCount: value.tournamentCount,
        TournamentMoney: value.tournamentMoney,
      })
    })
    return result;
  }

  public getPromoPlayerReports(start: string, end: string, companyId: number, page: number, pageRows: number): Observable<PromoPlayerReport[]> {
    const url = baseUrl.concat('promo-player-report/all');
    return this.http.get<UnmappedPromoPlayerReport[]>(url, {
      params: {
        fromDate: start,
        toDate: end,
        companyId: companyId,
        page: page,
        pageRows: pageRows
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).pipe(
      map((unmapped: UnmappedPromoPlayerReport[]) => this.mapPromoPlayerReportData(unmapped))
    )
  }

  public promoPlayerReportExcelMapper(data: PromoPlayerReport[]): PromoPlayerReportExcel[] {
    return data.map(item => {
      return {
        PlayerID: item.playerId,
        UserName: item.username,
        PromoCreditsCount: item.promoCreditsCount,
        PromoCreditsAmount: item.promoCreditsAmount,
        FreeBetsCount: item.freeBetsCount,
        FreeBetsMoney: item.freeBetsMoney,
        JackpotsCount: item.jackpotsCount,
        JackpotsMoney: item.jackpotsMoney,
        TournamentsCount: item.tournamentsCount,
        TournamentsMoney: item.tournamentsMoney,
        TotalBonus: item.totalBonus,
        CurrencyID: item.currencyId
      }
    })
  }

  private mapPromoPlayerReportData(unmapped: UnmappedPromoPlayerReport[]): PromoPlayerReport[] {
    return unmapped.map(item => new PromoPlayerReport(item.PlayerID, item.UserName, item.PromoCreditsCount, item.PromoCreditsAmount, item.FreeBetsCount, item.FreeBetsMoney, item.JackpotsCount, item.JackpotsMoney, item.TournamentsCount, item.TournamentsMoney, item.TotalBonus, item.CurrencyID, item.TotalCount));
  }

  public getCurrencies(): Observable<Currency[]> {
    const url = baseUrl.concat('currency/company/show');
    return this.http.get<Currency[]>(url, {
      params: {
        forComboBox: 1
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getPlayersReport(settings: PlayersReportSettings): Observable<UnmappedPlayersReport[]> {
    const url = baseUrl.concat('players-report/all');
    return this.http.get<UnmappedPlayersReport[]>(url, {
      params: {
        companyId: settings.companyId,
        dateFrom: settings.fromDate,
        dateTo: settings.toDate,
        orderBy: settings.orderBy,
        minTickets: settings.minTickets,
        topResults: settings.topResults,
        promoCredits: settings.promoCredits,
        freeTickets: settings.freeTickets,
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getPlayersReportDetails(fromDate: string, toDate: string, playerId: number): Observable<UnmappedPlayersDetailsReport[]> {
    const url = baseUrl.concat('players-report/details');
    return this.http.get<UnmappedPlayersDetailsReport[]>(url, {
      params: {
        playerId: playerId,
        dateFrom: fromDate,
        dateTo: toDate
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getActiveCompanies(fromDate: string, toDate: string): Observable<CompanySimple[]> {
    const url = baseUrl.concat('company/active');
    return this.http.get<CompanySimple[]>(url, {
      params: {
        dateFrom: fromDate,
        dateTo: toDate,
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public playersReportExcelMapper(reports: PlayersReport[]): PlayersReportExcel[]{
    let result: PlayersReportExcel[] = []
    reports.forEach(value => {
      result.push({
        ID: value.id,
        Username: value.username,
        FirstName: value.firstName,
        LastName: value.lastName,
        SessionsCount: value.sessionsCount,
        Bets: value.bets,
        StakeEUR: value.stakeEUR,
        WinEUR: value.winEUR,
        GGR: value.ggr
      })
    })
    return result;
  }

  public getPlatformSummaryReports(dateFrom: string, dateTo: string, platformId: number): Observable<UnmappedPlatformSummaryReport[]>{
    const url = baseUrl.concat('platforms/reports');
    return this.http.get<UnmappedPlatformSummaryReport[]>(url, {
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
        platformId: platformId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getPlatforms(): Observable<UnmappedPlatforms[]>{
    const url = baseUrl.concat('platforms/all');
    return this.http.get<UnmappedPlatforms[]>(url, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getJackpotHistoryReport(game: string, level: number, dateFrom: string, dateTo: string): Observable<JackpotHistoryReport[]> {
    const url = baseUrl.concat('jackpot-history-report/all');
    return this.http.get<UnmappedJackpotHistoryReport[]>(url, {
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
        game: game,
        jackpotLevel: level
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).pipe(
      map((unmapped: UnmappedJackpotHistoryReport[]) => this.mapJackpotHistoryReport(unmapped)),
      catchError(() => of([])),
      shareReplay()
    )
  }

  private mapJackpotHistoryReport(unmapped: UnmappedJackpotHistoryReport[]): JackpotHistoryReport[] {
    return unmapped.map(item => {
      const date = this.utilsService.timestampToString(item.JackpotTime, "YYYY-MM-DD HH:mm");
      return new JackpotHistoryReport(item.CompanyName, item.GameName, item.UserName, date, item.Level, item.RoundID, item.JackPotValueEUR, item.JackpotLocalCurrency, item.CurrencyID);
    })
  }

  public getSummaryReportTranslationColumns(): string[] {
    return [
      'reports.summaryReport.columns.companyId',
      'reports.summaryReport.columns.companyName',
      'reports.summaryReport.columns.bets',
      'reports.summaryReport.columns.stakeInEUR',
      'reports.summaryReport.columns.wins',
      'reports.summaryReport.columns.winsInEUR',
      'reports.summaryReport.columns.resultInEUR',
      'reports.summaryReport.columns.percEUR']
  }
  public getSummaryReportDetailsTranslationColumns(): string[] {
    return [
      'reports.summaryReport.detailColumns.currencyId',
      'reports.summaryReport.detailColumns.bets',
      'reports.summaryReport.detailColumns.stake',
      'reports.summaryReport.detailColumns.wins',
      'reports.summaryReport.detailColumns.winMoney',
      'reports.summaryReport.detailColumns.result',
      'reports.summaryReport.detailColumns.percEUR']
  }
  public getFinancialReportTranslationColumns(): string[] {
    return [
      'reports.financialReport.columns.dateId',
      'reports.financialReport.columns.bets',
      'reports.financialReport.columns.players',
      'reports.financialReport.columns.stakeInEUR',
      'reports.financialReport.columns.wins',
      'reports.financialReport.columns.winsInEUR',
      'reports.financialReport.columns.resultInEUR',
      'reports.financialReport.columns.percEUR']
  }
  public getFinancialReportDetailsTranslationColumns(): string[] {
    return [
      'reports.financialReport.detailColumns.currencyId',
      'reports.financialReport.detailColumns.bets',
      'reports.financialReport.detailColumns.players',
      'reports.financialReport.detailColumns.stake',
      'reports.financialReport.detailColumns.wins',
      'reports.financialReport.detailColumns.winMoney',
      'reports.financialReport.detailColumns.result',
      'reports.financialReport.detailColumns.percEUR']
  }
  public getCurrencyReportTranslationColumns(): string[] {
    return [
      'reports.currencyReport.columns.currencyId',
      'reports.currencyReport.columns.betCount',
      'reports.currencyReport.columns.playerCount',
      'reports.currencyReport.columns.betInCurrency',
      'reports.currencyReport.columns.betEur',
      'reports.currencyReport.columns.winCount',
      'reports.currencyReport.columns.winInCurrency',
      'reports.currencyReport.columns.winEur',
      'reports.currencyReport.columns.paidCount',
      'reports.currencyReport.columns.paidInCurrency',
      'reports.currencyReport.columns.paidEur']
  }
  public getCurrencyReportDetailsTranslationColumns(): string[] {
    return [
      'reports.currencyReport.detailColumns.companyName',
      'reports.currencyReport.detailColumns.bets',
      'reports.currencyReport.detailColumns.players',
      'reports.currencyReport.detailColumns.stake',
      'reports.currencyReport.detailColumns.stakeEur',
      'reports.currencyReport.detailColumns.wins',
      'reports.currencyReport.detailColumns.winMoney',
      'reports.currencyReport.detailColumns.winMoneyEur',
      'reports.currencyReport.detailColumns.result',
      'reports.currencyReport.detailColumns.resultEur',
      'reports.currencyReport.detailColumns.percEur']
  }
  public getRocketmanRoundReportTranslationColumns(): string[] {
    return [
      'reports.roundReport.games.rocketman.columns.id',
      'reports.roundReport.games.rocketman.columns.roundOpen',
      'reports.roundReport.games.rocketman.columns.multiplier',
      'reports.roundReport.games.rocketman.columns.bets',
      'reports.roundReport.games.rocketman.columns.stakeInEUR',
      'reports.roundReport.games.rocketman.columns.wins',
      'reports.roundReport.games.rocketman.columns.winsInEUR',
      'reports.roundReport.games.rocketman.columns.hold']
  }
  public getRocketmanRoundReportDetailsTranslationColumns(): string[] {
    return [
      'reports.roundReport.games.rocketman.detailColumns.ticketStatus',
      'reports.roundReport.games.rocketman.detailColumns.ticketType',
      'reports.roundReport.games.rocketman.detailColumns.bet',
      'reports.roundReport.games.rocketman.detailColumns.currency',
      'reports.roundReport.games.rocketman.detailColumns.multiplier',
      'reports.roundReport.games.rocketman.detailColumns.win',
      'reports.roundReport.games.rocketman.detailColumns.jackpot',
      'reports.roundReport.games.rocketman.detailColumns.paid']
  }
  public getFastballsRoundReportTranslationColumns(): string[] {
    return [
      'reports.roundReport.games.fastballs.columns.id',
      'reports.roundReport.games.fastballs.columns.roundOpen',
      'reports.roundReport.games.fastballs.columns.roundNumbers',
      'reports.roundReport.games.fastballs.columns.bets',
      'reports.roundReport.games.fastballs.columns.stakeInEUR',
      'reports.roundReport.games.fastballs.columns.wins',
      'reports.roundReport.games.fastballs.columns.winsInEUR',
      'reports.roundReport.games.fastballs.columns.hold']
  }
  public getFastballsRoundReportDetailsTranslationColumns(): string[] {
    return [
      'reports.roundReport.games.fastballs.detailColumns.ticketStatus',
      'reports.roundReport.games.fastballs.detailColumns.ticketType',
      'reports.roundReport.games.fastballs.detailColumns.bet',
      'reports.roundReport.games.fastballs.detailColumns.currency',
      'reports.roundReport.games.fastballs.detailColumns.numbers',
      'reports.roundReport.games.fastballs.detailColumns.odd6',
      'reports.roundReport.games.fastballs.detailColumns.odd7',
      'reports.roundReport.games.fastballs.detailColumns.odd8',
      'reports.roundReport.games.fastballs.detailColumns.odd9',
      'reports.roundReport.games.fastballs.detailColumns.odd10',
      'reports.roundReport.games.fastballs.detailColumns.odd11',
      'reports.roundReport.games.fastballs.detailColumns.odd12',
      'reports.roundReport.games.fastballs.detailColumns.win',
      'reports.roundReport.games.fastballs.detailColumns.jackpot',
      'reports.roundReport.games.fastballs.detailColumns.paid']
  }
  public getGoldminesRoundReportTranslationColumns(): string[] {
    return [
      'reports.roundReport.games.goldmines.columns.id',
      'reports.roundReport.games.goldmines.columns.roundOpen',
      'reports.roundReport.games.goldmines.columns.bets',
      'reports.roundReport.games.goldmines.columns.stakeInEUR',
      'reports.roundReport.games.goldmines.columns.wins',
      'reports.roundReport.games.goldmines.columns.winsInEUR',
      'reports.roundReport.games.goldmines.columns.hold']
  }
  public getGoldminesRoundReportDetailsTranslationColumns(): string[] {
    return [
      'reports.roundReport.games.goldmines.detailColumns.bet',
      'reports.roundReport.games.goldmines.detailColumns.betEUR',
      'reports.roundReport.games.goldmines.detailColumns.fieldPos',
      'reports.roundReport.games.goldmines.detailColumns.odd',
      'reports.roundReport.games.goldmines.detailColumns.win',
      'reports.roundReport.games.goldmines.detailColumns.winEUR'
    ]
  }
  public getRaccoonRushRoundReportTranslationColumns(): string[] {
    return [
      'reports.roundReport.games.raccoonrush.columns.id',
      'reports.roundReport.games.raccoonrush.columns.roundOpen',
      'reports.roundReport.games.raccoonrush.columns.bets',
      'reports.roundReport.games.raccoonrush.columns.stakeInEUR',
      'reports.roundReport.games.raccoonrush.columns.wins',
      'reports.roundReport.games.raccoonrush.columns.winsInEUR',
      'reports.roundReport.games.raccoonrush.columns.hold']
  }
  public getRaccoonRushRoundReportDetailsTranslationColumns(): string[] {
    return [
      'reports.roundReport.games.raccoonrush.detailColumns.bet',
      'reports.roundReport.games.raccoonrush.detailColumns.betEUR',
      'reports.roundReport.games.raccoonrush.detailColumns.fieldPos',
      'reports.roundReport.games.raccoonrush.detailColumns.odd',
      'reports.roundReport.games.raccoonrush.detailColumns.win',
      'reports.roundReport.games.raccoonrush.detailColumns.winEUR'
    ]
  }
  public getPromoReportBottomTranslationColumns(): string[] {
    return [
      'reports.promoReport.columnsBottom.promoDate',
      'reports.promoReport.columnsBottom.promoPlayers',
      'reports.promoReport.columnsBottom.promoCount',
      'reports.promoReport.columnsBottom.promoCredits',
      'reports.promoReport.columnsBottom.freePlayers',
      'reports.promoReport.columnsBottom.freeTicketsCount',
      'reports.promoReport.columnsBottom.freeTicketsMoney',
      'reports.promoReport.columnsBottom.jackpotCount',
      'reports.promoReport.columnsBottom.jackpotMoney',
      'reports.promoReport.columnsBottom.rocketpotCount',
      'reports.promoReport.columnsBottom.rocketpotMoney',
      'reports.promoReport.columnsBottom.boosterpotCount',
      'reports.promoReport.columnsBottom.boosterpotMoney',
      'reports.promoReport.columnsBottom.tournamentCount',
      'reports.promoReport.columnsBottom.tournamentMoney'
    ]
  }
  public getPromoReportTopTranslationColumns(): string[] {
    return [
      'reports.promoReport.columnsTop.date',
      'reports.promoReport.columnsTop.promoCredits',
      'reports.promoReport.columnsTop.freeBets',
      'reports.promoReport.columnsTop.jackpots',
      'reports.promoReport.columnsTop.tournaments',
    ]
  }
  public getPromoPlayerReportTopTranslationColumns(): string[] {
    return [
      'reports.promoPlayersReport.columnsTop.id',
      'reports.promoPlayersReport.columnsTop.username',
      'reports.promoPlayersReport.columnsTop.promoCredits',
      'reports.promoPlayersReport.columnsTop.freeBets',
      'reports.promoPlayersReport.columnsTop.jackpots',
      'reports.promoPlayersReport.columnsTop.tournaments',
      'reports.promoPlayersReport.columnsTop.totalBonus',
      'reports.promoPlayersReport.columnsTop.currency',
    ]
  }
  public getPromoPlayerReportBottomTranslationColumns(): string[] {
    return [
      'reports.promoPlayersReport.columnsBottom.id',
      'reports.promoPlayersReport.columnsBottom.username',
      'reports.promoPlayersReport.columnsBottom.promoCreditsCount',
      'reports.promoPlayersReport.columnsBottom.promoCreditsAmount',
      'reports.promoPlayersReport.columnsBottom.freeBetsCount',
      'reports.promoPlayersReport.columnsBottom.freeBetsMoney',
      'reports.promoPlayersReport.columnsBottom.jackpotsCount',
      'reports.promoPlayersReport.columnsBottom.jackpotsMoney',
      'reports.promoPlayersReport.columnsBottom.tournamentsCount',
      'reports.promoPlayersReport.columnsBottom.tournamentsMoney',
      'reports.promoPlayersReport.columnsBottom.totalBonus',
      'reports.promoPlayersReport.columnsBottom.currency',
    ]
  }
  public getExchangeRatesReportTranslationColumns(): string[] {
    return [
      'reports.exchangeRatesReport.columns.exchangeDateTime',
      'reports.exchangeRatesReport.columns.exchangeRate',
      ]
  }
  public getPlayersReportTranslationColumns(): string[] {
    return [
      'reports.playersReport.columns.id',
      'reports.playersReport.columns.username',
      'reports.playersReport.columns.firstName',
      'reports.playersReport.columns.lastName',
      'reports.playersReport.columns.sessionsCount',
      'reports.playersReport.columns.bets',
      'reports.playersReport.columns.stakeEur',
      'reports.playersReport.columns.winEur',
      'reports.playersReport.columns.ggr',
    ]
  }
  public getPlayersDetailsReportTranslationColumns(): string[] {
    return [
      'reports.playersReport.detailColumns.gameName',
      'reports.playersReport.detailColumns.bets',
      'reports.playersReport.detailColumns.stakeEur',
      'reports.playersReport.detailColumns.winEur',
      'reports.playersReport.detailColumns.ggr',
      'reports.playersReport.detailColumns.ggrPercentage'
    ]
  }

  public getPlatformSummaryReportTranslationColumns(): string[] {
    return [
      'reports.platformSummary.columns.companyId',
      'reports.platformSummary.columns.companyName',
      'reports.platformSummary.columns.bets',
      'reports.platformSummary.columns.stakeInEUR',
      'reports.platformSummary.columns.wins',
      'reports.platformSummary.columns.winsInEUR',
      'reports.platformSummary.columns.resultInEUR',
      'reports.platformSummary.columns.percEUR',
    ]
  }

  public getJackpotHistoryReportTranslationColumns(): string[] {
    return [
      'reports.jackpotHistoryReport.columns.company',
      'reports.jackpotHistoryReport.columns.game',
      'reports.jackpotHistoryReport.columns.player',
      'reports.jackpotHistoryReport.columns.winTime',
      'reports.jackpotHistoryReport.columns.jackpotLevel',
      'reports.jackpotHistoryReport.columns.roundId',
      'reports.jackpotHistoryReport.columns.winEur',
      'reports.jackpotHistoryReport.columns.winLocalCurrency',
    ]
  }

  public getSummaryReportTitle(currentGame: Games) {
    if (currentGame === Games.ROCKET_MAN) {
      return 'reports.summaryReport.games.rocketman.title';
    }else if (currentGame === Games.FAST_BALLS) {
      return 'reports.summaryReport.games.fastballs.title';
    }else if (currentGame === Games.GOLD_MINES) {
      return 'reports.summaryReport.games.goldmines.title';
    }
    return 'reports.summaryReport.title';
  }
}
